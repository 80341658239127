body.mistyGrey_dark {
  // **************************************
  --primary-color: #43ade8; //
  --secondary-color: #fe7268; //
  --danger-color: #f04438; //
  --active-color: #43ade8; //
  --background-color: #121212; //
  --sidebar-bg-color: #232323; //
  --header-bg-color: #121212; //
  --line-and-stroke: #303030; //
  --description: #d0d5dd; //
  --header-text: #ffffff; //
  --table-header-text: #bababa; //
  --input-label-color: #d0d5dd; //
  --sidebar-hover-color: #303030; //
  --mode-switch-button-hover: #bababa; //
  --apply-button-bg: #bababa; //
  --apply-button-text: #000000; //
  --welcome-description: #d0d5dd; //

  --ai-assist-header-color: #121212; //
  --ai-sidebar-color: #0a0a0a; //
  --ai-sidebar-border: #303030; //
  --ai-sidebar-tooltip-bg: #454343; //
  --ai-tooltip-bg: #454343; //
  --ai-prompt-bg-color: linear-gradient(
    132deg,
    rgba(255, 255, 255, 0.09) 0%,
    rgba(82, 80, 80, 0.11) 100%
  ); //
  --ai-prompt-border: transparent; //
  --ai-user-stripe-line: #303030; //

  --ai-user-avatar: #667085; //
  --ai-boat-avatar: #303030;
  --ai-scroll-to-top-icon: #bababa; //
  --ai-scroll-thumb: #454343; //
  --ai-scroll-track: #303030; //
  --ai-generation-response-bg: #454343; //
  --ai-generation-response-border: #303030; //
  --ai-generation-response-text: #ffffff; //

  --clear-conversation: #5f5f5f; //
  // text color
  --primary-text-color: #ffff;
  --secondary-text-color: #bababa; //
  // backgound--color
  --secondary-bg-color: #232323; //
  --twentyfour-bg-color: #bababa; //

  // primary input filed dark mode
  --primary-border-color: #303030; //
  --primary-input-bg-color: #1c1c1c; //
  --primary-input-text-color: #ffffff; //
  --primary-focus-color: #d0d5dd; //
  --primary-placeholder-color: #bababa; //

  --eleventh-text-color: #000000; //

  --primary-course-title-color: #ffffff;
  // sidebar toggle button
  --primary-sidebar-toggle-color: #bababa; //

  // search icon color
  --primary-search-icon-color: #bababa; //

  // dropdown
  --dropdown-bg-color: #1c1c1c; //
  --primary-dropdown-border: #303030; //
  --primary-dropdown-text: #d0d5dd; //
  --primary-dropdown-bg: #303030; //

  //  skleton
  --skleton--bg--color: linear-gradient(
    110deg,
    #373535 8%,
    #303030 18%,
    #303030 33%
  ); //

  // header--drop-down
  --header-drop-bg-color: #303030; //

  // divider
  --primary-divider-color: #303030; //
  --black-trns-border: transparent; //
  --tac-billing-button-border-color: #303030; //

  padding: 0;
  color: #fff;
  background-color: #121212;
  font-size: 16px;
  word-break: break-word;
  font-family: "Inter", sans-serif;
  font-style: normal;
}

body.mistyGrey_light {
  --primary-color: #43ade8; //
  --secondary-color: #fe7268; //
  --danger-color: #f04438; //
  --active-color: #43ade8; //
  --background-color: #ffffff; //
  --sidebar-bg-color: #232323; //
  --header-bg-color: #ffffff; //
  --line-and-stroke: #d0d5dd; //
  --description: #000000; //
  --header-text: #101828; //
  --table-header-text: #303030; //
  --input-label-color: #344054; //
  --sidebar-hover-color: #303030; //
  --mode-switch-button-hover: #000000; //
  --apply-button-bg: #667085; //
  --apply-button-text: #ffffff; //

  --primary-text-color: #000000;
  --secondary-text-color: #667085; //

  --ai-assist-header-color: #121212; //
  --ai-sidebar-color: #0a0a0a; //
  --ai-sidebar-border: #303030; //
  --ai-sidebar-tooltip-bg: #454343; //
  --ai-tooltip-bg: #f5f5f5; //
  --ai-prompt-bg-color: #ffffff; //
  --ai-prompt-border: #d6d6d6; //
  --ai-user-stripe-line: #f5f5f5; //

  --ai-user-avatar: #667085; //
  --ai-boat-avatar: #303030; //
  --ai-scroll-to-top-icon: #000000; //
  --ai-scroll-thumb: #454343; //
  --ai-scroll-track: #303030; //
  --ai-generation-response-bg: #f5f5f5; //
  --ai-generation-response-border: #d0d5dd; //
  --ai-generation-response-text: #40414f; //

  --clear-conversation: #5f5f5f; //

  // primary input filed light mode
  --primary-border-color: #d0d5dd; //
  --primary-input-bg-color: #ffffff; //
  --primary-input-text-color: #101828; //
  --primary-focus-color: #43ade8; //
  --primary-placeholder-color: #667085; //

  // backgound--color
  --secondary-bg-color: #ffff; //
  --twentyfour-bg-color: #667085; //
  --eleventh-text-color: #ffffff; //
  --primary-course-title-color: #000000;

  // sidebar toggle button
  --primary-sidebar-toggle-color: #667085; //

  // search icon color
  --primary-search-icon-color: #667085; //

  // dropdown
  --dropdown-bg-color: #ffffff; //
  --primary-dropdown-border: #d0d5dd; //
  --primary-dropdown-text: #000000; //
  --primary-dropdown-bg: #ffffff; //

  // header--drop-down
  --header-drop-bg-color: #d0d5dd; //

  //  skleton
  --skleton--bg--color: linear-gradient(
    110deg,
    #cdcfd1 8%,
    #d0d5dd 18%,
    #d0d5dd 33%
  ); //

  // divider
  --primary-divider-color: #d0d5dd; //
  --black-trns-border: #d6d6d6; //
  --tac-billing-button-border-color: #d0d5dd; //


  padding: 0;
  background-color: #ffffff;
  font-size: 16px;
  word-break: break-word;
  font-family: "Inter", sans-serif;
  font-style: normal;
}
