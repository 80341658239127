.quicklinkbody {
  padding: 0 24px;
  padding-bottom: 50px;
  overflow-x: auto;
  width: 100%;
  padding-bottom: 50px;
  table {
    border-collapse: collapse;
    width: 100%;
    overflow: scroll !important;
    tr {
      border-bottom: 1px solid var(--primary-table-header-color) !important;
      &:last-child {
        border-bottom: none;
      }
    }
    thead {
      height: 38px;
      th {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;

        color: var(--ql-th);
        &:nth-child(1) {
          text-align: left;
        }
        &:nth-child(2) {
          padding-left: 25px;

          text-align: left;
          padding-right: 25px;
        }
        &:nth-child(3) {
          //   background-color: red;
          text-align: center;
          white-space: nowrap;
          width: 120px;
        }
        &:nth-child(4) {
          text-align: left;
          padding-left: 25px;
        }
        &:nth-child(5) {
          text-align: right;
          color: transparent;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 24px 0;
          vertical-align: text-top;
          &:nth-child(1) {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: var(--third-text-color);
            min-width: 130px;
          }
          &:nth-child(2) {
            padding-left: 25px;
            min-width: 300px;
            max-width: 345px;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: var(--third-text-color);
            padding-right: 25px;
          }
          &:nth-child(3) {
            text-align: center;
            white-space: nowrap;
            img {
              width: 21px;
              height: 21px;
            }
          }
          &:nth-child(4) {
            padding-left: 25px;
            > div,
            a {
              display: block;
              width: 250px;
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              color: #6f6ff1;
              text-align: left;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;
            }
          }
          &:nth-child(5) {
            // background-color: red;
            display: flex;
            justify-content: flex-end;
            width: calc(14px * 2 + 27px * 3);
            button {
              all: unset;
              margin-left: 27px;
              cursor: pointer;
              color: var(--primary-placeholder-color);
              svg {
                height: 14px;
                width: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.custom-table-wrapper {
  // max-height: calc(38px * 10);
  max-height: 60rem;
  overflow: auto;
  padding-right: 20px;
  .custom-table {
    .ct-head {
      height: 38px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: var(--ql-th);
    }
    .ct-body {
      .ct-row {
        padding: 24px 0;
        vertical-align: text-top;
        border-bottom: 1px solid var(--primary-table-header-color) !important;
        background-color: var(--primary-bg-color);
        &:last-child {
          border-bottom: none;
        }
      }
      .ct-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: var(--third-text-color);
        white-space: nowrap;
      }
      .ct-description {
        padding-left: 25px;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        text-align: left;
        color: var(--third-text-color);
      }
      .ct-icon {
        text-align: center;
        white-space: nowrap;
        img {
          width: 21px;
          height: 21px;
        }
      }
      .ct-url {
        padding-left: 25px;
        > div,
        a {
          display: block;
          width: 250px;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: #6f6ff1;
          text-align: left;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
      }

      .ct-action {
        .action-container {
          display: flex;
          justify-content: flex-end;
          width: calc(14px * 2 + 27px * 3 + 30px);
          padding-right: 30px;
          button {
            all: unset;
            margin-left: 27px;
            cursor: pointer;
            color: var(--primary-placeholder-color);
            svg {
              height: 14px;
              width: 14px;
            }
          }
        }
      }
    }
    .ct-head,
    .ct-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;

      .ct-title {
        text-align: left;
        min-width: 130px;
        white-space: nowrap;
      }
      .ct-description {
        padding-left: 25px;
        min-width: 345px;
        max-width: 345px;
        text-align: left;
        padding-right: 25px;
        // background-color: #6f6ff1;
      }
      .ct-icon {
        text-align: center;
        white-space: nowrap;
        width: 120px;
        // background-color: greenyellow;
      }
      .ct-url {
        text-align: left;
        padding-left: 25px;
        width: 275px;
        white-space: nowrap;
        // background-color: aqua;
      }
      .ct-action {
        padding-right: 30px;
        // background-color: pink;
        width: calc(14px * 2 + 27px * 3 + 30px);
        text-align: right;
        color: transparent;
      }
    }
  }
}
