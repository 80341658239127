.card {
  padding: 2rem;
  // padding-bottom: 1.5rem;
  // border: 1px solid red;
  background-color: var(--secondary-bg-color);
  width: 100%;
  height: 100%;
  // filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.25));
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  position: relative;
  .card_title {
    display: block;
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    &.mt1 {
      margin-top: 0.5rem;
    }
  }
}
