.file_upload_progress_section {
  display: flex;
  margin-bottom: 1rem;

  .icon {
    width: 1.7rem;
  }
  .info__section__div {
    margin-left: 1.2rem;
    width: 100%;
    cursor: pointer;
    .row_section {
      display: flex;
      justify-content: space-between;
    }

    .cancel__icon {
      width: 1.5rem;
      cursor: pointer;
    }
    .file_name {
      display: block;
      font-weight: 400;
      font-size: 14px;
      color: var(--ninth-text-color);
      .file_size {
        font-size: 1.2rem;
        color: #667085;
        margin-left: 1rem;
      }
      margin-bottom: 0.7rem;
    }
    .percentage_uploaded {
      font-weight: 300;
      font-size: 12px;
      color: var(--sixth-text-color);
    }
  }
}
