.faq_container {
  padding: 2rem;
  background-color: var(--secondary-bg-color);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  .headings {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 426px) {
      flex-direction: column;
    }

    .left_section {
      display: flex;
      align-items: center;
      svg {
        cursor: pointer;
      }

      .avatar__name {
        width: 2.4rem !important;
        height: 2.4rem !important;
        border-radius: 50%;
        font-weight: 600;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--twentyeight-bg-color);
        text-transform: capitalize;
        margin-right: 1.2rem;
      }

      @media screen and (max-width: 426px) {
        margin: 1rem 0;
      }
      img {
        margin-right: 10px;
      }
      h3 {
        font-size: 18px;
        font-weight: 500;
      }

      .user__image {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
      }
    }
    .right_section {
      display: flex;
      color: var(--ternary-table-header-color) !important;
      .role {
        margin-right: 2.5rem;
        p {
          font-weight: 400;
          font-size: 14px;
        }
      }
      .question_no {
        p {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
}
