.mobile__question__div{
    // background: var( --seventh-bg-color);
    background: var(--alpha-primary-modal-bg-color);
    .mobile__question__wrapper{
        .mobile__question__container{
            .question__wrapper{
                max-height: 60vh;
                overflow-y: auto;
                padding-right: 2rem;
                padding-left: 2rem;
                padding-bottom: 8rem;
                padding-top: 5px;
                &::-webkit-scrollbar {
                    width: 7px;
                  }
                  &::-webkit-scrollbar-thumb {
                    -webkit-border-radius: 10px;
                    border-radius: 10px;
                    background: var( --alpha-scroll-thumb-color);
                    
                  }
                  &::-webkit-scrollbar-track {
                    background:var(--alpha-scroll-track-color);
                
                    -webkit-border-radius: 10px;
                    border-radius: 10px;
                  }
         
                .question__div{
                    margin-top: 15px;
                    padding-bottom: 15px ;
                    border-bottom: 1px solid var(--chatbot-header-underline-color);
                    // &:last-child{
                    //     border-bottom: none;
                    // }
                  
                    .question__item{
                        display: flex;
                        cursor: pointer;
                        .square__messagebox{
                            margin-right: 1.4rem;
                            height: 2.4rem;
                            width: 2.4rem;
                            color: var(--fourth-text-color);
                            stroke-width: 1.5px;
                        }
                        span{
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 24px;
                            color: var(--fourth-text-color);
                        }
                        &:hover{
                            
                            .square__messagebox{
                                color: var(--ternary-table-header-color);
                            }
                            span{
                                color: var(--ternary-table-header-color);
                            }
                        }

                    }
                }

            }
          
        }
      }
    }
  

