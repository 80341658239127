.button__text {
  align-items: center;
  // background-color: var(--secondary-bg-color);
  // background-color: var(--video-record-bg-color);
  // background: var(  --ai-prompt-bg-color);
  background: var(--secondary-color);
  // width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #EF6C00;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  color: #262626;
  color: #fff;
  display: flex;
  gap: 12px;
  // margin-right: 20px;
  padding: 4px 10px;
  @media screen and (max-width:690px) {
    // display: none;
     
   }
  p {
    color: var(--secondary-text-color);
    color: #fff;
    font-weight: 500;
    font-size: 13px;
  }
  .upgradeBtn {
    all: unset;
    border-radius: 4px;
    // height: 25px;
    // width: 110px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0px;
    // color: var(--active-color);
    color: #EF6C00;
    // background: var(--dropdown-bg-color);
    // background: #d0d5dd;
    // background: var(--skleton--bg--color);
    // background: var( --dropdown-bg-color);
    background: #fff;
    // border: 1px solid var(--line-and-stroke);
    cursor: pointer;
  }
}
.mobileBtnText{
  margin: 0 2rem;
  margin-top:7rem;
  margin-bottom: -5rem;
}
