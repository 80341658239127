.learning_table__container {
  min-height: 58.1rem;

  .published {
    display: flex;
    align-items: center;
    color: #3ed0cf;
    .dot {
      color: transparent;
      background-color: #3ed0cf;
      border-radius: 50%;
      width: 0.7rem;
      height: 0.7rem;
    }
  }
  .un_published {
    display: flex;
    align-items: center;
    color: #fe7268;
    .dot {
      color: transparent;
      background-color: #fe7268;
      border-radius: 50%;
      width: 0.7rem;
      height: 0.7rem;
      // margin-right: 1rem;
    }
  }

  .learning_course_table_body {
    .learning_course_name_td {
      padding: 1.5rem;
      // width: 60rem;
    }
    .learning_course_status_td {
      // width: 30rem;
    }
    .learning_course_action_td {
      padding: 1rem;
      // padding-left: 3rem;
    }
    .learning_course_hidden_column {
      // width: 20rem;
    }
  }
}
.learning_course_action_section {
  width: 6rem;
  // border: 1px solid red;
}
