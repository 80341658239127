.archive__drag__table__container {
  .th__width_20 {
    width: 20rem;
  }
  .th__width_45 {
    width: 45rem;
  }
  a{
    color: inherit;
    text-decoration: inherit;
  }
  .date__td {
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 20rem;
  }
  .time__td {
    width: 20rem;
  }
  .topic_td {
    width: 45rem;
  }
  .link_td {
    width: 20rem;

    color: #6f6ff1;
  }
  .drag__team__action__icons {
    color: #bababa;
    .action__section__action {
      margin-left: 5rem;
    }

    .drag_test_4544 {
      width: 9rem;
      padding-left: 2.5rem;
      //
    }
  }
}
