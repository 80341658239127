.footer {
  background-color: #1a1a1a;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  padding: 60px 15%;
  .foot-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    text-transform: uppercase;

    color: #ffffff;
    margin-bottom: 25px;
  }
  .foot1 {
    .foot-social {
      margin-top: 26px;
      display: flex;
      gap: 30px;
      .linkedin-img {
        margin-top: -1px;
      }
    }
    .copyright {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #ffffff;
      width: 194px;
      margin-top: 26px;
    }
  }
  .foot2 {
    .foot-links {
      display: flex;
      flex-direction: column;
      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 33px;
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
  .foot3 {
    .gi-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #bababa;
      margin-bottom: 10px;
    }
    .footer-form {
      width: 322px;
      display: flex;
      .footer-btn {
        all: unset;
        padding: 9px 15px;
        cursor: pointer;
        background-color: #fec97c;
        color: #201f1f;
        width: 77px;
        font-size: 14px;
        border-radius: 0 8px 8px 0;
      }
      input {
        border-radius: 8px 0 0 8px;
      }
    }
  }
}
