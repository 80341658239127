.custom__radio_button {
  all: unset;
  margin-top: 1.3rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  display: flex;
  align-items: center;
  cursor: pointer;

  .unchecked__icon {
    width: 2.3rem;
    height: 2.3rem;
    color: var(--primary-border-color);
  }
  .checked__icon {
    width: 2.3rem;
    height: 2.3rem;
    color: var(--primary-color);
  }
  .radio__icon {
    margin-right: 1.4rem !important;
    height: 2.1rem;
    width: 2.1rem;
    border: 1px solid var(--primary-color);
    border-radius: 50%;
    margin-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.tac {
      border: 1px solid var(--primary-border-color);
      background: var(--primary-input-bg-color);
    }
    &.active {
      border: 1px solid var(--primary-color);
    }
    .checked__icon_yes {
      width: 12px;
      height: 10px;
      color: var(--primary-color);
    }

    .tick {
      background-color: #6f6ff1;
      width: 0.8rem;
      height: 0.8rem;
      color: transparent;
      border-radius: 50%;
      &.tac {
        background-color: #43ade8;
      }
    }
  }
}
