.user__subscription__container {
  .user__subscription__header__section {
    padding: 1.8rem 2rem;
    display: flex;
    align-items: center;
    background-color: var(--secondary-bg-color);
    justify-content: space-between;
  }

  .active__inactinve {
    ul {
      padding-left: 0;
      margin-left: 1.5rem;
    }
    .active__color {
      color: var(--active-color);
    }
    .inactive__color {
      color: var(--secondary-color);
    }
  }

  margin-bottom: 5rem;
}
