.webinar_date_time_header_container {
    padding: 2rem;
    display: flex;
    align-items: center;
    background-color: var(--secondary-bg-color);
    justify-content: space-between;
    @media screen and (max-width: 480px) {
      width: 100%;
      flex-direction: column;
    }
    .right__section {
      @media screen and (max-width: 480px) {
        width: 70%;
        margin-top: 10px;

      }
    }
  }

  