.weekly__learning__table__section {
  .weekly_interval {
    width: 15%;
    vertical-align: text-top;
  }
  .weekly_title {
    width: 25%;
    padding-right: 4rem;
    vertical-align: text-top;
  }
  .weekly_description {
    width: 55%;

    padding: 2rem 0;
    vertical-align: text-top;
  }

  .weekly__action__icons {
    span {
      display: block;

      height: 2rem;
    }
    vertical-align: text-bottom;

    .action__holder {
      display: flex;
      padding-right: 2rem;
      height: 10rem;
    }
    .w_edit__icon {
      width: 2.6rem !important;
      height: 2.6rem !important;
      margin-right: 1rem;
      color: #bababa;
      padding-top: 1rem;

      &:hover {
        color: #6f6ff1 !important;
        cursor: pointer;
      }
    }

    .w_delete__icon {
      width: 2.6rem !important;
      height: 2.6rem !important;
      padding-top: 1rem;
      // margin-top: 1rem;
      color: #bababa;
      cursor: pointer;
      &:hover {
        color: #f04438 !important;
      }
    }
  }
}
.editor__wrapper__width__defin {
  width: 90rem;
}
