.admin__interview__wrapper_header {
  background: var(--secondary-bg-color);
  .table_heading {
    padding: 2rem;
  }
  .add__question__role__heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .add__question__role__btn{

    }
  }
  .text__center__td{
    span{
      display: block;
      margin-left: 6rem;
    }
  }
  // table {
  //   thead {
  //     // padding: 2rem;
  //     tr {
  //       display: flex;
  //       .title {
  //         // padding: 1.5rem 0;
  //         width: 50%;
  //         font-weight: 500;
  //         font-size: 12px;
  //         // color: var(--primary-colorful-text);
  //         color: var(--ternary-table-header-color) !important;
  //         background-color: transparent;
  //       }
  //       .heading__center{
  //         // display: flex;
  //         // justify-content: center;
  //         // align-items: center;
  //       }

  //     }
  //   }
  //   tbody {
  //     tr {
  //       display: flex;
  //       border-top: 1px solid var(--primary-divider-color);
  //       td {
  //         padding: 2rem;
  //         width: 50%;
  //         font-style: normal;
  //         font-weight: 400;
  //         font-size: 14px;
  //         cursor: pointer;
  //       }
  //       .hover {
  //         &:hover {
  //           color: var(--seventh-colorful-text);
  //         }
  //       }
  //       .text_center {
  //         width: 48%;
  //         padding-left: 70px;
  //       }
  //     }
  //   }
  // }
}

@media screen and (max-width: 426px) {
  .admin__interview__wrapper {
    .interviewprep__table {
      .table__body {
        .row {
          .text_center {
            padding-left: 15px;
          }
        }
      }
    }
  }
}
