.admin__sprint__wrapper {
  background: var(--secondary-bg-color);
  padding: 2rem;
  .sprint__table {
    .header {
      display: flex;
      .title {
        padding: 1.5rem 0;
        width: 50%;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: var(--primary-table-header-text);
      }
    }
    .table__body {
      .row {
        display: flex;
        border-top: 1px solid var(--primary-divider-color);
        .data {
          padding: 1.5rem 0;
          width: 50%;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          cursor: pointer;
          &:hover {
            color: var(--seventh-colorful-text);
          }
        }
      }
    }
  }
}
.sprint__data__list__section {
  margin: 0 2rem;
  .header__section__sprint {
    background: var(--primary-bg-color);
    z-index: 40;
    // border: 1px solid red;
    // margin: 2rem 0 3rem 0;
    padding: 1.7rem 0 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;

    .left__sction {
      display: flex;
      align-items: center;
      .back__icon {
        width: 2rem;
        cursor: pointer;
        &:hover {
          color: var(--seventh-colorful-text);
        }
      }
    }
  }
  .input__width {
    width: 60rem;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }

  .select__course__section {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    @media screen and (max-width: 420px) {
      height: 90rem;
      grid-template-columns: 100%;
    }
    max-height: 42rem;
    overflow: auto;
  }
  .content__margin {
    margin: 1rem 0;
  }
  .divder__line{
    height: 1px;
    background: var( --primary-progress-divider);
    margin: 3rem 0;

  }
  .course__lessons{
    all: unset;
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 2rem;


  }
  .selected__courses__desc__box{
    display: flex;
    flex-direction: column;
    gap: 1rem;
   
    // max-height: 45rem;
    // overflow-y:scroll;
    
    .course__desc__box{
      display: flex;
      gap: 1rem;
      align-items: center;
      &:last-child{
        margin-bottom: 3rem;
      }
      .desc__input{
        flex: 1 1 auto;
        .input-container{
          margin-bottom: 0 !important;
        }

      }
      .right__deletebox{
        display: flex;
        .right__box{
          min-height: 3.9rem;
          min-width: 3.9rem;
          background: #6F6FF1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          margin-top: 2rem;
          cursor: pointer;
          margin-right: 1.5rem;
          svg{
            height: 16px;
            width: 16px;
            color: #fff;

          }
        }
        .delete__icon__div{
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg{
            color: #667085;
            stroke-width: 1.5px;
            margin-top: 1rem;
          }

        }

      }

    }

  }
  .footer__section {
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 420px) {
      justify-content: space-between;
    }
  }
}
.add__rearrange__btn__wrapper{
  display: flex;
  gap: 1.5rem;
}
.add__btn{
  width: 9rem;
}
.plans__button__width{
  width: 15rem;
}
.subtext{
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #D0D5DD;
  margin-bottom: 1.8rem;
  margin-top: -1.5rem;

}
.submit__btn__width{
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.draggable__lists{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 66rem;
  max-height: 500px;
  overflow-y: auto;
  // padding-right: 1rem;
  @media screen and (max-width:670px) {
    width: 100%;
    
  }
  &::-webkit-scrollbar{
    width: 0.7rem;

  }
  &::-webkit-scrollbar-thumb{
    width: 0.7rem;
    border-radius: 1rem;
    background: #00000070;
  }
  &::-webkit-scrollbar-track{
    width: 0.7rem;
    border-radius: 1rem;
    background: #0000002B;

  }
  .draggable__list{
    padding: 1.6rem 1.5rem;
    display: flex;
    gap: 1.5rem;
    background: var(--primary-input-bg-color);
    border: 1px solid var(--primary-border-color);
    border-radius: 8px;
    cursor: pointer;

    svg{
      height: 2rem;
      width: 2rem;
      color: #BABABA;
    }
    .draggable__list__name{
      font-family: "Inter";
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
    
  }
}
.modal__contetn__width__drag{
  transform: unset;
}
.modal.active .modal__contetn__width__drag{
  transform: unset;

}