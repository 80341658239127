.learning__path__steppers__wrapper {
  margin: 2rem;
  display: flex;
  justify-content: space-between;
  background: var(--seventh-bg-color);
  padding: 2rem;
  box-shadow: var(--control-box-shadow);
  margin-top: 7rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}
