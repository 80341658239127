.team-card {
  width: 280px;
  height: 236px;
  background: #232323;
  border-radius: 10px;
  padding: 24px;
  .tc-img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 20px;
    img {
      object-fit: cover;
      max-width: 100%;
    }
  }
  .name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */

    text-align: center;

    color: #ffffff;
  }
  .role {
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    text-align: center;

    color: #bababa;
    margin-bottom: 16px;
  }
  .social-link {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
