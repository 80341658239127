.empty_container {
  width: 100%;
  height: 25rem;
  border-top: 1px solid var(--primary-divider-color);
  margin-top: -5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.custombg {
    background-color: var(--secondary-bg-color);
  }
  .empty_description {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    text-align: center;
    .high_light_text {
      color: var(--secondary-progress-bar-bg);
    }

    color: #667085;
  }
}
