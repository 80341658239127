.all__profile__tab__container {
  margin-left: 2rem;
  .tab__container {
    .tab__list__section {
      border-bottom: 1px solid var(--line-and-stroke);
      .single__tab {
        display: inline-block;
        cursor: pointer;
        padding: 2rem;
        background: none;
        outline: none;
        margin-left: -4rem;
        color: var(--primary-input-text-color);

        &.ml-4 {
          margin-left: 4rem;
        }
      }
      .react-tabs__tab--selected {
        border-bottom: 1px solid var(--active-color);
        margin-bottom: -1rem;
        font: bolder;
      }
    }
    .tab__section_no_box {
      margin-top: 2.5rem;
      margin-left: 0;
      margin-right: 2.5rem;
    }
    .tab__section {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      margin-top: 2.5rem;
      margin-left: 0;
      margin-right: 2.5rem;
    }
    .invoice__pagination__section {
      display: flex;
      justify-content: center;
      margin: 4rem 0;
      margin-left: auto;
    }
  }
}
