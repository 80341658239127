.select__members__wrapper {
  cursor: pointer;
  .hide__and__show__section {
    position: relative;
    width: 100%;
  }

  .title {
    display: block;
    // margin-bottom: 0.3rem;
    margin-top: -0.5rem;
    margin-bottom: 0.4rem;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px;
    color: var(--sixth-text-color);
    margin-left: 0.3rem;
  }
  .select__box {
    border-radius: 0.8rem;
    background-color: #1c1c1c;
    height: 4.5rem;
    margin-top: 1rem;
    cursor: pointer;
  }
  .selection__container {
    position: absolute;
    width: 100%;
    top: 1rem;
    border-radius: 0.8rem;
    background-color: var(--ninth-bg-color);
    margin-top: 1rem;
    overflow: hidden;
    // height: 0;
    display: none;
    z-index: 100;
    transition: all 200ms;
    &.active {
      display: block;
      // height: 30rem;
    }

    cursor: pointer;

    .search__box {
      padding: 1rem;
    }
    .options__section {
      overflow-y: scroll !important;
      padding: 0 1.5rem;
      .content__margin {
        margin: 1rem 0;
      }
      min-height: 10rem;
      max-height: 25rem;
    }
  }
}
