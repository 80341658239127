body.light {
  // **********************Added colors ****************
  --gray-text-color: #d1d3d4; //dark #444444
  --gray-text-icon-color: #d1d3d4;
  --controls-bg: #ffffff;
  --control-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  --gray-btn-bg-color: #e2e2e2;
  --feedback-bg-color: #ffffff;
  --wrapup-text-color: #000000;
  --video-record-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  );
  --file-item-border: 1px solid #d6d6d6;
  --dashed-border: 1px dashed #d6d6d6;
  

  //*********PRODUCT MANAGER PAGE COLORS***************
  --course-bg-color: #ffffff;
  --our-course-divider: #d6d6d6;
  --archive-heading-colorful-text: #000000;
  --support-btn-bg-hover-color: #7cc9fe;

  //**********USER NOTIFICATION COLOR*************
  --user-notification-header-markdone-color: #6f6f6f;
  --notification-header-border-color: #d0d5dd;
  --notification-border-bottom-color: #d0d5dd;
  --bell-icon-color: #232323;
  --scrollbar-thumb-background-color: #bababa;
  --first-letter-bg-color: #e4e4e4;
  --notification-feedback-hover-color: #f2f2f2;
  // --scrollbar-trach-bg-color:
  --trial-user-card-bg-color: #f8f8f8;
  --trial-user-card-border-clr: #d6d6d6;

  // ********************A**********************************
  // instructor
  --primary-color: #6f6ff1;
  --secondary-color: #fe7268;
  --success-color: #3ed0cf;
  --danger-color: #f04438;
  --muted-color: #d0d5dd;
  --active-color: #ffc97a;
  --sidebar-bg-color: #232323;
  --header-bg-color: #ffffff;
  --sidebar-hover-color: #2c2c2c;
  --mode-switch-button-hover: #000000;
  --apply-button-bg: #667085;
  --apply-button-text: #ffffff;

  // primary input filed light mode
  --primary-border-color: #d0d5dd;
  --primary-input-bg-color: #fff;
  --primary-input-text-color: #101828;
  --input-label-color: #344054;

  --primary-hover-color: #6f6ff1;
  --primary-focus-color: #6f6ff1;
  --primary-bg-color: #ffff;
  --primary-placeholder-color: #667085;
  --primary-tabs-underline-color: #6f6ff1;

  // backgound--color
  --primary-bg-color: #ffff;
  --secondary-bg-color: #ffff;
  --third-bg-color: #ffff;
  --forth-bg-color: #e4e4e4;
  --fifth-bg-color: #f9fafb;
  --sixth-bg-color: #f7f7f7;
  --seventh-bg-color: #ffffff;
  --eighth-bg-color: #f9fafb;
  --ninth-bg-color: #f9fafb;
  --twelveth-bg-color: #f8f8f8;
  --thirteen-bg-color: #ffffff;
  --fourteen-bg-color: #f5f5f5;
  --fifteen-bg-color: #ffffff;
  --sixteen-bg-color: #ffffff;
  --seventeen-bg-color: #000000;
  --eighteen-bg-color: #f2f2f2;
  --nineteen-bg-color: #f8f8f8;
  --twenty-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  );
  --twentyone-bg-color: #383879;
  --twentytwo-bg-color: #f5f5f5;
  --twentythree-bg-color: #ffffff;
  --twentyfour-bg-color: #667085;
  --twentyfive-bg-color: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.09) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  --twentysix-bg-color: #f5f5f5;
  --twentyseven-bg-color: #ffffff;
  --twentyeight-bg-color: #e4e4e4;
  --twentynine-bg-color: #f2f2f2;
  --thirty-bg-color: #f5f5f5;
  --thirty-one-bg-color: #f2f2f2;
  --thirty-two-bg-color: #bababa;
  --thirty-three-bg-color: #bababa;

  --seotag-bg-color: #f9fafb;
  --tag-bg-color: #f2f2f2;
  // text color
  --primary-text-color: #344054;
  --secondary-text-color: #344054;
  --third-text-color: #000000;
  --fourth-text-color: #000000;
  --fifth-text-color: #040505;
  --sixth-text-color: #667085;
  --seventh-text-color: #667085;
  --eight-text-color: #101828;
  --ninth-text-color: #000000;
  --tenth-text-color: #667085;
  --eleventh-text-color: #ffffff;
  --filter-label-top: #000000;
  --primary-course-title-color: #000;
  // colorful text
  --primary-colorful-text: #6f6ff1;
  --secondary-colorful-text: #ffc97a;
  --third-colorful-text: #43ade8;
  --fourth-colorful-text: #53c4c7;
  --fifth-colorful-text: #ffffff;

  --sixth-colorful-text: #5454f9;

  --seventh-colorful-text: #5454f9;
  --eight-colorful-text: #fe7268;
  --ninth-colorful-text: #6f6ff1;
  --tenth-colorful-text: #6f6ff1;
  --elevtnth-colorful-text: #000000;

  // button color
  --plan-bg-color: #fff;
  --instructor-text-color: #667085;
  --btn-text-color: #201f1f;

  // sidebar toggle button
  --primary-sidebar-toggle-color: #232323;

  // search icon color
  --primary-search-icon-color: #667085;

  // table
  --primary-table-header-color: #f9fafb;
  --secondary-table-header-color: #d0d5dd;
  --ternary-table-header-color: #6f6ff1;
  --primary-table-header-text: #667085;

  --ternary-table-header-color: #6f6ff1;

  // table
  --even-tr-color: #f9fafb;
  --odd-tr-color: #ffffff;
  // pagination color
  --primary-pg-bg-color: #e9e9fd;
  --primary-pg-text-color: #4e7e95;
  --pg-three-dot-color: #667085;

  // audio player track
  --player-slider-bg: #d6d6d6;
  --player-slider-track: #bababa;

  // profile section
  --profile-divider-color: #d0d5dd;
  // progress bar
  --primary-progress-bar-bg: #d0d5dd;
  --secondary-progress-bar-bg: #6f6ff1;
  --secondary-progress-bar-title: #667085;
  // according
  --primary-progress-divider: #d0d5dd;
  --primary-divider: #d0d5dd;
  --secondary-progress-divider: transparent;
  // dropdown
  --dropdown-bg-color: #ffffff;
  --primary-dropdown-border: #d0d5dd;
  --primary-dropdown-text: #667085;
  --primary-dropdown-bg: #ffffff;
  --secondary-dropdown-border: #d0d5dd;
  --primary-box-shadow: rgba(1, 1, 1, 0.05);
  // stepper
  --stepper-bg-color: #e3f3fc;
  --counter--border-color: #d0d5dd;
  --dashed--border-color: #bababa;
  // header--drop-down
  --header-drop-bg-color: #f9fafb;
  --header-drop-divider: #d0d5dd;
  --header-drop-text-color: #101828;
  // select box
  --primary-select-text-color: #101828;

  // divider
  --primary-divider-color: #d0d5dd;
  --third-divider-color: #d6d6d6;
  --fourth-divider-color: #e1e1e1;
  //share certificate
  --share-certificate: #f9fafb;
  //blog
  --single-blog-bg: #f9fafb;
  //interviews-and-webinars
  --iw-duration: #6f6ff1;
  //landing button
  --button-yellow: #fec97c;

  // scroll bar
  --thunmb-color: #bababa;
  --track--color: #d4d4d4;
  --new-thumb: #bababa;
  --new-track: rgba(0, 0, 0, 0.17);
  --filupload--thumb-color: #bababa;

  // chat scroll button icon color
  --chat-scroll-icon-color: #bababa;

  //custom select  box or drop down
  --select-bg-color: #f9fafb;
  --select-search-bg-color: #ffffff;

  // moving ball color
  --ball-bg-color: #eeeeee;

  //team member detail
  --select-border: #d0d5dd;
  --card-back-bg: #f9fafb;
  --baw-card-back-bg: #ffffff;
  --filter-show-text: #667085;
  --darkonly-bg-color: #fff;
  --your-recent-courses: #667085;
  --drop-down-according: #232323;

  --course--tag--title--bg: #ffc97a;
  --course--tag--title: #000000;
  // stepper

  //  skleton
  --skleton--bg--color: linear-gradient(
    110deg,
    #eceef4 8%,
    #f0f1f4 18%,
    #f0f1f4 33%
  );

  // new design
  --black-trns-border: #d0d5dd;
  --black-trns-border2: transparent;
  --toggle-btn-border: #e3f3fc;

  --urc-card-bg: #fff;
  --urc-card-border: #d6d6d6;
  --urc-card-border-bottom: #d6d6d6;
  --cp-user-top-bg: #f8f8f8;
  --button-inner: #ffffff;
  --ql-th: #667085;
  --custom-label-txt: #344054;
  --date-txt-color: #344054;
  --notgivent-text-color: #667085;
  --modal-close-icon-color: #444444;

  //**************TAC COLORS STARTS HERE****************************
  --archive-border-color: #d0d5dd;
  --tac-active-color: #ef6c00;
  --tac-table-header-color: #667085;
  --tac-coupon-title-color: #667085;
  --tac-tab-active-color: #ee6c00;
  --apply-now--view-details-btn-coor: #ee6c00;
  --apply-now-view-details-border-color: #d0d5dd;
  --tac-duration-color: #667085;
  --tac-occupation-color: #667085;
  --response-btn-color: #40414f;

  --download-user-file-bg-color: #98a2b3;
  --tac-billing-button-border-color: #d0d5dd;

  --badge-bg-color: #fdf0e5;
  --premium-course-warning-bg: #e7f4fc;

  //**************TAC COLORS ENDS HERE****************************

  // New  colors
  --new-primary-bg-color: #f8f8f8;
  --new-secondary-bg-color: #ffffff;
  --alpha-ternary-bg-color: #f8f8f8;
  --alpha-primary-gradient-bg-color: #fff;
  --alpha-first-bg-color: #f2f2f2;
  --alpha-second-bg-color: #f2f2f2;
  --alpha-third-bg-color: #d6d6d6;
  --alpha-fourth-bg-color: #e4e4e4;
  --alpha-fifth-bg-color: #bababa;
  --alpha-sixth-bg-color: #141d28;
  --alpha-seventh-bg-color: #f2f2f2;
  --alpha-eighth-bg-color: #303030;
  --alpha-ninth-bg-color: #e4e4e4;
  --alpha-tenth-bg-color: #f5f5f5;
  --alpha-eleventh-bg-color: #f7f7f7;
  --alpha-tweleth-bg-color: #f5f5f5;
  --alpha-thirteen-bg-color: #fff;
  --alpha-fourteen-bg-color: #f8f8f8;
  --alpha-first-letter-bg-color: #e4e4e4;
  --alpha-primary-first-letter-bg-color: #e4e4e4;

  //Input colors
  --alpha-primary-input-bg-color: #fff;
  --alpha-primary-input-border-color: #d0d5dd;
  --alpha-primary-input-text-color: #667085;
  --alpha-secondary-input-borer-color: #d0d5dd;
  --alpha-secondary-input-bg-color: #fff;
  --alpha-ternary-input-border-color: #d0d5dd;
  --alpha-eye-color: #d0d5dd;

  //dropdown colors
  --alpha-primary-dropdown-bg-color: #f9fafb;
  --alpha-primary-dropdown-box-border-color: #d0d5dd;
  --alpha-secondary-dropdown-bg-color: #fff;
  --alpha-ternary-dropdown-bg-color: #f9fafb;

  //Text colors
  --alpha-primary-text-color: #667085;
  --alpha-seconday-text-color: #d1d3d4;
  --alpha-ternary-text-color: #6f6f6f;
  --alpha-fourth-text-color: #8e97a9;
  --alpha-fifth-text-color: #bababa;
  --alpha-sixth-text-color: #000;

  //Button color
  --alpha-disable-btn-bg-color: #e2e2e2;
  --alpha-disable-btn-text-color: #6f6f6f;
  --alpha-primary-btn-bg-color: #f2f2f2;
  --alpha-secondary-btn-bg-color: #ffffff;

  //Scroll bar colors
  --alpha-scroll-thumb-color: #bababa;
  --alpha-scroll-track-color: rgba(0, 0, 0, 0.17);
  --alpha-primary-scroll-thumb-color: #16293e;
  --alpha-primary-track-color: #0f1b28;
  --alpha-secondary-scroll-thumb: #203245;
  --alpha-secondary-scroll-track: #162331;

  //Divider colors
  --alpha-primar-divider-color: #d0d5dd;
  --alpha-secondary-divider-color: #333333;
  --alpha-ternary-divider-color: #d0d5dd;
  --alpha-fourth-divider-color: #242d39;
  --alpha-fifth-divider-color: #d0d5dd;
  --alpha-sixth-divider-color: #e1e1e1;
  --alpha-seventh-divider-color: #d6d6d6;

  //Tooltip color
  --alpha-primary-tooltip-bg-color: #203249;
  --alpha-secondary-ttoltip-bg-color: #203245;
  --alpha-secondary-tooltip:#d5d5dd;

  //Checkbox color
  --alpha-primary-checkbox-border-color: #6f6ff1;

  //Border Color
  --alpha-primary-border-color: #d6d6d6;
  --alpha-secondary-border-color: #d0d5dd;

  //Modal colors
  --alpha-primary-modal-bg-color: #fff;
  --alpha-primary-modal-divider-color: #d0d5dd;
  --alpha-primary-alert-bg-color: #f5f5f5;

  //Notification bg colors
  --notification-bg-color: #fff;
  --notification-hover-color: #f2f2f2;

   // skeleton color 
   --skeleton-bg-clr:hsla(240, 22%, 80%, 1);
   --skeleton-slide-bg-clr:hsla(240, 22%, 92%, 1);

  //Arrow icon color
  --alpha-primary-arrow-color: #000;

  //Theme cards colors
  --alpha-primary-theme-card-bg-color: rgba(0, 0, 0, 0.15);
  --alpha-primary-active-theme-card-border-color: #d0d5dd;
  --alpha-disable-btn-color: #e2e2e2;

  --ai-assist-header-color: #05103a; //
  --ai-sidebar-color: #0e1823; //--
  --ai-sidebar-border: #303538; //--
  --ai-sidebar-tooltip-bg: #203245; //--
  --ai-tooltip-bg: #203245; //--
  --ai-prompt-bg-color: #ffffff; //--
  --ai-prompt-border: #d6d6d6; //--
  --ai-user-stripe-line: #f5f5f5; //--
  --ai-user-avatar: #667085; //--
  --ai-boat-avatar: #303030; //--
  --ai-top-up-btn-bg-color: #f5f5f5;
  --ai-scroll-to-top-icon: #bababa; //--
  --ai-scroll-thumb: #203245; //--
  --ai-scroll-track: #162331; //--
  --ai-generation-response-bg: #f5f5f5; //--
  --ai-generation-response-border: #d0d5dd; //--
  --ai-generation-response-text: #40414f; //--

  --clear-conversation: #8e97a9; //--
  --background-color: #ffff; //--
  --ai-message-list-bg-color: #fff;
  --ai-prompt-active-color: #6f6ff1;

  // Onboarding Popup colors
  --alpha-show-step-popup-bg-color: #fff;
  --alpha-show-step-popup-below-progress-bg-color: #e9ecf0;
  --alpha-back-btn-border-color: #667085;

  //
  --admin-video-processing-border-color: #f0f0f0;

  //new colors || interview prep admin side badge design|| delete all design
  --role-badge-bg-clr:#98A2B3;
  --delete-btn-border-clr:#bababa;

  --tag-color:#98A2B3;

  padding: 0;
  background-color: #fff;
  font-size: 16px;
  word-break: break-word;
  font-family: "Inter", sans-serif;
  font-style: normal;
}
body.dark {
  --modal-close-icon-color: #ffffff;
  // ****************Added colors ****************
  --gray-text-color: #444444;
  --gray-text-icon-color: #ffffff;
  --gray-btn-bg-color: #1c1c1c;
  --controls-bg: #141e2a;
  --heading-text-color: #000000;
  --feedback-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  );
  --wrapup-text-color: #bababa;
  --video-record-bg-color: #141e2a;
  // --file-item-border:1px solid #333333;
  --dashed-border: 1px dashed #333333;

  //*********PRODUCT MANAGER PAGE COLORS***************
  --course-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.08) 2.05%,
    rgba(82, 80, 80, 0.06) 68.52%
  );
  --our-course-divider: #303030;
  --archive-heading-colorful-text: #fe7268;
  --support-btn-bg-hover-color: #00cccc;

  //**********USER NOTIFICATION COLOR*************
  --user-notification-header-markdone-color: #bababa;
  --notification-header-border-color: transparent;
  --notification-border-bottom-color: #303538;
  --bell-icon-color: #ffffff;
  --scrollbar-thumb-background-color: rgba(0, 0, 0, 0.44);
  --first-letter-bg-color: #303538;
  --notification-feedback-hover-color: #2c2c2c;

  --trial-user-card-bg-color: linear-gradient(
    132deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.11) 68.52%
  );
  --trial-user-card-border-clr: transparent;

  // **************************************
  --primary-color: #6f6ff1;
  --secondary-color: #fe7268;
  --success-color: #3ed0cf;
  --danger-color: #f04438;
  --muted-color: #d0d5dd;
  --active-color: #ffc97a;
  --sidebar-bg-color: #232323;
  --header-bg-color: #121212;
  --sidebar-hover-color: #2c2c2c;
  --mode-switch-button-hover: #bababa;
  --welcome-description: #d0d5dd;
  --apply-button-bg: #bababa;
  --apply-button-text: #000000;

  // color: #d0d5dd;
  // backgound--color
  --primary-bg-color: #121212;
  --secondary-bg-color: #232323;
  --third-bg-color: #1c1c1c;
  --forth-bg-color: #232323;
  --fifth-bg-color: #303030;
  --sixth-bg-color: #474747;
  --seventh-bg-color: #232323;
  --eighth-bg-color: #232323;
  --ninth-bg-color: #1c1c1c;
  --twelveth-bg-color: #121212;
  --thirteen-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  );
  --fourteen-bg-color: #2c2c2c;
  --fifteen-bg-color: #0d0d0d;
  --sixteen-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.045) 2.05%,
    rgba(82, 80, 80, 0.057) 68.52%
  );
  --seventeen-bg-color: linear-gradient(
    236.79deg,
    rgba(255, 69, 147, 0.3) 3.16%,
    rgba(255, 231, 19, 0.3) 30.23%,
    rgba(23, 215, 255, 0.3) 46.88%,
    rgba(7, 123, 255, 0.3) 69.78%
  );

  --eighteen-bg-color: #0d0d0d;
  --nineteen-bg-color: #0d0d0d;

  --twentyone-bg-color: #fda82c;
  --twentytwo-bg-color: #303030;
  --twentythree-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  );
  --twentyfour-bg-color: #bababa;
  --twentyfive-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  );
  --twentysix-bg-color: #444444;
  --twentyseven-bg-color: #303030;
  --twentyeight-bg-color: #303538;
  --twentynine-bg-color: #303030;
  --thirty-bg-color: #1c1c1c;
  --thirty-one-bg-color: #303030;
  --thirty-two-bg-color: #232323;
  --thirty-three-bg-color: #ffffff;

  --seotag-bg-color: #ffffff;
  --tag-bg-color: #d0d5dd;

  // primary input filed dark mode
  --primary-border-color: #303538;
  --primary-input-bg-color: #1c1c1c;
  --primary-input-text-color: #ffff;
  --input-label-color: #d0d5dd;
  --primary-hover-color: #d0d5dd;
  --primary-focus-color: #d0d5dd;
  --primary-placeholder-color: #bababa;
  --primary-tabs-underline-color: #ffc97a;

  // text color
  --primary-text-color: #ffff;
  --secondary-text-color: #d0d5dd;
  --third-text-color: #d0d5dd;
  --fourth-text-color: #ffffff;
  --fifth-text-color: #ffff;
  --sixth-text-color: #d0d5dd;
  --seventh-text-color: #ffffff;
  --eight-text-color: #d0d5dd;
  --ninth-text-color: #bababa;
  --tenth-text-color: #ffc97a;
  --filter-label-top: #bababa;
  --eleventh-text-color: #000000;

  --helper-text-color: #bababa;
  // button color
  --plan-bg-color: #d0d5dd;
  --btn-text-color: #888888;

  // colorful text
  --primary-colorful-text: #ffc97a;
  --secondary-colorful-text: #6f6ff1;
  --third-colorful-text: #ffc97a;
  --fourth-colorful-text: #fe7268;
  --fifth-colorful-text: #fec97c;
  --sixth-colorful-text: #fdbd60;
  --seventh-colorful-text: #ffc97a;
  --eight-colorful-text: #53c4c7;
  --ninth-colorful-text: #ffffff;
  --tenth-colorful-text: #fec97c;
  --elevtnth-colorful-text: #6f6ff1;

  // table
  --even-tr-color: #303030;
  --odd-tr-color: #232323;

  --secondary-table-header-color: #303030;
  --ternary-table-header-color: #ffc97a;
  // instructor
  --instructor-text-color: #6f6ff1;

  --primary-course-title-color: #d0d5dd;
  // sidebar toggle button
  --primary-sidebar-toggle-color: #d0d5dd;

  // search icon color
  --primary-search-icon-color: #bababa;

  // table
  --primary-table-header-color: #303030;
  --primary-table-header-text: #ffc97a;
  // pagination color
  --primary-pg-bg-color: #303030;
  --primary-pg-text-color: #ffffff;
  --pg-three-dot-color: #ffffff;

  // audio player track
  --player-slider-bg: #bababa;
  --player-slider-track: #ffffff;

  // progress bar
  --primary-progress-bar-bg: #363636;
  --secondary-progress-bar-bg: #ffc97a;
  --secondary-progress-bar-title: #d0d5dd;

  // according
  --primary-progress-divider: #303030;
  --primary-divider: #303030;
  --secondary-progress-divider: #303030;

  // dropdown
  --dropdown-bg-color: #1c1c1c;
  --primary-dropdown-border: #303538;
  --primary-dropdown-text: #bababa;
  --primary-dropdown-bg: #303030;
  --secondary-dropdown-border: transparent;
  --primary-box-shadow: rgba(0, 0, 0, 0.05);

  // profile section
  --profile-divider-color: #232323;
  // stepper
  --stepper-bg-color: #121212;
  --counter--border-color: #444444;
  --dashed--border-color: #33333f;

  //  skleton
  --skleton--bg--color: linear-gradient(
    110deg,
    rgb(72, 70, 70) 8%,
    #303030 18%,
    #303030 33%
  );

  // header--drop-down
  --header-drop-bg-color: #303030;
  --header-drop-divider: #232323;
  --header-drop-text-color: #ffffff;
  // select box
  --primary-select-text-color: #ffffff;

  // divider
  --primary-divider-color: #303030;
  --secondary-divider-color: #232323;
  --third-divider-color: #333333;
  --fourth-divider-color: #303030;

  //share certificate
  --share-certificate: #1c1c1c;
  //blog-box
  --blog-category-label: #ffc97a;
  //blog
  --single-blog-bg: #232323;
  //interviews-and-webinars
  --iw-duration: #ffc97a;
  //landing button
  --button-yellow: #fec97c;

  // scroll bar
  --thunmb-color: #101010;
  --track--color: #1d1d1d;
  --new-thumb: #52565a;
  --new-track: #29292a;
  --filupload--thumb-color: rgba(0, 0, 0, 0.17);

  // chat scroll button icon color
  --chat-scroll-icon-color: #000000;

  //custom select  box or drop down
  --select-bg-color: #1c1c1c;
  --select-search-bg-color: #232323;
  // moving ball color
  --ball-bg-color: #363636;

  //team member detail
  --select-border: #232323;
  --card-back-bg: #232323;
  --baw-card-back-bg: #232323;
  --filter-show-text: #d0d5dd;
  --darkonly-bg-color: #121212;
  --your-recent-courses: #98a2b3;
  --drop-down-according: #232323;

  --course--tag--title--bg: #232323;
  --course--tag--title: #ffc97a;

  // new design
  --black-trns-border: transparent;
  --black-trns-border2: transparent;
  --toggle-btn-border: #283841;

  --urc-card-bg: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  );
  --urc-card-border: transparent;
  --urc-card-border-bottom: #333333;
  --cp-user-top-bg: #0d0d0d;
  --button-inner: #201f1f;
  --ql-th: #ffc97a;
  --custom-label-txt: #d0d5dd;
  --date-txt-color: #bababa;
  --notgivent-text-color: #bababa;
  // test purpose

  //**************TAC COLORS STARTS HERE****************************
  --archive-border-color: #443e3e;
  --tac-active-color: #43ade8;
  --tac-table-header-color: #8b8c8d;
  --tac-coupon-title-color: #ef6c00;

  --tac-tab-active-color: #43ade8;
  --apply-now--view-details-btn-coor: #667085;
  --apply-now-view-details-border-color: #454545;
  // #454545
  --tac-duration-color: #8b8c8d;
  --tac-occupation-color: #bababa;
  --response-btn-color: #ffffff;

  --download-user-file-bg-color: #52565a;
  --tac-billing-button-border-color: #6f6f6f;

  --badge-bg-color: #1c1c1c;
  --premium-course-warning-bg: #19262e;
  //**************TAC COLORS ENDS HERE****************************

  // New  colors
  --new-primary-bg-color: #08111c;
  --new-secondary-bg-color: #030e1a;
  --alpha-ternary-bg-color: #0a141e;
  --alpha-primary-gradient-bg-color: linear-gradient(
    132deg,
    rgba(255, 255, 255, 0.09) 0%,
    rgba(82, 80, 80, 0.11) 100%
  );
  --alpha-first-bg-color: #141e2a;
  --alpha-second-bg-color: #16293d;
  --alpha-third-bg-color: #243a52;
  --alpha-fourth-bg-color: #16293d;
  --alpha-fifth-bg-color: #16293d;
  --alpha-sixth-bg-color: #141d28;
  --alpha-seventh-bg-color: #192533;
  --alpha-eighth-bg-color: #273747;
  --alpha-ninth-bg-color: #273747;
  --alpha-tenth-bg-color: #203245;
  --alpha-eleventh-bg-color: #203245;
  --alpha-tweleth-bg-color: #1e2834;
  --alpha-thirteen-bg-color: #08111c;
  --alpha-fourteen-bg-color: #030e1a;
  --alpha-first-letter-bg-color: #0b1a2b;
  --alpha-primary-first-letter-bg-color: #182635;
  // 182635
  // #0A141E

  //Input Colors
  --alpha-primary-input-bg-color: #132130;
  --alpha-primary-input-border-color: #212f55;
  --alpha-primary-input-text-color: #9095b1;
  --alpha-secondary-input-borer-color: transparent;
  --alpha-secondary-input-bg-color: #0a1d30;
  --alpha-ternary-input-border-color: #0e1823;
  --alpha-eye-color: #6b7194;

  //Dropdown colors
  --alpha-primary-dropdown-bg-color: #0c192a;
  --alpha-primary-dropdown-box-border-color: #151d33;
  --alpha-secondary-dropdown-bg-color: #13263e;
  --alpha-ternary-dropdown-bg-color: #13263e;

  //Text Colors
  // --alpha-constant-text-color:#8E97A9;
  --alpha-primary-text-color: #8e97a9;
  --alpha-seconday-text-color: #323f4f;
  --alpha-ternary-text-color: #8e97a9;
  --alpha-fourth-text-color: #8e97a9;
  --alpha-fifth-text-color: #8e97a9;
  --alpha-sixth-text-color: #e1e1e1;

  //Button color
  --alpha-disable-btn-bg-color: #0a1421;
  --alpha-disable-btn-text-color: #334a61;
  --alpha-primary-btn-bg-color: #0c2135;
  --alpha-secondary-btn-bg-color: #131d2b;

  //Scroll bar colors
  --alpha-scroll-thumb-color: #16293e;
  --alpha-scroll-track-color: #0f1b28;
  --alpha-primary-scroll-thumb-color: #16293e;
  --alpha-primary-track-color: #0f1b28;
  --alpha-secondary-scroll-thumb: #203245;
  --alpha-secondary-scroll-track: #162331;

  //Divider colors
  --alpha-primar-divider-color: #1b2b3b;
  --alpha-secondary-divider-color: #1b2b3b;
  --alpha-ternary-divider-color: #2a3a4e;
  --alpha-fourth-divider-color: #242d39;
  --alpha-fifth-divider-color: #1c2a3a;
  --alpha-sixth-divider-color: #1b2b3b;
  --alpha-seventh-divider-color: #1b2b3b;

  //Tooltip color
  --alpha-primary-tooltip-bg-color: #203249;
  --alpha-secondary-ttoltip-bg-color: #203245;
  --alpha-secondary-tooltip:#4b4c50;

  //Checkbox colors
  --alpha-primary-checkbox-border-color: #2b435c;

  //Border colors
  --alpha-primary-border-color: transparent;
  --alpha-secondary-border-color: #091521;

  //Modal Colors
  --alpha-primary-modal-bg-color: #0e1823;
  --alpha-primary-modal-divider-color: #152c45;
  --alpha-primary-alert-bg-color: #0c203a;

  //Notification colors
  --notification-bg-color: #0a1a2c;
  --notification-hover-color: #142941;

  //13263e

  // skeleton color 
  --skeleton-bg-clr:rgba(255, 255, 255, 0.1);
  --skeleton-slide-bg-clr:rgba(255, 255, 255, 0.2);

  //Arrow icon color
  --alpha-primary-arrow-color: rgba(255, 255, 255, 0.2);

  //Theme cards colors
  --alpha-primary-theme-card-bg-color: linear-gradient(
    134deg,
    rgba(255, 255, 255, 0.09) 0%,
    rgba(0, 0, 0, 0.11) 100%
  );
  --alpha-primary-active-theme-card-border-color: #43ade8;
  --alpha-disable-btn-color: #1c1c1c;

  --ai-assist-header-color: #05103a; //
  --ai-sidebar-color: #0e1823; //--
  --ai-sidebar-border: #1d2d3f; //--
  --ai-sidebar-tooltip-bg: #203245; //--
  --ai-tooltip-bg: #203245; //--
  --ai-prompt-bg-color: linear-gradient(
    132deg,
    rgba(255, 255, 255, 0.09) 0%,
    rgba(82, 80, 80, 0.11) 100%
  ); //--
  --ai-prompt-border: transparent; //--
  --ai-user-stripe-line: #162331; //--
  --ai-user-avatar: #273747; //--
  --ai-boat-avatar: #203245; //--
  --ai-top-up-btn-bg-color: #203245;
  --ai-scroll-to-top-icon: #000; //--
  --ai-scroll-thumb: #203245; //--
  --ai-scroll-track: #162331; //--
  --ai-generation-response-bg: #203245; //--
  --ai-generation-response-border: #06121f; //--
  --ai-generation-response-text: #ffffff; //--

  --clear-conversation: #8e97a9; //--
  --background-color: #030e1a; //--
  --ai-message-list-bg-color: linear-gradient(
    132deg,
    rgba(255, 255, 255, 0.09) 0%,
    rgba(32, 50, 69, 0.11) 100%
  );
  --ai-prompt-active-color: #ffc97a;

  // Onboarding Popup colors
  --alpha-show-step-popup-bg-color: #203249;
  --alpha-show-step-popup-below-progress-bg-color: #112236;
  --alpha-back-btn-border-color: transparent;

  //

  --admin-video-processing-border-color: #2f2f2f;

  //new colors || interview prep admin side badge design|| delete all design
  --role-badge-bg-clr:#5C5C5C;
  --delete-btn-border-clr:#667085;

  --tag-color:#5C5C5C;

  padding: 0;
  color: #fff;
  background-color: #121212;
  font-size: 16px;
  word-break: break-word;
  font-family: "Inter", sans-serif;
  font-style: normal;

  // test purpose
}
