label {
  display: block;
  margin-bottom: 6px;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--input-label-color);

  font-style: normal;
  line-height: 20px;
  position: relative;
}
.blold__label {
  font-weight: 500;
}
.label__baw {
  color: var(--fourth-text-color);
}
.red_astrick {
  color: #f04438;
}
