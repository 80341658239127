.date__session__boxes{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

    .date__session__box{
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 1rem;
        .date__input{
          .input-container{
            margin-bottom: 0 !important;
          }

        }
        .start__time,.end__time{
            .time__box {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 8px;
                span {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: var(--secondary-text-color);
                }
                input[type="time"] {
                  outline: none;
                  border: none;
                  border: 1px solid var(--primary-border-color);
                  border-radius: 5px;
                  padding: 12px 15px;
                  // color: #fff;
                  color: var(--primary-input-text-color);
                  background: var(--primary-input-bg-color);
                  cursor: text;
                  &:hover,
                  &:focus,
                  &:active {
                    border: 1px solid var(--primary-hover-color);
                  }
                  &::-webkit-calendar-picker-indicator {
                    background-image: url("../../../../assets/icons/clock.svg");
                    cursor: pointer;
                  }
                }
              }

        }

    }
    .remove__button{
      display: flex;
      justify-content: flex-end;
      margin-top: 0.4rem;
      button{
        all: unset;
        cursor: pointer;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #F04438;

      }
    }

}
.dropdwn__apo__session{
  margin-left: 2rem;
  width: 23rem;
}

.modal__footer__div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;
  .button__witdh{
    width: 11rem;
  }
  .buttons{
    display: flex;
    gap: 1rem;

  }
}