.goole__login__info {
  width: calc(50% - 1rem);

  background-color: var(--secondary-bg-color);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  padding: 1.5rem;
  border-radius: 8px;
  display: flex;
  .info__icon__section {
    margin-right: 1rem;
  }

  .info__info__section {
    .top__section {
      display: flex;
      justify-content: space-between;
      span {
        font-weight: 500;
        font-size: 16px;
      }
      .close__icon {
        width: 2rem;
        color: #bababa;
        cursor: pointer;
      }
    }
    p {
      margin-top: 0.5rem;

      font-weight: 400;
      font-size: 14px;
    }
  }
}
