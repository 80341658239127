.team__count {
  padding: 1.8rem;
}
.c__list{
    padding: 1.8rem 0;
    ul{
        padding-left: 1.8rem;
        li{
            font-size: 1.4rem;
        }
    }
}
.no__wrap{
    white-space: nowrap;
}
