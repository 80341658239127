.section-title-blog {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
}

.list-blogs-container {
  padding: 0 25px;
  margin-bottom: 5vh;
  .most-recent-posts-container {
    display: flex;
    @media screen and (max-width: 1000px) {
      flex-wrap: wrap;
    }
    justify-content: space-between;
    margin-bottom: 45px;
    margin-top: 30px;
    gap: 30px;
    .right {
      min-width: 326px;
      display: flex;
      flex-direction: column;
      gap: 35px;
      @media screen and (max-width: 600px) {
        max-width: 100%;
        min-width: 100%;
      }
    }
    .left {
      min-width: 665px;
      @media screen and (max-width: 1000px) {
        min-width: 0;
        max-width: 100%;

        .blog-desc {
          width: 90%;
        }
      }
      @media screen and (max-width: 600px) {
        .bb-img {
          height: 197px;
          max-width: 100%;
        }
      }
    }
  }
}

.all-blog-posts-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 30px;
  .blogbox {
    max-width: 100%;
  }
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
}
