.chating__area__section {
  width: calc(100vw - 27rem);
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
  .messaging__list__area {
    margin-bottom: 14rem;
  }
  .fixed__button__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .fixed__response__stop__button {
      position: fixed;
      bottom: 80px;
      cursor: pointer;
      button {
        all: unset;
        background: var(--ai-generation-response-bg);
        border: 1px solid var(--ai-generation-response-border);
        border-radius: 8px;
        padding: 12px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Inter";
        font-style: normal;
        // cursor: pointer;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 19px;
        color: var(--ai-generation-response-text);
        .tick__icon {
          color: var(--ai-generation-response-text);
          margin-right: 0.5rem;
          margin-top: -2px;
        }
        .refresh__icon {
          height: 1.5rem;
          width: 1.5rem;
          margin-right: 10px;
          color: var(--ai-generation-response-text);
          animation: spin 1s linear infinite;
        }
        img {
          margin-right: 10px;
        }
      }
    }
  }

  .fixed__input__message {
    position: fixed;
    bottom: 0;
    padding: 1rem;
    padding-bottom: 0.5rem;
    background-color: var(--background-color);
    right: 0;
    left: 27rem;
    @media screen and (max-width: 1000px) {
      left: 0;
    }
    .width__padding {
      @media screen and (max-width: 1000px) {
        width: 100%;
      }
    }
  }
}

.chat__scroll__button {
  all: unset;
  cursor: pointer;
  position: fixed;
  bottom: 8rem;
  right: 2.5rem;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ai-user-stripe-line);
  border-radius: 50%;
  .chat_up__icon {
    color: var(--ai-scroll-to-top-icon);
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
}
.chat__scroll__button__style{
  background: var( --ai-top-up-btn-bg-color);
  .chat_up__icon {
    color: var(--ai-scroll-to-top-icon);
  }

}

.mobile__view__nav__bar {
  display: none;
  @media screen and (max-width: 1000px) {
    display: flex;
    width: 100vw;
    // background: #ffffff;
  }
  justify-content: space-between;
  padding: 3.5rem;
  @media screen and (max-width: 650px) {
    padding: 3.5rem 2rem;
  }
  .ai_assist {
    font-weight: 600;
    font-size: 18px;
  }
  .icon {
    width: 4rem;
    @media screen and (max-width: 650px) {
      width: 2rem;
    }
  }
}
