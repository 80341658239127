.pm__user__new__progress__wrapper {
    display: flex;
    justify-content: center;
    // margin: 10rem 0;
    // margin-top: 0;
    padding: 6rem 0;
    background: var(--new-primary-bg-color);
    // margin-bottom: 0;
    @media screen and (max-width:1000px) {
    //   margin: 7rem 0;
    margin: 0;
      padding: 7rem 0;
    }
  
    .pm__user__center_wrapper {
      width: 100rem;
      margin-top: 4rem;
      margin-bottom: 7rem;
      @media screen and (max-width: 1000px) {
        width: 80rem;
        margin: 0;
      }
      @media screen and (max-width: 820px) {
        width: 70rem;
      }
      @media screen and (max-width: 650px) {
        width: 80%;
      }
    }
    .title {
      display: block;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
    //   color: #53C4C7;
      color: var(--eight-colorful-text);
      @media screen and (max-width: 500px) {
        font-size: 32px;
        line-height: 27px;
      }
    }
    .progress__list__sections {
      margin-top: 6rem;
      display: grid;
      grid-template-columns: 32rem 32rem 32rem;
      column-gap: 2rem;
  
      @media screen and (max-width: 1000px) {
        grid-template-columns: auto auto;
      }
      @media screen and (max-width: 820px) {
        grid-template-columns: auto auto;
      }
      @media screen and (max-width: 650px) {
        grid-template-columns: auto;
      }
      .progress__cards__box2{
        margin-top: 6rem;

        @media screen and (max-width:800px) {
          margin-top: 0;
          
        }
      }
      .progress__card {
        height: 16.5rem;
        // width: 32rem;
        min-height: 10rem;
        padding: 2rem;
        margin-bottom: 2rem;
        border: 1px solid var(--black-trns-border);
        background: var(--thirteen-bg-color);
        transition: all 0.2s ease-in-out;
  
        &:hover {
          transform: translateY(-5px);
          .course__name {
            color: var(--seventh-colorful-text);
          }
        }
        .name__div {
          height: 4rem;
        }
        .course__name {
          display: block;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          text-transform: capitalize;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          cursor: pointer;
        }
        .progress_card_divider {
          border-top: 1px dashed var(--third-divider-color);
          margin-top: 2rem;
        }
        .row {
          margin-top: 3rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .progress__div {
            display: flex;
            align-items: center;
  
            .precentage {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              margin-left: 1rem;
            }
          }
          .resume {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #6f6ff1;
            cursor: pointer;
            &:hover {
              color: var(--seventh-colorful-text);
            }
          }
          .completed {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: var(--third-colorful-text);
            cursor: pointer;
            &:hover {
              color: var(--seventh-colorful-text);
            }
          }
        }
      }
    }
  }
  .empty__section__in__progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    border: 1px solid var(--black-trns-border);
    height: 23rem;
    background: var(--thirteen-bg-color);
  
    .empty__description {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      margin: 2rem 0;
    }
  }
  