.header_container {
  padding: 1.8rem 2rem;
  display: flex;
  align-items: center;
  background-color: var(--secondary-bg-color);
  justify-content: space-between;

  .swich__div {
    border-radius: 80px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    // background: #283841;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 5px solid var(--toggle-btn-border);
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #6f6ff1;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #6f6ff1;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .left__section {
    display: flex;
    align-items: center;
    .title__section {
      margin-left: 2rem;
      @media screen and (max-width: 820px) {
        display: flex;
        width: 100%;
        flex-direction: column;
      }
      .hint__text {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #6f6f6f;
        margin-left: 1rem;
        @media screen and (max-width: 820px) {
          margin-left: 0;
        }
      }
    }
    // border: 1px solid red;
  }
  .right__section {
    display: flex;
    align-items: center;

    .search {
      width: 25rem;
      margin-right: 2rem;
    }

    .button__witdh {
      width: 10rem;
    }
  }
  .button__witdh {
    margin-left: auto;
    width: 10rem;
  }
}
