.sitemanager__wrapper {
  background-color: var(--secondary-bg-color);
  padding: 3rem 2.4rem;
  .sitemanager__container {
    .sitemanager__heading {
      span {
        color: var(--fourth-text-color);
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .sitemanager__logo__upload__section {
      display: flex;
      // justify-content: space-between;
      gap: 1.6rem;
      margin-top: 1.8rem;

      .sm__logo__upload__container {
        width: 50%;
        span {
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: var(--secondary-text-color);
          display: block;
          margin-bottom: 6px;
        }
        // form{
        .file__upload__section {
          .file_fieldd {
            width: 100%;
            height: 20rem;
            background-color: var(--primary-input-bg-color);
            border: 1px solid var(--primary-border-color);
            border-radius: 0.8rem;

            .image__section {
              position: relative;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                max-width: 10rem;
              }

              .button__section {
                position: absolute;
                bottom: 1.5rem;
                right: 0;
              }
            }
            .file_input_design {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;
            }
            .logout_icon {
              font-size: 1.5rem;
              cursor: pointer;
              transform: rotate(270deg);
              width: fit-content;
              margin-top: 1rem;
              color: #667085;
            }
            .clik_text {
              cursor: pointer;
              width: fit-content;
              margin-top: 1rem;
              margin-bottom: 0.5rem;
              color: #667085;
              font-family: "Inter";
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }
            .sm__img__size__recomemded {
              display: block;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              text-align: center;
              color: #bababa;
            }
            .filefield {
              display: none;
            }
            .thumbnail__section {
              // border: 1px solid red;
              padding: 1rem;
              padding-bottom: 0;
              .thumbnail__image {
                width: 100%;
                height: 18rem;
                object-fit: cover;
                border-radius: 0.5rem;
                cursor: pointer;
              }
              .remove__image {
                line-height: 17px;
                display: block;
                text-align: center;
                text-decoration-line: underline;
                text-underline-offset: 4px;
                text-align: right;
                color: #fe7268;
                margin-top: 0.6rem;
                cursor: pointer;
              }
            }
          }
        }

        // }
      }
    }
    .sm__theme__wrapper__section {
      margin-top: 2rem;
      .sm__theme__container {
        span {
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: var(--secondary-text-color);
        }
        .sm__theme__card__container {
          margin-top: 1.2rem;
          .sm__theme__cards {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 15px;
            @media screen and (max-width: 1100px) {
              grid-template-columns: repeat(2, 1fr);
            }
            .sm__theme__card {
              // width: 321px;
              border-radius: 8px;
              padding: 1.8rem;
              background: var(--dropdown-bg-color);
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              cursor: pointer;
              position: relative;
              border: 1px solid var(--notification-header-border-color);
              // overflow: hidden;
              margin-bottom: 20px;
              &:hover {
                background: linear-gradient(
                  134deg,
                  rgba(255, 255, 255, 0.09) 0%,
                  rgba(0, 0, 0, 0.11) 100%
                );
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                .sm__theme__preview__btn {
                  visibility: visible;
                  transform: translateX(0);
                }
              }
              .sm__theme__card__heading {
                display: flex;
                justify-content: space-between;
                p {
                  color: var(--fourth-text-color);
                  font-family: "Inter";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;
                }
                span {
                  border-radius: 15px;
                  background: #3ed0cf;
                  color: #fff;
                  padding: 3px 8px;
                  text-align: center;
                  font-family: Inter;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 12px;
                }
              }
              .sm__theme__text {
                color: var(--primary-placeholder-color);
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-top: 0.5rem;
              }
              .sm__theme__image {
                margin-top: 1.7rem;
                img {
                  width: 100%;
                  object-fit: cover;
                }
              }
              .sm__theme__preview__btn {
                width: calc(100% - 3.6rem);
                border-radius: 8px;
                background: #43ade8;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                padding: 8px 0;
                color: #fff;
                outline: none;
                border: none;
                cursor: pointer;
                position: absolute;
                left: 1.8rem;
                bottom: 1rem;
                visibility: hidden;
                // transform: translateY(20px);
                // transition: transform 0.5s;
                // margin: 0 1.8rem;
              }
            }
            .sm__theme__active__card {
              border: 1px solid
                var(--alpha-primary-active-theme-card-border-color);
              background: var(--alpha-primary-theme-card-bg-color);
              // rgba(0, 0, 0, 0.15)
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
          }
        }
      }
    }
    .sm__theme__divide__line {
      height: 1px;
      margin-bottom: 20px;
      width: 100%;
      background: var(--secondary-table-header-color);
    }
    .sm__theme__submit__button {
      // width: 100%;
      display: flex;
      justify-content: flex-end;
      button {
        all: unset;
        border-radius: 8px;
        background: #43ade8;
        border: none;
        outline: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: #fff;
        cursor: pointer;
        padding: 8px 14px;
        .spinner {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          border: 3px solid #ddd;
          border-right-color: rgb(110, 108, 108);
          animation: spin 0.5s linear infinite;
        }
        @keyframes spin {
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
