.circular_progress_bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background-color: rgba($color: #000000, $alpha: 0.7);

  p {
    font-weight: 400;
    font-size: 14px;
  }
}

.circle-bg {
  fill: none;
  stroke: #d0d5dd;
}

.circle-progress {
  fill: none;
  stroke: #fff;
  /* stroke: #12c2e9; */
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle_text {
  font-size: 14px;
  font-weight: bold;
}
