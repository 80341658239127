.banner__section {
  .banner__image {
    width: 100%;
    // height: 45rem;
    height: 450px;
    background-position: center;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;

    @media screen and (max-width: 650px) {
      height: 28rem;
    }
    @media screen and (max-width: 500px) {
      height: 25rem;
    }
    @media screen and (max-width: 400px) {
      height: 23rem;
    }
  }
}
