.manage__assignment__wrapper {
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }
  .select__member__and__group {
    display: flex;
    // margin-top: 3.5rem;
    .user_name {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #121212;
      padding: 8px 10px;
      background: var(--tag-bg-color);
      border-radius: 5px;
    }
    @media screen and (max-width: 420px) {
      flex-direction: column;
    }
    justify-content: space-between;
    .member__colums {
      width: 49%;
      @media screen and (max-width: 420px) {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }
  .single__deadline__container {
    margin-bottom: 1.7rem;
    margin-top: 3rem;
  }
  .date__selection__wrapper {
    .date__section {
      @media screen and (max-width: 420px) {
        flex-direction: column;
        align-items: flex-start !important;
      }
    }
    .radio {
      all: unset;
      margin-top: 1.3rem;
      font-style: normal;

      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      display: flex;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 420px) {
        margin-bottom: 1rem;
      }
      .radio__icon {
        margin-right: 1.4rem !important;
        height: 1.8rem;
        width: 1.8rem;
        border: 1px solid var(--primary-color);
        border-radius: 50%;
        margin-right: 7px;
        display: flex;
        align-items: center;
        justify-content: center;

        .tick {
          background-color: #6f6ff1;
          width: 0.6rem;
          height: 0.6rem;
          color: transparent;
          border-radius: 50%;
        }
      }
    }
    .date__section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .date__label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        display: block;
      }
      .calendar__width {
        width: 20rem;
      }
      // width: 49%;
    }
    .multiple__date__wrapper {
      width: 46%;
      margin-left: 3rem;
      @media screen and (max-width: 820px) {
        width: 65%;
      }
      @media screen and (max-width: 420px) {
        width: 100%;
      }
    }
    .single__date__wrapper {
      width: 46%;
      margin-left: 3rem;
      @media screen and (max-width: 820px) {
        width: 65%;
      }
      @media screen and (max-width: 420px) {
        width: 100%;
      }
    }
    .date__divider {
      border-top: 1px solid var(--primary-divider);
      margin-bottom: 1rem;
    }
  }
  .manage__assignment__divider {
    border-top: 1px solid var(--primary-divider);
    margin: 2rem 0;
  }
  .button__section {
    display: flex;
    width: 9rem;
    margin-left: auto;
  }
}
