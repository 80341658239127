.member__table__section {
  min-height: 50rem;
  .member_table_tr {
    padding: 1rem 0 !important;
  }
  .team__member__info {
    vertical-align: top;
    &.not_admin:hover {
      color: var(--primary-colorful-text);
    }
    .name_and_avatar_section {
      padding: 1.5rem 0;

      // width: 100%;
      display: flex;
      img {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
      }
      .name__section {
        margin-left: 1rem;
        .name {
          display: block;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
        }
        .user_name {
          display: block;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #43ade8;
        }
      }
    }
  }
  .admin {
    vertical-align: top;
    padding-top: 1.5rem;
    text-transform: capitalize;
  }
  .date {
    padding-top: 1.5rem;

    vertical-align: top;
  }
  .course__progress_td {
    vertical-align: top;
    padding-top: 1.5rem;

    .progress__section {
      .total__assigned__course {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        // color: #ffffff;
        color: var(--fourth-text-color);
        margin-bottom: 0.5rem;
      }
      .completed__text__precent {
        display: block;
        font-style: italic;
        font-weight: 300;
        font-size: 12px;
        color: var(--primary-placeholder-color);
        margin-top: 0.5rem;
      }
    }
  }
  .groups__td {
    vertical-align: top;
    padding-top: 1.5rem;

    // padding: 1rem 0;
    // .group__list__name {
    // }
    .group__name {
      display: flex;
      align-items: center;
      // color: #fff;
      text-transform: capitalize;
      color: var(--fourth-text-color);
      margin-bottom: 0.5rem;
      .dot {
        color: transparent;
        // background-color: #fff;
        background-color: var(--fourth-text-color);
        border-radius: 50%;
        width: 0.7rem;
        height: 0.7rem;
        margin-right: 1rem;
      }
    }

    .view__more__groups__section {
      position: relative;
      .view__more__text {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #6f6ff1;
        margin-left: 2rem;
        margin-bottom: 1.5rem;
        cursor: pointer;
        // margin-top: -0.1rem;
      }
      &:hover {
        .group__list__card {
          display: block;
        }
      }
      .group__list__card {
        display: none;
        min-width: 16rem;
        padding: 2rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        position: absolute;
        background: var(--fifth-bg-color);
        border: 1px solid var(--profile-divider-color);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 8px;

        left: -2rem;
        z-index: 100;
      }
    }
  }
  .add_assignment {
    padding-top: 1.5rem;

    vertical-align: top;

    color: #6f6ff1;
    text-align: right;
    padding-right: 2rem;

    .resend__button_div {
      width: 12.8rem;
      margin-left: auto;
      margin-top: 1rem;
      margin-right: -2px;
      margin-bottom: 2rem;
    }
  }
}
