.cancel__subscription__container {
  .cancel__subscription__desc {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #d0d5dd;
    margin-top: -0.5rem;
  }
  .cancel__sub__divider {
    border-top: 1px solid var(--primary-divider-color);
    margin: 2rem 0 2.5rem 2rem;
  }
  .cancel__subscription__button__section {
    width: 100%;
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
    .confirm__button {
      margin-right: 1rem;
      width: 13rem;
    }
  }
}
