.team__menber__header {
  padding: 1.8rem 2rem;
  display: flex;
  align-items: center;
  background-color: var(--secondary-bg-color);
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
  .invited__member__text {
    font-size: 14px;
    font-weight: 300;
    color: var(--notgivent-text-color);
  }
  .right__section {
    display: flex;
    @media screen and (max-width: 480px) {
      width: 100%;
      flex-direction: column;
      margin: 0.5rem;
      align-items: center !important;
    }
    .search {
      width: 25rem;
      margin-right: 2rem;
    }

    .button__witdh {
      width: 12rem;
      @media screen and (max-width: 480px) {
        margin-right: 10rem;
        margin-top: 1.5rem;
      }
    }
  }
}
