.profile-header-container {
  padding: 0 1.5rem;
  .connect-gohighlevelbox{
    padding: 2.3rem 2.4rem;
    background-color: var(--secondary-bg-color);
    margin-bottom: 2.5rem;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    // margin-top: 20px;
    .connect-text{
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
    }
    .connect-desc{
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      margin-top: 15px;
      padding: 18px 24px;
      background: var(--primary-input-bg-color);
      border: 1px solid var(--primary-border-color);
      color: var(--input-label-color);
      border-radius: 8px;
    }
    .connect-btn-wrapper{
      margin-top: 2rem;
      .btn-width-handle{
        width: 9rem;
        margin-left: auto;
        display: block;
      
      }
      .disconnect-btn-width{
        width: 11rem;
      }

    }
  }
}
.profile__row {
  display: flex;
  justify-content: space-between;
}
.profile_card__container {
  width: calc(50% - 1rem);
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-bottom: 2rem;
  }
  .card_wrapper {
    background-color: var(--secondary-bg-color);
    padding: 2.4rem 2.3rem;
    margin: 20px auto;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    @media screen and (max-width: 500px) {
      margin-bottom: 0px;
    }
  }
  .card__wrapper__div{
    background: var( --alpha-primary-modal-bg-color);
  }
  .mt-0 {
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
  .mb-0 {
    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }
  }
}
.profile__header__links {
  border-bottom: 1px solid var(--profile-divider-color);
  padding: 7px 0;
  margin: 0 1rem;
}
.profile__topography {
  display: block;
  font-weight: 500;
  font-weight: 1.8rem;
  margin-bottom: 1.5rem;
}
.profile_link {
  text-decoration: none;
  // color: #fff;
  color: var(--fifth-text-color);
  font-weight: 500;
  font-size: 1.6rem;
  padding: 10px 20px;
  border-bottom: 1px solid var(--secondary-progress-bar-bg);
}
.upload__image {
  text-decoration: underline;
}
.forms-section-user {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
.button__witdh {
  margin-left: auto;
  width: 10rem;
}
.button__witdhh {
  margin-left: auto;
  width: 20rem;
}

.description-subscription {
  font-weight: 100;
  border: 1px solid #303030;
  color: #fff;
  margin: 10px auto;
  padding: 1rem;
}

.subscription-desc {
  width: 95%;
}
