.invite__member__section {
  padding: 2rem;
  padding-top: 0;

  .inivte__member__page__topography {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    &.mt_2 {
      margin-top: 2rem;
    }
    .invited__member__text {
      font-size: 14px;
      font-weight: 300;
      color: var(--notgivent-text-color);
      text-transform: capitalize;
    }
  }
  .uppercase {
    text-transform: uppercase;
  }
  .navigation__section {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    .back__button {
      width: 2rem;
      margin-left: -0.6rem;
      cursor: pointer;
    }
  }

  .card__input__sections {
    padding: 0 0.5rem;
    .member__info__text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    .email__input__section {
      margin-top: 1rem;
      .helper__text {
        display: block;
        margin-top: 2rem !important;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        // color: #d0d5dd;
        color: var(--primary-placeholder-color);
      }
      .error__text {
        display: block;
        margin-top: 2rem !important;
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        color: #f04438;
        margin-bottom: -1.5rem;
      }
    }
    .row {
      width: 100%;

      display: flex;
      @media screen and (max-width: 820px) {
        flex-direction: column;
      }
      .left__section {
        margin-top: 2rem;
        width: 50%;
        @media screen and (max-width: 820px) {
          width: 100%;
        }
        .mb_2 {
          margin-bottom: 1.5rem;
        }
        .radio {
          all: unset;
          margin-top: 1.3rem;
          font-style: normal;

          font-weight: 400;
          font-size: 14px;
          text-align: right;
          display: flex;
          align-items: center;
          cursor: pointer;
          .radio__icon {
            margin-right: 1.4rem !important;
            height: 1.8rem;
            width: 1.8rem;
            border: 1px solid var(--primary-color);
            border-radius: 50%;
            margin-right: 7px;
            display: flex;
            align-items: center;
            justify-content: center;

            .tick {
              background-color: #6f6ff1;
              width: 0.6rem;
              height: 0.6rem;
              color: transparent;
              border-radius: 50%;
            }
          }
        }
        .hint {
          width: 40rem;
          display: block;
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          // color: #d0d5dd;
          color: var(--primary-placeholder-color);
          margin-left: 3rem;
          text-align: justify;
          @media screen and (max-width: 820px) {
            width: 100%;
            padding-right: 5rem;
          }
        }
      }
      .right__section {
        margin-top: 2rem;

        width: 50%;
        @media screen and (max-width: 820px) {
          width: 100%;
        }
        .mb_2 {
          margin-bottom: 1.5rem;
        }
      }
    }

    .footer__section {
      .assignment__section {
        border-top: 1px solid var(--primary-divider-color);
        border-bottom: 1px solid var(--primary-divider-color);
        padding: 2rem 0;
        margin: 2rem 0;
        .according__header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .according__spinner {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            background-color: var(--third-bg-color);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--ninth-text-color);
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
            .down_spinner {
              width: 2rem;
              &.active {
                transform: rotate(180deg);
                margin-bottom: 0.3rem;
              }
            }
          }
        }
        .member__according__content {
          height: 0;
          overflow: hidden;
          transition: 200ms ease-in-out;
          &.active {
            height: auto;
            transition: 200ms ease-in-out;
            @media screen and (max-width: 820px) {
              height: 100rem;
            }
          }
          .according__content__row {
            display: flex;
            @media screen and (max-width: 820px) {
              flex-direction: column;
            }
            // justify-content: space-between;

            .content__title {
              display: block;
              margin-top: 1.5rem;
              margin-bottom: 1.4rem;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              color: #ffc97a;
            }

            .content__margin {
              margin: 1.5rem 0;

              &.extra {
                width: 50%;
                margin: 0.8rem 0 !important;
                @media screen and (max-width: 420px) {
                  width: 100%;
                }
                // width: 40rem;
                // flex-basis: 1;
              }
            }
            .content__column__left {
              width: 40%;
              @media screen and (max-width: 820px) {
                width: 100%;
              }
              display: block;
              .left__scrollabe__content {
                max-height: 42rem;
                width: 80%;
                overflow: auto;
                @media screen and (max-width: 820px) {
                  width: 100%;
                }
              }
            }

            .content__column__right {
              width: 65%;
              @media screen and (max-width: 820px) {
                width: 100%;
              }
              .scrollable_row {
                width: 100%;
                display: grid;
                grid-template-columns: 50% 50%;
                max-height: 42rem;
                overflow: auto;
                @media screen and (max-width: 420px) {
                  height: 90rem;
                }
              }
            }
          }
        }
      }
      .single__deadline__container {
        margin-bottom: 1.5rem;
      }

      .date__selection__wrapper {
        .mb-3 {
          margin-bottom: 2rem;
        }
        .radio {
          all: unset;
          margin-top: 1.3rem;
          font-style: normal;

          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: right;
          display: flex;
          align-items: center;
          cursor: pointer;
          .radio__icon {
            margin-right: 1.4rem !important;
            height: 1.8rem;
            width: 1.8rem;
            border: 1px solid var(--primary-color);
            border-radius: 50%;
            margin-right: 7px;
            display: flex;
            align-items: center;
            justify-content: center;

            .tick {
              background-color: #6f6ff1;
              width: 0.6rem;
              height: 0.6rem;
              color: transparent;
              border-radius: 50%;
            }
          }
        }
        .date__section {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media screen and (max-width: 820px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
          .date__label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            display: block;
          }
          .calendar__width {
            width: 20rem;
          }
          // width: 49%;
        }
        .multiple__date__wrapper {
          width: 46%;
          margin-left: 3rem;
          @media screen and (max-width: 820px) {
            width: 80%;
          }
          @media screen and (max-width: 420px) {
            width: 100%;
          }
        }
        .single__date__wrapper {
          width: 46%;
          margin-left: 3rem;
          @media screen and (max-width: 820px) {
            width: 80%;
          }
          @media screen and (max-width: 420px) {
            width: 100%;
          }
        }
        .date__divider {
          border-top: 1px solid var(--primary-divider-color);
          margin-bottom: 1rem;
        }
      }
    }
    .button__section {
      display: flex;
      margin-left: auto;
      width: 14rem;
      //
    }
    .button__top__divider {
      border-top: 1px solid var(--primary-divider-color);
      margin: 2rem 0;
    }
  }
}
.team_left_section {
  width: 50%;
  .radio {
    all: unset;
    margin-top: 1.3rem;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    display: flex;
    align-items: center;
    cursor: pointer;
    .radio__icon {
      margin-right: 1.4rem !important;
      height: 2.1rem;
      width: 2.1rem;
      border: 1px solid var(--primary-color);
      border-radius: 50%;
      margin-right: 7px;
      display: flex;
      align-items: center;
      justify-content: center;

      .tick {
        background-color: #6f6ff1;
        width: 0.8rem;
        height: 0.8rem;
        color: transparent;
        border-radius: 50%;
      }
    }
  }
}
