.filter__section {
  padding: 0 2rem;
}
.premium__course__warning__wrapper{
  padding: 0 2rem;
  margin-top: 2rem;
  .premium__course__warning{
    padding: 10px;
    display: flex;
    border: 1px solid var(--active-color);
    border-radius: 8px;
    align-items: center;
    gap: 8px;
    position: relative;
    .warning__icon{
      min-height: 16px;
      min-width: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        height: 100%;
        width: 100%;
        color: var(--active-color);
      }
    }
    p{
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0px;
      color: var( --twentyfour-bg-color);
    }
    .warning__close{
      cursor: pointer;
      position: absolute;
      right: 10px;
      color: var(--header-text);
    }
  
  }

}

.list__all__courses {
  padding: 2rem;
  display: grid;
  justify-content: space-between;
  grid-row-gap: 1.5rem;
  grid-template-columns: calc(50% - 0.8rem) calc(50% - 0.8rem);
  @media screen and (max-width: 480px) {
    width: 100%;
    grid-template-columns: 100%;
  }
}

.coursefilter-container {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CourseFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .left__section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    @media screen and (max-width: 480px) {
      flex-direction: column;
      // align-items: flex-start;
    }
    .filter__tag {
      color: var(--secondary-text-color);
      font-size: 1.4rem;
      font-weight: 400;
      cursor: pointer;
    }
    .reset__tag {
      all: unset;
      // margin-left: 20px;
      color: var(--secondary-color);
      font-size: 1.4rem;
      font-weight: 400;
      cursor: pointer;
    }
    .dropdown__width_sm {
      width: 15rem;
    }
    .dropdown__width {
      width: 22rem;
      @media screen and (max-width: 768px) {
        width: 18rem;
      }
      @media screen and (max-width: 480px) {
        width: 32rem;
        margin: 1rem 0;
      }
    }
  }
  .right__section {
    .search_filter {
      width: 18rem;
      @media screen and (max-width:1100px) {
        // margin-top: 1rem;
        
      }
      @media screen and (max-width: 768px) {
        // margin-top: 2rem;
      }
      @media screen and (max-width: 480px) {
        width: 32rem;
        margin: 1rem 0;
        width: 100%;
      }
    }
  }
  .search__right_section{
    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
}
.handle__res__course{
  width: 100%;
  @media screen and (max-width:1190px) {
    gap: 1rem;
    
  }
}
// @media screen and (max-width: 480px) {

.dropdown-container {
  // margin-left: 13px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.courses_empty_screen_wrapper {
  width: 100%;
  padding: 0 2rem;
}
