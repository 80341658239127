.member__detail__header__section {
  .naigation_section {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    .back__button {
      width: 2rem;
      margin-left: -0.6rem;
      cursor: pointer;
    }
    .member__detail__topography {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .card__padding__section {
    .top__section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 820px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
      }
      @media screen and (max-width: 420px) {
        align-items: flex-start;
      }
      .name_and_avatar_section {
        width: 100%;
        padding: 1.5rem 0;
        display: flex;
        align-items: center;
        img {
          width: 6rem;
          height: 6rem;
          border-radius: 50%;
        }
        .name__section {
          margin-left: 1rem;
          .name {
            display: block;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
          }
          .user_name {
            display: block;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #43ade8;
            @media screen and (max-width: 820px) {
              width: 50rem;
            }
            @media screen and (max-width: 420px) {
              width: 30rem;
            }
          }
        }
      }
      .simple__title {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: var(--primary-colorful-text);
      }
      .simple__text {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        text-transform: capitalize;
        &.num {
          font-size: 24px;
        }
      }
      .group__section {
        .group__name {
          width: 100%;
          display: flex;
          // align-items: center;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          margin: 0.1rem 0;
          text-transform: capitalize;
          .dot {
            color: transparent;
            background-color: var(--fourth-text-color);
            border-radius: 50%;
            width: 0.7rem !important;
            height: 0.7rem !important;
            margin-right: 1rem;
            margin-top: 0.7rem;
          }
        }
      }
      .assigned__course__section {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 420px) {
          align-items: flex-start;
          margin-bottom: 1.5rem;
        }
      }

      .course__porgress__section {
        .total__assigned__course {
          display: block;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 0.5rem;
        }
        .completed__text__precent {
          display: block;
          font-style: italic;
          font-weight: 300;
          font-size: 12px;
          color: var(--sixth-text-color);
          margin-top: 0.5rem;
        }
      }
    }
    .header__divider__section {
      margin-top: 1rem;
      margin-bottom: 2rem;
      border-top: 1px solid var(--primary-divider-color);
    }
    .bottom__section {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left__edit__section {
        display: flex;
        align-items: center;
        color: #6f6ff1;
        cursor: pointer;
        .edit__icon {
          width: 1.5rem;
          margin-right: 1rem;
        }
      }
      .right__acive__section {
        color: var(--sixth-text-color);
        font-style: italic;
        font-weight: 300;
        font-size: 12px;
      }
    }
  }
}
