.noresult-found {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.nrf-img {
  height: 145px;
  width: 145px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  background: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.08) 2.05%,
    rgba(82, 80, 80, 0.06) 68.52%
  );
}

.dark {
  .nrf-image-light {
    display: none;
  }
}
.light {
  .nrf-image-dark {
    display: none;
  }
}
