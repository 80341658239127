.chatbot__section__header__wrapper{
    .chatbot__section__header__container{
        padding: 24px;
        background: var( --alpha-primary-modal-bg-color);
        .chatbot__header{
            display:flex;
            align-items: center;
            justify-content: space-between;
            .mobile__view__menu__icon{
                display: none;
                @media screen and (max-width:1000px) {
                    display: block;
                    height: 2rem;
                    width: 2rem;
                    cursor: pointer;
                    color: var(--fourth-text-color);
                    &:hover{
                        color: #7f7f7f;
                    }
                }
            }
            span{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: var(--fourth-text-color);
            }
            .close{
                height: 2.2rem;
                width: 2.2rem;
                cursor: pointer;
                color: var(--fourth-text-color);
                &:hover{
                    color: #7f7f7f;
                }
            }

        }
    }
    .chatbot__header__underline{
        height: 1px;
        width: 100%;
        // background:  #303239;
        background: var(--alpha-fifth-divider-color);
    }
   
}