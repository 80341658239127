.archive__class__table__container {
  width: calc(100% - 27.3rem - 3.4rem);

  border: 1px solid var(--black-trns-border);

  @media screen and (max-width:1100px) {
    width: 100%;
    
  }
  @media screen and (max-width: 820px) {
    overflow: scroll;
  }
  @media screen and (max-width: 620px) {
  //  padding: 0 2rem;
  }
  .search__filter__wrapper{
    display: flex;
    justify-content: flex-end;
    // align-items: center;
    .searchbar__width__handle{
      .searchbar{
        .search__input{
          background: var(--alpha-primary-input-bg-color) !important;
          // border: 1px solid var(--alpha-ternary-input-border-color) !important;
        }
      }

    }
  }
  .secect__section {
    // background: var(--eighteen-bg-color);
    background: var(--new-primary-bg-color);
    padding: 10px 20px;
    border-bottom: 1px solid var(--alpha-primar-divider-color);
    position: relative;
    @media screen and (max-width: 700px) {
      width: 700px !important;
      // overflow-x: scroll !important;
      // overflow-y: hidden !important;
    }

    .title__section {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;

      .custom_date_clear_section {
        visibility: hidden;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 220px;
        padding: 0 20px;
        top: 265px;
        .calendaer__button {
          cursor: pointer;
          font-weight: 400;
          font-size: 14px;
          color: #216ba5;
        }
        &.active {
          visibility: visible;
        }
      }
      .react-datepicker-wrapper {
        width: 210px !important;
      }

      .react-datepicker {
        width: 220px !important;
        height: 265px;
        overflow: hidden;
        position: absolute;
        top: 35px;
        right: 0;
        .react-datepicker__current-month {
          font-size: 12px;
        }
        .react-datepicker__day-name {
          font-size: 11px;
          width: 25px;
          margin-top: 5px;
        }
        .react-datepicker__month-container {
          width: 100%;
          height: 230px;
          overflow: hidden;
        }

        .react-datepicker__week {
          margin: 5px 0;
        }
      }
      .react-datepicker__day {
        width: 25px;
        height: 25px;
        font-size: 11px !important;
        padding: 3px;
      }

      .selected__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        cursor: pointer;
      }
      .down__icon {
        width: 20px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
}

.div__talbe__section {
  width: 100%;
  // padding: 0 10px;
  @media screen and (max-width: 700px) {
    width: 700px !important;
    overflow: scroll !important;
  }

  .table__heading__section {
    display: flex;
    // background: var(--eighteen-bg-color);
    background: var(--new-primary-bg-color);

    .heading__title {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
    .date {
      width: 250px;
      // border: 1px solid red;
    }
    .topic {
      width: 480px;
      // border: 1px solid red;
      padding-left: 0px;
    }
    .link {
      width: 130px;
      text-align: start !important;
      white-space: nowrap;
      // padding-left: 0px;
      // border: 1px solid red;
      @media screen and (max-width:800px) {
        padding-left: 0px;        
      }
    }
  }
  .table__body__section {
    background: var(--thirteen-bg-color);
    max-height: calc(63.3px * 7.8);
    max-height: 325.5px;
    // min-height: 345.5px;
    overflow-y: auto;

    .table__row__section {
      display: flex;

      &.dark {
        border-width: 0 0 1px 0;
        border-style: solid;
        border-image:linear-gradient(207deg, rgba(255, 69, 147, 0.20) 0%, rgba(255, 231, 19, 0.20) 40.63%, rgba(23, 215, 255, 0.20) 65.63%, rgba(7, 123, 255, 0.20) 100%) 1;
        
      }
      &.light {
        border-bottom: 1px solid rgba(214, 214, 214, 0.4);
      }

      padding: 10px 0;
      margin: 0 20px;
      &:last-child {
        border-bottom: none;
      }
      .table__data {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        // border:1px solid green;
        .anchor {
          text-decoration: none;
          color: inherit;
        }
        .topic__width__handle{
          @media screen and (max-width:800px) {
            padding-left: 5px;
            
          }
        }
        .link__width__handle{
          padding-left: 13px;
          text-align: start;
          a{
            white-space: nowrap;
          }
          @media screen and (max-width:800px) {
            padding-left: 15px;
            
          }
        }

        .date {
          display: block;
        }
        .time {
          display: block;
          font-size: 13px;
          margin-top: 2px;
        }
        &:first-child {
          // width: 245px;
          width: 241px;
        }
        &:nth-child(2) {
          width: 480px;
          // padding-left: 160px;
          padding-right: 40px;
        }
        &:last-child {
          width: 13rem;
          // width: 10.4rem;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #6f6ff1;
          cursor: pointer;
          // padding-left: 3.5rem;
          text-align: right !important;
          &:hover {
            color: #fec97c;
          }
        }
      }
      // .topic__content{
      //   padding-left: 20px;
      // }
    }

  }
  .archive__empty__wrapper{
    background: var(--thirteen-bg-color);
    .archive__empty__container{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 30rem;
      height: 325.5px;
      p{
        margin-top: 2rem;
      }
    }

  }
  .custom__scrollbar__new {
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: var(--alpha-scroll-thumb-color);
    }
    &::-webkit-scrollbar-track {
      background-color: var(--alpha-scroll-track-color);
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }
  }
}
.example-custom-input {
  all: unset;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 15rem;
  // padding: 1rem;
  height: 3.6rem;
  background: var(--alpha-primary-input-bg-color);
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  color: var(--alpha-primary-input-text-color);
  margin-right: 1.5rem;
  // justify-content: flex-end;
  svg{
    // all: unset;
    margin-right: 0.5rem;
    height: 1.6rem;
    width: 1.6rem;
    stroke-width: 1.5px;
  }
}

.filter__section{
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  .filter__text{
    all: unset;
    display: block;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    // margin-top: 1rem;
    margin-right: 1rem;
    color: var(--input-label-color);

  }
  .reset__btn{
    button{
      all: unset;
      display: block;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #FE7268;
      cursor: pointer;
    }
  }
}
.date__filter__wrapper{
    display: flex;
  align-items: center;

}
.react-datepicker__close-icon {
  margin-right: 22px !important;
}
.react-datepicker__triangle {
  display: none !important;
}
