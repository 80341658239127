.referenceform {
  //   background-color: red;
  .form2 {
    display: flex;
    gap: 10px;
    min-height: 64px;
    > div {
      width: calc(100% / 2 - 5px);
    }
  }
  .reference_image_section {
    .upload_avatar_sec {
      display: flex;
      align-items: center;
      //   background-color: red;
      img {
        height: 64px;
        width: 64px;
      }
      .image__functionality {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .upload_image {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          /* identical to box height, or 167% */
          text-decoration-line: underline;

          color: #6f6ff1;
          cursor: pointer;
        }
        .remove_image {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          /* identical to box height, or 167% */

          text-decoration-line: underline;

          color: #f04438;
          cursor: pointer;
        }
      }
    }
  }
}
