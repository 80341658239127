.ForgetPasswordFrom__container {
  .recover__text {
    display: block;
    color: var(--input-label-color);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 6px;
    // margin-left: 5px;
  }
  .choose_email {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;

    text-align: center;

    color: #ffffff;
    margin-bottom: 3rem;
  }
  button {
    margin-bottom: 20px;
  }
  .return-to-signin {
    margin-top: 50px;
    display: grid;
    place-items: center;
    a {
      color: var(--primary-color);
      text-decoration: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
