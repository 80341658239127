@mixin secondaryButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--seventh-colorful-text);
  color: var(--odd-tr-color);
  padding: 8px 14px;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  outline: none;
  border: none;
  font-weight: 500;
  cursor: pointer;
}
.audio__video__response__radio {
  .response__type__wrapper{
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    .response__type {
      display: flex;
      gap: 13px;
      cursor: pointer;
     .out__circle{
      height: 16px;
      width: 16px;
      border: 2px solid #FFC97A;
      border-radius: 50%;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      .inner__circle{
        height: 8px;
        width: 8px;
        background: #FFC97A;
        border-radius: 50%;
  
      }
  
     }
      label {
        font-family: "Inter";
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;
      }
    }

  }

  .audio__video__response__footer{
    display: flex;
    justify-content: flex-end;
    gap: 2.4rem;
    margin-top: 4.8rem;
    button{
      @include secondaryButton;
      &.cancelBtn{
        background: #F04438;
        color: #fff;
      }
    }
  }
}

.response__type__modal__width{
  width: 400px;
  .modal__body{
    width: 100%;
  }
  
}