.user__invoice__history__container {
  .user__invoice__history__header__section {
    padding: 1.8rem 2rem;
    display: flex;
    align-items: center;
    background-color: var(--secondary-bg-color);
    justify-content: space-between;

    .right__section {
      display: flex;
      align-items: center;

      .search__box {
        width: 25rem;
        margin-right: 2rem;
        @media screen and (max-width: 450px) {
          width: 80%;
          // margin-top: 1.2rem;
          margin: 0;
        }
      }
    }
  }
  .download__td {
    width: 2rem;
    padding: 2rem;
    margin-left: auto;
    a {
      margin-right: -15px;
    }
    .download__button {
      width: 1.8rem;
      cursor: pointer;
      color: #bababa;
    }
  }
  .user__invoice__history__table {
    min-height: 45.2rem;
  }
}
