.myaccount__wrapper{
  background: var(--new-secondary-bg-color);
  .myaccount {
    //   padding: 130px 12rem;
    margin: 0 auto;
    padding-top: 130px;
    width: calc(553px * 2 + 20px);
    max-width: 90%;
    background: var(--new-secondary-bg-color);
    @media screen and (max-width:800px) {
      padding: 7rem 0;
      
    }
    .profile__picture__section {
      display: none;
      margin-top: 20px;
      margin-bottom: 60px;
    }
    .profile-header-container {
      padding: 0;
    }
    .forms-section-user {
      justify-content: space-between;
      gap: 20px;
  
      .profile_card__container {
        width: 100%;
      }
    }
  
    @media screen and (max-width: 750px) {
      .forms-section-user {
        flex-direction: column;
        .profile_card__container {
          width: 100%;
        }
      }
    }
  }

}

