.lerner_empty{
    // height: 100%;
    width: 100%;
    height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;

    .img{
        width: 100%;
        height: 100%;
        flex-direction: column;

        display: flex;
        justify-content: center;
        align-items: center;

        p{
            margin-top: 15px;
            font-size: 14px;
            font-weight: 400;
            color: var(--fourth-text-color);
        }
    }


}