.feedback__warning__wrapper {
  //   height: 6rem;
  display: flex;
  align-items: center;
  padding: 2rem;
  background: var(--thirteen-bg-color);
  margin-bottom: 1.4rem;
  margin-top: 0.5rem;
  border: 1px solid var(--black-trns-border);
  @media screen and (max-width:500px) {
    align-items: start;
    
  }
  .alert__icon {
    width: 3rem;
    color: #f04438;
    margin-right: 1rem;
  }
  span {
    font-weight: 500;
    font-size: 14px;
  }
  .cancel__icon {
    width: 2rem;
    color: #bababa;
    margin-left: auto;
    cursor: pointer;
    @media screen and (max-width:500px) {
        margin-top: -13px;
        margin-right: -10px;
        
    }
  }
}
