.subscriptions_info__section {
  margin-top: 1.5rem;
  color: var(--secondary-text-color);
  font-weight: 500;
  font-size: 14px;
  background: var(--primary-input-bg-color);
  border: 1px solid var(--primary-dropdown-border);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 2rem;
}
.upgrade__button {
  width: 17rem;
  margin-top: 2rem;
  margin-left: auto;
}
