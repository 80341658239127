.session__tr{
    .oc__date__td{
        width: 35rem;
        padding: 2rem 0;
        vertical-align: text-top;
        padding-left: 2rem;
        @media screen and (max-width:1180px) {
            width: 15rem;
            
        }

    }
    .oc__session__td{
        width: 35rem;
        padding: 2rem 0;
        padding-left: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @media screen and (max-width:1180px) {
            width: 26rem;
            
        }

        .session__box{
            display: flex;
            flex-direction: column;
            .session__date{
                all: unset;
                display: block;
                font-family: "Inter";
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                // color: var( --tac-active-color);
                color: #43ADE8;
                
            }
            .session__time{
                all: unset;
                display: block;
                font-family: "Inter";
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin-top: 0.5rem;


            }

        }

    }
    .oc__confirm__td{
        padding: 2rem 0;
        vertical-align: text-top;
        // text-align: center;
        padding-left: 7rem;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        @media screen and (max-width:1180px) {
            padding-left: 4rem;
            
        }


    }
    .oc__action__wrapper{
        vertical-align: text-top;
    }

}