.User_header_container {
    padding: 2rem;
    display: flex;
    align-items: center;
    background-color: var(--secondary-bg-color);
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      width: 100%;
      flex-direction: column;
    }
    .user_right_section {
        display: flex;
        @media screen and (max-width: 600px) {
            margin-top: 10px;
            width: 100%;
            flex-direction: column;
          }
        .user_date{
            display: flex;
            @media screen and (max-width: 600px) {
                justify-content: center;
              }
              @media screen and (max-width: 540px) {
                flex-direction: column;
                align-items: center;
              }
            .user_left_date{
                display: flex;
                align-items: center;
                @media screen and (max-width: 540px) {
                   margin-bottom: 10px;
                  }
                .filter_by{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #667085;
                    margin-right: 1.5rem;
                }
                .date_input{
                    width: 20rem;
                    height: 4rem;
                }
            }
            .webinar_user_searchbox{
                width: 17rem;
                margin-left: 1.5rem;
                // .search_input_handle{
                //   height: 4.4rem; 
                //   // padding: 1rem 1.2rem;
                // }
                @media screen and (max-width: 540px) {
                    width: 25rem;
                    margin-left: 0px;
                   }
            }
        }
    }
  }
  