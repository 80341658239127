.blog_content__header_container {
  padding: 2rem;
  display: flex;
  align-items: center;
  background-color: var(--secondary-bg-color);
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    width: 100%;
    flex-direction: column;
  }
  .right__section {
    display: flex;
    align-items: center;
    @media screen and (max-width: 480px) {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .search {
        width: 100%;
        margin-left: 2rem;
        margin-top: 1rem;
      }
      .button__witdh {
        margin-top: 1rem;
        margin-right: calc(50% - 3.5rem);
      }
    }
    .search {
      width: 23rem;
      margin-right: 2rem;
    }

    .button__witdh {
      width: 7rem;
    }
  }
}
