.ap__container{
    display: flex;
    justify-content: center;
    padding: 100px 10% 0 10%;
    iframe{
        max-width: 710px;
        border: none;
        width: 100%;
        height: 80vh;
        @media (min-width: 2500px){
            max-width: 70vw;
            height: 87vh;
        }
    }
}