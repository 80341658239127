.message__input__section {
  position: relative;
  width: 100%;
  input {
    padding-right: 4rem !important;
    width: 100%;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }
  .right_arrow {
    position: absolute;
    right: 12px;
    width: 2rem;
    stroke-width: 1px;
    bottom: 17px;
    color: var(--primary-placeholder-color);
    cursor: pointer;
  }
  .right__arrow__icon{
    color: var(--alpha-primary-text-color);

  }
}
.chat__box {
  overflow: hidden;
  all: unset;
  min-height: 5rem !important;
  word-break: break-all;
}

.chat__textarea {
  color: #1f1e1e !important;
  resize: none;
  width: 100%;
  height: 5rem;
  max-height: 15rem;
  transition: height 0.3s;
  // padding: 0.9rem 1.5rem;
  padding: 1.2rem 1.5rem;
  padding-top: 1.3rem;
  background: #f8fafb;
  border-radius: 0.5rem;
  border: none;
  outline: none;

  color: var(--primary-input-text-color) !important;
  background: var(--primary-input-bg-color);

  padding-right: 8rem;
  text-align: justify;
  font-family: "Inter", sans-serif !important;
  // border: 1px solid var(--primary-hover-color);
  border: 1px solid var(--primary-border-color);

  &::placeholder {
    color: var(--primary-placeholder-color);
  }

  &:hover {
    border: 1px solid var(--primary-focus-color);
  }

  &:focus {
    box-shadow: 0 0 5px var(--primary-focus-color);
    border: 1px solid var(--primary-focus-color);
  }

  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
}
.chat__textarea__input{
  background: var(--alpha-primary-input-bg-color) ;
  border: 1px solid var(--alpha-ternary-input-border-color) ;
  &::placeholder{
    color: var(--alpha-primary-text-color);
  }

}
