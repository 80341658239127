.custom_admin__interview__wrapper {
  background: var(--secondary-bg-color);
  // padding: 2rem;

  .table_headings {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    .left_control {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .right_control {
      display: flex;
      .search {
        margin-right: 15px;
      }
    }
    .table__heading__right__section{
      display: flex;
      gap: 1.2rem;
      .search{
        margin-right: 0;
      }
      .vertical__line{
        width: 1px;
        background: var(--secondary-table-header-color);
      }
      .deleteall__cancel__btn{
        display: flex;
        gap: 6px;
        button{
          all: unset;
          border: 1px solid var( --delete-btn-border-clr);
          padding: 0.8rem 1.2rem;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0em;
          border-radius: 8px;
          color: var( --delete-btn-border-clr);
          cursor: pointer;
          gap: 4px;
          transition: 0.3s;
          .delete__icon{
            margin-top: -2px;
          }
          &.deleteall__button:hover{
            border: 1px solid #6f6ff1;
            color: #6f6ff1;
            svg{
              color: #6f6ff1;
            }
            
          }
          &.cancel__button{
            color: #F04438;
            padding: 0.8rem 1.6rem;
            &:hover{
              border: 1px solid #F04438;
            }

          }

        }
      }
    }
  }
  .interview_question__table {
    .title__wrapper{
      display: flex;
    }
    .table__container {
      table {
        thead {
          tr {
            display: flex;
            justify-content: space-between;
            width: 80%;
            // padding: 20px 0px;
            padding: 2rem;
            .title {
              font-weight: 500;
              font-size: 12px;
              // color: var(--primary-colorful-text);
              color: var(--ternary-table-header-color) !important;
              background-color: transparent !important;
              padding: 0;
            }
          }
        }
        tbody {
          display: flex;
          flex-direction: column;

          .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-top: 1px solid var(--primary-divider-color);
            padding: 0;

            .question_td {
              width: 60%;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              // cursor: pointer;
              // &:hover {
              //   color: var(--seventh-colorful-text);
              // }
            }
            .question__td__wrapper{
              display: flex;
            }

            .time_td {
              width: 20%;
              padding-left: 50px;
            }
          }
        }
      }
    }
  }
}

.emptyquestion {
  font-weight: 500;
  font-size: 14px;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  .empty {
    // background-color: var(--odd-tr-color);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 1px solid var(--secondary-table-header-color);
  }

  td {
    color: #667085;

    span {
      color: #6f6ff1;
    }
  }
}

@media screen and (max-width: 769px) {
  .custom_admin__interview__wrapper {
    .interview_question__table {
      .table__container {
        table {
          tbody {
            .row {
              .question_td {
                white-space: pre-wrap;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .custom_admin__interview__wrapper {
    .table_headings {
      flex-direction: column;
      justify-content: start;

      .right_control {
        justify-content: space-between;
        padding: 20px 0px;
      }
    }
    .interview_question__table {
      .table__container {
        table {
          thead {
            tr {
              width: 93%;
            }
          }
          tbody {
            .row {
              .question_td {
                width: 627px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 376px) {
  .custom_admin__interview__wrapper {
    .table_headings {
      .right_control {
        flex-direction: column;
        justify-content: flex-start;

        .search {
          margin-bottom: 15px;
        }
      }
    }
  }
}
