.resource__manager__header {
  padding: 1.8rem 2rem;
  display: flex;
  align-items: center;
  //   background-color: var(--secondary-bg-color);
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
  .right__section {
    display: flex;
    align-items: center;
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
    .search {
      width: 25rem;
      margin-right: 1.2rem;
      @media screen and (max-width: 480px) {
        margin-bottom: 1rem;
        margin-top: 0.5rem;
      }
    }
    .button__section {
      display: flex;
    }
    .button__witdh {
      width: 13rem;
    }
    .button__witdh_sm {
      width: 11rem;
      margin-right: 1.2rem;
    }
    #sec-btn-dsgn {
      background: #fff;
      color: #667085;
      border: 1px solid #d0d5dd;
    }
  }
  .file_info__section {
    border-top: 1px solid var(--primary-divider-color);
    margin-top: -1rem;
    .detail_row {
      padding-top: 1.5rem;
      .property_title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: var(--seventh-text-color);
      }
      .property_detail {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: var(--eight-text-color);
        margin-left: 1rem;
      }
    }
  }
  .button__witdh_upload {
    margin-top: 5rem;
  }
  //   .button__witdh {
  //     margin-left: auto;
  //     width: 15rem;
  //   }
}
.upload_file_modal_section {
  border: 1px solid var(--primary-border-color);
  border-radius: 5px;
  height: 30rem;
}
.sm-8 {
  width: 8rem;
}
.preview__image {
  width: 100%;
  object-fit: cover;
}
