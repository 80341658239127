.csv_uploader_foooter__section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .waiting__screen {
      display: flex;
      align-items: center;
    }
    .text {
      color: var(--secondary-progress-bar-bg);
      display: block;
      margin-left: 0.5rem;
      margin-right: 2rem;
    }
  
    .waiting_spinner {
      width: 1.7rem;
      height: 1.7rem;
      border-radius: 50%;
      border: 2px solid var(--secondary-progress-bar-bg);
      border-right-color: rgb(110, 108, 108);
      animation: spin 0.5s linear infinite;
    }
  
    .file__upload__btn{
      // width: 12rem;
      margin-right: 1.2rem;
  
    }
  
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
  