.teams__actionsss {
  width: 20rem;
}
.button__td {
  padding: 1rem;
  .detail__button__width {
    width: 12rem;
  }
  .view__details__button{
    all: unset;
    cursor: pointer;
    padding: 0.8rem 0;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: var(--apply-now--view-details-btn-coor);
    &:hover{
      color: #43ADE8;
    }
  }
}
.tenant_team_modal {
  font-style: normal;

  .detail__section {
    display: flex;
    align-items: center;
    .owner__image__section {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      margin-right: 2rem;
      .tenant_ownerImage {
        width: 100%;
        object-fit: cover;
      }
    }

    .name__section {
      display: flex;
      flex-direction: column;
      .name {
        font-weight: 400;
        font-size: 16px;
      }
      .email {
        font-weight: 400;
        font-size: 14px;
        color: #6f6ff1;
      }
    }
  }
  .detail__title {
    display: block;
    font-weight: 600;
    font-size: 16px;
    color: var(--sixth-text-color);
    margin-top: 2rem;
  }
  .modal__divider {
    border-top: 1px solid var(--primary-progress-divider);
    margin-top: 1rem;
  }
  .info__section {
    all: unset;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .single__section {
      width: 50%;
      &.padding {
        padding-left: 7rem;
      }
      .info {
        // all: unset;
        width: 100%;
        padding: -1rem 0 !important;
        text-align: left;
        .title {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--tenth-text-color);
        }
        .content {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--eight-text-color);
        }
      }
    }
  }
  .extra__inf0 {
    margin-top: 0.8rem;
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: var(--tenth-text-color);
    }
    .content {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #6f6ff1;
      a {
        color: inherit;
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
}
.spinner {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 3px solid #ddd;
  border-right-color: rgb(110, 108, 108);
  animation: spin 0.5s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}