.category_table {
  // min-height: 59.2rem;
  min-height: 35.2rem;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
}
.category__pagination__section {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
