$tac__btn__color: #43ade8;

.LearningPathSteppers {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  // @media screen and (max-width: 480px) {
  //   width: 100%;
  //   grid-template-columns: 100%;
  // }
  .stepper__container {
    background-color: var(--secondary-bg-color);
    width: calc(100% - 31.5rem);
    @media screen and (max-width: 768px) {
      width: 100%;

      // position: absolute;
    }
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    padding: 2rem 4rem;
    .course__title__section {
      margin-bottom: 2rem;
      img {
        width: 1.5rem;
        margin-left: -2rem;
        transform: rotate(90deg);
        cursor: pointer;
      }
      display: flex;
      .course__title {
        font-size: 1.6rem;
        font-style: medium;
        margin-left: 0.5rem;
        color: var(--header-text);
        text-transform: uppercase;
      }
    }
    .stepper {
      border: 1px solid var(--line-and-stroke);
      border-top: none;
      border-right: none;
      border-bottom: 1px solid transparent;
      min-height: 10rem;
      position: relative;
      &:last-child {
        border: none !important;
      }
      .counter {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: -2.5rem;
        top: 0;
        width: 4.5rem;
        height: 4.5rem;
        text-align: center;
        border-radius: 50%;
        font-size: 1.6rem;
        font-weight: 500;
        font-style: medium;
        background-color: var(--line-and-stroke);
      }
      .course__detail {
        // border-top: 1px solid var(--primary-progress-divider);
        // padding: 0 2rem;
        padding-left: 5rem;
        // padding-right: 5rem;
        padding-top: 0.5rem;

        .title {
          // padding: 2rem;
          display: block;
          cursor: pointer;
          font-weight: 500;
        }
        .duration {
          display: block;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 20px;
          color: var(--active-color);
        }
        .description {
          display: block;
          font-size: 1.4rem;
          margin-top: 1rem;
          color: var(--description);
        }
        .button_width_stepper {
          width: 18rem;
          margin-top: 1rem;
        }
        .stepper-divider {
          margin-top: 2rem;
          border-top: 1px solid var(--line-and-stroke);
        }
      }
    }
  }

  .stepper__right__container {
    width: 30rem;
    position: fixed;
    right: 2rem;
    background-color: var(--secondary-bg-color);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    padding: 2rem;
    max-height: 90vh;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      display: none;
    }
    @media screen and (max-width: 768px) {
      position: unset;
      margin-top: 3rem;
      width: 100%;
      // background-color: var(--secondary-bg-color);
    }
    .stepper__top__section {
      display: flex;
      flex-direction: column;
      .second_course__title {
        font-size: 1.7rem;
        color: var(--header-text);
        font-weight: 500;
      }
      .total__courses {
        display: block;
        margin-top: 0.5rem;
        // color: #ef6c00;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 20px;
        color: var(--active-color);
      }
      .course__description {
        display: block;

        color: var(--description);
        font-size: 1.4rem;
        &.mt {
          margin-top: -1rem;
        }
      }
      ul {
        li {
          margin-left: 1.5rem;
          list-style-type: disc;
          color: #667085;
          font-size: 1.4rem;
          font-weight: 600;
        }
      }
    }
    .title {
      font-size: 1.6rem;
      font-weight: 500;
    }
    .divider {
      border-top: 1px solid var(--primary-divider-color);
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    .instructor__profile {
      margin-top: 1rem;
      display: flex;
      // align-items: center;
      .pic {
        margin-top: 0.5rem;

        min-width: 6.5rem;
        min-height: 6.5rem;
        max-width: 6.5rem;
        max-height: 6.5rem;
        object-fit: cover;
      }
    }
    .info {
      margin-left: 1.5rem;
      .name {
        display: block;
        font-size: 1.6rem;
        // font-family: 600;
        font-weight: 500;
      }
      .occupation {
        word-wrap: break-word;
        width: 19rem;
        display: block;
        font-size: 1.4rem;
        font-family: "Inter";
        font-weight: 400;
        font-style: italic;
        color: var(--secondary-text-color);
      }
    }
  }
}
