.name__td {
  display: flex;
  gap: 1.2rem;
  padding: 2rem;
  width: 25rem;
  vertical-align: text-top;
  .testimonial__user__img__wrapper {
    height: 2.4rem;
    width: 2.4rem;
    overflow: hidden;
    min-height: 2.4rem;
    min-width: 2.4rem;
    align-self: flex-start;
    border-radius: 50%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
.action__wrapper {
  .action__container {
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
  }
}
.profession__td{
  width: 20rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  vertical-align: text-top;
}
.description__td{
  padding-top: 2rem;
  padding-bottom: 2rem;

}
.top__tt__td{
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fe7268;
  min-width: 130px;
}
