@import "../../styles/mixins/breakpoints.scss";
.table__container {
  width: 100%;
  // height: 51rem;

  &.full {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  @include md {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    // height: 60rem !important;
    // border: 1px solid red;
  }
  @include airmax {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    // height: 60rem !important;
    // border: 1px solid red;
  }

  // @media screen and (max-width: 768px) {
  //   // overflow: scroll;
  //   margin-top: 30rem;
  // }
  table {
    width: 100%;

    border-collapse: collapse;
    overflow: scroll !important;

    thead tr th {
      cursor: pointer;
      font-size: 1.4rem;
      font-weight: 600;
      text-align: left;
      color: var(--primary-table-header-text) !important;
      // width: 100%;
      background-color: var(--primary-table-header-color);
      padding: 1rem 2rem;
      font-style: medium;
    }

    tbody tr td {
      cursor: pointer;

      font-size: 1.4rem;
      font-weight: 400;
      // @media screen and (max-width: 768px) {
      //   width: 100%;
      // }
      padding-left: 2rem;
    }
    .action__icons {
      display: flex;
      padding: 1.5rem 2rem !important;
      justify-content: flex-end;
      color: #bababa;
      &.hide {
        visibility: hidden;
      }
      // margin-top: 0.8rem;
      &.increse_padding {
        padding: 2rem !important;
      }
    }
    tbody tr:nth-child(even) {
      background-color: var(--even-tr-color);
    }
    tbody tr:nth-child(odd) {
      background-color: var(--odd-tr-color);
    }
    .delete__icon {
      width: 1.6rem;
      margin-left: 1rem;
      color: #bababa;
      cursor: pointer;

      &:hover {
        color: #f04438 !important;
      }
    }
    .edit__icon {
      width: 1.6rem;
      margin-right: 1.5rem;
      color: #bababa;

      &:hover {
        color: #6f6ff1 !important;
        cursor: pointer;
      }
    }
    // tbody tr:hover {
    //   cursor: pointer;
    //   .delete__icon {
    //     color: #fff !important;
    //   }
    //   .edit__icon {
    //     color: #fff !important;
    //   }
    // }
  }
  .hide__text {
    color: transparent !important;
  }
}
