.profile__picture__section {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  .image__section {
    width: 10rem;
    height: 10rem;
    img {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .image_function_section {
    margin-left: 2rem;

    display: flex;
    flex-direction: column;
    .upload {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      /* identical to box height, or 167% */

      text-decoration-line: underline;
      text-underline-offset: 4px;

      color: var(--primary-color);
    }
    .remove {
      margin-top: 1rem;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      /* identical to box height, or 167% */

      text-decoration-line: underline;
      text-underline-offset: 4px;

      color: #f04438;
    }
  }
  .cropper__section {
    border: 1px solid #fff;
    // width: 50%;
    .cropper_div {
      border-top: 1px solid #303030;
      border-bottom: 1px solid #303030;
    }
  }

  .uploader__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    // border: 1px solid var(--secondary-dropdown-border);
    border: 1px solid var(--primary-border-color);
    cursor: pointer;
    // background-color: var(--third-bg-color);
    background-color: var(--primary-input-bg-color);
    height: 20rem;

    .circle_bg {
      height: 6rem;
      width: 6rem;
      border-radius: 50%;
      background: var(--primary-border-color);
      display: flex;
      align-items: center;
      justify-content: center;

      .upload_icon {
        width: 3rem;
        height: 3rem;
        color: var(--secondary-text-color);
      }
    }
    .upload_description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: var(--secondary-text-color);
    }
  }
  .footer__section {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .right__section {
      display: flex;
    }
    .save__button {
      width: 7rem;
    }
    .crop__button {
      margin-right: 1.2rem;
    }
  }
}
.top__divider {
  border-top: 1px solid var(--primary-divider-color);
  margin-top: 0.5rem;
}
.top__secction {
  display: block;
  width: 100%;
}
.bottom__diver {
  display: block;
  margin-top: 2rem;
  border-top: 1px solid var(--primary-divider-color);
}
