.certificate {
  //   background-color: red;
  padding: 30px;
  //   aspect-ratio: 4/3;
  height: calc(100% - 30rem);
  background: #f5f5f5;
  .c-inner {
    border: 8px solid #cfcfcf;
    position: relative;
    margin: 0 auto;
    height: auto;
    padding: 30px 0;
    .certidesign {
      position: absolute;
    }
    .certidesign1 {
      top: 6px;
      left: 6px;
    }
    .certidesign2 {
      top: 2px;
      right: 8px;
      transform: rotate(90deg);
    }
    .certidesign3 {
      bottom: 6px;
      right: 6px;
      transform: rotate(180deg);
    }
    .certidesign4 {
      bottom: 1px;
      left: 8px;
      transform: rotate(-90deg);
    }

    .big-title {
      margin: 0 auto;
      //   background-color: red;
      text-align: center;
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 54px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #232323;
      margin-bottom: 20px;
    }
    .ribbon {
      background: linear-gradient(90.15deg, #ffc97a 3.35%, #e09c3a 104.36%);
      height: 44px;
      //   padding: 11px 0;
      width: 65%;
      margin: 0 auto;
      text-align: center;
      display: grid;
      place-items: center;
      position: relative;
      font-weight: 600;
      font-size: 1.55rem;
      letter-spacing: 0.1em;

      color: #ffffff;
      .ribbon-left {
        position: absolute;
        height: 44px;
        width: 44px;
        // background: linear-gradient(90.15deg, #ffc97a 3.35%, #e09c3a 104.36%);
        background-color: #ffc879;
        left: -43px;
        clip-path: polygon(
          100% 0,
          100% 50%,
          100% 100%,
          0% 100%,
          50% 50%,
          0% 0%
        );
      }
      .ribbon-right {
        position: absolute;
        height: 44px;
        width: 44px;
        // background: linear-gradient(90.15deg, #ffc97a 3.35%, #e09c3a 104.36%);
        background-color: #e09c3a;
        right: -43px;
        clip-path: polygon(100% 0, 50% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
      }
    }
    .name {
      color: #1c1c1c;
      font-family: "Times New Roman";
      font-style: normal;
      font-weight: 400;
      font-size: 3rem;
      text-align: center;
      line-height: 69px;
      height: 69px;
      margin-top: 20px;
    }
    .certi__divider_holder {
      display: flex;
      align-items: center;
      justify-content: center;
      .certi__divider {
        border-bottom: 1px solid #e3e3e3;
        width: 40rem;
      }
      margin-bottom: 20px;
    }

    .desc {
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      width: 75%;
      margin: 0 auto;
      text-align: center;
      color: #1c1c1c;
      margin-bottom: 50px;
    }
    .img {
      //   background-color: red;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      img {
        width: 12rem;
      }
    }
    .sign-row {
      width: 75%;
      margin: 0 auto;
      color: #5c5c5c;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .sign-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        .vivek__signature {
          width: 12rem;
          margin-bottom: 7px;
        }
        .pabitra__signature {
          width: 12rem;
          margin-bottom: 7px;
        }
        .sign-name {
          font-weight: 600;
          font-size: 1.4rem;
          /* identical to box height, or 75% */

          color: #000000;
          margin-bottom: 8px;
        }
        .signature {
          font-weight: 600;
          font-size: 1rem;
          line-height: 19px;
          text-align: center;
          color: #485463;
        }
        .hrline {
          height: 1px;
          width: 150px;
          background-color: #5c5c5c;
        }
      }
    }
  }
}
.certi__ribbon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 50rem;
  }
  span {
    position: absolute;
  }
}
