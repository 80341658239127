.only__logo__header__container {
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s ease;
  padding-left: 2rem;
  padding-top: 1rem;
  .toggle__section {
    .menu__button {
      width: 2rem;
      margin-left: 2rem;
      cursor: pointer;
      color: var(--primary-sidebar-toggle-color);
    }
  }
  .right__section {
    // margin-top: 2rem !important;
    display: flex;
    .avatar_img {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      margin: 0 2rem;
      object-fit: cover;
      background-position: center;
    }
    .avatar_image_div {
      position: relative;
      cursor: pointer;
    }
    .dropdown {
      z-index: 100;
      width: 15rem;
      padding: 1.3rem;
      position: absolute;
      border-radius: 0.5rem;
      background-color: var(--header-drop-bg-color);
      top: 5rem;
      right: 2rem;
      border: none;
      box-shadow: 0px 2px 8px rgb(0 0 0 / 8%);
      .drop_item {
        display: flex;
        align-items: center;
      }
      .divider_item {
        // margin-top: 2rem;
        margin: 1rem 0;
        border-top: 1px solid var(--header-drop-divider);
      }
      .align {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .drop_icon {
        width: 1.8rem;
        color: var(--header-drop-text-color);
      }
      span {
        font-size: 1.4rem;
        font-weight: 400;
        margin-left: 1rem;
        color: var(--header-drop-text-color);
      }
    }

    .logout {
      width: 1.5rem;
    }
  }
}
