.filter__wrapper {
    margin-right: 3.4rem;
    @media screen and (max-width:1100px) {
      margin-right: 0;;
    }

  .filter__container {
    padding: 1.8rem;
    background: var(--alpha-ternary-bg-color);
    width: 27.3rem;
    box-sizing: border-box;
    @media screen and (max-width:1100px) {
      width: 100%;
      
    }
    .searchbar__width__handle {
      .searchbar {
        .search__input {
          background: var(--alpha-primary-input-bg-color) !important;
          // border: 1px solid var(--alpha-ternary-input-border-color) !important;
        }
      }
    }
    .filterby{
        padding: 1.4rem 0;
        padding-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border-bottom: 1px dashed var(--alpha-primar-divider-color);

        .filterbyText{
            all: unset;
            display: block;
            font-family: "Inter";
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            // margin-top: 1rem;
            margin-right: 1rem;
            color: var(--input-label-color);
        
          }
    

    }
    .reset__btn{
      all: unset;
      display: block;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #FE7268;
      cursor: pointer;
  }
    .date__filter__dropdown{
      // margin-top: 1.9rem;
      // margin-bottom: 0.8rem;
    }
    .filterby__date__month{
        margin-top: 1.9rem;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 2.4rem;
        row-gap: 1.6rem;
        @media screen and (max-width:1100px) {
          display: flex;
          flex-wrap: wrap;
          
        }

        .month__wrapper{

        }

    }
    .other__filter{
        margin-top: 2.4rem;
        .other__text{
            font-family: "Inter",sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 28px;
            text-align: left;
            color: #BABABA;
            margin-bottom: 0.8rem;
        }
        

    }
  }
}
