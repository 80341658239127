.conversation__wrappper {
  margin-top: 6.5rem;
  display: flex;

  .margin__for__chating {
    margin-left: 27rem;
    overflow-x: hidden;
    @media screen and (max-width: 1000px) {
      margin-left: 0;
    }
  }
}
