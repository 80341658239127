.webinar_user_table{
    .table__container{
        table{
            tbody{
                .table_row_handle{
                    height: 54px;
                    .webinar_user_td{
                        color: #6f6ff1;
                    }
                    .time_padiing_handle_td{
                        padding-right: 2rem;

                    }
                }

            }
        }
    }

}
