@mixin dFlex($justfyContent) {
  display: flex;
  justify-content: $justfyContent;
  align-items: center;
}

.video__uploader__wrapper {
  width: 100%;
  height: 23rem;
  background-color: var(--primary-input-bg-color);
  border: 1px solid var(--primary-border-color);
  border-radius: 1rem;
  .video__upload__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    svg {
      color: var(--primary-border-color);
      font-size: 1.5rem;
      cursor: pointer;
      width: -moz-fit-content;
      width: fit-content;
      margin-top: 1rem;
    }
    .video__upload__desc {
        color: var(--primary-border-color);
        font-size: 1.5rem;
        cursor: pointer;
        width: -moz-fit-content;
        width: fit-content;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      .video__upload__color__text {
      }
      .video__upload__second__text {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #bababa;
      }
    }
  }
  .show__upload__video__section {
    iframe{
      width: 100%;
    }
    .video__tour__frame{
      .camera-video {
        width: 100%;
        height: 23rem !important;
        background: #08111c;
        @include dFlex(center);
        flex-direction: column;
        .text-center {
          text-align: center;
        }
        .video-spinner {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          border: 5px solid #34516d;
          border-right-color: #d0d5dd;
          animation: spin 0.5s linear infinite;
        }
        img {
          height: 30px;
          width: 30px;
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-size: 12px;
          font-weight: 400;
          color: #8e97a9;
          margin-top: 5px;
        }
      }
    }
  }
  .remove__uploaded__video{
    margin-top: 1rem;
    text-align: right;

    span{
        cursor: pointer;
        font-size: 14px;
        margin-right: 1.5rem;
        color: #e65e5e;
    }
  }
}
