@import "../../../styles/global.scss";
.interviews-and-webinars-container {
  padding: 25px;
  .iw-search {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .searchbar {
      width: 170px;
    }
  }
  .iw-section {
    display: flex;
    align-items: center;
    // justify-content: space-around;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 14px;
    @media screen and (max-width:1100px) {
      justify-content: center;
      
    }
    // @media screen and (max-width: 900px) {
    //   justify-content: space-around;
    // }
  }
}
.handle__margin{
  margin-top: -1rem;
}
.iw-box {
  width: 514px;
  cursor: pointer;
  @media screen and (max-width:1100px) {
    width: 100%;
    
  }
  @media screen and (max-width: 513px) {
    width: 100%;
  }
  @media screen and (max-width: 486px) {
    flex-direction: column;
    height: auto;
    .iw-img {
      width: 100% !important;
      min-height: 100% !important;
    }
    .iw-text {
      width: 100% !important;
    }
  }
  height: 177px;
  padding: 20px;
  display: flex;
  background: var(--secondary-bg-color);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  gap: 20px;
  &:hover {
    border: 1px solid var(--active-color);
  }
  .iw-img {
    width: 257px;
    height: 137px;
    background-color: rgb(92, 92, 92);
    border-radius: 5px;
    overflow: hidden;
    img {
      object-fit: cover;
      max-width: 100%;
    }
  }
  .iw-text {
    padding-top: 10px;
    width: 197px;
    @media screen and (max-width:1100px) {
      width: 59%;
      
    }
    .iw-title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      /* or 125% */

      color: var(--primary-text-color);
      margin-bottom: 7px;
    }
    .iw-duration {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      color: var(--active-color);
    }
  }
}

.modal_width__handle {
  .modal__body {
    width: 960px;
    @media screen and (max-width: 1100px) {
      width: 800px;
    }
    @media screen and (max-width: 800px) {
      width: 650px;
    }
    .IAW__video__container {
      margin-top: 1.5rem;
      iframe {
        border: none;
        width: 960px;
        height: 540px;
        max-height: 540px;
        @media screen and (max-width: 1100px) {
          width: 800px;
        }
        @media screen and (max-width: 800px) {
          width: 650px;
          height: 375px;
        }
        @media screen and (max-width: 600px) {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
