.text__and__select__section {
  margin-top: 23rem;
  // margin-top: 230px;
  // margin-top: 4rem;

  @media screen and (max-width: 650px) {
    margin-top: 4rem;
  }
  @media screen and (max-width: 400px) {
    margin-top: 3rem;
  }
  .welcome__text { 
    font-weight: 600;
    font-size: 48px;
    text-transform: capitalize;
    color: #ffffff;

    @media screen and (max-width: 650px) {
      font-size: 30px;
    }
    @media screen and (max-width: 500px) {
      font-size: 22px;
    }
  }
  .text__wrapper{
    // margin-top: 20px;

    span{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      color: #121212;
      // color: #fff;
      @media screen and (max-width:400px) {
        font-size: 18px;
        
      }
    }
  }
  .select__wrapper {
    // margin-top: 2rem;
    margin-top: 20px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 400px) {
      margin-top: 1rem;
    }
    .simple__text {
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      color: #000;
      // color: #fff;
      @media screen and (max-width: 500px) {
        font-size: 17px;
      }
    }
    .select__section {
      cursor: pointer;
      position: relative;
      margin: 0 0.8rem;
      .select__title__section {
        // width: 20.5rem;
        display: flex;
        align-items: flex-end;
        border-bottom: 2px dashed #fff;
        .select__title {
          font-weight: 500;
          font-size: 22px;
          line-height: 27px;
          color: #000;
          // color: #fff;
          @media screen and (max-width: 500px) {
            font-size: 17px;
            line-height: 21px;
          }
        }
        .down__icon {
          width: 2rem;
          margin-left: 5px;
          color: #fff;
          margin-bottom: -1px;
        }
      }
      .option__sections {
        // width: 20.5rem;
        width: 100%;
        background-color: #212121;
        position: absolute;
        top: 3.5rem;
        z-index: 110;
        display: none;
        padding: 1rem 2rem;
        &.active {
          display: block;
        }
        .option {
          display: block;
          font-weight: 400;
          font-size: 14px;
          padding: 0.5rem 0;
          color: #fff;
          &:hover {
            color: var(--seventh-colorful-text) !important;
          }
        }
      }
    }
  }
}
