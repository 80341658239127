.custom__react__audio__player {
  background: var(--thirty-one-bg-color);
  padding: 1.5rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .audio__play__pause__section {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background: var(--thirty-two-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .play__icon {
      width: 1.7rem;
      margin-left: 4px;
    }
    .pause__icon {
      width: 1.7rem;
    }
  }
  .pause_play_icon {
    cursor: pointer;
    width: 1.5rem;
    &.sm {
      width: 1.3rem;
    }
  }
  .current__durtation {
    font-weight: 400;
    font-size: 14px;
    // width: 5rem;
    margin-left: 1rem;
    min-width: 50px;
  }

  .total__durtation {
    width: 5rem;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    min-width: 50px;
  }

  .time__progress__section {
    
    width: 100%;
    @media screen and (max-width:1440px) {
      // width: 18rem;
    }
    .rc-slider-track {
      background: var(--player-slider-track);
    }

    .rc-slider-rail {
      background: var(--player-slider-bg) !important;
    }
    .rc-slider-handle {
      background: var(--player-slider-track) !important;
      border: 2px solid var(--player-slider-track);
      &:hover {
        border-color: var(--player-slider-track);
      }
      opacity: 100 !important;
    }
    .rc-slider-handle-dragging {
      background: white;
      box-shadow: none !important;
    }
  }
}
.handleAudioPlayerStyle{
  background: var(--alpha-second-bg-color);
  .time__progress__section {
    .rc-slider-rail {
      background: var(--alpha-third-bg-color) !important;
    }

  }

}
