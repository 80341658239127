.propmt__card__wrapper {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 1.6rem;
  .prompt__card {
    width: 30.7rem;
    @media screen and (max-width: 650px) {
      width: 100%;
    }
    height: 7.5rem;
    padding: 1.5rem;
    background: var(--ai-prompt-bg-color) !important;
    // background: red;
    cursor: pointer;
    border: 1px solid var(--black-trns-border);
    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &:hover {
      color: var(--active-color);
    }
  }
  .prompt__card__style{
    &:hover{

      color: var(--ai-prompt-active-color);
    }

  }

  &:hover {
    .prpmpt__popover {
      display: block;
    }
  }

  .custom-tool-tip {
    max-width: 50rem;
    background: var(--ai-tooltip-bg) !important;
    border-radius: 8px;
    color: #ffffff !important;
    position: absolute !important;
    left: 0 !important;
    z-index: 100;

    @media screen and (max-width: 1150px) {
      max-width: 40rem;
    }

    @media screen and (max-width: 850px) {
      max-width: 30.7rem;
    }
  }

  .custom-react-tooltip-arrow {
    // background: red;
    left: 2rem !important;
  }
}
