@import "../../styles/global.scss";

.error404page-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-style: normal;
  h1 {
    margin: 0;
    font-weight: 700;
    font-size: 200px;
    display: flex;
    color: var(--sixth-text-color);
    @media screen and (max-width: 800px) {
      font-size: 170px;
    }
  }
  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--primary-input-text-color);
  }
  a {
    margin: 0;
    margin-top: 60px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #fec97c;
  }
}
