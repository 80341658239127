.subscribers-head {
    border-bottom: 1px solid var(--profile-divider-color);
    margin: 0 2rem;
    .sh-title {
      padding: 15px 20px;
      max-width: fit-content;
      border-bottom: 1px solid var(--primary-colorful-text);
      transform: translateY(1px);
    }
    margin-bottom: 24px;
  }
  
  .subscribers-bottom {
    background-color: var(--secondary-bg-color);
  
    .sh-table-top {
      height: 79px;
      padding: 21px 23px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .subscriber__table__head__right__section {
        display: flex;
        position: relative;
        .searchbar__wrapper {
        }
        .download__user__file__btn__width__handle {
          margin-left: 1.2rem;
          position: relative;
          .download__user__file__btn {
            all: unset;
            background: var(--download-user-file-bg-color);
            border-radius: 8px;
            padding: 0.8rem 1.4rem;
            cursor: pointer;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
          }
        }
        .download__user__file__download__dropdown__wrapper {
          position: absolute;
          right: 0;
          top: 50px;
          background: var(--twentyseven-bg-color);
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          padding: 15px;
          border: 1px solid var(--notification-header-border-color);
          width: 158px;
          z-index: 1;
          .download__user__file__download__dropdow {
            .checkbox__wrapper {
              padding-bottom: 15px;
              .checkbox__container {
                .checkbox {
                  .checkbox__icon {
                    border: 1px solid #43ade8;
                  }
                }
              }
            }
            .download__btn__width {
              button {
                background: #43ade8;
              }
            }
          }
        }
      }
    }
    .subscriber__talbe__section {
      // min-height: 50rem;
      .subscriber_name {
        position: relative;
        max-width: 20rem;
      }
    }
    table {
      tbody {
        td {
          padding: 24px 25px !important;
        }
      }
    }
  }
  .subscriber__download_button {
    margin-left: 2rem;
  }
  