
.modal__contetn__width__handle__generate__link{
    width: 56rem;
}
.generate__link__text{
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.generate__link__buttn{
    margin-top: 1.5rem;
    width: 12.4rem;
    button{
        &:disabled{
            background: var(--gray-btn-bg-color);
            color: #545454;
        }
    }
}
.link__input{
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid  var(--primary-progress-divider);
    position: relative;
    input{
        padding-right: 5rem;
        &:read-only{
            &:hover{
                border: 1px solid var(--primary-border-color);
            }
        }

    }
    .copy__icon{
        position: absolute;
        display: flex;
        align-items: center;
        top: 64%;
        right: 1.5rem;
        cursor: pointer;
        svg{
            color: #bababa;
            height: 1.8rem;
            width: 1.8rem;
        }

    }
}
.done__button{
    display: flex;
    justify-content: flex-end;
    margin-top: 1.8rem;
    .done__button__width{
        width: 8.5rem;
    }
}