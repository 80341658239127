.mobile__chatbot__container__wrapper {
    // margin-left: 2rem;
    width: 100%;
    height: 100vh;
    // background: var( --seventh-bg-color);
    background: var(--alpha-primary-modal-bg-color);
    .mobile__chatbot__container {
      .mobile__chatbot__tab__list__section {
        border-bottom: 1px solid (var(--primary-border-color));
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 10;
        .mobile__chatbot__single__tab {
          display: inline-block;
          cursor: pointer;
          padding: 2rem;
          background: none;
          outline: none;
          margin-left: -4rem;
          width: 50%;
          .new__chat{
            display: flex;
            // justify-content: space-between;
            justify-content: center;
            align-items: center;

            span{
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: var(--fourth-text-color);
            }

          }
    
  
          color: var(--primary-input-text-color);
          &.mll {
            margin-left: 4rem;
          }
        }
        .m__recent__chat__width__handle{
          width: 60%;

        }
        .react-tabs__tab--selected {
          // border-bottom: 1px solid var(--primary-tabs-underline-color);
          border-bottom: 1px solid #53C4C7;
          margin-bottom: -0.1rem;
          font: bolder;
        }
      }
      .box__shadow {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        margin-top: 2rem;
        margin-left: 0;
      }
    }
  }

  .mobile__chat__input__area__section{
    // position: fixed;
    position: absolute;
    bottom: 0;
    min-height: 50px;
    width: 100%;
    background-color: var(--seventh-bg-color);
    // border-top: 1px solid var(--chatbot-header-underline-color);
}
  