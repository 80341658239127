.t__user__avatar {
  margin-bottom: 1.8rem;
    @media screen and (max-width: 480px) {
      margin-top: 2rem;
    }
    .team_type_title{
        display: block;
        font-size: 1.4rem;
        font-weight: 400;
        color: var(--secondary-text-color);
        font-style: normal;
        line-height: 20px;
    }
    .upload_avatar_sec {
      margin-top: 1rem;
      display: flex;
      .author_img {
        width: 4.2rem;
        height: 4.2rem;
        border-radius: 50%;
      }
      .image__funcanality {
        font-size: 1.3rem;
        height: 4.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between !important;
        .upload_image {
          cursor: pointer;
          color: #6f6ff1;
          text-decoration-line: underline;
          text-underline-offset: 3px !important;
        }
        .remove_image {
          cursor: pointer;

          color: #f04438;
          text-decoration-line: underline;

          text-underline-offset: 3px !important;
        }
      }
    }
  }