.single__feedbacK__wrapper {
  margin-left: 2rem;
  display: flex;
  gap: 1.2rem;
  border-bottom: 1px solid var(--primary-divider-color);
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  @media screen and (max-width:900px) {
    margin-left: 0;
    
  }
  .left__section {
    .avatar__name {
      width: 3rem !important;
      height: 3rem !important;
      border-radius: 50%;
      font-weight: 600;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--twentyeight-bg-color);
      text-transform: capitalize;
    }
  }
  .right__section {
    width: 100%;
    .top__section {
      display: flex;
      justify-content: space-between;
      position: relative;
      .user__name__role {
        display: flex;
        align-items: baseline;
        .user__name {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }
        .dot {
          display: block;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: #667085;
          margin: 0 0.5rem;
          // color: #667085;
        }
        .user__role {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #667085;
        }
      }

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
      .three__dot {
        color: #6f6f6f;
        cursor: pointer;
        margin-right: -1rem;
      }
      .select__section {
        display: none;
        position: absolute;
        right: 0;
        top: 3rem;
        width: 121px;

        background: var(--fifth-bg-color);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 1.5rem;
        &.active {
          display: block;
        }

        .action__divider {
          border-bottom: 1px solid var(--profile-divider-color);
          margin: 1rem 0;
        }
        .select__action {
          display: flex;
          align-items: center;
          cursor: pointer;

          .action__icon {
            width: 1.5rem;
            margin-right: 1rem;
          }
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
    .content__section {
      p {
        width: 98%;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
.scroll__bar {
  height: 50rem;
  overflow: auto;
  padding-right: 20px;
  @media screen and (max-width:2300px) {
    height: 34rem;
    overflow: auto;
    padding-right: 20px;

  }
  @media screen and (max-width:1700px) {
    height: 28rem;
    overflow: auto;
    padding-right: 20px;    
  }
  
}
.fd_button_width {
  padding: 0 2rem;
}
