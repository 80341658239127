.custom__react__pagination {
  @mixin pg_button {
    // min-width: 3rem;
    // min-height: 3rem;
    padding: 0.2rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 0.3rem;
    font-weight: 400;
    font-size: 14px;
  }
  @mixin next__and__prev {
    border: 1px solid var(--primary-divider-color);
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .react__paginate__button__container {
    display: flex;
    align-items: center;
    list-style: none;
  }
  .breakClassName {
    @include pg_button;
  }
  .pageClassName {
    list-style: none;
    // &:focus {
    //   background-color: var(--primary-pg-bg-color);
    //   border-radius: 5px;
    //   padding: 0.2rem 0.4rem;
    // }
    // &:hover {
    //   background-color: var(--primary-pg-bg-color);
    //   border-radius: 5px;
    //   padding: 0.2rem 0.4rem;
    // }
  }

  .nextClassName {
    @include pg_button;
    @include next__and__prev;

    margin-left: 1rem;
  }

  .previousClassName {
    @include pg_button;
    @include next__and__prev;

    margin-right: 1rem;
  }
  .next__icon {
    width: 2rem;
    color: #bababa;
  }
  .activeClassName {
    @include pg_button;
    background-color: var(--primary-pg-bg-color);
  }
  .pageLinkClassName {
    @include pg_button;
  }
}
