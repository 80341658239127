.UserCourseDetail__wrapper {
  padding: 2rem;
  padding-top: 0;
  display: flex;
  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  .course__detail__section {
    width: 75%;
    padding: 2rem;
    background-color: var(--secondary-bg-color);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    @media screen and (max-width: 820px) {
      width: 100%;
    }
    .header__section {
      all: unset;
      display: flex;
      align-items: center;

      .back__icon {
        width: 2rem;
        margin-right: 0.3rem;
        margin-left: -0.5rem;
        cursor: pointer;
      }
      .header__title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
      }
      margin-bottom: 0.5rem;
    }
    .custom__according__section {
      .accordion {
        border: none;
      }
      .active {
        color: var(--secondary-progress-bar-bg);
      }
      .accordion__item {
        background-color: var(--secondary-bg-color) !important;
        border-bottom: 2px solid var(--secondary-progress-divider);
        &:last-child {
          border-bottom: none;
        }
      }
      .accordion__button {
        display: flex;
        background-color: var(--secondary-bg-color);
        // background-color: var(--drop-down-according);
        color: var(--fourth-text-color);
        font-size: 1.5rem;
        font-weight: 500;

        padding: 0;
        margin-left: -1rem;

        .test {
          display: block;
          width: 100%;
          padding: 1.3rem;
        }
        .accordian__content__wrapper{
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          svg{
            stroke-width: 1.5px;

          }
        }
      }
      .accordion__button::before {
        display: none;
        content: "";
      }

      .accordion__button[aria-expanded="true"],
      .accordion__button[aria-selected="true"] {
        color: var(--secondary-progress-bar-bg);
        .accordian__content__wrapper{
          svg{
            transform: rotate(180deg);
          }
        }
      }
      .accordion__panel {
        padding: 0.4rem;
        border-top: 1.5px solid var(--primary-progress-divider);
        margin-bottom: 1rem;
      }
      .content__list {
        display: flex;
        align-items: center;
        padding-top: 1.5rem;
      }
      .course__unit {
        display: block;
        font-size: 1.4rem;
        font-weight: 400;
        cursor: pointer;
      }
      .dot {
        width: 0.5rem !important;
        height: 0.5rem !important;
        margin-right: 1rem;
        border-radius: 50%;
        background-color: var(--fourth-text-color);
      }
    }
  }
  .align_center {
    display: flex;
    justify-content: center;
  }
  .course__instructor__section {
    width: 30%;
    margin-left: 2rem;
    @media screen and (max-width: 820px) {
      width: 100%;
      margin-top: 3rem;
      margin-left: 0;
    }
    .card__section {
      background-color: var(--secondary-bg-color);
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      padding: 2.5rem;
      padding-top: 1.8rem;
      .buldle__tile {
        display: block;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 28px;
      }
      .buldle__duration_tile {
        display: block;
        font-size: 1.3rem;
        cursor: pointer;
        color: var(--secondary-progress-bar-bg);
      }
      .buldle__deadline_tile {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #d0d5dd;
        color: var(--filter-show-text);
      }
      .row {
        display: flex;
        flex-direction: column;
        margin: 1rem 0 1.5rem 0;

        .coures__count {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--primary-colorful-text);
        }
        .deadline {
          display: block;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--primary-course-title-color);
          margin-top: 0.5rem;
        }
        .duration {
          display: block;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--primary-colorful-text);
        }
      }
      .completed__count {
        font-style: italic;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        color: #d0d5dd;
        color: var(--primary-course-title-color);
      }
      .description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        // color: #d0d5dd;
        color: var(--primary-course-title-color);
        margin-top: 1rem;
      }
      .detail__and__instructor__divider {
        border-top: 1px solid var(--primary-progress-divider);
        margin: 2rem 0;
      }

      .instructor__profile {
        margin-top: 1rem;
        margin-bottom: 2.5rem;
        display: flex;
        .pic {
          width: 5rem;
          height: 5rem;
        }
        .info {
          margin-left: 1.5rem;
          .name {
            display: block;
            font-size: 1.6rem;
            font-family: 600;
          }
          .occupation {
            word-wrap: break-word;
            width: 19rem;
            display: block;
            font-size: 1.4rem;
            font-family: 400;
            color: #6f6ff1;
            font-style: italic;
          }
        }
      }
    }
  }
}
