.filter-left {
  min-width: 273px;
  max-width: 100%;
  padding: 18px;
  // background: var(--nineteen-bg-color);
  background: var(--alpha-ternary-bg-color);
  height: min-content;
  @media screen and (max-width: 1100px) {
    min-width: 100%;
    // .filter-bottom {
    //   display: none;
    // }
  }
  .filter-search {
    position: relative;
    svg {
      height: 12px;
      width: 12px;
      color: #bababa;
      display: block;
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
    }
    input {
      height: 36px;
      padding-left: 37px;
      color: #bababa;
    }
  }
}

.filter-row {
  display: flex;
  margin-top: 19px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px dashed var(--alpha-secondary-divider-color);
  .fr-left {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #667085;
    display: flex;
    align-items: center;
    svg {
      height: 14px;
    }
    @media screen and (max-width: 1100px) {
      cursor: pointer;
      user-select: none;
    }
  }
  button {
    all: unset;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #fe7268;
  }
}

.filter-option-container {
  padding-top: 10px;
  margin-bottom: 10px;
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: var(--filter-label-top);
    margin-bottom: 10px;
  }
  .filter-options {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
