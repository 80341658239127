.banner__wrapper {
  display: flex;
  //   align-items: center;
  width: 100%;
  flex-direction: column;
  .banner__input__text__field {
    // width: calc(100% - 7.5rem);
    // width: calc(100% - 4.5rem);
    width: 100%;
  }
  .grid__input{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2rem;
  }
  .popup__banner__inputs__wrapper {
    width: 100%;
    .banner__input__text__field {
      width: 100%;
    }
  }

  .button__section {
    border-radius: 8px;
    display: flex;
    gap: 2rem;
    align-items: center;
    .checkboxes__modal {
      .checkbox__sec {
        display: flex;
        gap: 2rem;
        align-items: center;
        .checks {
          display: flex;
          gap: 2rem;
        }
      }
    }
  }
}
.flex__col {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: start;
  margin-top: 2rem;
  .buttons__right {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    align-items: center;
    width: 100%;

    .check__button {
      all: unset;
      width: 3rem;
      height: 3rem;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 1px solid #6f6ff1;
      background: transparent;
      border-radius: 8px;
      .check__icon {
        display: none;
      }
      &.active {
        background: #6f6ff1;
        .check__icon {
          display: block;
          width: 2rem;
          color: #fff;
        }
      }
    }
    .addBtn {
    }
  }
}
.banner__header__content {
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: var(--secondary-bg-color);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  .title {
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 1rem;
  }
}
