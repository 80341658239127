.adminPanelLayout__container {
  width: 100%;
  display: flex;
  .content__container {
    // margin-left: 24rem;
    // margin-left: 274px;
    margin-left: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease;
    @media screen and (max-width: 820px) {
      margin-left: 0;
      width: 100%;
    }
    &.sm {
      margin-left: 7rem;
      transition: all 0.5s ease;
    }
    .header__section {
      width: 100%;
      position: fixed;
      // background: #1c1c1c;

      background: var(--header-bg-color);
      z-index: 100;
    }
  }
}
