.terms_container {
  background-color: var(--secondary-bg-color);

  padding: 2rem;
  margin: 2rem;
  .company__setting {
    display: block;
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--fifth-text-color);
  }
  .company__setting__title {
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: var(--sixth-text-color);
  }
  .editor-wrapper {
    border: 1px solid var(--primary-border-color);
  }
}

.paragraph_terms {
  background-color: var(--primary-bg-color);
  padding: 10px;
  border-radius: 1rem;
  border: 1px solid var(--primary-border-color);
}

.button_sec {
  display: flex;
  justify-content: flex-end;
  //   margin: 20px 0;
  margin-top: 8rem;
  //   margin: 5rem 0 0.1rem 0;
}
