@import "../../../styles/mixins/forms.scss";

input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="date"],
input[type="month"],
input[type="file"],
input[type="number"] {
  @include form-input;
}
input:disabled{
  border: none;
  box-shadow: none;
}
.handleInputStyle{
  background: var(--alpha-primary-input-bg-color) !important;
  border: 1px solid var(--alpha-ternary-input-border-color) !important;
  &::placeholder{
    color: var(  --alpha-primary-text-color) !important;
  }

  &:hover {
    border: 1px solid var(--primary-focus-color) !important;
  }

  &:focus {
    box-shadow: 0 0 5px var(--primary-focus-color) !important;
    border: 1px solid var(--primary-focus-color) !important;
  }
  
}
input[type="date"],input[type="month"] {
  // color: #bababa !important;
  color: var(--primary-dropdown-text);
  cursor: pointer;
  &::-webkit-calendar-picker-indicator {
    background-image: url("./../../../../public/white_calendar.svg");
  }
}
