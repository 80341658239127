.OrgUsercourses__wrapper {
  padding: 2rem;
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 50% auto;
  gap: 2rem;
  @media screen and (max-width: 820px) {
    grid-template-columns: auto;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  .course__wapper {
    padding: 2rem;
    display: flex;
    cursor: pointer;
    height: 100%;
    background-color: var(--secondary-bg-color);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    .cover__img {
      width: 12rem;
      height: 12rem;
      border-radius: 5px;
      object-fit: cover;
      @media screen and (max-width: 480px) {
        width: 6rem;
        height: 6rem;
      }
    }
    .course__info__section {
      padding: 0 2rem;
      width: 100%;
      @media screen and (max-width: 480px) {
        padding: 0 1rem;
      }
      .course__title {
        display: block;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
      .course__row {
        margin: 1rem 0;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 480px) {
          flex-direction: column;
        }
        .course__durarion {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #ffc97a;
          color: var(--primary-colorful-text);
          @media screen and (max-width: 480px) {
            margin-bottom: 1rem;
          }
        }
        .course__deadline {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #d0d5dd;
          color: var(--primary-course-title-color);
        }
      }
      .course__description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        // color: #d0d5dd;
        color: var(--primary-course-title-color);
      }
      .progress__section {
        margin-top: 2rem;
      }
      .completed__text {
        font-style: italic;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        // color: #d0d5dd;
        color: var(--sixth-text-color);
      }
    }
  }
}
