.user__table__section__div {
  .table__container {
    @media screen and (max-width:1300px) {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      
    }
    table{
      thead{
        tr{
          background-color: var(--primary-table-header-color);
          vertical-align: top;
        }
      }
    }
  }
  .title__wrapper{
    display: flex;
  }

  .flex_td {
    display: flex;
    align-items: center;
    position: relative;
    .checkbox__container__wrapper__div{
      position: absolute;
    }
  }
  .roles__tag__td{
    display: flex;
    gap: 8px;
    span{
      padding: 2px 8px;
      background: var(--tag-color);
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      border-radius: 8px;
      color: #fff;
      white-space: nowrap;
    }
  }
  .avatar__section {
    min-width: 2.5rem;
    min-height: 2.5rem;
    max-width: 2.5rem;
    max-height: 2.5rem;
    margin-right: 1rem;
    &.avatar__marging__left{
      margin-left: 2.8rem;
    }
  }
  .email_td {
    width: 24rem;
  }
}

.created__dropdown{
  position: relative;
  .created__dropdown__header{
    display: flex;
    align-items: center;
    gap: 0.4rem;
    cursor: pointer;
    p{
      all: unset;
      display: block;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    svg{
      height: 1.4rem;
      width: 1.4rem;
      stroke-width: 1.5px;

    }

  }
  .created__dropdownBox{
    padding: 0.5rem 0.8rem;
    border: 1px solid var(--primary-border-color);
    background: var(--primary-input-bg-color);
    border-radius: 0.2rem;
    // position: absolute;
    // margin-top: 1rem;
    // top: 2rem;
    z-index: 10;
    .enrolled__on{
      font-family: "Inter",sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #43ADE8;

    }
    .enrolle__dates{
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      p{
        all: unset;
        font-family: "Inter",sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

      }
    }
  }

}