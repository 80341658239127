@mixin spanStyle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  // color: #FFFFFF;
  color: var(--fourth-text-color);
}

@mixin dateTimeStyle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  // color: #BABABA;
  color: var(--primary-placeholder-color);
}

.learning__path__archive {
  margin-top: 15px;
  .learning__path__archive__container__wrapper {
    width: 30rem;
    // background-color: var(--secondary-bg-color);
    // background: #303030;
    background: var(--twentyseven-bg-color);
    // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    box-shadow: var(--control-box-shadow);
    padding: 2rem;
    padding-right: 0px;
    @media screen and (max-width: 768px) {
      margin-top: 3rem;
      width: 100%;
    }
    .learning__path__archive__container {
      .learning__path__archive__header {
        padding-bottom: 10px;
        span {
          @include spanStyle;
          font-size: 18px;
        }
      }
      .learning__path__archive__items {
        max-height: 85vh;
        overflow-y: auto;
        padding-right: 1.5rem;
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          -webkit-border-radius: 10px;
          border-radius: 10px;
          background: var(--new-thumb);
        }
        &::-webkit-scrollbar-track {
          background-color: var(--new-track);

          -webkit-border-radius: 10px;
          border-radius: 10px;
        }

        .learning__path__archive__item {
          padding-bottom: 15px;
          border-bottom: 1px solid var(--archive-border-color);
          margin-bottom: 15px;
          &:last-child {
            border-bottom: none;
          }
          span {
            @include spanStyle;
            font-size: 16px;
          }
          .learning__path__arcive__date__time {
            margin-top: 10px;
            .date__time {
              display: flex;
              align-items: center;
              .clock__icon {
                margin-top: 3px;
                svg {
                  height: 14px;
                  width: 14px;
                  color: var(--primary-placeholder-color);
                  margin-right: 10px;
                }
              }
              .date {
                @include dateTimeStyle;
              }
              .time__range {
                @include dateTimeStyle;
              }
            }
            .zoom__link {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              text-decoration: underline;
              color: #43ade8;
              cursor: pointer;
              margin-top: 1rem;
              display: block;
              &:hover {
                color: #0077b5;
              }
            }
          }
        }
      }
    }
  }
}
