.view__course__seciton {
  display: flex;
  padding: 2rem;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  .courses__according {
    width: calc(100% - 30rem);
    @media screen and (max-width: 1000px) {
      width: 100%;
      padding-right: 0px;
    }
    padding-right: 1.5rem;
    .according__card {
      padding: 2rem;
      padding-bottom: 0;
      background-color: var(--secondary-bg-color);
      width: 100%;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      .card_title {
        display: block;
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: 500;
        margin-top: -0.5rem;
        margin-bottom: 1rem;
      }
    }
    .custom__according__section {
      .accordion {
        border: none;
      }
      .active {
        color: var(--active-color);
      }
      .accordion__item {
        background-color: var(--secondary-bg-color);
        border-bottom: 2px solid var(--line-and-stroke);
        &:last-child {
          border-bottom: none;
        }
      }
      .accordion__button {
        display: flex;
        background-color: var(--secondary-bg-color);
        color: var(--description);
        font-size: 1.5rem;
        font-weight: 500;

        padding: 0;
        margin-left: -1rem;

        .test {
          display: block;
          width: 100%;
          padding: 1.3rem;
        }
        .accordian__content__wrapper{
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          svg{
            stroke-width: 1.5px;

          }
        }
      }
 
      .accordion__button::before {
        display: none;
        content: "";
      }

      .accordion__button[aria-expanded="true"],
      .accordion__button[aria-selected="true"] {
        color: var(--secondary-progress-bar-bg);
        .accordian__content__wrapper{
          svg{
            transform: rotate(180deg);
          }
        }
      }
      .accordion__panel {
        padding: 0.4rem;
        border-top: 1.5px solid var(--line-and-stroke);
        margin-bottom: 1rem;
      }
      .course__unit {
        display: block;
        font-size: 1.4rem;
        font-weight: 400;
        cursor: pointer;
        padding-top: 1.5rem;
      }
    }
  }
  .align_center {
    display: flex;
    justify-content: center;
  }
  .courses__detail {
    width: 30rem;
    position: fixed;
    right: 2rem;
    @media screen and (max-width: 1000px) {
      all: unset;
      margin-top: 3rem;
      background-color: var(--secondary-bg-color);
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      // padding: 2rem;
      // margin-right: 1.5rem;
    }
    max-height: 90vh;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      display: none;
    }

    .course__titile {
      display: block;
      font-size: 1.6rem;
      font-weight: 400;
    }
    .course__duration {
      display: block;

      // color: var(--secondary-progress-bar-bg);
      color: var(--active-color);
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 20px;
    }
    .course__description {
      display: block;
      color: var(--description);
      font-size: 1.4rem;
      &.mt {
        margin-top: -1rem;
      }
    }
    ul {
      li {
        margin-left: 1.5rem;
        list-style-type: disc;
        color: #667085;
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
    .divider {
      border-top: 1px solid var(--line-and-stroke);
      margin-top: 2rem;
    }
    .instructor__profile {
      display: flex;
      margin-top: 1rem;

      .pic {
        width: 5rem;
        height: 5rem;
      }
      .pic-handle{
        min-height: 6.5rem;
        min-width: 6.5rem;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .info {
      margin-left: 1.5rem;
      .name {
        display: block;
        font-size: 1.4rem;
        font-family: 400;
      }
      .occupation {
        display: block;
        font-size: 1.4rem;
        font-family: 400;
        font-style: italic;
        color: var(--secondary-text-color);
      }
    }
  }
}
.tt-widget-fallback p a{
  cursor: pointer;
  width: 100%;
  text-align: center;
  display: grid;
  border: none;
  outline: none;
  place-items: center;
  color: #ffff;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  border-radius: 0.8rem;
  padding: 1rem 2rem;
  white-space: nowrap;
  &.sm {
    padding: 0.8rem 1.5rem;
  }
  &.tiny {
    padding: 0.6rem 0.9rem;
  }
  &.submit {
    width: 9rem;
  }
  background: var(--primary-color);
  &.sm {
    padding: 0.8rem 1.5rem;
  }
  &:disabled {
    cursor: no-drop;
  }

}
.tac__color__btn__color__handle__InLg{

  @media screen and (max-width:1000px) {
    display: none;
  }


}
.tac__color__btn__color__handle__Insm{
  display: none;
  @media screen and (max-width:1000px) {
    display: grid;
  }

}
.fit__width{
  width: fit-content;
  margin-top: 1rem;
}
