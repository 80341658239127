.member__assignment__wapper {
  padding: 2rem;
  padding-top: 0;

  .date__selection__wrapper {
    .mb-3 {
      margin-bottom: 2rem;
    }
    .radio {
      all: unset;
      margin-top: 1.3rem;
      font-style: normal;

      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: right;
      display: flex;
      align-items: center;
      cursor: pointer;
      .radio__icon {
        margin-right: 1.4rem !important;
        height: 1.8rem;
        width: 1.8rem;
        border: 1px solid var(--primary-color);
        border-radius: 50%;
        margin-right: 7px;
        display: flex;
        align-items: center;
        justify-content: center;

        .tick {
          background-color: #6f6ff1;
          width: 0.6rem;
          height: 0.6rem;
          color: transparent;
          border-radius: 50%;
        }
      }
    }
    .date__section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 820px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .date__label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        display: block;
      }
      .calendar__width {
        width: 20rem;
      }
      // width: 49%;
    }
    .multiple__date__wrapper {
      width: 46%;
      margin-left: 3rem;
      @media screen and (max-width: 820px) {
        width: 80%;
      }
      @media screen and (max-width: 420px) {
        width: 100%;
      }
    }
    .single__date__wrapper {
      width: 46%;
      margin-left: 3rem;
      @media screen and (max-width: 820px) {
        width: 80%;
      }
      @media screen and (max-width: 420px) {
        width: 100%;
      }
    }
    .date__divider {
      border-top: 1px solid var(--primary-divider-color);
      margin-bottom: 1rem;
    }
  }
  .button__section {
    display: flex;
    margin-left: auto;
    width: 15rem;
    //
  }
  .button__top__divider {
    border-top: 1px solid var(--primary-divider-color);
    margin: 2rem 0;
  }
}
