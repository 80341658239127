.learning_paths_cards_container {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 4rem;
  margin-bottom: 4rem;
  // grid-auto-rows: auto;
  // grid-gap: 30px;
  // margin: auto;
  // width: 100%;
  // padding: 15px;
  @media screen and (max-width: 480px) {
    width: 100%;
    grid-template-columns: auto !important;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    grid-template-columns: auto auto;
  }
  @media screen and (min-width: 2000px) {
    width: 100%;
    grid-template-columns: auto auto auto auto;
  }
  .card_container {
    border: 1px solid var(--primary-border-color);
    border-radius: 1rem;
    background-color: var(--secondary-bg-color);
    width: 37rem;

    margin: auto;
    .learning_path_top {
      height: 25rem;
      .course_thumbnail {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .learning_path_bottom {
      .lower_inner_container {
        padding: 15px;
      }
      .course_tag {
        color: var(--primary-tabs-underline-color);
        margin-bottom: -1rem;
      }

      .course_button_container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
      }
      #sec-btn-dsgn {
        max-width: 16rem;
        background: #fff;
        color: #667085;
        border: 1px solid #d0d5dd;
      }
      #pmry-btn-dsgn {
        max-width: 16rem;
      }
    }
  }
}
.learning_path_bundleTitle {
  display: block;
  font-weight: 500;
  font-size: 1.6rem;
  font-size: normal;
  margin: 1rem 0;
}

// @media screen and (max-width: 576px) {
//   .learning_paths_cards_container {
//     grid-template-columns: repeat(1, 1fr);
//   }

//   .card_container {
//     width: 100%;
//   }

//   .learning_path_top {
//     height: 20rem;
//   }

//   .learning_path_bottom {
//     .lower_inner_container {
//       padding: 10px;
//     }
//   }

//   .course_button_container {
//     margin-bottom: 5px;
//   }

//   .course_tag {
//     color: var(--primary-tabs-underline-color);
//     margin-bottom: -1rem;
//   }
// }
// @media screen and (min-width: 576px) and (max-width: 768px) {
//   .learning_paths_cards_container {
//     grid-template-columns: repeat(2, 1fr);
//   }

//   .card_container {
//     width: 100%;
//   }

//   .learning_path_top {
//     height: 20rem;
//   }

//   .learning_path_bottom {
//     .lower_inner_container {
//       padding: 10px;
//     }
//   }

//   .course_button_container {
//     margin-bottom: 5px;
//   }

//   .course_tag {
//     color: var(--primary-tabs-underline-color);
//     margin-bottom: -1rem;
//   }
// }

// @media screen and (min-width: 768px) and (max-width: 992px) {
//   .learning_paths_cards_container {
//     grid-template-columns: repeat(2, 2fr);
//   }

//   .card_container {
//     width: 100%;
//   }

//   .learning_path_top {
//     height: 20rem;
//   }

//   .learning_path_bottom {
//     .lower_inner_container {
//       padding: 10px;
//     }
//   }

//   .course_button_container {
//     margin-bottom: 5px;
//   }

//   .course_tag {
//     color: var(--primary-tabs-underline-color);
//     margin-bottom: -1rem;
//   }
// }

// @media screen and (min-width: 992px) and (max-width: 1200px) {
//   .learning_paths_cards_container {
//     grid-template-columns: repeat(2, 2fr);
//   }

//   .card_container {
//     width: 100%;
//   }

//   .learning_path_top {
//     height: 20rem;
//   }

//   .learning_path_bottom {
//     .lower_inner_container {
//       padding: 10px;
//     }
//   }

//   .course_button_container {
//     margin-bottom: 5px;
//   }

//   .course_tag {
//     color: var(--primary-tabs-underline-color);
//     margin-bottom: -1rem;
//   }
// }

// @media screen and (min-width: 1200px) and (max-width: 1400px) {
//   .learning_paths_cards_container {
//     grid-template-columns: repeat(2, 3fr);
//   }

//   .card_container {
//     width: 100%;
//   }

//   .learning_path_top {
//     height: 20rem;
//   }

//   .learning_path_bottom {
//     .lower_inner_container {
//       padding: 10px;
//     }
//   }

//   .course_button_container {
//     margin-bottom: 5px;
//   }

//   .course_tag {
//     color: FFC97A;
//   }
// }

// @media screen and (min-width: 1400px) and (max-width: 1600px) {
//   .learning_paths_cards_container {
//     grid-template-columns: repeat(3, 3fr);
//   }

//   .card_container {
//     width: 100%;
//   }

//   .learning_path_top {
//     height: 20rem;
//   }

//   .learning_path_bottom {
//     .lower_inner_container {
//       padding: 10px;
//     }
//   }

//   .course_button_container {
//     margin-bottom: 5px;
//   }

//   .course_tag {
//     color: var(--primary-tabs-underline-color);
//     margin-bottom: -1rem;
//   }
// }

// @media screen and (min-width: 1600px) and (max-width: 1700px) {
//   .learning_paths_cards_container {
//     grid-template-columns: repeat(3, 3fr);
//   }

//   .card_container {
//     width: 100%;
//   }

//   .learning_path_top {
//     height: 20rem;
//   }

//   .learning_path_bottom {
//     .lower_inner_container {
//       padding: 10px;
//     }
//   }

//   .course_button_container {
//     margin-bottom: 5px;
//   }

//   .course_tag {
//     color: var(--primary-tabs-underline-color);
//     margin-bottom: -1rem;
//   }
// }

// @media screen and (min-width: 1800px) and (max-width: 2000px) {
//   .learning_paths_cards_container {
//     grid-template-columns: repeat(4, 3fr);
//   }

//   .card_container {
//     width: 100%;
//   }

//   .learning_path_top {
//     height: 20rem;
//   }

//   .learning_path_bottom {
//     .lower_inner_container {
//       padding: 10px;
//     }
//   }

//   .course_button_container {
//     margin-bottom: 5px;
//   }

//   .course_tag {
//     color: var(--primary-tabs-underline-color);
//     margin-bottom: -1rem;
//   }
// }

// @media screen and (min-width: 2000px) {
//   .learning_paths_cards_container {
//     grid-template-columns: repeat(5, 3fr);
//   }

//   .card_container {
//     width: 100%;
//   }

//   .learning_path_top {
//     height: 20rem;
//   }

//   .learning_path_bottom {
//     .lower_inner_container {
//       padding: 10px;
//     }
//   }

//   .course_button_container {
//     margin-bottom: 5px;
//   }

//   .course_tag {
//     color: var(--primary-tabs-underline-color);
//     margin-bottom: -1rem;
//   }
// }
