.modal_width {
  width: 680px;
}
form {
  width: 100%;
  .content {
    .lg_width {
      width: 100%;
    }
    .addbtn {
      display: flex;
      justify-content: flex-end;
    }
  }
}
