.subscriber_name{
    position: relative;
    a{
        width: 200px !important;
    }
}

.contact__message__wrapper{
  position: relative;
  p{
    position: relative;
  }
    &:hover span {
        opacity: 1;
        visibility: visible;
      }
      span {
        position: absolute;
        padding: 6px 10px;
        background: var(--alpha-secondary-tooltip);
        font-size: 12px;
        width: 300px;
        font-weight: 400;
        line-height: 16px;
        border-radius: 4px;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        z-index: 100;
        display: block;
        visibility: hidden;
        transition: opacity 0.4s ease, visibility 0.4s ease;
        // white-space: nowrap;
        &::before{
          content: "";
          position: absolute;
          height: 10px;
          width: 10px;
          background: var(--alpha-secondary-tooltip);
          bottom: 0px;
          z-index: -1;
          top: -5px;
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
        }
      }

}
