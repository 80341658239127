.custom__empty__container {
  &.show_empty_box {
    width: 100%;
    height: 35rem;
    border-top: 1px solid var(--primary-divider-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-bg-color);
  }
  &.hide_empty_box {
    display: none;
  }
  .empty__description__text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: var(--secondary-text-color);
  }

  .file__not__found {
    width: 7rem;
    height: 7rem;
    color: var(--secondary-text-color);
  }
}
