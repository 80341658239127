.dashboard__far__wrapper {
  width: 100%;
  // background: var(--twelveth-bg-color);
  background: var(--new-primary-bg-color);
  display: flex;
  justify-content: center;
  // @media screen and (max-width: 500px) {
  //   display: block;
  // }

  .center__wrapper {
    width: 80rem;
    margin: 11rem;
    @media screen and (max-width:800px) {
      width: 100%;
      margin: 7rem 4rem;
      
    }
    @media screen and (max-width: 500px) {
      width: 95% !important;
      margin: 7rem 2rem;
    }
    .title {
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      color: var(--eight-colorful-text);
      @media screen and (max-width: 500px) {
        font-size: 22px;
        line-height: 27px;
        // text-align: left;
        // margin-left: 0.5rem;
      }
    }
    .faq__list__sections {
      margin-top: 6rem;
      position: relative;

      .faq__card {
        padding: 2rem;
        margin-bottom: 2rem;
        border: 1px solid var(--black-trns-border);
        background: var(--thirteen-bg-color);

        .header__section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:hover {
            cursor: pointer;
            .faq__questions {
              color: var(--seventh-colorful-text);
            }
            .plus__icon {
              color: var(--seventh-colorful-text);
            }
          }
          .faq__questions {
            width: calc(100% - 3rem);
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
          }
          .plus__icon {
            width: 2rem;
            height: 2rem;
            cursor: pointer;
            &:hover {
              color: var(--seventh-colorful-text);
            }
          }
        }
        .faq__content {
          display: none;
          overflow: hidden;
          transition: all 0.2s ease-in-out;
          &.active {
            display: block;
            transition: all 0.2s ease-in-out;
          }
          .faq__divider {
            border-top: 1px solid var(--alpha-sixth-divider-color);
            margin: 1.5rem 0;
          }
          .content {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
