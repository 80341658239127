.notice {
  font-size: 1.4rem;
}
.divider {
  border-top: 1px solid var(--primary-progress-divider);
  margin: 2rem 0 2rem 0;
}
.row {
  display: flex;
  justify-content: flex-end;
}
