.onboarding__wrapper {
  padding: 2rem;
  padding-top: 0;
  .onboard__card {
    padding: 2rem;
    margin-bottom: 2rem;
    background-color: var(--secondary-bg-color);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    .title {
      display: block;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 1rem;
    }
    .desc__buttons {
      width: 9rem;
      display: block;
      margin-left: auto;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;
      .plans__button__width {
        width: 10rem;
      }
      .lessons {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
}
.single__lesson__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  .lesson__field__width {
    width: calc(100% - 9.5rem);
  }
  .button__section {
    width: 9.5rem;
    display: flex;
    align-items: center;
    // justify-content: space-evenly;
  }
  .onboarding__button {
    all: unset;
    background: #6f6ff1;
    width: 3rem;
    height: 3rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -1rem 2rem 0 2rem;
    cursor: pointer;
  }
  .check__icon {
    width: 2rem;
    color: #fff;
  }
  .trans__ion {
    width: 2rem;
    color: #667085;
    margin-top: -1rem;
    cursor: pointer;
  }
}
