.product__manager__user__wrapper {
    width: 100%;
    @media screen and (max-width: 820px) {
    }
    .product__manager__user__padding__wrapper {
      padding: 0 12rem;
      margin-bottom: 6rem;
      @media screen and (max-width: 1000px) {
        padding: 0 4rem;
      }
      @media screen and (max-width: 650px) {
        padding: 0 2rem;
      }
    }
    .pm__our__courses__wrapper{
        padding: 0 12rem;
        background: var(--new-secondary-bg-color);
        @media screen and (max-width: 1000px) {
          padding: 0 4rem;
        }
        @media screen and (max-width: 650px) {
          padding: 0 2rem;
        }
    }
    .pm__archive__wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .product__manager__dashboard__faq__wrapper{
   
       
        .dashboard__far__wrapper{
            background: var(--new-secondary-bg-color);
            .center__wrapper{
                .title{
                    color: #FE7268;

                }
            }
        }
    }
  }
  .book__center__loading__section {
    // margin-left: -40rem !important;
    // padding-left: -40rem !important;
    display: flex;
    justify-content: center;
  }
  .test {
    display: flex;
    flex-direction: column;
  }
  .simplify {
    all: unset;
  }
  .book__center {
    display: flex;
    justify-content: center;
    margin-top: 50vh;
  }
  