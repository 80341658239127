.subscriptions__card__wrapper__padding {
  .subscriptions__card__wrapper {
    margin-top: -0.5rem;
    border: 1px solid var(--notification-border-bottom-color);
    background: var(--third-bg-color);
    color: var(--sixth-text-color);
    font-size: 14px;
    font-weight: 500;
    padding: 1.5rem 2rem;
    border-radius: 8px;
  }
  .owner__profile__subscription__button {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid var(--primary-divider);
    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  }
  margin-bottom: 5rem;
  .upgrade__width {
    width: 17rem;
    @media screen and (max-width: 480px) {
      margin-top: 2rem;
    }
  }
  .cancel__width {
    width: 15rem;
  }
  .cancel__subscription {
    all: unset;
    color: #bababa;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
  }
  .subscriptions__plans__details {
    .plan {
      margin-top: -0.5rem;
      display: block;
      font-weight: 500;
      font-size: 16px;
    }
    .expiration__details {
      margin-top: 1rem;

      display: flex;
      font-weight: 400;
      font-size: 14px;

      .pricing {
        font-weight: 500;

        font-size: 14px;
      }
    }
    .desc {
      margin-top: 1rem;
      display: block;
      font-weight: 400;
      font-size: 12px;
    }
  }
}
