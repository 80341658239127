.user-reference-body {
  //   background-color: red;
  background: var(--new-secondary-bg-color);
  padding-top: 132px;
  min-height: 100vh;
  h1 {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: var(--primary-text-color);
    @media screen and (max-width: 500px) {
      font-size: 28px;
    }
  }
  > p {
    text-align: center;
    width: 653px;
    max-width: 90%;
    margin: 0 auto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #bababa;
    color: var(--primary-dropdown-text);
    margin-bottom: 77px;
    @media screen and (max-width: 820px) {
      margin-bottom: 56px;
    }
  }
  .user-reference-card-container {
    width: calc(320px * 3 + 18px * 2.5);
    @media screen and (max-width: 1100px) {
      width: calc(320px * 2 + 18px);
    }
    @media screen and (max-width: 750px) {
      width: calc(320px);
    }
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // border: 1px solid red;
    gap: 18px;
    padding-bottom: 50px;
  }
  .user__reference__empty__wrapper{
    .user__reference__empty__container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p{
        margin-top: 1rem;
      }

    }

  }
}
