.assignment__section {
  //

  .single__assignment__row {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 420px) {
      flex-direction: column;
    }
  }
}

.single__assignment__section {
  width: 49%;
  @media screen and (max-width: 420px) {
    width: 100%;
  }
  gap: 2rem !important;
  background-color: var(--third-bg-color);
  min-height: 19rem;
  border-radius: 0.8rem;
  padding: 2rem;
  .title__section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    @media screen and (max-width: 420px) {
      flex-direction: column;
    }
    .title {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      // margin-top: -0.5rem;
      // margin-bottom: 1.5rem;
      text-transform: uppercase;

      @media screen and (max-width: 420px) {
        margin-bottom: 1.5rem;
      }
    }

    .add__assignment__text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6f6ff1;
      cursor: pointer;
    }
    .filter__section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .show__filter__text {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        margin-right: 2rem;

        // color: #d0d5dd;
        color: var(--filter-show-text);
      }
      .filter__box {
        // border: 1px solid #303538;
        border: 1px solid var(--primary-border-color);
        width: 13rem;
        padding: 0.5rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0.8rem;
        margin-right: -2rem;
        position: relative;

        .filter__title {
          display: block;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          text-transform: capitalize;
          line-height: 15px;
          color: var(--primary-placeholder-color);
        }
        .down__icon {
          width: 1.5rem;
          // color: #bababa;
          color: var(--primary-placeholder-color);
          cursor: pointer;
        }

        .filter__option__card {
          position: absolute;
          background-color: var(--fifth-bg-color);
          border: 1px solid var(--select-border);
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          min-width: 20rem;
          padding: 1rem 2rem;
          top: 4rem;
          left: -0.3rem;
          @media screen and (max-width: 420px) {
            left: -3rem;
          }
          .filter__item {
            display: block;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
            text-transform: capitalize;
            color: var(--primary-text-color);
            cursor: pointer;
          }
        }
      }
    }
  }
  .empty__text {
    width: 100%;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    color: #667085;
  }
  .data__section {
    .heading__section {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #98a2b3;
      display: flex;
      justify-content: space-between;
      &.align__heading {
        padding-top: 1.6rem !important;
      }
    }
    .data__divider {
      border-top: 1px solid var(--primary-divider-color);
      margin: 1rem 0;
    }

    .single__data__item {
      width: 100%;
      .row {
        display: flex;
        justify-content: space-between;
        .date__and__delte__section {
          // border: 1px solid red;
          display: flex;
        }
        .course__name {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          display: block;
          &.mr {
            margin-right: 2rem;
          }
        }
        .trash__icon {
          width: 1.7rem;
          color: #bababa;
          cursor: pointer;
          margin-left: 1rem;
        }
        .course__porgress__section {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.margin-top {
            margin-top: 0.5rem;
          }
          .icon__section {
            display: flex;
            align-items: center;
            .tool_section_for_hover {
              position: relative;
              display: flex;
              .user_resoure_icon {
                width: 1.8rem;
                color: #bababa;
                &.ml {
                  margin-left: 4rem;
                }
              }

              .eye_icon_hover:hover ~ .preview_tool_tip {
                display: block;
              }
              .eye_icon_hover:hover ~ .preview_arrow_tool_tip {
                display: block;
              }

              .download_icon_hover:hover ~ .download_tool_tip {
                display: block !important;
              }
              .download_icon_hover:hover ~ .download_arrow_tool_tip {
                display: block !important;
              }

              .preview_arrow_tool_tip {
                position: absolute;
                display: none;
                width: 2.5rem;
                height: 1.2rem;
                background-color: var(--fifth-bg-color);
                clip-path: polygon(46% 100%, 0 0, 100% 0);
                left: -0.1rem;
                top: -1rem;
              }
              .preview_tool_tip {
                position: absolute;
                display: none;
                width: 5.8rem;
                padding: 0.6rem 0.8rem;
                // color: #fff;
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                background-color: var(--fifth-bg-color);
                border-radius: 0.4rem;
                left: -1rem;
                top: -3.5rem;
              }

              .download_tool_tip {
                position: absolute;
                display: none;
                width: 5.6rem;
                padding: 0.6rem 0.8rem;
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                background-color: var(--fifth-bg-color);
                border-radius: 0.4rem;
                left: -2rem;
                top: -3.5rem;
              }
              .download_arrow_tool_tip {
                position: absolute;
                display: none;
                width: 2.5rem;
                height: 1.2rem;
                background-color: var(--fifth-bg-color);
                clip-path: polygon(46% 100%, 0 0, 100% 0);
                left: 0.8rem;
                top: -1rem;
              }
            }
          }
        }

        .sync__icon {
          color: #bababa;
          width: 1.7rem;
          cursor: pointer;
          &.mt {
            margin-top: -0.8rem;
          }
        }
      }
      .completed__text__precent {
        display: block;
        font-style: italic;
        font-weight: 300;
        font-size: 12px;
        color: var(--primary-placeholder-color);
        margin-top: -0.5rem;
      }
    }
  }
}
.member__course__detail {
  font-style: normal;
  display: block;
  font-weight: 300;
  font-size: 14px;
  color: #ffc97a;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}
.member__course__name {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 2rem;
}
.date__override__section {
  width: 100% !important;
  margin-bottom: 1rem;
}
.member__modal_footer {
  display: flex;
  div {
    display: flex;
    margin-left: auto !important;
  }
}
.assignment__modal__detail__wrapper {
  .assignment__header__section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #98a2b3;
    }
  }
  .content__wrapper {
    .single__content {
      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .course__title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }
      // .divider {
      //   border-top: 1px solid red;
      // }
    }
  }
}
