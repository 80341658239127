.user__table__section {
    // height: 59.2rem;
    min-height: 45.2rem;
    // &.min_hight {
    //   height: 45.2rem;
    // }
    @media screen and (max-width: 768px) {
      // overflow: scroll;
    }
    .flex_td {
      display: flex;
      align-items: center;
    }
    .avatar__section {
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 1rem;
    }
    .email_td {
      width: 24rem;
    }
  }
  