.team__header__container {
  padding: 1.8rem 2rem;
  display: flex;
  align-items: center;
  background-color: var(--secondary-bg-color);
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    width: 100%;
    flex-direction: column;
  }
  .right__section {
    display: flex;
    align-items: center;
    @media screen and (max-width: 480px) {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .search {
        margin-top: 1rem;
      }
      .button__witdh {
        margin-top: 1rem;
        margin-right: calc(50% - 5rem);
      }
    }
    .search {
      width: 25rem;
      margin-right: 2rem;
    }

    .button__witdh {
      width: 10rem;
    }
  }

  .checkbox_profile {
    display: flex;
    padding: 0.5rem;
    justify-content: space-between;
    align-items: center;
  }
  .button__witdh {
    margin-left: auto;
    width: 10rem;
  }
  .checkbox_left {
    height: 100%;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .member__selection_section {
    margin: 10px 0;
  }
  .selection {
    display: flex;
    justify-content: space-between;
  }
  // .image__selection_section {
  //   display: flex;
  //   align-items: center;
  //   .functions__section {
  //     margin-left: 2rem;
  //     display: flex;
  //     flex-direction: column;
  //     .upload__image {
  //       color: #6f6ff1;
  //       font-size: 1.2rem;
  //       text-decoration-line: underline;
  //       text-underline-offset: 10px;
  //       cursor: pointer;
  //     }
  //     .remove__image {
  //       font-size: 1.2rem;

  //       text-decoration-line: underline;
  //       text-underline-offset: 3px;
  //       color: #f04438;
  //       margin-top: 0.5rem;
  //       cursor: pointer;
  //     }
  //   }
  // }
  form {
    .team_row_section {
      width: 100%;

      // border: 1px solid red;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 480px) {
        width: 100%;
        flex-direction: column;
      }
      .team_type_title {
        display: block;
        // margin-bottom: 1rem;
        font-size: 1.4rem;
        font-weight: 400;
        color: var(--secondary-text-color);
        font-style: normal;
        line-height: 20px;
      }
      .team_left_section {
        width: 50%;
        .radio {
          all: unset;
          margin-top: 1.3rem;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: right;
          display: flex;
          align-items: center;
          cursor: pointer;
          .radio__icon {
            margin-right: 1.4rem !important;
            height: 2.1rem;
            width: 2.1rem;
            border: 1px solid var(--primary-color);
            border-radius: 50%;
            margin-right: 7px;
            display: flex;
            align-items: center;
            justify-content: center;

            .tick {
              background-color: #6f6ff1;
              width: 0.8rem;
              height: 0.8rem;
              color: transparent;
              border-radius: 50%;
            }
          }
        }
      }
      .team_right_section {
        @media screen and (max-width: 480px) {
          margin-top: 2rem;
        }
        .upload_avatar_sec {
          margin-top: 1rem;
          display: flex;
          .author_img {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
          }
          .image__funcanality {
            font-size: 1.3rem;
            height: 4.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between !important;
            .upload_image {
              cursor: pointer;
              color: #6f6ff1;
              text-decoration-line: underline;
              text-underline-offset: 3px !important;
            }
            .remove_image {
              cursor: pointer;

              color: #f04438;
              text-decoration-line: underline;

              text-underline-offset: 3px !important;
            }
          }
        }
      }
    }
    .height {
      display: block;
      margin-top: 1.2rem;
    }
  }
}
