.author_table_tr {
  display: flex;
  align-items: center;
  height: 5.9rem;
  .avatar__section {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
  }
}
.author__table {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  // min-height: 64.3rem;
  min-height: 35.2rem;
}
.author__pagination__section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
