@import "../../styles/mixins/breakpoints.scss";
.tac__table__container {
  width: 100%;
  background-color: var(--secondary-bg-color);
  padding: 0 2rem;

  @include md {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    // height: 60rem !important;
    // border: 1px solid red;
  }
  @include airmax {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    // height: 60rem !important;
    // border: 1px solid red;
  }

  // @media screen and (max-width: 768px) {
  //   // overflow: scroll;
  //   margin-top: 30rem;
  // }
  table {
    width: 100%;

    border-collapse: collapse;
    overflow: scroll !important;

    thead tr th {
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: 600;
      text-align: left;
      color: var(--table-header-text) !important;

      // padding: 1rem 2rem;
      // padding-left: 0;
      font-style: medium;
      padding: 2rem 2rem 2rem 0;
    }
    thead tr {
      margin-bottom: 1rem !important;
      padding: 3rem;
    }

    tbody tr td {
      cursor: pointer;

      font-size: 1.4rem;
      font-weight: 400;
      // @media screen and (max-width: 768px) {
      //   width: 100%;
      // }
    }
    .tac__action__icons {
      display: flex;
      padding: 2rem 2rem !important;
      justify-content: flex-end;
      color: #bababa;

      &.increse_padding {
        padding: 2rem !important;
      }
      padding-right: 5px !important;
    }
    tbody tr {
      background-color: var(--secondary-bg-color);
      border-top: 1px solid var(--line-and-stroke);
    }

    .delete__icon {
      width: 1.6rem;
      color: var(--secondary-text-color);

      cursor: pointer;

      &:hover {
        color: var(--secondary-color) !important;
      }
    }
    .edit__icon {
      width: 1.6rem;
      color: var(--secondary-text-color);

      &:hover {
        color: var(--active-color) !important;
        cursor: pointer;
      }
    }
    // tbody tr:hover {
    //   cursor: pointer;
    //   .delete__icon {
    //     color: #fff !important;
    //   }
    //   .edit__icon {
    //     color: #fff !important;
    //   }
    // }
  }
  .hide__text {
    color: transparent !important;
  }
}
