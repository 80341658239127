.profile__and__logout_section {
  position: relative;
  cursor: pointer;

  .avatar_img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin: 0 2rem;
    margin-right: 0;
    object-fit: cover;
    background-position: center;
    @media screen and (max-width: 820px) {
      margin-right: 2rem;
    }
  }

  .dropdown {
    z-index: 100;
    width: 15rem;
    padding: 1.3rem;
    position: absolute;
    border-radius: 0.5rem;
    background-color: var(--header-drop-bg-color);
    top: 5rem;
    right: 2rem;
    border: none;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 8%);
    .drop_item {
      display: flex;
      align-items: center;
    }
    .divider_item {
      // margin-top: 2rem;
      margin: 1rem 0;
      border-top: 1px solid var(--header-drop-divider);
    }
    .align {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .drop_icon {
      width: 1.8rem;
      color: var(--header-drop-text-color);
    }
    span {
      font-size: 1.4rem;
      font-weight: 400;
      margin-left: 1rem;
      color: var(--header-drop-text-color);
    }
  }

  .logout {
    width: 1.5rem;
  }
}
