.nav__wrapper {
  width: 100%;
  padding: 0 12rem;
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;

  &.ai {
    padding: 0 4rem !important;
    @media screen and (max-width: 500px) {
      padding: 0 2rem !important;
    }
  }
  @media screen and (max-width: 1000px) {
    padding: 2rem 4rem 0 4rem;
  }
  @media screen and (max-width: 500px) {
    padding: 2rem 2rem 0 2rem;
  }
  .menu {
    display: none;
    @media screen and (max-width: 1000px) {
      display: block;
      margin-left: 2rem;
    }
    @media screen and (max-width: 400px) {
      margin-left: 0rem;
    }
  }
  background: transparent;
  z-index: 110 !important;
  &.active {
    // background: var(--primary-bg-color) !important;
    background: var( --new-secondary-bg-color) !important;
  }
  &.normal {
    background: var(--primary-bg-color) !important;
    // background: var( --new-secondary-bg-color)  !important;
  }
  &.handleNavBgColor{
    background: var( --new-secondary-bg-color) !important;

  }
  &.drawer {
    background: transparent !important;
    .left__section {
      visibility: hidden;
    }
  }
  .left__section {
    @media screen and (max-width: 1000px) {
    }
    .logo__image {
      @media screen and (max-width:400px) {
          width: 104px;
          height: 35px;
        
      }
      &.ai {
        margin-top: 0.5rem;
      }
    }
  }
  .right__section {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1000px) {
      margin-top: -1.5rem;
      .link {
        display: none;
      }
      .quick__linK__section {
        display: none !important;
      }
    }
    .quick__linK__section {
      cursor: pointer;
      display: flex;
      align-items: flex-end;
      position: relative;
      &:hover {
        .down__icon {
          color: var(--seventh-colorful-text) !important;
        }
        .quick__link {
          color: var(--seventh-colorful-text) !important;
        }
      }
      .quick__link {
        font-weight: 400;
        font-size: 16px;
        margin-right: 1.5rem;
        color: #fff;

        &.blackcolor {
          color: #000000;
        }
        &.active {
          color: var(--seventh-colorful-text) !important;
        }
        &.normal {
          color: var(--fourth-text-color);
        }
      }
      .down__icon {
        position: absolute;
        width: 2rem;
        right: -1rem;
        bottom: -2px;
        color: #fff;
        &.active {
          color: var(--seventh-colorful-text) !important;
        }
        &.blackcolor {
          color: #000000;
        }
        &.normal {
          color: var(--fourth-text-color);
        }
        &:hover {
          color: var(--seventh-colorful-text) !important;
        }
      }
      .option__sections {
        width: 19rem;
        // background-color: var(--alpha-secondary-dropdown-bg-color);
        background: #13263E;
        position: absolute;
        top: 3rem;
        z-index: 110;
        display: none;
        left: 0;
        &.light_mode {
          background-color: #ffffff;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
          border: 1px solid #f0f0f0;
        }
        &.active {
          display: block;
        }

        .option {
          display: block;
          font-weight: 400;
          font-size: 14px;
          line-height: 32px;
          padding: 0.5rem 2rem;
          color: #fff;
          &.light_mode {
            color: #000 !important;
          }
          &:hover {
            color: var(--seventh-colorful-text) !important;
          }
        }
      }
    }
    .margin__handle{
      margin-right: 4rem;
    }
    .link {
      font-weight: 400;
      font-size: 16px;
      margin-left: 4rem;
      color: #fff;
      cursor: pointer;
      &:hover {
        color: var(--seventh-colorful-text) !important;
      }

      &.mr-4 {
        margin-right: 4rem;
      }
      &.active {
        color: #000000;
      }
      &.normal {
        color: var(--fourth-text-color);
      }
    }
    .support__link{
      // margin-left: 0;
      margin-right: 4rem;
    }
    .my__account{
      margin-right: 4rem;
      margin-left: 0;
    }
    .theme__mode__button {
      border: none;
      outline: none;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      // margin-left: 4rem;
      margin-right:  2rem;
    }
    .logout {
      width: 1.5rem;
    }
    .light {
      .moon__icon {
        width: 1.8rem;
        color: #000;
      }
      background-color: #E4E4E4;
      &:hover {
        background-color: #232323;
      }
      &:hover .moon__icon {
        color: #d0d5dd;
      }
    }
    .dark {
      .sun__icon {
        width: 1.8rem;
        color: #d0d5dd;
      }
      background-color: #0B1A2B;
      &:hover {
        background-color: #d0d5dd;
      }
      &:hover .sun__icon {
        color: #232323;
      }
    }
    .user__profile__container {
      position: relative;
      cursor: pointer;
      @media screen and (max-width:400px) {
        margin-right: 2rem;
      }
      .user__profile{
        position: relative;
        .user__profile__picture{
          height: 32px;
          width: 32px;
          .user__first__letter{
            height: 100%;
            width: 100%;
            // background:var(--first-letter-bg-color);
            background: var(--alpha-primary-first-letter-bg-color);
            // Light mode -- bg color :#E4E4E4
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: var(--fourth-text-color);
          }

        }
      }

      .dropdown {
        z-index: 100;
        width: 17rem;
        padding: 2rem;
        position: absolute;
        border-radius: 0.5rem;
        // background-color: var(--header-drop-bg-color);
        background-color: var(--alpha-ternary-dropdown-bg-color);
        top: 5rem;
        right: 0rem;
        border: none;
        box-shadow: 0px 2px 8px rgb(0 0 0 / 8%);
        @media screen and (max-width:1000px) {
          right: -4rem;
        }
        .drop_item {
          display: flex;
          align-items: center;
          a{
            display: flex;
            align-items: center;

          }
        }
        .gap__handle{
          margin-top: 2rem;
        }
        // .divider_item {
        //   // margin-top: 2rem;
        //   margin: 1rem 0;
        //   border-top: 1px solid var(--header-drop-divider);
        // }
        .align {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        .drop_icon {
          width: 1.8rem;
          color: var(--header-drop-text-color);
        }
        span {
          font-size: 1.4rem;
          font-weight: 400;
          margin-left: 1rem;
          color: var(--header-drop-text-color);
        }
      }
    }
 


  }
}
.scroll__button {
  all: unset;
  cursor: pointer;
  position: fixed;
  bottom: 10rem;
  right: 3rem;
  width: 40px;
  height: 40px;
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  background: var(--seventeen-bg-color);
  border-radius: 5px;
  z-index: 2;
  // @media screen and (max-width:850px) {
  //   right: 2.5rem;
  //   bottom: 14rem;
    
  // }
  &.visible {
    visibility: visible;
  }
  .up__icon {
    color: var(--fifth-colorful-text);
  }
}
.chatbot__icon__wrapper{
  width: 60px;
  height: 60px;
  display: block;
  @media screen and (max-width:500px) {
    display: none;
    
  }

  .chatbot__div{
    cursor: pointer;
    position: fixed;
    bottom: 3rem;
    right: 2rem;
    width: 48px;
    height: 48px;
    background: linear-gradient(144.41deg, #FE7268 10.2%, #53C4C7 90.26%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    z-index: 100;
    img{
      height: 24px;
      width: 24px;
    }
    // @media screen and (max-width:850px) {
    //   right: 2rem;
    //   bottom: 8rem;
    //   height: 50px;
    //   width: 50px;
      
    // }
  }

}
.mobile__chatbot__icon__wrapper{
  width: 60px;
  height: 60px;
  display: none;
  @media screen and (max-width:500px) {
    display: block;
    
  }
  .mobile__chatbot__div{
    cursor: pointer;
    position: fixed;
    bottom: 2rem;
    right: 4rem;
    width: 60px;
    height: 60px;
    background: linear-gradient(144.41deg, #FE7268 10.2%, #53C4C7 90.26%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    z-index: 100;
    @media screen and (max-width:500px) {
      right: 2rem;
      // bottom: 8rem;
      height: 50px;
      width: 50px;
      
    }
  }

}
.desktop__chatbot{
  display: block;
  @media screen and (max-width:500px) {
    display: none;
    
  }

}
.mobile__chatbot__wrapper{
  display: none;
  @media screen and (max-width:500px) {
    display: block;
    
  }

}
.drawer__container {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  overflow: hidden;
  background-color: rgba($color: #000000, $alpha: 0.4);
  transition: width 0.2s ease-in-out;
  z-index: 10000 !important;
  &.active {
    width: 100%;
    opacity: 1;
    visibility: visible;
  }
  &.hide {
    opacity: 0;
    visibility: hidden;
  }

  .card__column {
    height: 100vh;
    width: 0;
    overflow: hidden;
    padding: 2.4rem;
    background-color: #232323;
    z-index: 1000000;
    position: relative;
    transition: width 1s ease-in-out;
    position: relative;

    &.active {
      transition: width 0.1s ease-in-out;
      width: 30rem;
      visibility: visible;
    }
    &.hide {
      transition: width 0.1s ease-in-out;
      width: 0;
      visibility: visible;
    }
    .closing__icon {
      width: 2rem;
      color: #667085;
      margin-left: auto;
      position: absolute;
      right: 2rem;
      top: 1.5rem;
      cursor: pointer;
      z-index: 1000;
    }
    .user__info__section {
      margin-top: 2.5rem;
      .user__image {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
      span{
        color: #ffffff;
      }
      .name {
        display: block;
        font-weight: 500;
        font-size: 14px;
      }
      .email {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #6f6ff1;
      }
    }
    .drawer__divider {
      width: 100%;
      height: 1px;
      background: linear-gradient(
        236.79deg,
        rgba(255, 69, 147, 0.2) 3.16%,
        rgba(255, 231, 19, 0.2) 30.23%,
        rgba(23, 215, 255, 0.2) 46.88%,
        rgba(7, 123, 255, 0.2) 69.78%
      );
      border-radius: 5px;
      &.mb-2 {
        margin-bottom: 2rem !important;
      }
    }
    .link {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin: 2rem 0;
      text-transform: capitalize;
      color: #ffffff;
      &.sub_link {
        margin-left: 2px;
      }
    }
    .logout__section {
      color: #bababa;
      .logout__button {
        display: flex;
        align-items: center;

        .logout__text {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          margin-left: 1rem;
        }
      }
    }
    .all__link__wrapper {
      height: calc(100% - 13rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .drawer__quick__link__wrapper {
      height: 2.5rem;
      overflow: hidden;
      transition: all 0.5s ease-in-out !important;
      &.active__drawer {
        height: auto;
      }
      .quick__linK__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          .quick__link {
            color: #ffc97a;
          }
          .down__icon {
            color: #ffc97a;
          }
        }
        .quick__link {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
          .active__drawer {
            color: #ffc97a;
          }
        }
        .down__icon {
          width: 2rem;
          color: #ffff;
          .active__drawer {
            color: #ffc97a;
          }
        }
      }
    }
  }
}
