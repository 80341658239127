@import "./variables/default.scss";
@import "./variables/indigo.scss";
@import "./variables/azure.scss";
@import "./variables/misteygrey.scss";
* {
  // padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: 1.5;
}
html {
  // font-size: 62.5%;
  font-size: 10px !important;
  -webkit-font-size: 10px !important;
  scroll-behavior: smooth;

  a {
    text-decoration: none;
    color: inherit;
  }
}

.rating__star__size {
  width: 2rem;
}
.elemName {
  &:hover {
    cursor: pointer;
  }
}
.custom__scrollbar {
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: var(--thunmb-color);
  }
  &::-webkit-scrollbar-track {
    background-color: var(--track--color);

    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
}

.spinner {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 3px solid #ddd;
  border-right-color: rgb(110, 108, 108);
  animation: spin 0.5s linear infinite;
}
.spinner2 {
  display: inline-block;

  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 3px solid #ddd;
  border-right-color: rgb(110, 108, 108);
  animation: spin 0.5s linear infinite;
}

.spinner3 {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 3px solid #ddd;
  border-right-color: rgb(110, 108, 108);
  animation: spin 0.5s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.hide__text {
  color: transparent;
}

.mt-1 {
  margin-top: 1rem;
}
.mt-1-5 {
  margin-top: 1.5rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mt-5 {
  margin-top: 5rem;
}
.mt-6 {
  margin-top: 6rem;
}
.mt-7 {
  margin-top: 7rem;
}
.mt-8 {
  margin-top: 8rem;
}
.mt-9 {
  margin-top: 9rem;
}
.mt-10 {
  margin-top: 10rem;
}

.mr-2 {
  margin-right: 2rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.ml-3 {
  margin-left: 3rem;
}
.ml-3 {
  margin-left: 4rem;
}
.ml-5 {
  margin-left: 5rem;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.boxshadow {
  margin: 0 2rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
}
.globl__margin {
  margin: 0 2rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  min-height: 45rem;
}
@mixin draw-progress--solid($bgcolor, $progress) {
  background-image: conic-gradient(
    from 181.66deg at 52% 50%,
    $bgcolor $progress,
    #f3726a 0deg,
    #fec97c 165deg,
    #53c4c7 223.13deg,
    #f3726a 360deg
  );
}
.progress__bar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #2c2c2c;
  @for $i from 0 through 100 {
    &.progress-#{$i} {
      @include draw-progress--solid(
        var(--alpha-tweleth-bg-color),
        #{100 - $i + "%"}
      );
    }
  }
}
.skeleton-filter-box {
  width: 100%;
  height: 177px;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 8%);
  background-color: var(--header-drop-bg-color);
  padding: 20px;
  display: flex;
  gap: 20px;
  .skeleton-img {
    width: 137px;
    height: 137px;
    border-radius: 5px;
  }
  .skeleton-txt {
    padding-top: 0px;
    width: 50%;
    .skeleton-title {
      width: 80%;
      height: 25px;
      border-radius: 5px;
    }
    .skeleton-duration {
      margin-top: 10px;
      width: 40%;
      height: 25px;
      border-radius: 5px;
    }
    // .skeleton-desc {
    //   margin-top: 10px;
    //   width: 100%;
    //   height: 65px;
    //   border-radius: 5px;
    // }
  }
}

.skeleton-myprogress-box {
  display: flex;
  gap: 15px;
  .skeleton-mp-img {
    width: 63px;
    height: 63px;
    border-radius: 5px;
  }
  .skeleton-mp-text {
    .skeleton-mp-title {
      height: 20px;
      width: 150px;
      margin-bottom: 5px;
      border-radius: 5px;
    }
    .skeleton-mp-desc {
      height: 10px;
      width: 100px;
      border-radius: 5px;
    }
  }
}

.skeleton-table-row {
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #000;
}

//  jodit editor customization

.jodit-ui-button__text {
  width: 6rem !important;
}
.jodit-ui-input__input {
  height: 3.5rem !important;
}
.jodit-ui-input__wrapper {
  border: none !important;
  background: transparent !important;
}
.jodit-ui-button_variant_primary {
  background-color: #6f6ff1 !important;
}

//  end of  jodit editor customization

.loader-div {
  // width: 100%;
  // height: 100%;
  // transform: translate(-50%, 50%);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}
