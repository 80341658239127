.view__group__wrapper {
  margin: 2rem;
  margin-top: 0;
  background-color: var(--secondary-bg-color);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  .header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title__section {
      display: flex;
      align-items: center;
      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
      }
      .back__icon {
        width: 2rem;
        margin-right: 0.5rem;
        cursor: pointer;
        margin-left: -0.5rem;
      }
    }
  }
  .content__section {
    display: flex;
    margin-top: 3rem;
    .left__section {
      width: 50rem;
    }
    .member__section {
      .members {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: var(--primary-colorful-text);
      }
      .member__list {
        .team__member__info {
          vertical-align: top;
          border-bottom: 1px solid var(--primary-divider-color);
          .name_and_avatar_section {
            padding: 1.5rem 0;

            // width: 100%;
            display: flex;
            img {
              width: 3rem;
              height: 3rem;
              border-radius: 50%;
            }
            .name__section {
              margin-left: 1rem;
              .name {
                display: block;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
              }
              .user_name {
                display: block;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #43ade8;
              }
            }
          }
        }
      }
    }
    .right__section {
      width: 100%;
      margin-left: 4rem;
    }
    .course__detail__section {
      padding: 2rem;
      background-color: var(--ninth-bg-color);
      border-radius: 0.5rem;
      display: flex;

      .colorful__title {
        display: block;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: var(--primary-colorful-text);
      }
      .content__list__section {
        margin-top: 0.5rem;
        .content {
          display: flex;
          align-items: center;
          padding: 0.5rem 0;
          .dot {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: var(--fifth-text-color);
            margin-right: 1rem;
          }
          .name {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            text-transform: capitalize;
          }
        }
      }
      .path__section {
        width: 50%;
      }
      .course__section {
        width: 50%;
      }
    }

    .deadline__section {
      margin-top: 3rem;
      padding: 2rem;
      background-color: var(--ninth-bg-color);
      border-radius: 0.5rem;
      .colorful__title {
        display: block;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: var(--primary-colorful-text);
      }
      .deadline__contents {
        margin-top: 1rem;
        display: grid;
        grid-template-columns: auto auto;
        gap: 2rem;
        .contnet__section {
          .date__section {
            display: flex;
            align-items: center;
          }
          .name {
            display: block;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
          }
          .calendar {
            width: 1.5rem;
            color: #6f6ff1;
            margin-right: 1rem;
          }
          .deadline {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #6f6ff1;
          }
        }
      }
    }
  }
}
