.user__table__header_container {
  padding: 1.8rem 2rem;
  display: flex;
  align-items: center;
  background-color: var(--secondary-bg-color);
  justify-content: space-between;
  @media screen and (max-width:850px) {
    flex-direction: column;
    
  }

  .right__section {
    display: flex;
    align-items: center;
    @media screen and (max-width:850px) {
      margin-top: 1rem;
      
    }

    .search__box {
      width: 25rem;
      margin-right: 2rem;
      @media screen and (max-width:450px) {
        width: 80%;
        // margin-top: 1.2rem;
        margin: 0;
       }
    }
    .button__witdh__handle {
      width: 12rem;
      // display: flex;
      margin-right: 1.2rem;
      @media screen and (max-width:450px) {
        width: 80%;
        margin-top: 1.2rem;
       }
      
    }
    .dorpdown__handle{
      position: relative;
      .download__user__file__btn__width__handle{
        margin-right: 1.2rem;
        .download__user__file__btn{
          all: unset;
          background: var( --download-user-file-bg-color);
          border-radius: 8px;
          padding: 0.8rem 1.4rem;
          cursor: pointer;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #FFFFFF;
          &.disable{
            color: #667085;
            background: var(--plan-bg-color);
            border: 1px solid var(--secondary-dropdown-border);
            cursor: not-allowed;

          }
          
  
        }
        .filter__button{
          border: 1px solid var(--primary-border-color);
          outline: none;
          color: var(--primary-input-text-color);
          background: transparent;
          padding: 0.8rem 1.6rem;
          font-size: 1.4rem;
          border-radius: 0.8rem;
          font-weight: 500;
          cursor: pointer;
          display: flex;
          align-items: center;
          .filter__icon{
            height: 20px;
            width: 20px;
            margin-right: 5px;
          }
        }
      }
      .download__user__file__download__dropdown__wrapper{
        position: absolute;
        top: 50px;     
        background: var(--twentyseven-bg-color);
        box-shadow:  0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 12px 15px;
        border: 1px solid var( --notification-header-border-color);
        width: 280px;
        left: 12px;
        z-index: 1;
  
        .download__user__file__download__dropdow{
          .checkboxes__container{
            &.mb-15{
              margin-bottom: 15px;
            }
            &.mb-20{
              margin-bottom: 20px;
            }
            p{
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0em;

            }
            .checkboxes{
              display: flex;
              margin-top: 10px;
              gap: 58px;
       
            }
            .filter__header{
              display: flex;
              justify-content: space-between;
              .filter__close{
                cursor: pointer;
                height: 18px;
                width: 18px;
              }

            }
          }
          .divider__line{
            margin: 15px 0;
            height: 1px ;
            background: var(--profile-divider-color);
            width: 100%;
          }
          .filter__reset{
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #bababa;
            cursor: pointer;

          }
          .checkbox__wrapper__div{
            .checkbox__container{
              .checkbox {
                .checkbox__icon {
                  border: 1px solid #43ADE8;
                }
              }
            }
          }
          .download__btn__width{
            button{
              background: #43ADE8;
            }
          }
  
        }
  
      }

    }
    .clear__button{
      border: 1px solid var(--primary-border-color);
      outline: none;
      color: #F04438;
      background: transparent;
      padding: 0.8rem 1.4rem;
      font-size: 1.4rem;
      border-radius: 0.8rem;
      font-weight: 500;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-right: 1.2rem;
      .clear__filter{
          height: 15px;
          width: 15px;
          margin-left: 5px;
      }

    }

  }
  .user__table__right__section{
      display: flex;
      .vertical__line{
        width: 0.5px;
        background: var(--secondary-table-header-color);
        margin: 0 12px;
        height: 38px;
      }
      .deleteall__cancel__btn{
        display: flex;
        gap: 6px;
        button{
          all: unset;
          // border: 1px solid var( --delete-btn-border-clr);
          padding: 0.8rem 1.2rem;
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0em;
          border-radius: 8px;
          // color: var( --delete-btn-border-clr);


          color: #fff;
          background: #6f6ff1;
          border: 1px solid transparent;


          cursor: pointer;
          gap: 4px;
          transition: 0.3s;
          .delete__icon__div{
            margin-top: -2px;
          }
          &.activeDeleteAllbtn{
            color: #667085;
            background: var(--plan-bg-color);
            border: 1px solid var(--secondary-dropdown-border);
            cursor: not-allowed;
            
          }
          &.cancel__button{
            color: #F04438;
            padding: 0.8rem 1.6rem;
            background: transparent;
            border: 1px solid #F04438;
            // &:hover{
            //   border: 1px solid #F04438;
            // }

          }

        }
      }
    @media screen and (max-width:850px) {
      gap: 10px;
      flex-direction: column;
    }
  }
  .roles__boxes{
    margin-bottom: 1.8rem;
    .select__role__text{
      display: block;
      margin-bottom: 6px;
      font-size: 1.4rem;
      font-weight: 400;
      color: var(--input-label-color);
      font-style: normal;
      line-height: 20px;

    }
    .role__checkboxes{
      // display: flex;
      // justify-content: space-between;
      display: grid;
      // flex-wrap: wrap;
      grid-template-columns: repeat(4,1fr);
      gap: 3rem;
      .checkbox__container{
        .checkbox {
          .checkbox__icon {
            border: 1px solid #43ADE8;
          }
          .label{
            white-space: nowrap;
          }
        }
      }

    }
    .error__message {
      font-size: 1.4rem;
      color: var(--danger-color);
      font-weight: 400;
      margin-top: 5px;
    }

  }
  .modal_footer {
    display: flex;
    align-items: center;
    // margin-left: auto;
    .resend__invitation {
      margin-left: 22rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: #6f6ff1;
      text-decoration-line: underline;
      text-underline-offset: 4px;
      margin-top: 0.5rem;
      cursor: pointer;
      @media screen and (max-width: 480px) {
        margin-left: 1rem;
      }
    }
    .left-margin {
      margin-left: 28rem !important;
      margin-top: 0.5rem;
    }
    .button__witdh {
      width: 10rem;
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 480px) {
      width: 100%;
      flex-direction: column;
    }
    .sm_width {
      width: 48.5%;
      @media screen and (max-width: 480px) {
        width: 100%;
      }
      .css-1n929ud-Input {
        color: red !important;
        border: none;
        background-color: transparent;

      }
    }
  }
}
.add_button__witdh {
  width: 7rem !important;
  margin-left: auto;
  &.update {
    width: 8.8rem !important;
  }
}
