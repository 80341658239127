.empty__div {
  height: 22rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .empty__text {
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #667085;
  }
}
.OrgUserLearningPath__wrapper {
  padding: 2rem;
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 50% auto;
  gap: 2rem;
  @media screen and (max-width: 820px) {
    grid-template-columns: auto;
  }
  a{
    text-decoration: none;
    color: inherit;
  }
  .bundle__wapper {
    padding: 2rem;
    display: flex;
    cursor: pointer;
    // background-color: #232323;
    background-color: var(--secondary-bg-color);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    .cover__img {
      width: 12rem;
      height: 12rem;
      border-radius: 5px;
      object-fit: cover;
      @media screen and (max-width: 480px) {
        width: 6rem;
        height: 6rem;
      }
    }
    .bundle__info__section {
      padding: 0 2rem;
      @media screen and (max-width: 480px) {
        padding: 0 1rem;
      }
      .bundle__title {
        display: block;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
      .bundle__count {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ffc97a;
        color: var(--primary-colorful-text);
        margin: 1rem 0;
      }
      .bundle__description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        // color: #d0d5dd;
        color: var(--primary-course-title-color);
      }
      .bundle__divider {
        border-top: 1px solid #303030;
        margin: 1rem 0;
      }
      .bundle__deadline {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #d0d5dd;
        color: var(--filter-show-text);
      }
    }
  }
}
