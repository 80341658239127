.audio__empty__wrapper {
  width: 100%;
  margin-top: 0 !important;
  margin: 8px 0px;
  background-color: var(--video-record-bg-color);

  padding: 10px;
  position: relative;
  margin-bottom: 0px;
  position: relative;
  .audio__empty__container {
    width: 100%;
    height: 40rem !important;
    background: #08111c;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      height: 15rem;
      width: 15rem;
    }
    p {
      font-family: "Inter";
      font-size: 13px;
      font-weight: 400;
      line-height: 19.36px;
      letter-spacing: 0.004em;
      text-align: center;
      margin-top: 4rem;
      color: #8e97a9;
    }
  }
}
