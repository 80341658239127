.new__academic__archive__padding__wrapper {
  padding: 0 120px;
  padding-top: 130px;
  min-height: 100vh;
  padding-bottom: 110px;
  background: var( --new-secondary-bg-color);
  @media screen and (max-width: 820px) {
    padding: 120px 0;
  }
  .table__wapper {
    margin-top: 40px;
    width: 100%;
    display: flex;
    padding-bottom: 4rem;
    // justify-content: center;
    @media screen and (max-width:1100px) {
      flex-direction: column;

      
    }
    @media screen and (max-width:820px) {
      margin-top: 19px;
      padding: 0 4rem;
      
    }
    @media screen and (max-width:620px) {
      padding: 0 2rem;
      
    }
  }
}
.info__section {
  width: 100%;
  margin: auto;
  // margin-top: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
  }
  .info {
    width: 650px;
    text-align: center;
    @media screen and (max-width: 820px) {
      padding: 30px;
      width: 100%;
      padding-bottom: 0px;
    }
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--ninth-text-color);
  }
}
