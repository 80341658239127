.faq__container {
  width: 100%;
  .row {
    width: 10rem;
    margin-left: auto;
  }

  .button_row {
    width: 8rem;
    margin-left: auto;
  }
}
