.preview__theme{
    .preview__theme__switch{
        display: flex;
        align-items: center;
        .darklight{
            color: var(--primary-placeholder-color);
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
        .switch {
            --secondary-container: #43ADE8;
            --primary: #fff;
            font-size: 17px;
            position: relative;
            display: inline-block;
            width: 3.2rem;
            height: 1.6rem;
            margin: 0 8px;
           }
           
           .switch input {
            display: none;
            opacity: 0;
            width: 0;
            height: 0;
           }
           
           .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(  --secondary-table-header-color);
            transition: .2s;
            border-radius: 30px;
           }
           
           .slider:before {
            position: absolute;
            content: "";
            height: 1.3rem;
            width: 1.3rem;
            border-radius: 20px;
            left: 0.2rem;
            bottom: 0.15rem;
            background-color: #fff;
            transition: .4s;
           }
           
           input:checked + .slider::before {
            background-color: var(--primary);
           }
           
           input:checked + .slider {
            background-color: var(--secondary-container);
           }
           
           input:focus + .slider {
            box-shadow: 0 0 1px var(--secondary-container);
           }
           
           input:checked + .slider:before {
            transform: translateX(1.5rem);
           }
    }
    .theme__preview__image__section{
        margin-top: 15px;
        // position: relative;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        .theme__preview__image__wrapper{
            .theme__preview__image{
                img{
                    width: 100%;
                    max-width: 100%;
                    object-fit: cover;
                    max-height: 590px;
                }
            }
        }
        .image__slider__button{
            all: unset;
            position: absolute;
            top: 50%;
            cursor: pointer;
            
            svg{
                color: var(--alpha-primary-arrow-color);
                &:hover{
                    color: #BABABA;
                  }
            }
        }
        .left__button{
            left: 0;

        }
        .right__button{
            right: 0;

        }
        .disable__btn{
            svg{
                color:var(--alpha-disable-btn-color) ;
            }
        }
        // 
    }

}
.theme__switch__modal__width__handle{
    width:  976px;
    .modal__body{
        width: 100%;
    }
}
