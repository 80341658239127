#sidbar {
  position: absolute;
  display: flex;
  width: 100%;
  height: calc(113vh + 300px);
  // height: 100%;
  background-color: var(--primary-bg-color);
  left: 0;
  top: 0;
  z-index: 10000;
  overflow-x: hidden;
  display: flex;
  &.active {
    background: rgba(0, 0, 0, 0.7);
    @media screen and (max-width:1370px) {
      height: calc(100vh + 300px);
      
  }
    @media screen and (max-width:1024px) {
      height: calc(100vh + 900px);
        
    }
    @media screen and (max-width:426px) {
      height: calc(100vh + 1100px);
        
    }
  }
  .allQuestionSection {
    width: 370px;
    height: 100%;
    padding: 20px 25px;
    background-color: var(--new-secondary-bg-color);
    overflow-y: auto;
    transition: all 0.5s;
    animation: left-right 1s ease-in-out;
    // border: 1px solid green;
    &::-webkit-scrollbar {
      background: transparent;
    }

    .allQuestion {
      width: 100%;
      // padding-bottom: 10px;
      .headings {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: var(--seventeen-bg-color);
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: var(--primary-placeholder-color);
        }
      }
    }

    .questions {
      margin-top: 16px;
      .question {
        width: 320px;
        padding: 15px;
        cursor: pointer;
        // margin: 10px 0px;
        margin-bottom: 10px;
        background: var(--alpha-primary-gradient-bg-color);
        border: 1px solid var(--alpha-primary-border-color);

        p {
          font-size: 14px;
          font-weight: 400;
          &:hover{
            color: var( --ternary-table-header-color);
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 1024px) {
//   #sidebar {
//     &.active {
//       height: 121vh;
//     }
//   }
// }
// @media screen and (max-width: 768px) {
//   #sidebar {
//     &.active {
//       height: 121vh;
//     }
//   }
// }

// @media screen and (max-width: 426px) {
//   #sidebar {
//     &.active {
//       height: 121vh;
//     }
//   }
// }

// Animation
// @keyframes left-right {
//   from {
//     transform: translateX(-100%);
//   }
//   to {
//     transform: translateX(0%);
//   }
// }
