.mdal {
  height: 100%;
}

.desc-textarea {
  min-height: 24rem;
}
.fields-forms {
  display: flex;
}
.selectField_blogg {
  width: 50%;
}

.checkbox_sec {
  // border: 1px solid;
  width: 100%;
  margin-right: auto;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  padding: 10px;
  .checks {
    display: flex;
    margin-right: auto;
    margin-left: 10px;
    position: relative;
    width: 20%;
    .checkboxx {
      position: absolute;
      left: 0;
    }
  }
}
.editor-wrapper {
  margin-top: 1rem;
  border: 1px solid var(--primary-border-color);
  .jodit-react-container{
    .jodit-container {
      a{
        color: #6f6ff1;
        text-decoration: underline;
      }
    }
  }
}
.add_button__witdh {
  width: 8rem !important;
  margin-left: auto;
  &.update {
    width: 8.8rem !important;
  }
}
.login__form__container {
  // background-color: var(--seventh-bg-color);
  margin-top: 2rem;
  padding: 2rem;
  .upper__section {
    display: flex;
    @media screen and (max-width: 480px) {
      width: 100%;
      flex-direction: column;
    }
    .left__section {
      width: calc(100% - 31rem);
      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
    .right__section {
      width: 31rem;
      margin-top: 3rem;
      margin-left: 2rem;
      @media screen and (max-width: 480px) {
        margin-left: 0;
        margin-bottom: 2rem;
      }
      .file_fieldd {
        // border: 1px solid #fff !important;
        width: 100%;
        height: 23rem;
        background-color: var(--primary-input-bg-color);
        border: 1px solid var(--primary-border-color);
        &.error {
          border: 1px solid var(--danger-color);
        }

        border-radius: 1rem;
        .file_input_design {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
        .logout_icon {
          color: var(--primary-border-color);
          font-size: 1.5rem;
          cursor: pointer;
          transform: rotate(270deg);
          width: fit-content;
          margin-top: 1rem;
        }
        .clik_text {
          color: var(--primary-border-color);
          font-size: 1.5rem;
          cursor: pointer;
          width: fit-content;
          margin-top: 1rem;
          margin-bottom: 0.5rem;
        }
        .filefield {
          display: none;
        }
        .thumbnail__section {
          // border: 1px solid red;
          padding: 1rem;
          padding-bottom: 0;
          .thumbnail__image {
            width: 100%;
            height: 18rem;
            object-fit: cover;
            border-radius: 0.5rem;
            cursor: pointer;
            // border: 1px solid #fff;
          }
          .remove__image {
            line-height: 17px;
            display: block;
            text-align: center;
            text-decoration-line: underline;
            text-underline-offset: 4px;
            text-align: right;
            color: #fe7268;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.seo_section_editor {
  margin-top: 30px;
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  }
  .sse_row {
    display: flex;
    gap: 16px;
    .sse1 {
      width: 100%;
    }
    .sse2 {
      width: 100%;
    }
  }
}
.blog__img__size__recomemded {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #bababa;
}
