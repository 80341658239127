.mobileview__chatbot__wrapper__div{
    height: 100vh;
    max-height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    top: 0;
    // border: 10px soli red;
    position: fixed;
    z-index: 10000000000;
    .mobileview__chatbot__container__div{
        width: 100%;
        // background: var( --seventh-bg-color);
        // height: 500px;
        max-height: 90vh;
        height: 80vh;
        position: fixed;
        bottom: 0;
        border-radius: 20px 0px 0px 0px;
    }
    .cross__icon{
        height: 3rem;
        .cross__icon__handle{
            position: fixed;
            right: 0px;
            height: 4rem;
            width: 3.5rem;
            // top: 64px;
            // background: var( --seventh-bg-color);
            background: var(--alpha-primary-modal-bg-color);
            // left: 374px;
            border-radius: 10px 0px 0px 0px;
            padding-top: 6px;
            svg{
                color: #BABABA;
                height: 1.8rem;
                width: 1.8rem;
                // background: var( --seventh-bg-color);
                margin-left: 8px;
            }

        }

  


    }
    .chatbot__controls{
        background: #2C2C2C;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        // border-radius: 20px 0px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1.5rem 0;

        .modify__controls{
            margin-right: 2.5rem;
            .edit__icon{
                margin-right: 1.5rem;
            }
            svg{
                height: 2.2rem;
                width: 2.2rem;
                color: #babababa;
            }
        }
    }

}