.user_side_course_card {
  width: 263px;
  height: 221px;
  padding: 18px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: var(--thirteen-bg-color);
  border: 1px solid var(--urc-card-border-bottom);
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translateY(-5px);
    // transition: all 0.3s ease-in-out;
  }
  .cc-title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    // color: #ffffff;
    cursor: pointer;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &:hover {
      color: #ffc97a;
    }
  }
  .cc-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    // color: #bababa;
    color: var(--your-recent-courses);
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .cc-bottom {
    border-top: 1px dashed var(--alpha-secondary-divider-color);
    padding-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-left {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--course--tag--title);
      display: flex;
      align-items: center;
      gap: 7px;
      svg {
        height: 14px;
        width: 14px;
      }
    }
    &-right {
      all: unset;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #6f6ff1;
      span {
        color: #ffc97a;
      }
      &:hover {
        color: #ffc97a;
      }
    }
  }
}
