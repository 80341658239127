
.course__progress__wrapper{
  background: var(--new-secondary-bg-color);
  min-height: 100vh;
}
.course-progress-top {
  background-color: var(--alpha-fourteen-bg-color);
  padding-top: 132px;
  padding-bottom: 65px;
  .cpt {
    display: flex;
    width: 80%;
    max-width: 90%;
    margin: 0 auto;
    gap: 175px;
    flex-wrap: wrap;
    @media screen and (max-width: 1200px) {
      gap: 100px;
    }
    .cpt-left {
      h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: var(--primary-text-color);
        margin-bottom: 20px;
      }
      > p {
        width: 635px;
        max-width: 90%;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary-dropdown-text);
        margin-bottom: 30px;
        @media screen and (max-width:1100px) {
          width: 100%;
          
        }
      }
      .button__width {
        min-width: 14rem !important;
        max-width: 19rem;
      }
    }
    .cpt-right {
      .cpt-r {
        margin-bottom: 10px;
        .cpt-r-top {
          font-weight: 600;
          font-size: 14px;
          line-height: 28px;
          color: var( --alpha-primary-text-color);
          margin-bottom: 1px;
        }
        .cpt-r-bottom {
          width: 229px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #d0d5dd;
          color: var(--third-text-color);
        }
      }
    }
    .other__courses__role__wrapper{
      width: 436px;
      background:var(--trial-user-card-bg-color);
      padding: 15px;
      border: 1px solid var(--trial-user-card-border-clr);
      @media screen and (max-width:1340px) {
        width: 100%;
        margin-top: 20px;
        
      }
      .other__courses__role__container{
        h1{
          color: var( --fourth-text-color);
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;

        }
        .contents__btn__wrapper{
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          @media screen and (max-width:700px) {
           flex-direction: column;
           gap: 10px;
          }
          .info__text{
            color: var(--third-text-color);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            width: 50%; 
            @media screen and (max-width:1340px) {
              width: 65%; 
            }
            @media screen and (max-width:700px) {
              width: 100%; 
            }

          }
          .buttons__wrapper{
            display: flex;
            flex-direction: column;
            gap: 10px;
            @media screen and (max-width:700px) {
              flex-direction: row;
             }
             @media screen and (max-width:460px) {
              flex-direction: column;
             }
            .btn__width{
              width: 18rem;
              @media screen and (max-width:460px) {
                width: 100%;
               }
              .sky_button{
                width: unset;
                margin: unset;
                width: 100%;
                font-size: 14px;
                height: 36px;
              }
            }

          }

        }

      }

    }
  }
  .cpt__handle{
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    justify-content: space-between;
    
  }
  .cpt-line {
    background: linear-gradient(
      236.79deg,
      rgba(255, 69, 147, 0.2) 3.16%,
      rgba(255, 231, 19, 0.2) 30.23%,
      rgba(23, 215, 255, 0.2) 46.88%,
      rgba(7, 123, 255, 0.2) 69.78%
    );
    border-radius: 5px;
    height: 1px;
    width: 80%;
    margin: 79px auto 25px auto;
  }
  .cpt-line-bottom {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1150px) {
      gap: 50px;
    }
    gap: 100px;
    .cpt-line-b {
      .cpt-txt {
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        color: var( --alpha-primary-text-color);
        margin-bottom: 19px;
      }
      .cpt-icon-txt-container {
        display: flex;
        align-items: center;
        gap: 30px;

        .cpt-icon-txt {
          display: flex;
          gap: 8px;
          align-items: center;
          .cpt-icon {
            object-fit: cover;
            width: 30px;
            height: 30px;
            overflow: hidden;
            background-color: rgb(77, 77, 77);
            border-radius: 50%;
            color: transparent;
          }
          .cpt-text-icon {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            // color: #ffffff;
            color: var(--primary-text-color);
          }
        }
      }
    }
  }
}

.course-progress-bottom {
  padding-top: 120px;
  padding-bottom: 112px;
  background: var( --alpha-thirteen-bg-color);
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    color: #ffffff;
    color: var(--primary-text-color);
    margin-bottom: 55px;
  }
  .cpb {
    width: 80%;
    margin: 0 auto;
    .cpb-list {
      .cpb-container {
        min-height: 70px;
        background: linear-gradient(
          132.06deg,
          rgba(255, 255, 255, 0.09) 2.05%,
          rgba(82, 80, 80, 0.114) 68.52%
        );
        background: var(--urc-card-bg);
        border: 1px solid var(--urc-card-border);
        margin-bottom: 20px;
        padding: 20px 30px;

        .cpb-head {
          all: unset;
          cursor: pointer;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:hover {
            color: #fec97c;
          }
          .cpb-icon {
            svg {
              height: 12px;
              width: 12px;
            }
          }
        }
        .cpb-body {
          display: none;
          .cpb-line {
            border: 1px solid var(--alpha-seventh-divider-color);
            width: 100%;
            margin: 15px 0;
          }
          .ull {
            padding-left: 2rem;
          }
        }
        .d-block {
          display: block;
        }
      }
    }
  }
}
