.UserLearningPathCourses__wrapper {
  padding: 2rem;
  padding-top: 0;
  display: flex;
  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  .courses__wrapper {
    width: 70%;
    background-color: var(--secondary-bg-color);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);

    padding: 1rem;
    padding-top: 2rem;
    @media screen and (max-width: 820px) {
      width: 100%;
    }
    .header__section {
      all: unset;
      display: flex;
      align-items: center;
      .back__icon {
        width: 2rem;
        margin-right: 0.3rem;
        margin-left: 0.5rem;
        cursor: pointer;
      }
      .header__title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
      }
      margin-bottom: 0.5rem;
    }
    .stepper__container {
      background-color: var(--secondary-bg-color);
      //   @media screen and (max-width: 768px) {
      //     width: 100%;
      //   }
      padding: 2rem;
      .course__title__section {
        margin-bottom: 2rem;
        img {
          width: 1.5rem;
          margin-left: -2rem;
          transform: rotate(90deg);
          cursor: pointer;
        }
        display: flex;
        .course__title {
          font-size: 1.6rem;
          font-style: medium;
          margin-left: 0.5rem;
          color: var(--primary-input-text-color);
          text-transform: uppercase;
        }
      }
      .stepper {
        border: 2px dashed var(--dashed--border-color);
        border-top: 1px solid transparent;
        border-right: none;
        border-bottom: 1px solid transparent;
        position: relative;
        &:last-child {
          border: none !important;
        }
        .counter {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          left: -9px;
          top: -1px;
          width: 1.5rem;
          height: 1.5rem;
          text-align: center;
          border-radius: 50%;
          font-size: 1.6rem;
          font-weight: 500;
          font-style: medium;

          background-color: var(--secondary-bg-color);
          border: 2px solid var(--counter--border-color);
          &.started {
            border: 2px solid #6f6ff1;
          }
          &.completed {
            background-color: #6f6ff1;
            border: 2px solid #6f6ff1;
          }
        }
        .course__detail {
          padding-left: 5rem;
          margin-top: -0.5rem;
          .title {
            display: block;
            cursor: pointer;
            font-weight: 500;
          }
          .duration {
            display: block;
            font-size: 1.3rem;
            cursor: pointer;

            color: var(--secondary-progress-bar-bg);
          }
          .description {
            display: block;
            font-size: 1.4rem;
            margin-top: 1rem;
            color: var(--third-text-color);
          }
          .button_width_stepper {
            width: 15rem;
            margin-top: 1rem;
          }
          .stepper-divider {
            margin-top: 2.5rem;
            margin-bottom: 2.2rem;

            border-top: 1px solid var(--primary-progress-divider);
          }
        }
      }
    }
  }
  .courses__detail__wrapper {
    width: 30%;
    margin-left: 2rem;
    @media screen and (max-width: 820px) {
      width: 100%;
      margin-top: 3rem;
      margin-left: 0;
    }
    .card__section {
      background-color: #232323;
      background-color: var(--secondary-bg-color);
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      padding: 2.5rem;
      padding-top: 2rem;
      .buldle__tile {
        display: block;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 28px;
      }
      .row {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0 2rem 0;

        .coures__count {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #ffc97a;
          color: var(--primary-colorful-text);
        }
        .deadline {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #d0d5dd;
          color: var(--primary-course-title-color);
        }
      }
      .completed__count {
        font-style: italic;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        color: #d0d5dd;
        color: var(--sixth-text-color);
      }
      .description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #d0d5dd;
        color: var(--primary-course-title-color);
        margin-top: 1rem;
      }
      .detail__and__instructor__divider {
        border-top: 1px solid var(--primary-progress-divider);
        margin: 2rem 0;
      }

      .instructor__profile {
        margin-top: 1rem;
        margin-bottom: 2.5rem;
        display: flex;
        .pic {
          width: 5rem;
          height: 5rem;
        }
        .info {
          margin-left: 1.5rem;
          .name {
            display: block;
            font-size: 1.6rem;
            font-family: 600;
          }
          .occupation {
            word-wrap: break-word;
            width: 19rem;
            display: block;
            font-size: 1.4rem;
            font-family: 400;
            color: #6f6ff1;
            font-style: italic;
          }
        }
      }
    }
  }
}
