.ai__assist__conatiner{
  background:var(--new-secondary-bg-color);
  .Ai__conversation__wrapper {
    background: var(--ai-prompt-bg-color) ;
    min-height: calc(100vh - 7.5rem);
  }

}


.Ai__conversation__wrapper__tac {
  background: var(--ai-prompt-bg-color);
  min-height: calc(100vh - 7.5rem);
  .tac__conversation__wrapper {
    .new__chat__button {
      background: #43ade8;
    }
  }
}
