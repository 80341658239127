.allcoursesbody__wrapper{
  background: var(--new-secondary-bg-color);
  .allcoursesbody {
    padding: 120px 0;
    width: 1126px;
    max-width: 90%;
    margin: 0 auto;
    display: flex;

    @media screen and (max-width: 1100px) {
      flex-wrap: wrap;
    }
    gap: 34px;
  
    .courses-right {
      width: 100%;
      h1 {
        margin-bottom: 20px;
      }
      .courses-right-list {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        @media screen and (max-width: 1100px) {
          justify-content: space-around;
        }
      }
    }
  }

}

