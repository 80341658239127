.tutions__plans__section {
  padding: 2rem;
  padding-top: 0;
  .title {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    &.mb_3 {
      margin-bottom: 2rem;
    }
  }
  .description__title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: var(--secondary-text-color);
  }
  .description__title__hint {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #6f6f6f;
  }
  .title_and_button_section {
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .desc__buttons {
    width: 9rem;
    display: block;
    margin-left: auto;
  }
  .plans__button__width {
    width: 10rem;
  }

  .plans__card {
    padding: 2rem;
    margin-bottom: 2rem;
    background-color: var(--secondary-bg-color);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    &.empty {
      height: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .empty__text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #667085;
      .colorful_text {
        color: var(--secondary-colorful-text);
        cursor: pointer;
      }
    }

    .plans_row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .plan_input_width {
        width: 49%;
      }
    }
    .buttom__section {
      justify-content: flex-end;
      align-items: center;
      display: flex;
    }
    .editor__wrapper {
      margin-bottom: 2rem;
      margin-top: 0.3rem;
    }
    .remove__text {
      display: block;
      cursor: pointer;
      margin-right: 2rem;
      text-decoration: underline;
      color: #f04438;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-underline-offset: 3px;
    }
  }
}
