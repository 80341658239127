.login__form__container {
  .form_row {
    width: 100%;
    .or__text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 1.3rem;
      line-height: 16px;
      text-align: center;
      display: block;
      color: var(--input-label-color);
      margin-top: 1rem;
      margin-bottom: -1rem;
    }
    .google__login__btn__wrapper{
      .google__button{
        border: 1px solid #202F3E;
        background: #132130;
      }

    }
  }
  .fr-c2 {
    display: flex;
    gap: 10px;
    > div {
      width: 50%;
    }
  }
  .welcome__text {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }
  .welcome__description {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: var(--welcome-description);
  }
  .welcome__description__style{
    color: var(--alpha-primary-text-color);
  }
  .margin {
    margin: 1rem 0 1.5rem 0;
  }
  .mb {
    margin-bottom: 2rem;
  }
  .forget__password {
    display: block;
    font-size: 1.4rem;
    font-weight: 300;
    color: var(--input-label-color);
    text-align: end;
    margin-bottom: 1.5rem;
    cursor: pointer;
  }
  .signup__text {
    display: block;
    font-size: 1.3rem;
    color: var(--input-label-color);
    text-align: center;
    margin-top: 1rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    .coloured__text {
      color: var(--primary-color);
      font-weight: 400;
      cursor: pointer;
    }
  }
}
