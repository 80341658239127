.resetform__container {
  .choose__password {
    display: block;
    color: var(--welcome-description);
    text-align: center;
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }
  // button {
  //   margin-bottom: 20px;
  // }
}
