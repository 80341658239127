.file__upload__container {
  .m-1 {
    margin: 1rem 0;
  }
  .uploader__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 1px solid var(--secondary-dropdown-border);
    cursor: pointer;
    background-color: var(--third-bg-color);
    height: 17rem;

    .circle_bg {
      height: 6rem;
      width: 6rem;
      border-radius: 50%;
      background: var(--forth-bg-color);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .upload_description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #bababa;
    }
  }
  .uploader__counter {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 1.5rem;
  }
  // .progress_section {
  //    border: 1px solid red;
  // }
}
