@import "../../styles/global.scss";
.dropdown {
  position: relative;
  user-select: none;
  background: var(--dropdown-bg-color);
  border-radius: 8px;

  // z-index: 50;
  .ddc-title {
    all: unset;
    cursor: pointer;
    color: var(--twentyfour-bg-color);
    width: calc(170px - 24px);
    @media screen and (max-width: 450px) {
      width: calc(90vw - 24px);
    }
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    border: 1px solid var(--primary-dropdown-border);
    border-radius: 8px;

    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .ddc-option {
    min-width: 170px;
    position: absolute;
    margin-top: 10px;
    background: var(--primary-dropdown-bg);
    border: 1px solid var(--primary-dropdown-border);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: block;
    z-index: 999 !important;
    max-width: fit-content;
    @media screen and (max-width: 500px) {
      max-width: 100%;
    }

    .ddc-options {
      all: unset;
      cursor: pointer;
      // width: 170px;
      font-weight: 500;
      font-size: 14px;
      color: var(--primary-dropdown-text);
      padding: 10px 12px;
      display: flex;
      align-items: center;
      gap: 15px;
      .checkbox-div {
        width: 20px;
        height: 20px;
        border: 1px solid var(--primary-color);
        border-radius: 6px;
        // display: grid;
        // place-items: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .tick__icon {
          margin: 0 !important;
          color: var(--primary-color);
          width: 1.5rem;
          margin-bottom: 1rem;
        }
      }
    }
    .handle__dropdown__options__width {
      all: unset;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      color: var(--primary-dropdown-text);
      padding: 10px 12px;
      display: flex;
      align-items: center;
      gap: 15px;
      white-space: nowrap;
      @media screen and (max-width: 500px) {
        white-space: pre-wrap;
      }
      .checkbox__div__wrapper {
        .checkbox-div {
          width: 20px;
          height: 20px;
          border: 1px solid var(--primary-color);
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
