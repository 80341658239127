.feedback__delete__button {
  border-radius: 0;
  background: #ffc97a;
  color: var(--eleventh-text-color);
  font-weight: 500;
  font-size: 14px;
}
.feedback__cancel__button {
  border-radius: 0;
  background: #f04438;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}
