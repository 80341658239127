@import "../../../styles/mixins/forms.scss";

input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="file"],
input[type="number"] {
  @include form-input;
}
.handleInputStyle{
  background: var(--alpha-primary-input-bg-color) !important;
  border: 1px solid var(--alpha-ternary-input-border-color) !important;

  &:hover {
    border: 1px solid var(--primary-focus-color) !important;
  }

  &:focus {
    box-shadow: 0 0 5px var(--primary-focus-color) !important;
    border: 1px solid var(--primary-focus-color) !important;
  }
  
}
.password__field__section {
  position: relative;
  .eye_icon {
    position: absolute;
    width: 1.8rem;
    right: 2rem;
    bottom: 1.2rem;
    color: #d0d5dd;
    cursor: pointer;
  }
  .eye__icon{
    color: var(--alpha-eye-color);
  }
}
