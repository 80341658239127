.trial__user__form__container{
    .flex {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 480px) {
          width: 100%;
          flex-direction: column;
        }
        .sm_width {
          width: 48.5%;
          @media screen and (max-width: 480px) {
            width: 100%;
          }
          .css-1n929ud-Input {
            color: red !important;
            border: none;
            background-color: transparent;
          }
        }
        .select__option__width{
            width: 100%;
        }
      }

}
