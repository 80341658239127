.file__upload__progress__section__wrapper {
    display: flex;
    margin-bottom: 1rem;
    .info__section__wrapper {
      margin-left: 1.2rem;
      width: 100%;
      cursor: pointer;
      .row__section {
        display: flex;
        justify-content: space-between;
  
        .file__controls {
          .reupload__icon {
            width: 1.5rem;
            // height: 1.5rem;
            cursor: pointer;
            margin-right: 5px;
            &:hover {
              color: #bababa;
            }
          }
  
          .cancel__icon {
            width: 1.5rem;
            cursor: pointer;
            &:hover {
              color: #bababa;
            }
          }
        }
      }
  
      .file__name {
        display: block;
        font-weight: 400;
        font-size: 14px;
        color: var(--ninth-text-color);
        .file__size {
          font-size: 1.2rem;
          color: #667085;
          margin-left: 1rem;
        }
        margin-bottom: 0.7rem;
      }
      .percentage__uploaded {
        font-weight: 300;
        font-size: 12px;
        color: var(--sixth-text-color);
      }
    }
  }
  
  .file__upload__error__wrapper {
    .file__upload__error__container {
      max-height: 18vh;
      height: 15vh;
      overflow-y: auto;
  
      padding-right: 1rem;
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        // background: rgba(0, 0, 0, 0.44);
        // background: var( --new-thumb);
        background: var(--filupload--thumb-color);
      }
      &::-webkit-scrollbar-track {
        // background-color: var(--new-track);
        background: rgba(0, 0, 0, 0.17);
  
        -webkit-border-radius: 10px;
        border-radius: 10px;
      }
      &.all__unset{
        max-height: unset;
        height: unset;
        padding-right: unset;
        overflow-y: unset;
        margin-bottom: 2rem;
      }
  
      .file__upload__error {
        display: flex;
        align-items: center;
  
        .check__icon {
          height: 2.5rem;
          svg {
            width: 1.8rem;
          }
        }
        .created__message {
          font-family: "Inter";
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          line-height: 18px;
          margin-left: 1rem;
        }
      }
      .error__color {
        .error__icon {
          height: 2.5rem;
          svg {
            // height: 1.5rem;
            width: 2rem;
            color: #f04438;
          }
        }
        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          line-height: 18px;
          margin-left: 1rem;
          color: #f04438;
        }
      }
    }
  }
  