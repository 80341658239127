.seo_body {
  //   background-color: red;
  background-color: var(--secondary-bg-color);
  padding: 18px 24px;
  .seorow {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
    .seor1 {
      width: 70%;
      max-width: 100%;
      flex-grow: 1;
    }
    .seor2 {
      // width: 30%;
      width: 278px;
      flex-grow: 1;
      display: flex;
      margin-bottom: 25px;
      .file_fieldd {
        // border: 1px solid #fff !important;
        // flex: 1;
        width: 100%;
        height: 23rem;
        background-color: var(--primary-input-bg-color);
        border: 1px solid var(--primary-border-color);
        &.error {
          border: 1px solid var(--danger-color);
        }

        border-radius: 1rem;
        .file_input_design {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
        .logout_icon {
          color: var(--primary-border-color);
          font-size: 1.5rem;
          cursor: pointer;
          transform: rotate(270deg);
          width: fit-content;
        }
        .clik_text {
          color: var(--primary-border-color);
          font-size: 1.5rem;
          cursor: pointer;
          width: fit-content;
        }
        .filefield {
          display: none;
        }
        .thumbnail__section {
          // border: 1px solid red;
          padding: 1rem;
          padding-bottom: 0;
          .thumbnail__image {
            width: 100%;
            height: 18rem;
            object-fit: cover;
            border-radius: 0.5rem;
            cursor: pointer;
            // border: 1px solid #fff;
          }
          .remove__image {
            line-height: 17px;
            display: block;
            text-align: center;
            text-decoration-line: underline;
            text-underline-offset: 4px;
            text-align: right;
            color: #fe7268;
            cursor: pointer;
          }
        }
      }
    }
  }
  .seorowbottom {
    > div {
      width: 100%;
    }
  }
  .seorowbutton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      width: max-content;
    }
  }
}
