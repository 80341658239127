
// .navigate__into__interview__popup__section{
//     width: 100%;
//     min-height: 100vh;
//     position: fixed;
//     // background-color: rgba(0, 0, 0, 0.5);
//     background: transparent;
//     top: 0;
//     z-index: 111;
    .navigate__into__interview__popup__wrapper{
        background: var(--notification-bg-color);
        // background: red;
        padding: 1.5rem;
        width: 380px;
        position: fixed;
        z-index: 101;
        left: 2rem;
        bottom: 2rem;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        @media screen and (max-width:400px) {
            width: 340px;
            
        }
    
        .popup__close__icon__wrapper{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            .popup__close {
                color: var(--modal-close-icon-color);
                right: 2rem;
                top: 1rem;
                width: 2rem;
                cursor: pointer;
                &:hover{
                  color: #BABABA;
                }
              }
    
        }
       
          .popup__container{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 15px;
            .image__wrapper{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 189px;
                }
            }
         
            p{
                color: var(--fourth-text-color);
                font-family: "Inter";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                margin-bottom: 15px;
                margin-top: 30px;
    
            }
            span{
                color: var(--alpha-sixth-text-color);
                text-align: center;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
    
            }
            button{
                color: var(--button-inner);
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                outline: none;
                border: none;
                background: var(--tenth-colorful-text);
                padding: 12px 20px;
                margin-top: 30px;
                cursor: pointer;
                width: 11.1rem;
                
            }
    
          }
       
    
    }
 

// }

