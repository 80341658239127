body.azure_dark {
  // **************************************
  --primary-color: #2c80ff;
  --secondary-color: #fe7268;
  --danger-color: #f04438;
  --active-color: #2c80ff;
  --background-color: #090d1c;
  --sidebar-bg-color: #16192e;
  --header-bg-color: #090d1c;
  --line-and-stroke: #2b2e4b;
  --description: #d0d5dd;
  --header-text: #ffffff;
  --table-header-text: #636e8f;
  --input-label-color: #e0e0e0;
  --sidebar-hover-color: #242743;
  --mode-switch-button-hover: #5a5e80;
  --apply-button-bg: #242743;
  --apply-button-text: #ffffff;
  --welcome-description: #9095b1;

  --ai-assist-header-color: #090d1c;
  --ai-sidebar-color: #090c1a;
  --ai-sidebar-border: #2b2e4b;
  --ai-sidebar-tooltip-bg: #2b2e4b;
  --ai-tooltip-bg: #2b2e4b;
  --ai-prompt-bg-color: linear-gradient(
    132deg,
    rgba(100, 106, 167, 0.09) 0%,
    rgba(113, 115, 140, 0.11) 100%
  );
  --ai-prompt-border: transparent;
  --ai-user-stripe-line: #21243c;
  --ai-user-avatar: #16192e;
  --ai-boat-avatar: #333758;
  --ai-scroll-to-top-icon: #3a4062;
  --ai-scroll-thumb: #2b2e4b;
  --ai-scroll-track: #21243c;
  --ai-generation-response-bg: #2b2e4b;
  --ai-generation-response-border: #2b2e4b;
  --ai-generation-response-text: #ffffff;

  --clear-conversation: #9095b1;
  // text color
  --primary-text-color: #ffff;
  --secondary-text-color: #9095b1;
  // backgound--color
  --secondary-bg-color: #16192e;
  --twentyfour-bg-color: #9095b1;

  // primary input filed dark mode
  --primary-border-color: #2b2e4b;
  --primary-input-bg-color: #242743;
  --primary-input-text-color: #ffffff;
  --primary-focus-color: #e0e0e0;
  --primary-placeholder-color: #9095b1;

  --eleventh-text-color: #ffffff;

  --primary-course-title-color: #ffffff;
  // sidebar toggle button
  --primary-sidebar-toggle-color: #9095b1;

  // search icon color
  --primary-search-icon-color: #9095b1;

  // dropdown
  --dropdown-bg-color: #242743;
  --primary-dropdown-border: #2b2e4b;
  --primary-dropdown-text: #9095b1;
  --primary-dropdown-bg: #252743;

  //  skleton
  --skleton--bg--color: linear-gradient(
    110deg,
    #2d304a 8%,
    #2b2e4b 18%,
    #2b2e4b 33%
  );

  // header--drop-down
  --header-drop-bg-color: #2b2e4b;

  // divider
  --primary-divider-color: #2b2e4b;
  --black-trns-border: transparent;
  --tac-billing-button-border-color: #2b2e4b;

  padding: 0;
  color: #fff;
  background-color: #090d1c;
  font-size: 16px;
  word-break: break-word;
  font-family: "Inter", sans-serif;
  font-style: normal;
}

body.azure_light {
  --primary-color: #2c80ff;
  --secondary-color: #fe7268;
  --danger-color: #f04438;
  --active-color: #2c80ff;
  --background-color: #ffffff;
  --sidebar-bg-color: #16192e;
  --header-bg-color: #ffffff;
  --line-and-stroke: #eceef2;
  --description: #000000;
  --header-text: #101828;
  --table-header-text: #071952;
  --input-label-color: #474e5d;
  --sidebar-hover-color: #242743;
  --mode-switch-button-hover: #191919;
  --apply-button-bg: #8ab6ff;
  --apply-button-text: #ffffff;

  --primary-text-color: #000000;
  --secondary-text-color: #474e5d;

  --ai-assist-header-color: #090d1c;
  --ai-sidebar-color: #090c1a;
  --ai-sidebar-border: #2b2e4b;
  --ai-sidebar-tooltip-bg: #2b2e4b;
  --ai-tooltip-bg: #f5f5f5;
  --ai-prompt-bg-color: #ffffff;
  --ai-prompt-border: #d6d6d6;
  --ai-user-stripe-line: #f5f5f5;
  --ai-user-avatar: #c5c7ca;
  --ai-boat-avatar: #4f5156;
  --ai-scroll-to-top-icon: #bababa;
  --ai-scroll-thumb: #2b2e4b;
  --ai-scroll-track: #21243c;
  --ai-generation-response-bg: #f5f5f5;
  --ai-generation-response-border: #d0d5dd;
  --ai-generation-response-text: #40414f;

  --clear-conversation: #9095b1;

  // primary input filed light mode
  --primary-border-color: #eceef2;
  --primary-input-bg-color: #ffffff;
  --primary-input-text-color: #101828;

  --primary-focus-color: #2c80ff;
  --primary-placeholder-color: #474e5d;

  // backgound--color
  --secondary-bg-color: #ffff;
  --twentyfour-bg-color: #474e5d;
  --eleventh-text-color: #ffffff;
  --primary-course-title-color: #000000;

  // sidebar toggle button
  --primary-sidebar-toggle-color: #474e5d;

  // search icon color
  --primary-search-icon-color: #474e5d;

  // dropdown
  --dropdown-bg-color: #ffffff;
  --primary-dropdown-border: #eceef2;
  --primary-dropdown-text: #474e5d;
  --primary-dropdown-bg: #ffffff;

  // header--drop-down
  --header-drop-bg-color: #eceef2;

  //  skleton
  --skleton--bg--color: linear-gradient(
    110deg,
    #cfd5dc 8%,
    #dee2e8 18%,
    #dee2e8 33%
  );

  // divider
  --primary-divider-color: #eceef2;
  --black-trns-border: #d6d6d6;
  --tac-billing-button-border-color: #eceef2;

  padding: 0;
  background-color: #ffffff;
  font-size: 16px;
  word-break: break-word;
  font-family: "Inter", sans-serif;
  font-style: normal;
}
