.certificate_table_container {
  background: var(--secondary-bg-color);

  .table-top {
    height: 79px;

    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    .tt-title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
    }
    .searchbar {
      width: 170px;
    }
  }
  .h-44px {
    height: 44px;
  }
  tr {
    padding: 0 24px;
    height: 72px;
    .course {
      color: var(--table-header-text) !important;

      width: 50%;
    }
    .completed {
      color: var(--table-header-text) !important;
    }

    .certi__button {
      display: flex;
      margin-right: 2rem;
      justify-content: flex-end;
      margin-top: 1.5rem;
    }

    .visibility-hidden {
      visibility: hidden;
    }
    .opacity-0 {
      color: transparent !important;
    }
    .action {
      button {
        max-width: 146px;
      }
    }
  }

  .custom-certi-table {
    background: var(--secondary-bg-color);

    .table-head {
      th {
        background: var(--secondary-bg-color) !important;
      }
    }
    tbody {
      tr {
        background: var(--secondary-bg-color) !important;
        color: var(--header-text);
        border-top: 1px solid var(--line-and-stroke);
      }
    }
  }
}

.certificate_view_container {
  height: 685px;
  width: 863px;
  // max-width: 80vw;
  // background-color: teal;
  .cv-btn-container {
    height: 36px;
    // background-color: red;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 22px;
    padding-bottom: 19px;
    gap: 10px;
    button {
      all: unset;
      margin-right: 1.5rem;

      // min-width: 67px;
      padding: 0 14px;
      height: 36px;
      cursor: pointer;
      background: yellow;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
    }
    .secondary {
      a {
        all: unset;
      }
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      color: #667085;
    }
    .share-div {
      position: relative;
      .share-container {
        margin-top: 10px;
        right: 0;
        width: 397px;
        @media screen and (max-width: 320px) {
          right: -100px;
          max-width: 200px;
          .logo-container {
            flex-wrap: wrap;
          }
        }
        @media (min-width: 321px) and (max-width: 800px) {
          right: -125px;
          max-width: 320px;
          .logo-container {
            flex-wrap: wrap;
          }
        }

        padding: 24px;
        position: absolute;
        // background: var(--primary-dropdown-bg);
        background: red !important;

        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
          0px 8px 8px -4px rgba(16, 24, 40, 0.03);
        border-radius: 12px;
        z-index: 999;
        .shareto {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;

          color: var(--header-drop-text-color);
        }
        .logo-container {
          margin-top: 15px;
          display: flex;
          gap: 15px;
          .logo-single {
            all: unset;
            width: 37px;
            height: 37px;
            background: var(--share-certificate);
            border-radius: 50%;
            display: grid;
            place-items: center;
            cursor: pointer;
          }
        }
        .extra-logo-container {
          margin-top: 15px;
          width: 100%;
          .hrline {
            height: 1px;
            width: 100%;
            background-color: #d0d5dd;
            margin-bottom: 15px;
          }

          .logo-single {
            all: unset;
            width: 37px !important;
            height: 37px !important;
            background: var(--share-certificate);
            border-radius: 50%;
            display: grid;
            place-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  // padding-top: 20px;
  .certificate-view {
    max-width: 100%;
    overflow: hidden;
  }
}
