.team__header__container {
  padding: 1.8rem 2rem;
  display: flex;
  align-items: center;
  background-color: var(--secondary-bg-color);
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    width: 100%;
    flex-direction: column;
  }
  .right__section {
    display: flex;
    align-items: center;
    @media screen and (max-width: 480px) {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .search {
        margin-top: 1rem;
      }
      .button__witdh {
        margin-top: 1rem;
        margin-right: calc(50% - 5rem);
      }
    }
    .search {
      width: 25rem;
      margin-right: 2rem;
    }

    .button__witdh {
      width: 10rem;
    }
  }

  .button__witdh {
    margin-left: auto;
    width: 10rem;
  }



  form {
    width: 100%;
    .flex__month__day{
      display: grid;
      // justify-content: space-between;
      grid-template-columns: repeat(2,1fr);
      gap: 2rem;
      .input__box{
        
      }
    }

  }
}
