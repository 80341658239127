.header_container {
  padding: 1.8rem 2rem;
  display: flex;
  align-items: center;
  background-color: var(--secondary-bg-color);
  justify-content: space-between;

  .right__section {
    display: flex;
    align-items: center;

    .search {
      width: 25rem;
      margin-right: 2rem;
    }

    .button__witdh {
      width: 10rem;
    }
  }
  .button__witdh {
    margin-left: auto;
    width: 10rem;
  }
}
