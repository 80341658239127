.chatbot__message__input__section {
    padding: 1rem;
    max-width: 100%;
    .right_arrow {
      position: absolute;
      right: 25px;
      width: 2rem;
      stroke-width: 1px;
      bottom: 27px;
      color: var(--alpha-primary-text-color);
      cursor: pointer;
    }
  }
  
  .chatbot__chat__textarea {
    resize: none;
    width: 100%;
    max-height: 15rem;
    height: 5rem ;
    transition: height 0.3s;
    padding: 1.2rem 1.5rem;
    padding-top: 1.2rem;
    border-radius: 0.5rem;
    outline: none;
    color: var(--seventh-text-color);
    padding-right: 8rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    font-style: normal;
    text-align: justify;
    font-family: "Inter", sans-serif !important;
    background: var(--alpha-primary-input-bg-color) ;
    border: 1px solid var(--alpha-ternary-input-border-color) ;
  

    &::placeholder{
      font-weight: 400;
      font-size: 14px;
      color: var(--alpha-primary-text-color);
    }
    &:hover {
      border: 1px solid var(--primary-hover-color);
    }
  
    &:focus {
      box-shadow: 0 0 5px var(--primary-focus-color);
      border: 1px solid var(--primary-focus-color);
    }
  
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
  }
  