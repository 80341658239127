.authlayout__container {
  width: 46.6rem;
  padding: 2rem 0;
  @media screen and (max-width: 480px) {
    width: 90%;
  }
  .auth_card_section {
    padding: 30px;

    background-color: var(--secondary-bg-color);
    border-radius: 0.8rem;
    filter: drop-shadow(0px 23px 25px rgba(0, 0, 0, 0.25));
  }
  .logo__section {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    img {
      width: 13rem !important;
      cursor: pointer;
    }
  }
  .auth__card__section__wrapper{
    background: var(--alpha-primary-modal-bg-color);
  }
}
