.admin__interview_answer__wrapper {
  background: var(--secondary-bg-color);
  .table_headings {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    @media screen and (max-width: 426px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .left_control {
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 426px) {
        margin: 1rem 0rem;
      }
    }

    .right_control {
      display: flex;
    }
  }
  .interview_question_answer__table {
    .table__container {
      table {
        thead {
          tr {
            //                  display: flex;
            //   justify-content: space-between;
            //   width: 80%;s

            th {
              // border-top: 1px solid red;
              background-color: transparent;
              font-weight: 500;
              font-size: 12px;
            }
            .title_role {
              width: 50%;
            }
          }
        }
        tbody {
          tr {
            padding: 2rem 0rem;
            td {
              // padding: 2rem 0rem;
              padding-top: 2rem;
              padding-bottom: 2rem;
              .users {
                display: flex;
                align-items: center;

                img {
                  margin-right: 10px;
                  width: 2.4rem;
                  height: 2.4rem;
                  border-radius: 50%;
                }
                span {
                  font-weight: 400;
                  font-size: 14px;
                }
              }
            }
            .user__td {
              width: 75%;
              .users {
                .avatar__name {
                  width: 2.4rem !important;
                  height: 2.4rem !important;
                  border-radius: 50%;
                  font-weight: 600;
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: var(--twentyeight-bg-color);
                  text-transform: capitalize;
                  margin-right: 1.2rem;
                }
                .name__text {
                  font-family: "Inter";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  &:hover {
                    color: var(--ternary-table-header-color);
                  }
                }
              }
            }
            .time__td {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.emptyquestion {
  font-weight: 500;
  font-size: 14px;
  position: relative;
  display: revert !important;
  .empty {
    position: absolute;
    z-index: 1;
    background: transparent;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 1px solid var(--secondary-table-header-color);
    padding: 2rem !important;
  }

  td {
    color: #667085;

    span {
      color: #6f6ff1;
    }
  }
}

@media screen and (max-width: 769px) {
  .custom_admin__interview__wrapper {
    .interview_question__table {
      .table__container {
        table {
          tbody {
            .row {
              .question_td {
                white-space: pre-wrap;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .custom_admin__interview__wrapper {
    .table_headings {
      flex-direction: column;
      justify-content: start;

      .right_control {
        justify-content: space-between;
        padding: 20px 0px;
      }
    }
    .interview_question__table {
      .table__container {
        table {
          thead {
            tr {
              width: 93%;
            }
          }
          tbody {
            .row {
              .question_td {
                width: 627px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 376px) {
  .custom_admin__interview__wrapper {
    .table_headings {
      .right_control {
        flex-direction: column;
        justify-content: flex-start;

        .search {
          margin-bottom: 15px;
        }
      }
    }
  }
}
