.webinar_date_time__container {
    // padding: 2rem;
    // margin: 0 2rem;
    padding-top: 0;
    .webinar_date_time_tab__container {
      .webinar_date_time_tab__list__section {
        border-bottom: 1px solid (var(--primary-border-color));
        padding: 0;
        .webinar_date_time_single__tab {
          display: inline-block;
          cursor: pointer;
          padding: 2rem;
          background: none;
          outline: none;
          color: var(--primary-input-text-color);
        }
        .react-tabs__tab--selected {
          border-bottom: 1px solid var(--primary-tabs-underline-color);
          margin-bottom: -1rem;
          font: bolder;
        }
      }
      .webinar_date_time__header {
        // border: 1px solid red;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        margin-top: 2rem;
      }
    }
  }
.padding_empty{
  padding: 1.5rem 2rem !important;
  text-align: center;
}