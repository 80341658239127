@import "../../../../styles/mixins/breakpoints.scss";
.list_file_and_folder_section {
  padding: 0 2rem;
  .nested_folder_name_section {
    margin-top: -0.8rem;
    margin-bottom: 0.3rem;
    display: flex;
    align-items: center;
    .nested_folder_back_icon {
      width: 2rem;
      margin-left: -0.8rem;
      cursor: pointer;
    }
    .nested_folder_name {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin-left: 0.4rem;
    }
  }
  .list_file_table_section {
    width: 100%;
    @include md {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
    border-collapse: collapse;
    overflow: scroll !important;
    margin-bottom: 5rem;
    thead tr th {
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      text-align: left;
      color: var(--secondary-progress-bar-bg) !important;
      padding: 1.5rem 0;
      font-style: medium;
      width: 21%;
      @media screen and (max-width: 500px) {
        padding-right: 5rem;
      }
    }
    thead tr th:first-child {
      width: 40%;
    }
    thead tr th:last-child {
      width: 20rem;
      @media screen and (max-width: 820px) {
        text-align: left;
      }
      text-align: center;
    }
    tbody tr {
      border-top: 0.5px solid var(--primary-border-color) !important;
    }
    tbody tr td {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      padding: 1.5rem 0;
    }
    tbody tr td:first-child {
      padding-right: 5rem;
    }
    tbody tr td:last-child {
      display: flex;
      justify-content: flex-end;
    }
    .talbe_icons {
      margin-right: 1.5rem;
    }
    .action__icons {
      width: 1.8rem;
      margin-left: 4rem;
    }
    .text_and_icons {
      display: flex;
      align-items: center;
      position: relative;

      .action_dropdown {
        width: 12rem;
        padding: 1.2rem 1.5rem;
        position: absolute;
        right: 1rem;
        top: 2rem;
        display: none;
        z-index: 1000;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        background: var(--fifth-bg-color);
        border-radius: 5px;
        .action_list {
          padding-left: 5px;
          a {
            all: unset;
            display: flex;
            align-items: center;
          }
          display: flex;
          align-items: center;
          color: var(--fifth-text-color);
          .action_list_icon {
            width: 1.5rem;
            margin-right: 1rem;
          }
        }
        .actioin_divider {
          border-top: 1px solid var(--header-drop-divider);
          margin: 0.5rem 0;
        }
        &.active {
          display: block;
        }
      }
    }
    .empty_section {
      width: 100%;
      border: 1px solid red;
    }
  }
  .text_and_icons {
    display: flex;
    align-items: center;
    position: relative;
    .action_dropdown {
      width: 13rem !important;
      padding: 1.2rem 1.5rem;
      position: absolute;
      right: 1rem;
      top: 2rem;
      display: none;
      z-index: 1000;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      background: var(--fifth-bg-color);
      border-radius: 5px;
      .action_list {
        padding-left: 5px;
        display: flex;
        align-items: center;
        color: var(--fifth-text-color);
        .action_list_icon {
          width: 1.5rem;
          margin-right: 1rem;
        }
      }
      .actioin_divider {
        border-top: 1px solid var(--header-drop-divider);
        margin: 0.5rem 0;
      }
      &.active {
        display: block;
      }
    }
  }
}

.editor_name_form {
  display: flex;
  flex-direction: column;
  input {
    all: unset !important;
    // background-color: #474747 !important;
    background-color: var(--sixth-bg-color) !important;
    // border: 1px solid red !important;
  }

  .rename_error {
    color: #f04438;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
  }
}

.whole__window_cover {
  width: 82%;
  height: 90%;
  padding: 1.2rem 1.5rem;
  position: absolute;
  right: 0;
  top: 0;

  z-index: 10;
  background: transparent;
}
