.LearningPathCourse {
  padding: 2rem;
  display: grid;
  justify-content: space-between;
  grid-row-gap: 1.5rem;
  grid-template-columns: calc(50% - 0.8rem) calc(50% - 0.8rem);
  @media screen and (max-width: 480px) {
    width: 100%;
    grid-template-columns: 100%;
  }
}
