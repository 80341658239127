@import "../../../../styles/mixins/breakpoints.scss";
.user_resource_list_file_and_folder_section {
  padding: 0 2rem;
  .nested_folder_name_section {
    margin-top: -0.8rem;
    margin-bottom: 0.3rem;
    display: flex;
    align-items: center;
    .nested_folder_back_icon {
      width: 2rem;
      margin-left: -0.8rem;
      cursor: pointer;
    }
    .nested_folder_name {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      margin-left: 0.4rem;
    }
  }
  .user_list_file_table_section {
    width: 100%;
    @include md {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
    border-collapse: collapse;
    overflow: scroll !important;
    margin-bottom: 5rem;
    .hide_text_action {
      color: transparent !important;
    }
    thead tr th {
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      text-align: left;
      color: var(--table-header-text) !important;
      padding: 1.5rem 0;
      font-style: medium;
      width: 21%;
      @media screen and (max-width: 800px) {
        width: 40%;
      }
    }
    thead tr th:first-child {
      width: 40%;
    }
    thead tr th:nth-child(3) {
      @media screen and (max-width: 700px) {
        padding-left: 2rem;
      }
    }
    thead tr th:nth-child(4) {
      @media screen and (max-width: 700px) {
        padding-left: 2rem;
      }
    }
    thead tr th:last-child {
      width: 10rem;
      text-align: center;
      @media screen and (max-width: 820px) {
        text-align: left;
      }
    }
    tbody tr {
      border-top: 0.5px solid var(--line-and-stroke) !important;
      color: var(--header-text);
    }
    tbody tr td {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      padding: 1.5rem 0;
      // border: 1px solid red;
    }
    tbody tr td:last-child {
      display: flex;
      justify-content: flex-end;
    }
    tbody tr td:first-child {
      padding-right: 5rem;
    }
    tbody tr td:nth-child(3) {
      @media screen and (max-width: 700px) {
        padding-left: 2rem;
      }
    }
    tbody tr td:nth-child(4) {
      @media screen and (max-width: 700px) {
        padding-left: 2rem;
      }
    }

    .talbe_icons {
      width: 2rem;
      margin-right: 1.5rem;
    }
    .action__icons {
      width: 1.8rem;
      margin-left: 4rem;
    }
    .text_and_icons {
      display: flex;
      align-items: center;
      position: relative;
      .action_dropdown {
        width: 12rem;
        padding: 1.2rem 1.5rem;
        position: absolute;
        right: 1rem;
        top: 2rem;
        display: none;
        z-index: 1000;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        background: var(--fifth-bg-color);
        border-radius: 5px;
        .action_list {
          padding-left: 5px;
          a {
            all: unset;
            display: flex;
            align-items: center;
          }
          display: flex;
          align-items: center;
          color: var(--fifth-text-color);
          .action_list_icon {
            width: 1.5rem;
            margin-right: 1rem;
          }
        }
        .actioin_divider {
          border-top: 1px solid var(--header-drop-divider);
          margin: 0.5rem 0;
        }
        &.active {
          display: block;
        }
      }
    }
    .empty_section {
      width: 100%;
    }
  }
  .user_resoure_action_td {
    // width: 10rem;
    // display: flex;
    padding-left: 1rem !important;

    .parent_of_tool_action {
      display: flex;
      .tool_section_for_hover {
        position: relative;
        display: flex;
        .user_resoure_icon {
          width: 1.8rem;
          color: var(--secondary-text-color);

          &.ml {
            margin-left: 4rem;
          }
          &.tac_eye_hover {
            &:hover {
              color: var(--active-color);
            }
          }

          &.tac_download_hover {
            &:hover {
              color: var(--danger-color);
            }
          }
        }

        .eye_icon_hover:hover ~ .preview_tool_tip {
          display: block;
        }
        .eye_icon_hover:hover ~ .preview_arrow_tool_tip {
          display: block;
        }

        .download_icon_hover:hover ~ .download_tool_tip {
          display: block !important;
        }
        .download_icon_hover:hover ~ .download_arrow_tool_tip {
          display: block !important;
        }

        .preview_arrow_tool_tip {
          position: absolute;
          display: none;
          width: 2.5rem;
          height: 1.2rem;
          background-color: var(--line-and-stroke);
          clip-path: polygon(46% 100%, 0 0, 100% 0);
          left: -0.1rem;
          top: -1rem;
        }
        .preview_tool_tip {
          position: absolute;
          display: none;
          width: 5.8rem;
          padding: 0.6rem 0.8rem;
          // color: #fff;
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          background-color: var(--line-and-stroke);
          border-radius: 0.4rem;
          left: -1rem;
          top: -3.5rem;
          color: var(--primary-text-color);
        }

        .download_tool_tip {
          position: absolute;
          display: none;
          width: 7rem;
          padding: 0.6rem 0.8rem;
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          background-color: var(--line-and-stroke);
          border-radius: 0.4rem;
          left: 0;
          top: -3.5rem;
          color: var(--primary-text-color);
        }
        .download_arrow_tool_tip {
          position: absolute;
          display: none;
          width: 2.5rem;
          height: 1.2rem;
          background-color: var(--line-and-stroke);
          clip-path: polygon(46% 100%, 0 0, 100% 0);
          left: 3.7rem;
          top: -1rem;
        }
      }
    }

    .user_resoure_icon_tp {
      width: 1.8rem;
      color: transparent;
    }
  }
}

.whole__window_cover {
  width: 82%;
  height: 90%;
  padding: 1.2rem 1.5rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  background: transparent;
}
.eye_parent_section {
  // width: 25rem !important;
  position: relative;
}
