.header__container {
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s ease;
  .toggle__section {
    display: flex;
    .menu__button {
      width: 2.5rem;
      height: 2.5rem;
      margin-left: 2rem;
      cursor: pointer;
      color: var(--primary-sidebar-toggle-color);
    }
    .logo__wrapper {
      height: 25px;
      width: 136px;
      display: none;
      @media screen and (max-width: 820px) {
        display: block;
      }
      margin-left: 2rem;
      .logo {
        height: 100%;
        width: 100%;
      }
    }
  }
  .right__section {
    // margin-top: 2rem !important;
    display: flex;
    margin-right: 2rem;
    position: fixed;
    right: 0;

    // .avatar_image_div {
    //   position: relative;
    //   cursor: pointer;
    // }
    .sm__menu__button {
      display: none;
      @media screen and (max-width: 820px) {
        display: block;
      }
    }

    // .light {
    //   // background-color: #d0d5dd;
    //   // &:hover {
    //   //   background-color: #232323;
    //   // }
    //   // &:hover .moon__icon {
    //   //   color: #d0d5dd;
    //   // }
    // }

    // .dark {
    //   // background-color: #232323;
    //   // &:hover {
    //   //   background-color: #d0d5dd;
    //   // }
    //   // &:hover .sun__icon {
    //   //   color: #232323;
    //   // }
    // }
  }
}
.layout__header__width__handle{
  width: calc(100% - 7rem);

}
.header__width__handle{
  width: calc(100% - 30rem);
  @media screen and (max-width:1180px) {
    width: calc(100% - 30rem);
    
  }
  @media screen and (max-width:820px) {
    width: calc(100% - 10rem);
    
  }

}
