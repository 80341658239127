.question_container {
  padding: 0rem 2rem;
  background-color: var(--secondary-bg-color);
  margin: 2rem 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);

  .question_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0rem;
    cursor: pointer;

    .question {
      width: 760px;
      padding-right: 2rem;

      p {
        font-size: 16px;
        font-weight: 400;
        color: var(--fourth-text-color);
      }
    }

    .attempt_on {
      display: flex;
      justify-content: space-between;

      p {
        margin-right: 1rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        color: var(--date-txt-color);
        font-size: 12px;
        width: 170px;

        @media screen and (max-width: 426px) {
          width: 60px;
        }
      }
    }
  }
  .answer_content {
    display: flex;
    justify-content: space-between;
    min-height: 380px;
    padding: 2rem 0rem;
    width: 100%;
    border-top: 1px solid var(--secondary-table-header-color);

    @media screen and (max-width:900px) {
      flex-direction: column;
      
    }

    @media screen and (max-width: 426px) {
      flex-direction: column;
      justify-content: center;
    }

    .left_content {
      // width: 40%;
      border-right: 1px solid var(--secondary-table-header-color);
      margin-right: 1rem;
      min-width: 15%;
      h3 {
        font-weight: 500;
        font-size: 12px;
        color: #6f6f6f;
        padding-bottom: 1rem;
      }
      @media screen and (max-width: 426px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
      }
      .video_title {
        display: flex;
        flex-direction: column;
        .video__counter {
          display: flex;
          align-items: center;
          span {
            cursor: pointer;
            font-weight: 400;
            padding: 1rem 0;
            font-size: 14px;
            color: var(--instructor-text-color);
            text-decoration: underline;
            text-underline-offset: 4px;
            max-width: 110px;
            width: 115px;
            sup {
              margin-left: -4px;
            }

            &:hover {
              color: #6f6ff1 !important;
            }
          }
          .generate__sharable__link{
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-right: 1rem;
            svg{
              width: 1.5rem;
              color: #bababa;
              margin-bottom: -5px;

            }
          }
          .delete__icon {
            width: 1.5rem;
            // margin-left: auto;
            // margin-right: 2rem;
            margin-bottom: -5px;
            cursor: pointer;
            color: #bababa;

            &.active {
              color: #f04438;
            }
            &:hover {
              color: #f04438;
            }
          }
        }
      }
    }
    .mid_content {
      width: 100%;
      margin-left: 3rem;

      @media screen and (max-width:900px) {
        margin: 3rem 0;
        
      }

      @media screen and (max-width: 426px) {
        margin: 0.5rem 0;
        width: 100%;
      }
      .video_section {
        background: linear-gradient(
          132.06deg,
          rgba(255, 255, 255, 0.09) 2.05%,
          rgba(82, 80, 80, 0.114) 68.52%
        );

        .video_content {
          position: relative;
          padding: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;

          .video__loading__section {
            position: absolute;
            left: 1rem;
            right: 1rem;
            bottom: 1rem;
            top: 1rem;
            background: #000;
            opacity: 0.4;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          video {
            height: 100%;
            width: 100%;
            @media screen and (max-width:1440px) {
              width: 54rem;
              
            }
  
            @media screen and (max-width:1370px) {
              width:100%;
              
            }
  
            @media screen and (max-width:1100px) {
              width: 100%;
              
            }
          }
          .react-player {
            transform: "scaleX(-1)" !important;
          }

          .video__controller__section {
            position: absolute;
            left: 1rem;
            bottom: 2rem;
            right: 2rem;
            width: calc(100% - 2rem);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1.5rem;

            @media screen and (max-width:1100px) {
              width: 90%;
              margin-left: 2rem;
              
            }

            @media screen and (max-width:600px) {
              margin-left: 0;
              
            }

            .play__pause__section {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 4px;

              .play__image {
                width: 2rem;
                cursor: pointer;
              }
            }

            .time__slider__section {
              display: flex;
              align-items: center;
              width: 80%;
              .time {
                width: 4.2rem;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #ffffff;
                width: 60px;
                @media screen and (max-width:1370px) {
                  width: 80px;
                  
                }
                &.mr-1 {
                  margin-right: 1rem;
                }
              }

              .time__progress__section {
                width: 100%;
                .rc-slider-track {
                  background: #fff;
                }

                .rc-slider-rail {
                  background: rgba(255, 255, 255, 0.5) !important;
                }
                .rc-slider-handle {
                  background: #fff !important;
                  border: 2px solid #fff;
                  &:hover {
                    border-color: #fff;
                  }
                  opacity: 100 !important;
                }
                .rc-slider-handle-dragging {
                  background: white;
                  box-shadow: none !important;
                }
              }
            }

            .volume__and__download__section {
              display: flex;
              align-items: center;
              .volume__section {
                display: flex;
                align-items: center;
                position: relative;
                .volume___controller {
                  margin-bottom: -5px;
                  width: 1.9rem;
                  cursor: pointer;
                  margin-right: 1rem;
                }
                .volume__slider__div {
                  position: absolute;
                  height: 10.5rem;
                  bottom: 3rem;
                  left: 0;
                  background: rgba(45, 45, 45, 0.65);
                  backdrop-filter: blur(4.90647px);
                  padding: 1.2rem 0.3rem;
                  border-radius: 10px;
                  display: none;
                  &.active {
                    display: block;
                  }

                  .rc-slider-track {
                    background: #fff;
                    // display: none;
                  }

                  .rc-slider-rail {
                    background: rgba(255, 255, 255, 0.5) !important;
                  }
                  .rc-slider-handle {
                    background: #fff !important;
                    border: none !important;
                    &:hover {
                      border-color: #fff;
                    }
                    opacity: 100 !important;
                  }
                  .rc-slider-handle-dragging {
                    background: white;
                    box-shadow: none !important;
                  }
                }
              }

              .download__button {
                width: 1.8rem;
                cursor: pointer;
                margin-bottom: -5px;
              }
            }
          }
        }
      }
    }
    .right_cotent {
      height: 100%;
      width: 100%;

      .form_content {
        height: 100%;
        width: 100%;
        padding-left: 4rem;

        @media screen and (max-width:900px) {
          padding-left: 0;
          
        }

        @media screen and (max-width: 426px) {
          padding-left: 0;
        }

        .heading {
          font-weight: 500;
          font-size: 14px;
          color: var(--custom-label-txt);
          padding: 0.5rem 0;
        }
        // .feedback_text{
        //     width: 100%;
        //     textarea{
        //         width: 100%;
        //         height: 192px;
        //         border-radius: 8px;
        //         outline: none;
        //         border: none;
        //         // background: #1C1C1C;
        //         box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        //         border-radius: 8px;
        //         border: 1px solid var(--primary-border-color);
        //         padding: 1rem 1.2rem;
        //         font-size: 1.4rem;
        //         color: var(--primary-input-text-color);
        //         background: var(--primary-input-bg-color);
        //     }
        //     @media screen and (max-width:426px) {
        //         width: 100%;

        //     }
        // }
        .admin_rating {
          // margin: 1.4rem 0;
          display: flex;
          margin-top: 1.5rem;

          p {
            font-weight: 500;
            font-size: 14px;
            color: var(--custom-label-txt);
          }
          .star {
            padding-left: 0.8rem;
            svg {
              cursor: pointer;
            }
            // span{
            //     color: #FFC600;

            // }
          }
        }
        .feebback_btn {
          width: 76px;
        }
      }
    }
  }
  .not_given {
    border-top: 1px solid var(--secondary-table-header-color);
    color: var(--notgivent-text-color);
    padding: 15px 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}
