.agile__badge{
font-size: 12px;
font-weight: 600;
line-height: 15px;
letter-spacing: 0px;
padding: 2px 6px;
border: 1px solid var(--active-color);
color: var(--active-color);
color: #EF6C00;
background: var(--dropdown-bg-color);
border-radius: 5px;
border: 1px solid var(--line-and-stroke);
width: fit-content;
@media screen and (max-width:1100px) {
    font-size: 10px;
    
}

}
.purches__badge{
    padding: 4px 6px;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid var(--line-and-stroke);
    background:var(--dropdown-bg-color);
    color: var(--secondary-text-color);
    border-radius: 5px;
    svg{
        height: 14px;
        width: 14px;
        margin-top: -1px;

    }
}