.status__active {
  display: flex;
  align-items: center;
  .active__dot {
    height: 8px;
    width: 8px;
    border-radius: 50px;
    color: transparent;
    background: #3ed0cf;
    margin-right: 7px;
  }
  .active__text {
    color: #3ed0cf;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}
.status__inactive {
  display: flex;
  align-items: center;
  .inactive__dot {
    height: 8px;
    width: 8px;
    border-radius: 50px;
    background: #fe7268;
    color: transparent;
    margin-right: 7px;
  }
  .inactive__text {
    color: #fe7268;
    /* Text sm/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    // color: var(--apply-now--view-details-btn-coor);
  }
}
