.blog_content_table {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  // height: 59.2rem;
  min-height: 35.2rem;
}
.published {
  display: flex;
  align-items: center;
  color: #3ed0cf;
  width: fit-content;
  .dot {
    color: transparent;
    background-color: #3ed0cf;
    border-radius: 50%;
    width: 0.7rem;
    height: 0.7rem;
    margin-right: 1rem;
  }
}
.un_published {
  display: flex;
  align-items: center;
  color: #fe7268;
  .dot {
    color: transparent;
    background-color: #fe7268;
    border-radius: 50%;
    width: 0.7rem;
    height: 0.7rem;
    margin-right: 1rem;
  }
}

.blog__pagination__section {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
