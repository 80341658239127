.Learning{
    display: flex;
    width: 100%;
    height: 100vh;
    // background-color: cyan;
    // border: 5px solid red;
    background: var(--new-secondary-bg-color);

    .learning_content{
        width: 100%;
        margin:0 auto ;
        padding: 0 12rem;
        color: white;
        margin-top: 100px;
        @media screen and (max-width:800px) {
            padding: 0 4rem;
        }
        @media screen and (max-width:600px) {
            padding: 0 2rem;
        }

        .heading{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--fourth-text-color);
            display: flex;

            .blue{
                color: 
                #6F6FF1;

            }
            
            span{
                color: 
                var( --wrapup-text-color);
            }

            p:not(:first-child)::before{
                content: "/";
                color: white;
                margin: 0 10px;
            }

            .pointer{
                cursor: pointer;
            }
        }
        .learning_item{
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 70px;
            // gap: 45px;
            // margin: 0 auto ;
            // justify-content: space-between;

            @media screen and (max-width:800px) {
                gap: 20px;
                justify-content: space-between;
                
            }

        }

    }
}

.box{
    width:272px;
    height:auto;
    background: var(--feedback-bg-color);
    // background: linear-gradient(132.06deg, rgba(255, 255, 255, 0.09) 2.05%, rgba(82, 80, 80, 0.114) 68.52%);
    margin-top: 16px;
    margin-right: 16px;
    border: var(--file-item-border);
    @media screen and (max-width:800px) {
        width: 48%;
        margin: 0;
        
    }
    @media screen and (max-width:600px) {
        width: 100%;
        
    }

    .box_content{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 18px;
        
        .content{
            width: 100%;
            display: flex;
            padding-bottom: 16px;
            border-bottom:1px dashed var(--alpha-primar-divider-color);

            .icon{
                height: 45px;
                width: 36px;
                img{
                    // height: 100%;
                    width: 100%;
                    // object-fit: cover;
                }

            }
            .title{
                width: 180px;
                margin-left: 14px;
                display: flex;
                flex-direction: column;
                color: var(--fourth-text-color);

                .resource_name{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                p{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    color: var(--fourth-text-color);
                    cursor: pointer;
                }
                span{
                    font-weight: 400;
                    margin-top: 6px;
                    font-size: 14px;
                    line-height: 20px;
                    display: flex;
                    color: var(--fourth-text-color);
                    
                    p{
                        margin-right: 2px;
                        color: var(--fourth-text-color);
                    }

                }
            }

        }
        .modified{
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-top: 16px;
            
            p{
                font-weight: 300;
                font-size: 14px;
                line-height: 20px;
                color: var(--wrapup-text-color);
            }

            .download{
                cursor: pointer;
                color: var(--fourth-text-color);
                stroke-width: 1px;

            }
        }
    }
}
.custom-react-tooltip-learning-arrow {
    background-color: var(--alpha-primary-tooltip-bg-color) !important;
    left: 3rem !important;
}

.custom-tool-tip-learning {
    text-align: left;
    background-color: var(--alpha-primary-tooltip-bg-color) !important;
    opacity: 1;
    max-width: 20rem;
    border-radius: 8px !important;
}