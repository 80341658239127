.pay__first__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .payment__section__width {
    margin: 5rem 0;

    background-color: #232323;
    width: 56rem;
    padding: 2rem 3rem;
    border-radius: 8px;

    .main__title {
      display: block;
      text-align: center;
      font-weight: 600;
      font-size: 2rem;
    }

    .sub__title {
      display: block;
      text-align: center;
      font-weight: 400;
      font-size: 1.4rem;
      color: #667085;
      margin: 1rem 0;
    }
  }
}
