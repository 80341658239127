.meetWithteam__section {
  background: var(--new-secondary-bg-color);
  padding-top: 5rem;
  min-height: 100vh;
  padding-left: 20rem;
  padding-right: 20rem;
  padding-bottom: 10rem;
  @media screen and (max-width: 850px) {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  @media screen and (max-width: 420px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .header__section {
    display: flex;
    align-items: center;
    flex-direction: column;

    .desc {
      width: 70rem;
      @media screen and (max-width: 420px) {
        width: 100%;
      }
      color: var(--ninth-text-color);
      display: block;
      margin-top: 1rem;
      text-align: center;
    }
  }

  .center__container {
    display: flex;
    justify-content: center;
  }
  .meet_card__container {
    width: 100rem;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 2rem;

    @media screen and (max-width: 850px) {
      width: 100%;
      grid-template-columns: auto auto;
    }
    @media screen and (max-width: 420px) {
      grid-template-columns: auto;
    }
  }
  .meet_card__section {
    width: 32rem;
    background: var(--feedback-bg-color);
    border: 1px solid var(--black-trns-border);
    padding: 2rem;
    @media screen and (max-width: 420px) {
      width: 100%;
    }
    .top__section {
      .name {
        display: block;
        font-size: 16px;
        font-weight: 500;
      }
      .post {
        display: block;
        color: var(--alpha-primary-text-color);
        font-size: 13px;
        font-weight: 400;
        margin-top: 1rem;
      }
    }
    .bottom__section {
      border-top: 1px dashed var(--alpha-seventh-divider-color);
      margin-top: 1.5rem;
      display: flex;
      justify-content: flex-end;

      .candely__button {
        color: #6f6ff1;
        font-size: 14px;
        font-weight: 400;
        margin-top: 1.5rem;
        cursor: pointer;
      }
    }
  }
}
