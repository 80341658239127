.checkbox__handle {
  margin: 10px 0;
}
.feedback__delete__button {
  border-radius: 0;
  background: var(--ternary-table-header-color);
  color: var(--eleventh-text-color);
  font-weight: 500;
  font-size: 14px;
}
.feedback__cancel__button {
  border-radius: 0;
  background: #f04438;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
}

.sup__test {
  transform: translateY(-3.5px);

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  color: var(--secondary-text-color);
}
