@mixin labelStyle{
    display: block;
    margin-bottom: 6px;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--input-label-color);
    font-style: normal;
    line-height: 20px;
    position: relative;
}

.video__uploader{
    .upload__label{
        @include labelStyle;
    }

}
.thumbnail__uploader{
    margin-top: 1.8rem;
    .thumbnail__label{
        @include labelStyle;

    }

}
.show__hide__checkbox{
    margin-top: 1rem;
}