.checkout__billing__skleton {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
  }
}

.single__billing__skleton {
  width: 24rem;
  height: 12.2rem;
}

.single__payment__method__skleton {
  width: 100%;
  height: 3.5rem;
}
.single__pricing__detail__skleton {
  width: 100%;
  height: 3rem;
}
