// .interview__wrapper__div{
//   background: var(--new-secondary-bg-color);

// }
.interview {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
 padding:11rem 0px;
//  padding-bottom: 30px;
 background: var(--new-secondary-bg-color);
 @media screen and (max-width:1370px) {
  min-height: 100vh;
  
 }
  .content-center {
    // border: 1px solid red;
    color: var(--seventeen-bg-color);
    width: 37vw;

    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      @media screen and (max-width:460px) {
        font-size: 27px;
        
      }
    }
    .line {
      height: 1px;
      width: 100%;
      margin: 25px 0px;
      background: linear-gradient(207deg, rgba(255, 69, 147, 0.20) 0%, rgba(255, 231, 19, 0.20) 40.63%, rgba(23, 215, 255, 0.20) 65.63%, rgba(7, 123, 255, 0.20) 100%);
    }
    .warmup {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      // color: (--wrapup-text-color);
    }

    .contentpara {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin-top: 15px;
      margin-bottom: 15px;
      line-height: 20px;
      // color: #BABABA;
      color: var(--wrapup-text-color);
    }

    .listHandle {
      padding-left: 30px;
      margin-bottom: 10px;
      li {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: var(--ninth-text-color);
        // color: #BABABA;
      }
    }
    ul {
      @media screen and (max-width:460px) {
        padding-left: 16px;
        
      }
      li {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: var(--ninth-text-color);
        // color: #BABABA;
      }
    }
  }
}

.interview__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  background: var(--new-secondary-bg-color);
  button {
    padding: 12px 20px;
    background: var( --tenth-colorful-text);
    color: var(--odd-tr-color);
    width: 100%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    outline: none;
    border: none;
    cursor: pointer;

    // &:hover {
    //   background: #bababa;
    // }
  }
}

.answer__tagline {
  // padding: 5px 0px;
  //   color: #bababa;
  color: var(--wrapup-text-color);
  font-size: 16px;
  margin-bottom: 40px;
  margin-top: 15px;
}
.roles__btns{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 2rem;
  width: 100%;
  // display: flex;
  // flex-wrap: wrap;
  @media screen and (max-width:767px) {
    // grid-template-columns: repeat(auto-fit,minmax(210px,1fr));
    display: flex;
    overflow-x: auto;
    // flex-wrap: unset;
    width: 100%;
    
  }
  .interview__category__button {
    all: unset;
    padding: 12px 20px;
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--black-trns-border);
    background: var(--alpha-secondary-btn-bg-color);
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    &:hover{
      background: var(--eight-colorful-text);
    }
    &.active{
      background: var(--eight-colorful-text);

    }
    @media screen and (max-width:767px) {
     min-width: 210px;
      
    }
  }
  .skeleton__load {
    background-color: var(--skeleton-bg-clr);
    padding: 12px 20px;
    font-weight: 500;
    font-size: 16px;
    border: 1px solid var(--black-trns-border);
    background: var(--alpha-secondary-btn-bg-color);
    color: transparent;
    animation: skeleton-loading 1.2s infinite linear;
  }

  @keyframes skeleton-loading {
    0% {
      background-color: var(--skeleton-bg-clr);
    }
    50% {
      background-color: var(--skeleton-slide-bg-clr);
    }
    100% {
      background-color: var(--skeleton-bg-clr);
    }
  }


}
.dropdown__div {
  // font-size: 18px;
  position: relative;
  user-select: none;
  cursor: pointer;
  .dropdown-btn {
    background-color: var(--alpha-primary-input-bg-color);
    border: 1px solid var(--alpha-primary-input-border-color);
    color: var(--alpha-primary-input-text-color);
    padding: 5px 15px;
    font-weight: 400;
    font-size: 13px;
    border-radius: 8px;
    // margin-bottom: 8px;
    position: relative;
    display: flex;
    justify-content: space-between;
    min-width: 15rem;
    width: fit-content;
    svg{
      color: var(--alpha-primary-input-text-color);
      stroke-width: 1.5px;
      height: 2rem;
    }

  }
  .dropdown-content {
    position: absolute;
    padding: 5px 10px;
    font-weight: 500;
    width: 100%;
    color: var(--seventh-bg-color);
    background-color: var(--alpha-primary-dropdown-bg-color);
    margin-bottom: 10px;
    border-radius: 8px;
    z-index: 10;
    top: 50px;
    .dropdown-item {
      padding: 5px;
      cursor: pointer;
      // color: var(--primary-placeholder-color);
      color:var(--fourth-text-color) ;
      font-size: 13px;
      font-weight: 400;
      border-bottom: 1px solid var(--alpha-primary-input-border-color);
      // transition: all 0.3s;
      // padding-bottom: 0;
      &:last-child{
        // padding-bottom: 1rem;
        border-bottom: none;
      }
      &:hover{
        color: var( --ternary-table-header-color);
      }
      // &:hover {
      //   background-color: #29a1d4;
      // }
    }
  }
}

@media screen and (max-width: 1024px) {
  .interview {
    // margin-top: 70px;
    .content-center {
      width: 50vw;
    }
  }
}

@media screen and (max-width: 768px) {
  .interview {
    // margin-top: 100px;
    width: 100%;
    .content-center {
      // width: 80vw;
      width: 90%;
    }
  }
}

@media screen and (max-width: 426px) {
  .interview {
    .content-center {
      width: 90vw;
    }
  }
}

// --ninth-text-color

