.feedback__empty__wrapper {
  border: 1x solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25rem;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #667085;
  }
}

.feedback__footer__section {
  display: flex;
  align-items: center;

  .back__icon__div {
    background: var(--twentynine-bg-color);
    margin-right: 1rem;
    padding: 0.8rem 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.8rem;
    cursor: pointer;
    .back__icon {
      width: 2rem;
    }
  }

  .recored__feedback__icon {
    background: var(--twentynine-bg-color);
    padding: 0.8rem 1.2rem;
    border-radius: 0.8rem;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 1rem;
    .audio__mic {
      width: 1.5rem;
    }
  }
}
