.recored__feedback__section {
  background: var(--twentynine-bg-color);

  width: 100%;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  .play__pasue__section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 50%;
    background: var(--thirty-two-bg-color);
    cursor: pointer;
    .pause__icon {
      width: 0.8rem;
    }
    .play__icon {
      width: 0.8rem;
      margin-left: 2px;
    }
  }

  .wave__image__section {
    display: flex;
    align-items: center;
    margin: 0 1rem;
    .wave__image {
      width: 100%;
    }
  }

  .time__and__send__section {
    display: flex;
    align-items: center;
    width: 7rem;
    .timer__section {
      font-weight: 400;
      font-size: 14px;
    }
    .send__icon {
      display: flex;
      margin-left: auto;
      cursor: pointer;
      width: 1.6rem;
      transform: rotate(40deg);
      color: var(--thirty-three-bg-color);
    }
  }
}
.handleAudioFeedBackStyle{
  background: var(--alpha-primary-btn-bg-color);
  .Play__pause__button{
    background: var(--alpha-fifth-bg-color);

  }

}
