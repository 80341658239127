@mixin dFlex($justfyContent) {
  display: flex;
  justify-content: $justfyContent;
  align-items: center;
}
@mixin button {
  padding: 12px 14px;
  background-color: #1c1c1c;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #6f6f6f;
  outline: none;
  cursor: pointer;
}
@mixin paragraph {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--fourth-text-color);
}

@mixin secondaryButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--seventh-colorful-text);
  color: var(--odd-tr-color);
  padding: 8px 14px;
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  outline: none;
  border: none;
  font-weight: 500;
  cursor: pointer;
}
$hoverColor: var(--ternary-table-header-color);
@mixin hoverEffect {
  &:hover {
    color: var(--ternary-table-header-color);
  }
}

.record-video {
  width: 100%;
  @include dFlex(center);
  padding-top: 110px;
  background: var(--new-secondary-bg-color);
  .content-section {
    width: 1270px;
    @media screen and (max-width: 1620px) {
      width: 100%;
      padding: 0 12rem;
    }

    .heading-section {
      display: flex;
      flex-direction: column;
      width: 100%;

      .line {
        height: 1px;
        width: 100%;
        margin: 13px 0px;
        background: linear-gradient(
          236.79deg,
          rgba(255, 69, 147, 0.2) 3.16%,
          rgba(255, 231, 19, 0.2) 30.23%,
          rgba(23, 215, 255, 0.2) 46.88%,
          rgba(7, 123, 255, 0.2) 69.78%
        );
      }
      .headings {
        width: 100%;
        @include dFlex(space-between);
        @media screen and (max-width: 420px) {
          flex-direction: column;
          align-items: start;
        }
        .back__icon {
          margin-left: -5px;
          cursor: pointer;
        }

        h1 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          @media screen and (max-width: 610px) {
            display: flex;
            // justify-content: center;
            align-items: center;
            font-size: 23px;
          }
        }
        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #6f6ff1;
          text-decoration: underline;
          cursor: pointer;
          display: block;
          @include hoverEffect;
          @media screen and (max-width: 420px) {
            display: none;
          }
        }
      }
      .role-heading {
        width: 100%;
        margin: 5px 0px;
        @include dFlex(space-between);

        @media screen and (max-width: 420px) {
          flex-direction: column;
          align-items: start;
          margin: 20px 0px;
        }

        .role-para {
          display: flex;
          align-items: center;
          gap: 2rem;
          flex-wrap: wrap;
          p {
            color: var(--alpha-primary-text-color);
            font-size: 14px;
            font-weight: 400;

            span {
              font-size: 14px;
              font-weight: 500;
              color: var(--fourth-text-color);
            }
          }
        }

        .record-time {
          display: flex;
          @media screen and (max-width: 420px) {
            margin-top: 7px;
            justify-content: space-between;
            width: 100%;
          }
          p {
            font-size: 18px;
            font-weight: 500;
            min-width: 50px;
            text-align: right;
          }

          .time {
            @include dFlex(center);
            // width: 185px;
            justify-content: space-between;
            @media screen and (max-width: 400px) {
              // width: 170px;
            }
            .sm__handle {
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                margin-right: 5px;
              }
              span {
                font-size: 16px;
                font-weight: 400;
                // margin-right: 10px;
                color: var(--alpha-primary-text-color);
                @media screen and (max-width: 420px) {
                  font-size: 14px;
                }
                @media screen and (max-width: 325px) {
                  margin-right: 0px;
                  font-size: 14px;
                }
              }
            }
          }
          .sm__show__all_question {
            display: none;
            @media screen and (max-width: 420px) {
              display: block;
            }
            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              color: #6f6ff1;
              text-decoration: underline;
              cursor: pointer;
              @include hoverEffect;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .record-video {
    .content-section {
      height: 100vh;
      // width: 80vw;
      width: 90%;
      padding: 0;
    }
  }
}

@media screen and (max-width: 426px) {
  .record-video {
    .content-section {
      // width: 90vw;
      // height: 100vh;
      .heading-section {
        .controls {
          flex-direction: column;
          padding: 8px 0px;
          align-items: start;
          .left-controls {
            margin-bottom: 10px;
            justify-content: space-between;
            width: 54%;
          }
        }
      }

      .prev-controls {
        flex-direction: column;
        .left-controls {
          justify-content: space-between;
          width: 100%;
        }
        .next-controls {
          justify-content: space-between;
          width: 100%;
          margin-top: 8px;
        }
      }
    }
  }
}
@media screen and (max-width: 340px) {
  .record-video {
    .content-section {
      .heading-section {
        .controls {
          // flex-direction: column;
          padding: 8px 0px;
          // align-items: flex-start;

          .left-controls {
            margin-bottom: 10px;
            justify-content: space-between;
            width: 64%;
          }
        }
      }
    }
  }
}
.video__controller {
  width: 100%;
  transform: "scaleX(-1)" !important;
  // height: 445px !important;
  height: 40rem !important;
  object-fit: cover;
}
.video__controller2 {
  width: 100%;
  height: 40rem !important;
  object-fit: cover;
}
.timer__modal__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .alarm__image {
    animation: shake 1s linear infinite;
  }
  @keyframes shake {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(20deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-20deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .time_up {
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-top: 2rem;
  }
  .time__desc {
    display: block;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 1rem;
  }
}
.question__and__warning__wrapper {
  width: calc(100% - 46.7rem);
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  .question__section {
    width: 100%;
    font-size: 16px;
    margin: 10px 0px;
    font-weight: 400;
    font-size: 16px;
    color: var(--fourth-text-color);
    display: flex;
    pre {
      font-family: "Inter" !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 16px !important;
    }
    .question__number {
      width: 4rem;
      @media screen and (max-width: 500px) {
        width: 40px;
      }
    }
    .question__width__handle {
      .question__width__60 {
        @media screen and (max-width: 500px) {
          width: 60px;
        }
      }
      @media screen and (max-width: 500px) {
        width: 95px;
      }
    }
  }
}

// this is the latest code
.video__and__feedback__wrapper {
  display: flex;
  margin-bottom: 2rem;
  margin-top: 1.3rem;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  .video__and__controller___wrapper {
    width: calc(100% - 45rem);
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
    .video-section,.audio__interview__answers {
      width: 100%;
      margin-top: 0 !important;
      margin: 8px 0px;
      background-color: var(--video-record-bg-color);

      padding: 10px;
      position: relative;
      margin-bottom: 0px;
      position: relative;

      .camera-video {
        width: 100%;
        height: 40rem !important;
        background: #08111c;
        @include dFlex(center);
        flex-direction: column;
        .text-center {
          text-align: center;
        }
        .video-spinner {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          border: 5px solid #34516d;
          border-right-color: #d0d5dd;
          animation: spin 0.5s linear infinite;
        }
        img {
          height: 30px;
          width: 30px;
        }
        p {
          font-family: "Inter";
          font-style: normal;
          font-size: 12px;
          font-weight: 400;
          color: #8e97a9;
          margin-top: 5px;
          text-align: center
        }
      }
    }
    .controls {
      @include dFlex(space-between);
      width: 100%;
      padding: 10px 10px;

      background: var(--controls-bg);
      box-shadow: var(--control-box-shadow);
      @media screen and (max-width: 800px) {
        padding: 10px 0;
      }
      @media screen and (max-width: 500px) {
        flex-direction: column;
      }

      .left-controls {
        display: flex;
        @media screen and (max-width: 500px) {
          justify-content: space-between;
          width: 100%;
          margin-bottom: 1rem;
        }

        button {
          @include dFlex(center);
          margin-right: 10px;
          background: transparent;
          outline: none;
          border: none;
          cursor: pointer;
          color: var(--alpha-seconday-text-color);
          z-index: 100;

          &.disabled {
            color: var(--fourth-text-color);
            &:hover {
              svg {
                color: $hoverColor;
              }
              span {
                color: $hoverColor;
              }
            }
          }

          .play__icon {
            width: 1.5rem;
          }
          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-left: 5px;
          }
        }
      }
      .right-controls {
        display: flex;
        @media screen and (max-width: 426px) {
          width: 100%;
          .rec__btn__width__handle {
            width: 100%;
            button {
              width: 100%;
            }
          }
        }
        .btn {
          @include secondaryButton;
          .circle {
            height: 18px;
            width: 18px;
            border-radius: 50px;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 1rem;
            .red_circle {
              height: 10px;
              width: 10px;
              border-radius: 50px;
              background-color: #ff2727;
            }
          }

          // img {
          //   margin-right: 10px;
          // }
        }
        .disable_btn {
          @include button;
          background-color: var(--alpha-disable-btn-bg-color);
          color: var(--alpha-disable-btn-text-color);
        }
      }
    }
    .video__loader__wrapper {
      background-color: rgba($color: #000000, $alpha: 0.5);
      opacity: 5;
      min-height: 366px;
      width: 100%;
      position: absolute;
      left: 0;
      z-index: 20;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .prev-controls {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-top: 10px;

      .left-controls {
        font-size: 18px;
        @include dFlex(center);
        @media screen and (max-width: 500px) {
          width: 100%;
          justify-content: space-between;
          margin-bottom: 1rem;
        }
        @media screen and (max-width: 400px) {
          // flex-direction: column;
          // align-items: start;
        }
        .res__handle__btn {
          display: flex;
          @media screen and (max-width: 400px) {
            width: 67%;
            justify-content: space-between;
          }

          .back {
            @include dFlex(center);
            margin-right: 20px;
            background: transparent;
            outline: none;
            border: none;
            cursor: pointer;
            @media screen and (max-width: 500px) {
              margin-right: 0px;
              margin-right: 20px;
              padding: 0;
            }
            @media screen and (max-width: 400px) {
              padding: 0px;
              margin-right: 0;
            }
            &:hover {
              svg {
                color: $hoverColor;
              }
              span {
                color: $hoverColor;
              }
            }

            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              color: var(--primary-placeholder-color);
              @media screen and (max-width: 400px) {
                font-size: 12px;
              }
            }
            svg {
              margin-left: 5px;
              color: var(--primary-placeholder-color);
              @media screen and (max-width: 500px) {
                margin-left: -5px;
              }
            }
          }

          .forwad {
            @include dFlex(center);
            background: transparent;
            outline: none;
            border: none;
            cursor: pointer;
            &:hover {
              svg {
                color: $hoverColor;
              }
              span {
                color: $hoverColor;
              }
            }

            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              // margin-right: -5px;
              color: var(--primary-placeholder-color);
              @media screen and (max-width: 400px) {
                font-size: 12px;
              }
            }
            svg {
              color: var(--primary-placeholder-color);
            }
          }
        }
        .video__delete__btn {
          @include dFlex(center);
          background: transparent;
          outline: none;
          border: none;
          cursor: pointer;
          @media screen and (max-width: 400px) {
            padding-right: 0px;
          }
          svg {
            height: 1.8rem;
            width: 1.8rem;
            margin-right: 6px;
            @media screen and (max-width: 400px) {
              // margin-left: 9px;
            }
          }
          &:hover {
            svg {
              color: $hoverColor;
            }
            span {
              color: $hoverColor;
            }
          }

          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            // margin-right: -5px;
            color: var(--primary-placeholder-color);
            margin-top: 2px;
            @media screen and (max-width: 400px) {
              font-size: 12px;
            }
          }
          svg {
            color: var(--primary-placeholder-color);
          }
        }
      }

      .next-controls {
        display: flex;

        .submit-btn {
          margin-right: 8px;
          @media screen and (max-width: 426px) {
            width: 47%;
            button {
              width: 100%;
            }
          }
          .disable_btn {
            @include button;
            background-color: var(--alpha-disable-btn-bg-color);
            color: var(--alpha-disable-btn-text-color);
          }
          .btn {
            @include secondaryButton;
            padding: 12px 14px;
          }
        }
        .next-btn {
          @media screen and (max-width: 426px) {
            width: 47%;
            button {
              width: 100%;
            }
          }
          .disable_btn {
            @include button;
            background-color: var(--alpha-disable-btn-bg-color);
            color: var(--alpha-disable-btn-text-color);
          }
          .btn {
            @include secondaryButton;
            padding: 12px 14px;
          }
        }
      }
    }
  }
}
