.select__course__section__assign {
  margin-bottom: 2rem;
  .tag__divider {
    border-top: 1px dashed var(--primary-divider);

    // margin: 1rem 0 3rem 0;
    margin-top: 0.8rem;
    margin-bottom: 2rem;
  }
  .header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .select_path__title {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    text-transform: uppercase;
  }
  .clear {
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    color: var(--sixth-text-color);
  }
  .according__content__row {
    display: flex;
    @media screen and (max-width: 820px) {
      flex-direction: column;
    }
    // justify-content: space-between;

    .content__title {
      display: block;
      margin-top: 1.5rem;
      margin-bottom: 1.4rem;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: var(--primary-colorful-text);
    }

    .content__margin {
      margin: 1.5rem 0;
      &.extra {
        width: 100% !important;
        margin: 0.8rem 0 !important;
        @media screen and (max-width: 420px) {
          width: 100%;
        }
        // width: 40rem;
        // flex-basis: 1;
      }
    }
    .content__column__left {
      width: 40%;

      @media screen and (max-width: 820px) {
        width: 100%;
      }
      display: block;
      .left__scrollabe__content {
        max-height: 42rem;
        width: 80%;
        overflow: auto;
        @media screen and (max-width: 820px) {
          width: 100%;
        }
      }
    }

    .content__column__right {
      width: 65%;
      @media screen and (max-width: 820px) {
        width: 100%;
      }
      .scrollable_row {
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        // display: flex;
        @media screen and (max-width: 420px) {
          height: 90rem;
        }
        // flex-wrap: wrap;
        max-height: 42rem;
        overflow: auto;

        // justify-content: space-between;
        // .left__scrollabe__content {
        //   height: 42rem;
        //   width: 40%;
        //   overflow-y: scroll !important;
        // }

        // .right__scrollabe__content {
        //   height: 42rem;
        //   width: 45%;
        //   overflow-y: scroll !important;
        // }
      }
    }
  }

  .selected__course__section {
    margin-top: 3rem;
    .selected__header {
      //

      .selected__path__topography {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--sixth-text-color);
      }
      .selected__path__topography__thin {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        color: var(--sixth-text-color);
        margin-left: 2rem;
      }
      margin-bottom: 1.5rem;
    }
  }
  .tags__div {
    background-color: var(--third-bg-color);
    padding: 2rem 2rem 3rem 2rem;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  }
}
