.tenant__loading__wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .tenant_animation__loading__section {
    .tenant_amimation__div {
      display: flex;
      justify-content: center;
      align-items: baseline;
    }

    .tenant_processing {
      font-weight: 500;
      font-size: 2.5rem;
      color: var(--fourth-text-color);
      text-align: center !important;
    }
    .tenant_message {
      display: block;
      margin-top: 1rem;
      color: rgba(102, 112, 133, 1);
      font-size: 1.4rem;
    }

    .tenant_spinner2 {
      margin-left: -1rem;
      width: 70px;
      text-align: center;
    }

    .tenant_spinner2 > div {
      width: 10px;
      height: 10px;
      background-color: var(--fourth-text-color);

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }
    .m {
      margin: 0 0.5rem !important;
    }
    .tenant_spinner2 .tenant_bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
      background-color:var(--fourth-text-color);
    }

    .tenant_spinner2 .tenant_bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
      background-color: var(--fourth-text-color)
    }

    @-webkit-keyframes sk-bouncedelay {
      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
      }
      40% {
        -webkit-transform: scale(1);
      }
    }

    @keyframes sk-bouncedelay {
      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      40% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
}
