.manage_course_container {
  margin-bottom: 1rem;
  .header_container {
    padding: 1.8rem 2rem;
    display: flex;
    align-items: center;
    background-color: var(--secondary-bg-color);
    justify-content: space-between;

    .right__section {
      display: flex;
      align-items: center;

      .search {
        width: 25rem;
        margin-right: 2rem;
      }

      .button__witdh {
        width: 11rem;
      }
    }
    .button__witdh {
      margin-left: auto;
      width: 10rem;
    }
  }
  .manage-course-header{
    @media screen and (max-width:1000px) {
      flex-direction: column;
      flex-wrap: wrap;
      gap: 2rem;
      
    }
  }
  .published {
    display: flex;
    align-items: center;
    color: #3ed0cf;
    .dot {
      color: transparent;
      background-color: #3ed0cf;
      border-radius: 50%;
      width: 0.7rem;
      height: 0.7rem;
      margin-right: 1rem;
    }
  }
  .un_published {
    display: flex;
    align-items: center;
    color: #fe7268;
    .dot {
      color: transparent;
      background-color: #fe7268;
      border-radius: 50%;
      width: 0.7rem;
      height: 0.7rem;
      margin-right: 1rem;
    }
  }
  .table__container {
    // min-height: 45.2rem;

    .manage_course_table_body {
      .courseid_td {
        // width: 20rem;
      }
      .course_name_td {
        // width: 40%;
      }
      .instructor_profiles_td {
        width: 20%;
        .inst_profiles {
          display: flex;
          .inst-profile {
            height: 32px;
            width: 32px;
            // overflow: hidden;
            border-radius: 50%;
            position: relative;
            img {
              // height: 100%;
              // width: 100%;
              height: 32px;
              width: 32px;
              border-radius: 50%;
              object-fit: cover;
            }
            &:hover .span {
              opacity: 1;
              visibility: visible;
            }
            .span {
              position: absolute;
              padding: 6px 10px;
              background: var(--alpha-secondary-tooltip);
              font-size: 12px;
              
              font-weight: 400;
              line-height: 16px;
              border-radius: 4px;
              top: 40px;
              left: 50%;
              transform: translateX(-50%);
              opacity: 0;
              z-index: 100;
              display: block;
              visibility: hidden;
              transition: opacity 0.4s ease, visibility 0.4s ease;
              white-space: nowrap;
              &::before{
                content: "";
                position: absolute;
                height: 10px;
                width: 10px;
                background: var(--alpha-secondary-tooltip);
                bottom: 0px;
                z-index: -1;
                top: -5px;
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
              }
            }
          }
          .inst-profile + .inst-profile {
            margin-left: -5px;
          }

          .extra-instructors {
            margin-left: -5px;
            background: var(--alpha-secondary-tooltip);
            height: 32px;
            width: 32px;
            border-radius: 50%;
            
            font-size: 12px;
            font-weight: 600;
            line-height: 14.52px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            &:hover .remaining-instructors-tooltip {
              opacity: 1;
              visibility: visible;
            }
            .remaining-instructors-tooltip{
              position: absolute;
              opacity: 0;
              visibility: hidden;
              transition: opacity 0.4s ease, visibility 0.4s ease;
              top: 35px;
              background: var(--alpha-secondary-tooltip);
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              border-radius: 4px;
              padding: 6px 10px;
              top: 40px;
              left: 50%;
              white-space: nowrap;
              transform: translateX(-50%);
              z-index: 100;
              pointer-events: none;
              &::before{
                content: "";
                position: absolute;
                height: 10px;
                width: 10px;
                background: var(--alpha-secondary-tooltip);
                bottom: 0px;
                z-index: -1;
                top: -5px;
                left: 50%;
                transform: translateX(-50%) rotate(45deg);
              }

              span{
                display: block;
              }

            }
          }
        }
      }
      .course_status_td {
        // width: 40rem;
        .button-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 24px;
        }
        button {
          all: unset;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #6f6ff1;
        }
      }
      .course_duration_td {
        width: 12rem;
        padding: 1.5rem 0;
        padding-left: 2.5rem;
      }
      .course_action_td {
        width: 20rem;
        padding: 1rem;
        .course_action_section {
          padding-left: 10rem;
        }
      }
      .edit__course {
        width: 1.6rem;
        margin-right: 1.5rem;
        color: #bababa;

        &:hover {
          color: #6f6ff1;
          cursor: pointer;
        }
      }
    }
  }
}

.form_row-2 {
  display: flex;
  gap: 10px;
  // background-color: red;
  > div {
    width: 50%;
  }
}