.notice {
  font-size: 1.4rem;
}
.delete__divider {
  border-top: 1px solid var(--primary-progress-divider);

  margin: 2rem 0 2rem 0;
}
.divider__div{
  border-top: 1px solid var(--alpha-primary-modal-divider-color);

}
.row {
  display: flex;
  justify-content: flex-end;
}
