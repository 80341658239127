.passwordchange-form {
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 15px;
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: var(--welcome-description);
    margin: 0 auto;
    width: 346px;
    max-width: 90%;
    margin-bottom: 15px;
  }
  .change__password__text__tag{
    color: var(--alpha-primary-text-color);

  }
}
