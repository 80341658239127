.audio__recording__wrapper {
    width: 100%;
    margin-top: 0 !important;
    margin: 8px 0px;
    background-color: var(--video-record-bg-color);
    padding: 10px;
    position: relative;
    margin-bottom: 0px;
    position: relative;
    .audio__recording__container {
      width: 100%;
      height: 40rem !important;
      background: #08111c;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  
  .custom-loader {
    width:45px;
    height:60px;
    --c:linear-gradient(#766DF4 0 0);
    background: 
      var(--c) 0%   50%,
      var(--c) 50%  50%,
      var(--c) 100% 50%;
    background-size:9px 50%;
    background-repeat: no-repeat;
    animation:b7 1s infinite linear alternate;
  }
  @keyframes b7 {
      20% {background-size:9px 20% ,9px 50% ,9px 50% }
      40% {background-size:9px 100%,9px 20% ,9px 50% }
      60% {background-size:9px 50% ,9px 100%,9px 20% }
      80% {background-size:9px 50% ,9px 50% ,9px 100%}
  }