.master__container {
  padding: 2rem;
  margin: 0 2rem;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  &.margin_top_7 {
    margin-top: 7rem;
  }
  .tab__container {
    .tab__list__section {
      border-bottom: 1px solid (var(--primary-border-color));
      .single__tab {
        display: inline-block;
        cursor: pointer;
        padding: 2rem;
        background: none;
        outline: none;
        color: var(--primary-input-text-color);
      }
      .react-tabs__tab--selected {
        border-bottom: 1px solid var(--primary-tabs-underline-color);
        margin-bottom: -1rem;
        font: bolder;
      }
    }
    .author__header {
      // border: 1px solid red;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      margin-top: 2rem;
    }
  }
}
