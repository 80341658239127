.billing__card__container {
  .company__size__container {
    // margin-bottom: 1rem;
    margin: -1rem 0 -0.8rem 0;
  }
  .payment__method {
    margin-top: 1.5rem;
  }

  .coupon__section {
    margin-top: 1.5rem;

    .title {
      font-weight: 500;
      font-size: 14px;
      color: var(--tac-coupon-title-color);
      display: block;
      margin-bottom: 0.5rem;
    }
  }
  .billing__button__section {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 450px) {
      flex-direction: column;
      justify-content: center;
    }
  }
  .coupon__input__section {
    display: flex;
    align-items: center;

    .apply__button__section {
      margin-left: 1.5rem;
    }
  }
  .coupon__calculation__section {
    margin-top: 1rem;
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.7rem 0;
      .coupon__topography {
        font-weight: 400;
        font-size: 14px;
      }
      .coupon__blod__topography {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .coupon__divider {
      border-top: 1px solid var(--primary-divider-color);
      margin-top: 1.2rem;
      margin-bottom: 1.4rem;
    }
  }
  .button__section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
    .cancel__button {
      margin-right: 1rem;
      width: 17rem;

      @media screen and (max-width: 450px) {
        margin-bottom: 2rem;
      }
    }
    .upgrade__subscription__button {
      width: 17rem;
    }
    @media screen and (max-width: 450px) {
      margin-top: 2rem;
      flex-direction: column;
      justify-content: center;
    }
  }
}
