.al_container {
  // min-height: 219px;

  // background: #1c1c1c;
  background-color: var(--primary-input-bg-color);
  border: 1px solid var(--primary-border-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  display: flex;
  // align-items: flex-start;
  // justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px;
  min-height: 100px;
  &:hover {
    border: 1px solid var(--primary-hover-color);
  }

  &:focus {
    box-shadow: 0 0 5px var(--primary-focus-color);
    border: 1px solid var(--primary-focus-color);
  }

  .al_tag {
    display: flex;
    align-items: center;
    gap: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #303030;
    padding: 8px 10px;
    background: var(--seotag-bg-color);
    border-radius: 5px;
    width: max-content;
    height: 36px;
    button {
      all: unset;
      height: 100%;
      display: grid;
      place-items: center;
      cursor: pointer;
    }
  }
  input {
    all: unset !important;
    flex: 1 !important;
    height: 36px !important;
    outline: none;
  }
}
