.modal {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  // width: 95%;
  height: 100%;
  overflow: auto;
  background-color: rgba($color: #000000, $alpha: 0.4);
  transition: 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;

  // overflow: scroll;

  // max-height: calc(100vh - 30px);

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &__content {
    // width: 435px;
    // width: 95% !important;
    max-height: calc(100vh - 30px);
    background-color: var(--secondary-bg-color);
    border-radius: 5px;
    position: relative;
    transition: inherit;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-100px);
    padding: 2.4rem;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 8px;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.active &__content {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  .bluishBgModal{
    background-color: var(--alpha-primary-modal-bg-color);
  }

  // &__body,
  // &__footer {
  // }
  // &__body{
  //   background-color: green;
  //   height:80vh;
  //   overflow: scroll;
  // }
  // &.scroll {
  //   // background-color: rgb(236, 138, 33);
  //   // padding-right: 21px;
  //   // max-height: calc(100vh - 30px);

  //   overflow: scroll;
  //   // overflow-y: scroll;
  //   // overflow-x: hidden;

  //   @media (min-height: 436px) and (max-height: 665px) {
  //     max-height: 62vh;
  //   }
  //   @media (min-height: 200px) and (max-height: 435px) {
  //     max-height: 50vh;
  //   }
  // }

  &__header {
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 0.7rem;
    margin-bottom: 2rem;
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 2.12rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: #7f7f7f;

    &:hover {
      color: #000;
    }
  }
}
.modal__footer {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  a {
    width: 170px;
  }
}

.modal__close {
  color: var(--modal-close-icon-color);
  right: 2rem;
  top: 1rem;
  width: 2rem;
  &:hover{
    color: #BABABA;
  }
}

.width__for__modal {
  width: 500px;
  // width: 300px;
}
.modal__body {
  width: 50rem;

  @media screen and (max-width: 480px) {
    width: 31rem;
  }
}
