.singup__from__container {
  .signIn__text {
    display: block;
    font-size: 1.4rem;
    font-weight: 300;
    color: var(--secondary-text-color);
    text-align: center;
    margin-top: 1rem;
    .coloured__text {
      color: var(--primary-color);
      font-weight: 400;
    }
  }
}
