.redording__limit__modal__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width:600px) {
    width: 100%;
    
  }

  .alert__image__section {
    width: 6.4rem;
    height: 6.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: var(--thirty-bg-color);
    background:var(--alpha-primary-alert-bg-color);
  }

  .time_limit {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-top: 2rem;
    @media screen and (max-width:370px) {
      font-size: 16px;
      
    }
  }

  .limit__desc {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 383px;
    margin-top: 1rem;
    @media screen and (max-width:600px) {
      width: 100%;
      
    }
  }
}

.modal__content{
  .modal__body{
    @media screen and (max-width:600px) {
      width: 100% !important; 
    }
  }
  @media screen and (max-width:600px) {
    width: 80%; 
  }
}
.res__modal__body__width__handle{
  @media screen and (max-width:600px) {
    width: 100% !important;
    
  }
}
