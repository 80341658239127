.feedback__empty__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49rem;
  @media screen and (max-width:2300px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33rem;
    
  }
  @media screen and (max-width:1700px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25rem;
  }
    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #667085;
    }
  }
  .responsive__text__area{
    height: 43rem !important;
    @media screen and (max-width:2300px) {
      height: 26rem !important;
    }
    @media screen and (max-width:1700px) {
      height: 20rem !important;
    }

  }
  