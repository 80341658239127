@import "../../../../styles/global.scss";
.blogbox {
  text-decoration: none;
  max-width: 326px;
  height: 310px;
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
  .bb-img {
    height: 197px;
    max-width: 100%;
    background-color: rgb(119, 119, 119);
    border-radius: 5px;
    overflow: hidden;
    object-fit: cover;
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    img {
      max-width: 100%;
      transition: all 0.3s ease;
      object-fit: cover;
      min-width: 100%;
      min-height: 100%;
    }
  }
  .category-text {
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    color: var(--blog-category-label);
  }
  .blog-title {
    margin-top: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-transform: capitalize;
    color: var(--primary-text-color);
  }
  .blog-desc {
    width: 509px;
    // height: 51px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    // line-height: 17px;
    color: var(--secondary-text-color);
    margin-top: 10px;
    margin-bottom: 18px;
  }
}
.blog-detail-container {
  margin-top: 15px;
  font-style: normal;
  color: var(--primary-text-color);
  display: flex;
  align-items: center;
  .author-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgb(132, 132, 132);
    margin-right: 10px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: space-around;
    img {
      object-fit: cover;
      min-width: 100%;
      min-height: 100%;
    }
  }
  .author-name {
    font-weight: 500;
    font-size: 14px;
    margin-right: 5px;
  }
  .blog-date {
    font-weight: 300;
    font-size: 10px;
    margin-bottom: -3px;
  }
}

.blogboxbig {
  .bb-img {
    height: 432px;
  }
}
