.default__change__pasword{
    min-height: 100vh;
    background: var(--new-secondary-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
}
.Tac__default__change__pasword{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

}