.adminPanelLayout__without__sidebar__container {
  width: 100%;
  display: flex;
  .content__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease;
    @media screen and (max-width: 820px) {
      margin-left: 0;
      width: 100%;
    }
    &.sm {
      margin-left: 7rem;
      transition: all 0.5s ease;
    }
    .header__section {
      width: 100%;
    }
  }
}
