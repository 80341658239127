.ourteam-container {
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  .meettheteam {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
  }
  .smallhrline {
    height: 1px;
    width: 210px;
    background: linear-gradient(
      236.79deg,
      #ff4593 3.16%,
      #ffe713 30.23%,
      #17d7ff 46.88%,
      #077bff 69.78%
    );
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 24px;
  }
  .our-team-toggle-container {
    display: flex;

    width: 350px;
    gap: 10px;
    margin: 0 auto;
    margin-top: 35px;
  }
  .teams-display-container {
    // background-color: red;
    display: flex;
    gap: 20px;
    width: 1180px;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 60px;
    flex-wrap: wrap;
  }
}
