.pm__user__our__courses {
  // margin-top: 18rem;
  padding: 10rem 0;
  // padding-bottom: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 800px) {
    padding: 7rem 0;
  }
  @media screen and (max-width: 500px) {
    // margin-top: 7rem;
  }

  .pm__user__our__corses__cards__wrapper {

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      /* identical to box height */
      color: #fe7268;
      margin-bottom: 5rem;
    }
    .pm__user__our__corses__cards {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      .our__courses__card {
        background: var(--course-bg-color);
        padding: 1.5rem;
        width: 55.3rem;
        display: flex;
        cursor: pointer;
        gap: 2rem;
        border: var(--file-item-border);
        transition: all 0.5s;

        &:hover{
            transform: scale(1.03);
            .our__courses__details{
                .our__courses__name{

                    color: var(--primary-colorful-text);
                }

            }
        }

        @media screen and (max-width: 1370px) {
          width: 54rem;
        }
        @media screen and (max-width: 500px) {
          flex-direction: column;
        }

        .our__course__image {
          height: 155px;
          width: 228px;
          @media screen and (max-width: 500px) {
            height: 100%;
            width: 100%;
          }
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .our__courses__details {
          .our__courses__name {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 24px;
            // color: #FFFFFF;
            color: var(--fourth-text-color);
          }
          .our__courses__description {
            display: block;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            // color: #D0D5DD;
            color: var(--third-text-color);
            margin-top: 10px;
            padding-bottom: 15px;
            border-bottom: 1px solid var(--our-course-divider);
            transition: none;
          }
          .our__courses__duration {
            margin-top: 15px;
            display: flex;
            align-items: center;
            svg {
              height: 2rem;
              width: 2rem;
              // color: var( --ninth-colorful-text);
              color: var(--primary-colorful-text);
            }
            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: var(--primary-colorful-text);
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
