.card_25 {
  padding: 2.5rem;

  background-color: var(--card-back-bg);
  width: 100%;
  height: 100%;

  &.baw {
    background: var(--baw-card-back-bg);
  }

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  .card25_title {
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    margin-top: -0.5rem;
    margin-bottom: 2rem;
  }
}
