.image_row {
  margin-bottom: 10px;
  .file_fieldd {
    width: 100%;
    background-color: var(--primary-input-bg-color);
    border: 1px solid var(--primary-border-color);
    &.error {
      border: 1px solid var(--danger-color);
    }
    border-radius: 1rem;
    .file_input_design {
      padding-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logout_icon {
      color: var(--primary-border-color);
      font-size: 1.5rem;
      cursor: pointer;
      transform: rotate(270deg);
      width: fit-content;
    }
    .clik_text {
      color: var(--primary-border-color);
      font-size: 1.5rem;
      cursor: pointer;
      width: fit-content;
    }
    .filefield {
      display: none;
    }
    .thumbnail__section {
      padding: 1rem;
      padding-bottom: 0;
      .thumbnail__image {
        width: 100%;
        height: 18rem;
        object-fit: cover;
        border-radius: 0.5rem;
        cursor: pointer;
      }
      .remove__image {
        line-height: 17px;
        display: block;
        text-align: center;
        text-decoration-line: underline;
        text-underline-offset: 4px;
        text-align: right;
        color: #fe7268;
        cursor: pointer;
      }
    }
  }
}
.rating {
  margin-bottom: 20px;
  display: flex;
  gap: 16px;
  .t-rating {
    display: flex;
    gap: 9px;
  }
  button {
    all: unset;
    cursor: pointer;
  }
}
.select-top-testimonial {
  display: flex;
  margin-bottom: 20px;
  .stt-text {
    // color: var(--primary-text-color);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    font-style: italic;
  }
}

.testimonialbody {
  background-color: var(--secondary-bg-color);
  height: 70vh;
  .ttr {
    height: 72px;
    .ttd {
      // display: flex;
      &_image {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 24px;
          height: 24px;
          object-fit: scale-down;
        }
      }
      &_name {
        // background-color: #fe7268;
        height: 72px;
        display: flex;
        gap: 12px;
        align-items: center;
      }
      &_top {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        color: #fe7268;
      }
      &_action {
        // background-color: red;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 25px;
      }
    }
  }
}

.t-empty {
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  text-align: center;

  color: #667085;
  button {
    all: unset;
    cursor: pointer;
    color: #6f6ff1;
  }
}
.top_testiominal__error {
  margin-top: -2rem;
}
