.new-sidebar-link-container {
  padding: 0 25px 0 25px;
  margin-bottom: 30px;
  transition: all 0.3s ease-in;
  .nslc-title {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    &:hover {
      color: #ffc97a;
      img {
        color: #ffc97a;
      }
    }
    svg {
      width: 12px;
    }
    .nslc-icon-text {
      display: flex;
      align-items: center;
      gap: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      img {
        height: 16px;
      }
      svg {
        width: 16px !important;
      }
    }
  }
  .nslc-title-active {
    color: #ffc97a !important;
  }

  .nslc-row-container-none {
    display: none;
  }
  .nslc-row-container {
    .nslc-link {
      height: 40px;
      display: flex;

      gap: 16px;
      transition: all 0.3s ease-in;
      padding-left: 6px;
      &:first-of-type {
        margin-top: 15px;
      }
      .bar {
        height: 100%;
        width: 1px;
        background-color: #303538;
      }
      a,
      button {
        all: unset;
        cursor: pointer;
        width: 100%;
        padding: 10px;
        white-space: nowrap;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        &:hover {
          color: #ffc97a;
        }
      }
      .active-nslc-link {
        color: #ffc97a;
        background-color: #2c2c2c;
      }
    }
  }
}

.tac__user__sidebar__link__container {
  padding: 1.2rem 3rem 1.2rem 3rem;
  margin-bottom: 0;
  .nslc-title {
    &:hover {
      color: var(--active-color);
      img {
        color: var(--active-color);
      }
    }
    .nslc-icon-text {
      font-weight: 400;
      img {
        height: 16px;
      }
      svg {
        width: 1.9rem !important;
        margin-right: 4px;
        stroke-width: 1.5px;
      }
    }
  }
  .nslc-title-active {
    color: var(--active-color) !important;
  }
  .nslc-row-container {
    .nslc-link {
      a,
      button {
        display: flex;
        align-items: center;
        &:hover {
          color: var(--active-color);
        }
      }
      img{
        margin-left: 1rem;
        height: 16px;
        width: 16px;
        object-fit: cover;
        margin-top: -2px;
      }
      .active-nslc-link{
        color: var(--active-color);
        background-color: var(--sidebar-hover-color);
      }
    }
  }
}
