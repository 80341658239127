
.download__btn__wrapper{
    position: relative;
}

.download__user__file__btn__width__handle{
    margin-left: 1.2rem;
    // position: relative;
    .download__user__file__btn{
      all: unset;
      background: var( --download-user-file-bg-color);
      border-radius: 8px;
      padding: 0.8rem 1.4rem;
      cursor: pointer;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
      width: 10.5rem;
      

    }
  }
  .download__user__file__download__dropdown__wrapper{
    position: absolute;
    right: 0;
    top: 50px;        
    background: var(--twentyseven-bg-color);
    box-shadow:  0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 15px;
    border: 1px solid var( --notification-header-border-color);
    width: 158px;
    z-index: 1;
    .download__user__file__download__dropdow{
      .checkbox__wrapper{
        padding-bottom: 15px;
        .checkbox__container{
          .checkbox {
            .checkbox__icon {
              border: 1px solid #43ADE8;
            }
          }
        }
      }
      .download__btn__width{
        button{
          background: #43ADE8;
        }
      }

    }

  }