.notification__wrapper {
  // background-color: var(--header-drop-bg-color);
  position: relative;
  cursor: pointer;
  .user__notification__container {
    position: absolute;
    width: 46.7rem;
    // height: 63.3rem;
    height: 85vh;
    max-height: 85vh;
    right: -10rem;
    top: 2rem;
    background-color: var(--secondary-bg-color);
    border: 1px solid var(--notification-header-border-color);
    z-index: 1;
    // background-color: #232323;
    // border: 2px solid white;
    @media screen and (max-width: 1370px) {
      // height: 40rem;
    }
    @media screen and (max-width: 500px) {
      width: 38.7rem;
    }
    @media screen and (max-width: 400px) {
      width: 33.3rem;
    }
    @media screen and (max-width: 370px) {
      width: 28rem;
    }

    .notification__header__wrapper {
      width: 100%;

      .notification__header__container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 20px;
        @media screen and (max-width: 500px) {
          flex-direction: column;
        }
        .notification__header__left__section {
          display: flex;
          // justify-content: center;
          align-items: center;
          gap: 8px;
          @media screen and (max-width: 500px) {
            // flex-direction: column;
            justify-content: space-between;
            align-items: start;
          }

          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: var(--fourth-text-color);
          }
          span {
            padding: 4px 7px;
            background-color: #6f6ff1;
            border-radius: 8px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #ffffff;
            &:hover {
              background: #5454ec;
            }
          }
        }
        .notification__header__right__section {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          @media screen and (max-width: 500px) {
            align-items: start;
            justify-content: start;
            margin-top: 8px;
          }
          &:hover {
            p {
              color: var(--ternary-table-header-color);
            }
            .mark__done__icon {
              svg {
                color: var(--ternary-table-header-color);
              }
            }
          }
          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: var(--user-notification-header-markdone-color);
            // color: #BABABA;
          }
          .mark__done__icon {
            height: 14px;
            width: 14px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              height: 100%;
              width: 100%;
              color: var(--user-notification-header-markdone-color);
              //   object-fit: cover;
            }
          }
        }
      }
    }
    .notifications__wrapper {
      // padding: 20px;
      width: 100%;
      height: 85%;
      max-height: 85vh;
      overflow-y: auto;
      @media screen and (max-width: 500px) {
        height: 80%;
      }
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 3px;
        box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.17);
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-thumb-background-color);
        // color: #bababa;
        width: 6px;
        border-radius: 3px;
        // height: 10px;
      }
      .notifications__container {
        .notification {
          padding: 20px;
          padding-bottom: 0px;
          &:hover {
            background: var(--notification-feedback-hover-color);
          }
          .notify {
            display: flex;
            gap: 15px;
            .user__profile {
              position: relative;
              .user__profile__picture {
                height: 32px;
                width: 32px;
                .user__first__letter {
                  height: 100%;
                  width: 100%;
                  background: var(--first-letter-bg-color);
                  // Light mode -- bg color :#E4E4E4
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-family: "Inter";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 100%;
                  color: var(--fourth-text-color);
                }
              }
              span {
                position: absolute;
                height: 1.2rem;
                width: 1.2rem;
                background: #077bff;
                border-radius: 50%;
                top: -4px;
                left: 65%;
              }
            }
            .user__comment {
              display: flex;
              flex-direction: column;
              .comment__for {
                font-size: 14px;
                font-weight: 400;
                color: var(--fourth-text-color);

                span {
                  font-family: "Inter";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  // color: #FFFFFF;
                }
              }
              .commented__time {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                margin-top: 10px;
                color: #667085;
              }
            }
          }
          .notification__line {
            height: 1px;
            width: 100%;
            // background: #303538;
            background: var(--notification-border-bottom-color);
            margin: 15px 0;
            margin-bottom: 0;
            // margin-top: 15px;
          }
        }
      }
    }
    .no__notification {
      display: flex;
      height: 90%;
      width: 100%;
      justify-content: center;
      align-items: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #667085;
    }
  }
  .notification__container__style {
    background: var(--notification-bg-color);
    .notifications__wrapper__style {
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        box-shadow: inset 0 0 20px var(--alpha-scroll-track-color);
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--alpha-scroll-thumb-color);
        -webkit-border-radius: 10px;
        border-radius: 10px;
      }
      .notifications__container {
        .notification {
          &:hover {
            background: var(--notification-hover-color);
          }
          .notify{
            .user__profile{
              .user__profile__picture{
                .user__first__letter{
                  background: var(--alpha-ninth-bg-color);

                }

              }

            }
            .user__comment{
              .commented__time{
                color: var(--alpha-primary-text-color);

              }
            }

          }
          .notification__line{
            background: var(--alpha-primar-divider-color);

          }
        }
      }
    }
    .no__notification__style{
      color: var(--alpha-primary-text-color);
    }
  }

}
