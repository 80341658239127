.checkbox__container {
  .checkbox {
    display: flex;
    cursor: pointer;
    .checkbox__icon {
      height: 1.9rem !important;
      width: 2rem !important;
      border: 1px solid var(--primary-color);
      border-radius: 4px;
      margin-right: 7px;
      display: grid;
      place-items: center;
      &.checkbox__div{
        border: 1px solid var(--alpha-primary-checkbox-border-color);
        background: var(--alpha-primary-input-bg-color);
      }

      &.sm {
        height: 1.5rem !important;
        width: 1.6rem !important;
      }
      img {
        width: 1.3rem;
      }
      .tick__icon{
        color: var(--alpha-primary-checkbox-border-color);
      }
      .sm {
        width: 0.7rem;
      }
    }
    .label {
      text-align: left;
      width: 15rem;
      color: var(--sixth-text-color);
      font-weight: 500;
      font-size: 14px;
      font-style: normal;
      // padding-left: 7px;

      // margin-left: 2rem;
      // word-wrap: break-word;

      // line-height: 20px;
      &.active__label{
        color: #43ADE8;
      }
      &.full {
        width: 100%;
      }
      &.sm {
        font-weight: 400;
        font-size: 12px;
      }
    }
    .label__style{
      width: unset;
    }
  }
}
