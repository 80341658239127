.interview__tabs__container {
  margin-left: 2rem;
  .tab__container {
    .tab__list__section {
      border-bottom: 1px solid (var(--primary-border-color));
      .single__tab {
        display: inline-block;
        cursor: pointer;
        padding: 2rem;
        background: none;
        outline: none;
        margin-left: -4rem;

        color: var(--primary-input-text-color);
        &.mll {
          margin-left: 4rem;
        }
      }
      .margin__hadnle{
        margin-right: 4rem;
      }
      .react-tabs__tab--selected {
        border-bottom: 1px solid var(--primary-tabs-underline-color);
        margin-bottom: -1rem;
        font: bolder;
      }
    }
    .box__shadow {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      margin-top: 2rem;
      margin-left: 0;
    }
  }
}
