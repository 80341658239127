.team_talbe_container {
  // min-height: 73.2rem;
  min-height: 45.2rem;

  .team_table_row {
    padding: 3rem !important;
  }
  .user_section_team {
    // width: 100%;
    display: flex;
    padding: 1.3rem 0;

    .user_img {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
    }
    .user_name_sec {
      margin-left: 1.5rem;
      display: flex;
      flex-direction: column;
      .linked_in_link {
        color: #43ade8;
      }
    }
  }
  .icons_edit_del {
    display: flex;
    align-items: center;
    height: 9rem;
    justify-content: flex-end;
  }
}

.user_td_section {
  width: 45rem !important;
}
.team_designation__section {
  width: 30rem !important;
}
.team_member__section {
  width: 30rem !important;
}
.tetst__ted {
  width: 20rem;
}

.team__action__icons {
  width: 10rem;
  color: #bababa;
  .action__section__action {
    width: 10rem;
  }
}
.test-4544 {
  width: 100%;
  padding-left: 3rem;
  //
}

.drag__team__action__icons {
  color: #bababa;

  .action__section__action {
    margin-left: 5rem;
  }

  .drag_test_4544 {
    width: 9rem;
    padding-left: 2.5rem;
    //
  }
}

// for drag
