.header__container__tac {
  width: 100%;
  height: 6.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.5s ease;
  background: var(--ai-assist-header-color);
  position: fixed;
  top: 0;
  .toggle__section__tac {
    padding-left: 2rem;
    display: flex;
    align-items: center;
    .left__arrow {
      // height: 2.4rem;
      width: 2.4rem;
      margin-left: -8px;
      cursor: pointer;
      color: white;
      // color: var(--fourth-text-color);
    }
    // border: 1px solid red;
    .tac__logo__wrapper__div {
      height: 34px;
      width: 185px;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      // border: 1px solid red;
      .tac__logo {
        // height: 100%;
        width: 100%;
      }
    }
  }
  .right__section__tac {
    display: flex;
    margin-right: 2.5rem;
    @media screen and (max-width: 800px) {
      margin-right: 2rem;
    }
    @media screen and (max-width: 650px) {
      margin-right: 1.5rem;
    }
    .avatar_img_tac {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      margin: 0 2rem;
      margin-right: 0;
      object-fit: cover;
      background-position: center;
      @media screen and (max-width: 800px) {
        margin-right: 0rem;
      }
      @media screen and (max-width: 650px) {
        margin-right: 0rem;
      }
    }
    .avatar_image_div_tac {
      position: relative;
      cursor: pointer;
    }
    .dropdown {
      z-index: 100;
      width: 15rem;
      padding: 1.3rem;
      position: absolute;
      border-radius: 0.5rem;
      background-color: var(--header-drop-bg-color);
      top: 5rem;
      right: 2rem;
      border: none;
      box-shadow: 0px 2px 8px rgb(0 0 0 / 8%);
      .drop_item {
        display: flex;
        align-items: center;
      }
      .divider_item {
        // margin-top: 2rem;
        margin: 1rem 0;
        border-top: 1px solid var(--header-drop-divider);
      }
      .align {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .drop_icon {
        width: 1.8rem;
        color: var(--header-drop-text-color);
      }
      span {
        font-size: 1.4rem;
        font-weight: 400;
        margin-left: 1rem;
        color: var(--header-drop-text-color);
      }
    }
  }
}
