@import "../../../styles/mixins/forms.scss";

.custom__textarea {
  @include form-input;
  height: 20rem !important;
  resize: none;
  font-family: "Inter", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  // &::placeholder {
  //   font-size: 1.4rem;
  //   color: var(--primary-placeholder-color);
  // }
  // &.error {
  //   border: 1px solid var(--danger-color);
  // }
  &.half {
    height: 15rem !important;
  }
  &.height__32 {
    height: 32.1rem !important;
  }
  &.textarea__custom__style{
    background: var(--alpha-primary-input-bg-color);
    border: 1px solid var( --alpha-primary-input-border-color);
    // border: 1px solid var(--alpha-primary-input-border-color);

    &:hover {
      border: 1px solid var(--primary-focus-color) !important;
    }
  
    &:focus {
      box-shadow: 0 0 5px var(--primary-focus-color) !important;
      border: 1px solid var(--primary-focus-color) !important;
    }
  }
}
