.custom__tags_container {
  // min-height: 219px;
  // background: #1c1c1c;
  background-color: var(--primary-input-bg-color);
  // border: 1px solid var(--primary-border-color);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  display: flex;
  // align-items: flex-start;
  // justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px;
  min-height: 50px;
  margin-bottom: -1rem;
  &.nobg {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }
  &.input {
    border: 1px solid var(--primary-border-color);
    &:hover {
      border: 1px solid var(--primary-hover-color);
    }

    &:focus {
      box-shadow: 0 0 5px var(--primary-focus-color);
      border: 1px solid var(--primary-focus-color);
    }
  }
  &.error {
    border: 1px solid #f04438;
  }

  &.transparent {
    background-color: transparent;
    border: none;
    min-height: 30px;

    padding: 0;

    &:hover {
      border: none;
    }

    &:focus {
      box-shadow: none;
      border: none;
    }
  }
  .al_tag {
    display: flex;
    align-items: center;
    gap: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #121212;
    padding: 8px 10px;
    background: var(--tag-bg-color);
    border-radius: 5px;
    width: max-content;
    height: 30px;
    &.button__title {
      color: var(--course--tag--title);
      background-color: var(--course--tag--title--bg);
      text-transform: capitalize;
    }
    &.transparent {
      background-color: #1c1c1c;
      color: #d0d5dd;
    }
    &.boldFirstIndex {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;

      text-transform: capitalize;

      color: #121212;
    }
    .transparent {
      margin-left: 1.5rem;
    }
    button {
      all: unset;
      height: 100%;
      display: grid;
      place-items: center;
      cursor: pointer;
    }
  }
  input {
    all: unset !important;
    flex: 1 !important;
    // height: 36px !important;
    outline: none;
  }
}
.change__height {
  display: none;
}
