@import "../../../../styles/global.scss";
.view-blog-container {
  padding: 0 25px;
  margin-bottom: 5vh;
  @media screen and (max-width: 600px) {
    padding: 0 20px;
  }
  .view-single-blog-container {
    width: 100%;
    background-color: var(--single-blog-bg);
    padding: 40px;
    @media screen and (max-width: 600px) {
      padding: 20px;
    }
    margin-bottom: 40px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    .single-blog-title {
      font-style: normal;
      font-weight: 500;
      font-size: 27px;
      line-height: 140%;
      /* identical to box height, or 38px */

      text-align: center;
      letter-spacing: 0.02em;
      text-transform: capitalize;

      color: var(--third-text-color);
    }
    .blog-detail-container {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .single-blog-image {
      margin-top: 40px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      img {
        margin: 0 auto;
        max-width: 100%;
        min-width: 100%;
        max-height: 387px;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .single-blog-content {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      /* or 175% */

      letter-spacing: 0.02em;
      width: 70%;
      margin: 0 auto;
      margin-top: 53px;
      @media screen and (max-width: 800px) {
        width: 80%;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
}
