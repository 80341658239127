.thumbnail__container {
    // width: 31rem;
    height: 25rem;
    // margin-top: 2rem;
    @media screen and (max-width: 480px) {
    //   margin-left: 0;
    //   margin-bottom: 2rem;
    }
    .file_fieldd {
      // border: 1px solid #fff !important;
      width: 100%;
      height: 23rem;
      background-color: var(--primary-input-bg-color);
      border: 1px solid var(--primary-border-color);
      &.error {
        // border: 1px solid var(--danger-color);
      }

      border-radius: 1rem;
      .file_input_design {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
      .logout_icon {
        color: var(--primary-border-color);
        font-size: 1.5rem;
        cursor: pointer;
        transform: rotate(270deg);
        width: fit-content;
        margin-top: 1rem;
      }
      .clik_text {
        color: var(--primary-border-color);
        font-size: 1.5rem;
        cursor: pointer;
        width: fit-content;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }
      .filefield {
        display: none;
      }
      .thumbnail__section {
        // border: 1px solid red;
        padding: 1rem;
        padding-bottom: 0;
        .thumbnail__image {
          width: 100%;
          height: 18rem;
          object-fit: cover;
          border-radius: 0.5rem;
          cursor: pointer;
          // border: 1px solid #fff;
        }
        .thumbnail__actions__btn{
            display: flex;
            justify-content: flex-end;
            gap: 1rem;
            .remove__image {
                line-height: 17px;
                display: block;
                text-align: center;
                text-decoration-line: underline;
                text-underline-offset: 4px;
                text-align: right;
                color: #fe7268;
                cursor: pointer;
              }
              .upload__image{
                line-height: 17px;
                display: block;
                text-align: center;
                cursor: pointer;
                text-decoration-line: underline;
                text-underline-offset: 4px;
                  color: #6f6ff1;
              }

        }
 
      }
    }
  }