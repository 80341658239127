.user__prep__video__controller__section {
  position: absolute;
  left: 2.5rem;
  right: 2.5rem;
  bottom: 2rem;
  // width: calc(100% - 5rem);
  width: 92%;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    width: 87%;
  }

  &.hide {
    display: none !important;
  }
  //   padding: 0 1.5rem;

  .play__pause__section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;

    .play__image {
      width: 2rem;
      cursor: pointer;
      z-index: 100;
    }
  }

  .time__slider__section {
    display: flex;
    align-items: center;
    width: 80%;
    .time {
      // width: 4.2rem;
      width: 60px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      @media screen and (max-width: 500px) {
        width: 45px;
      }
      &.mr-1 {
        margin-right: 1rem;
        @media screen and (max-width: 500px) {
          margin-left: 1rem;
        }
      }
    }

    .time__progress__section {
      // width: 50rem;
      width: 100%;
      @media screen and (max-width: 500px) {
        width: 58%;
      }
      .rc-slider-track {
        background: #fff;
      }

      .rc-slider-rail {
        background: rgba(255, 255, 255, 0.5) !important;
      }
      .rc-slider-handle {
        background: #fff !important;
        border: 2px solid #fff;
        &:hover {
          border-color: #fff;
        }
        opacity: 100 !important;
      }
      .rc-slider-handle-dragging {
        background: white;
        box-shadow: none !important;
      }
    }
  }

  .volume__and__download__section {
    display: flex;
    align-items: center;
    .volume__section {
      display: flex;
      align-items: center;
      position: relative;
      .volume___controller {
        margin-bottom: -5px;
        width: 1.9rem;
        cursor: pointer;
        margin-right: 1rem;
        color: #ffffff;
      }
      .volume__slider__div {
        position: absolute;
        height: 10.5rem;
        bottom: 3rem;
        left: 0;
        background: rgba(45, 45, 45, 0.65);
        backdrop-filter: blur(4.90647px);
        padding: 1.2rem 0.3rem;
        border-radius: 10px;
        display: none;
        &.active {
          display: block;
        }

        .rc-slider-track {
          background: #fff;
          // display: none;
        }

        .rc-slider-rail {
          background: rgba(255, 255, 255, 0.5) !important;
        }
        .rc-slider-handle {
          background: #fff !important;
          border: none !important;
          &:hover {
            border-color: #fff;
          }
          opacity: 100 !important;
        }
        .rc-slider-handle-dragging {
          background: white;
          box-shadow: none !important;
        }
      }
    }

    .download__button {
      width: 1.8rem;
      cursor: pointer;
      margin-bottom: -5px;
      color: #ffffff;
    }
  }
}
