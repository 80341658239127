.apply__now__question__answer__wrapper{
    padding: 1rem 0;
    background: var(--odd-tr-color);
    .apply__now__question__answer__continer{
        padding: 2rem;
        background: var(  --twentyseven-bg-color);
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
        margin-bottom: 1.5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        span{
            color: var(--fourth-text-color);
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            padding-bottom: 1.2rem;
            margin-bottom: 1.2rem;
            border-bottom: 1px solid var(--apply-now-view-details-border-color);
            width: 100%;
            display: block;
        }
        p{
            color: var(--fourth-text-color);
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-top: 1.2rem;
        }

    }

}