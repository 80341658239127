.support__box__wrapper{
    margin-top: 15px;
    .support__box__container{
        width: 30rem;
        // background: #303030;
          background: var( --twentyseven-bg-color);
          box-shadow: var(--control-box-shadow);
        // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);

        padding: 2rem;
        @media screen and (max-width: 800px) {
            width: 100%;
            margin-top: 3rem;

          }

        span{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            // color: #FFFFFF;
            color: var(--fourth-text-color);;
            // padding-bottom: 17px;
        }
        .support__button__width__handle{
            margin-top: 17px;
            .support__btn{
                background: #43ADE8;

            }
        }
    }
}
