.header_container {
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }

  .right__section {
    @media screen and (max-width: 480px) {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
    }
    .button__witdh {
      @media screen and (max-width: 480px) {
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
      }
    }
  }
}
