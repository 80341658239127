.preview__audio__wrapper {
  width: 100%;
  margin-top: 0 !important;
  margin: 8px 0px;
  background-color: var(--video-record-bg-color);
  padding: 10px;
  position: relative;
  margin-bottom: 0px;
  position: relative;
  .preview__audio__container {
    width: 100%;
    height: 40rem !important;
    background: #08111c;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .audio__controls__wrapper{
      width: 50%;
    }
    /* Optional: Styling the audio element */
    audio {
      width: 50%;
      /* Background color for the audio controls */
      border-radius: 5px;
      color: white; /* Text color for the controls */
    }

    /* Set the background color of the control panel to #56AEFF */
    audio::-webkit-media-controls-panel {
        // background: var(--new-secondary-bg-color);
      background: #fff;
    }

    // /* Style the mute button with a background color of #B1D4E0 and make it circular */
    // audio::-webkit-media-controls-mute-button {
    //   background-color: #b1d4e0;
    //   border-radius: 50%;
    //   color: #fff;
    // }

    // /* Style the play button with a background color of #B1D4E0 and make it circular */
    // audio::-webkit-media-controls-play-button {
    //   background-color: #b1d4e0;
    //   border-radius: 50%;
    //   color: #fff;
    // }

    // /* Add a hover effect to the play button by changing the background color to a semi-transparent version of #B1D4E0 */
    // audio::-webkit-media-controls-play-button:hover {
    //   background-color: rgba(177, 212, 224, 0.7);
    // }

    // /* Set the text color of the current time display to white (#fff) */
    // audio::-webkit-media-controls-current-time-display {
    //   color: #fff;
    // }

    // /* Set the text color of the time remaining display to white (#fff) */
    // audio::-webkit-media-controls-time-remaining-display {
    //   color: #fff;
    // }

    // /* Style the timeline with a background color of #B1D4E0, add a border-radius, and adjust margins */
    // audio::-webkit-media-controls-timeline {
    //   background-color: #fff;
    //   border-radius: 25px;
    //   margin-left: 10px;
    //   margin-right: 10px;
    // }

    // /* Style the volume slider with a background color of #B1D4E0, add a border-radius, and adjust padding */
    // audio::-webkit-media-controls-volume-slider {
    //   background-color: #fff;
    //   border-radius: 25px;
    //   padding-left: 8px;
    //   padding-right: 8px;
    // }
  }
}
