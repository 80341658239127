.getstarted-container {
  margin-top: 112px;
  background: linear-gradient(
    236.79deg,
    #ff4593 3.16%,
    #ffe713 30.23%,
    #17d7ff 46.88%,
    #077bff 69.78%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 10px;
  .getstarted-container-inner {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #232323;
    border-radius: 8px;
    text-align: center;

    color: #ffffff;
    .studynow {
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      margin-top: 70px;
    }
    .getready {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      margin-top: 17px;
    }
    .getstarted-button {
      margin-top: 30px;
      margin-bottom: 70px;
    }
  }
}
