.searchbar {
  width: 100%;
  position: relative;
  //   height: 4rem;

  .search__icon {
    position: absolute;
    width: 1.5rem;
    left: 1rem;
    top: 0.8rem;
    color: var(--primary-search-icon-color);
    &.Search__icon__style__handle{
      color: var(--alpha-primary-input-text-color);

    }
  }
  
  .search__input {
    &.select__search {
      border: 1px solid var(--alpha-ternary-input-border-color);
      background-color: var(--alpha-primary-input-bg-color);
      &:hover {
        border: 1px solid var(--primary-focus-color) !important;
      }
    
      &:focus {
        box-shadow: 0 0 5px var(--primary-focus-color) !important;
        border: 1px solid var(--primary-focus-color) !important;
      }
      
      &::placeholder{
        color: var(--alpha-primary-input-text-color);
      }
    }
  }
}
