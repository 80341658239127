//
$screen-xsm-max: 500px;

// Small tablets and large smartphones (landscape view)
$screen-sm-max: 768px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 1024px;

// Large tablets and desktops
$screen-xl-min: 1440px;

//---------------------------------//
@mixin xsm {
  @media (max-width: #{$screen-xsm-max}) {
    @content;
  }
}

// Extra Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media screen and (max-width: 768px) {
    @content;
  }
}
@mixin airmax {
  @media screen and (max-width: 820px) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Custom devices
@mixin customBreakpoint($screen) {
  @media (max-width: #{$screen+'px'}) {
    @content;
  }
}

//---Template----//

// @include sm {
// }

// @include md {
// }

// @include lg {
// }

// @include xl {
// }
