.instructors__info__container {
  width: 30rem;
  // background-color: var(--secondary-bg-color);
  // background: #303030;
  background: var( --twentyseven-bg-color);
  margin-top: 44px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-top: 3rem;
  }
  .instructors__info__header__section {
    display: flex;
    flex-direction: column;
    .heading {
      color: var(--primary-input-text-color);
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 28px;
    }
  }
  .instructor__profile {
    margin-top: 1rem;
    display: flex;
    // align-items: center;
    .pic {
      margin-top: 0.5rem;
      width: 5rem;
      height: 5rem;
    }
  }
  .info {
    margin-left: 1.5rem;
    .name {
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      // color: #ffffff;
      color: var(--fourth-text-color);
    }
    .occupation {
      word-wrap: break-word;
      width: 19rem;
      display: block;
      font-size: 1.4rem;
      color: var(--instructor-text-color);
      font-family: "Inter";
      font-style: italic;
      font-weight: 400;
      line-height: 20px;
      // color: #bababa;
      color: var(--primary-placeholder-color);
      @media screen and (max-width: 800px) {
        width: 100%;
      }
    }
  }
}
