.new__chat__button {
  all: unset;
  // width: 242px;
  width: 100%;

  height: 50px;
  background: var(--primary-color) !important;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
  overflow: hidden;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  .new__chat__icon {
    width: 1.8rem;
    margin-right: 1rem;
  }
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}
