.interviewDone {
  height: 100vh;
  // height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;
  background: var(--new-secondary-bg-color);
  // margin-top: 100px;
  padding-top: 110px;
  @media screen and (max-width:1370px) {
    // margin-top: 150px;
    padding-top: 150px;
  }

  .done {
    width: 37vw;
    padding-bottom: 30px;
    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
    }
    .done__line {
      height: 1px;
      width: 100%;
      margin: 10px 0px;
      margin-top: 25px;
      background: linear-gradient(
        236.79deg,
        rgba(255, 69, 147, 0.2) 3.16%,
        rgba(255, 231, 19, 0.2) 30.23%,
        rgba(23, 215, 255, 0.2) 46.88%,
        rgba(7, 123, 255, 0.2) 69.78%
      );
    }
    .img {
      width: 100%;
      height: 300px;
      // margin: 20px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      // border: 1px solid green;

      img {
        height: 80%;
        width: 80%;
        object-fit: contain;
      }
    }
    .congrats {
      padding: 10px 0px;
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        text-align: center;
        // background: var(--seventh-colorful-text);
        color: var(--seventh-colorful-text);
      }
    }
    .whatnext {
      // padding: 10px 0px;
      margin-bottom: 15px;
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: var(--ninth-text-color);
        margin-top: 2rem;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        padding: 12px 20px;
        background: var(--seventh-colorful-text);
        color: var(--odd-tr-color);
        width: 100%;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        outline: none;
        border: none;
        cursor: pointer;

        // &:hover{
        //     background: #BABABA;
        // }
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 4rem;
    .interview__category__button {
      all: unset;
      width: 24rem;
      height: 4.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--black-trns-border);
      background: var(--alpha-secondary-btn-bg-color);
      cursor: pointer;
      font-weight: 500;
      font-size: 16px;
      @media screen  and (max-width:630px){
        width: 18rem;
        
      }
      @media screen  and (max-width:420px){
        width: 16rem;
        
      }
      @media screen  and (max-width:370px){
        width: 14rem;
        
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .interviewDone {
    .done {
      width: 55vw;
      margin-top: 120px;
    }
  }
}

@media screen and (max-width: 768px) {
  .interviewDone {
    margin-top: 65px;
    .done {
      width: 80vw;
      margin-top: 200px;
    }
  }
}

@media screen and (max-width: 426px) {
  .interviewDone {
    .done {
      width: 90vw;
      margin-top: 200px;

      h1 {
        font-size: 27px;
      }
    }
  }
}

@media screen and (max-width: 321px) {
  .interviewDone {
    .done {
      margin-top: 250px;
    }
  }
}
