.header_container{
    .left__section{
        .dropdown__holder__handle__width{
            // width: 24rem;
            width: auto;
        }

    }
    .right__section{
        .date__time__btn__width{
            width: 15rem;
        }

    }
    .handle__pmp__modal__width{
        width: 467px;
    }


}

.pmp__row {
  
    .time__row {
      width: 28rem;
      display: flex;
      justify-content: space-between;
  
      .sinlge__time {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #d0d5dd;
        .time__label {
          font-size: 1.4rem;
          font-weight: 400;
          color: var(--secondary-text-color);
          font-style: normal;
          line-height: 20px;
        }
      }
      .time__section {
        cursor: pointer;
        width: 13rem;
        margin-top: 0.8rem;
        color: var(--primary-input-text-color);
        background: var(--primary-input-bg-color);
        height: 4.8rem;
        border-radius: 8px;
        border: 1px solid var(--primary-border-color);
        padding: 1rem 1.2rem;
        position: relative;
  
        .options__section {
          z-index: 100;
          width: 15rem;
          position: absolute;
          background-color: var(--ninth-bg-color);
          top: 6rem;
          left: 0;
          display: none;
          overflow-y: scroll;
          max-height: 20rem;
          .option {
            display: block;
            font-size: 1.4rem;
            font-weight: 400;
            padding: 1rem 2rem;
            cursor: pointer;
            &:hover {
              color: #ffc97a;
            }
            &.color {
              color: #ffc97a;
            }
          }
          &.active {
            display: block;
          }
        }
        .time__title__section {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .title {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }
        .clock {
          width: 1.7rem;
          color: #bababa;
        }
      }
    }
    // .time__row__new {
    //   display: flex;
    //   width: 100%;
    //   gap: 20px;
      .pmp__time__box {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--secondary-text-color);
        }
        input[type="time"] {
          outline: none;
          border: none;
          border: 1px solid var(--primary-border-color);
          border-radius: 5px;
          padding: 12px 15px;
          // color: #fff;
          color: var(--primary-input-text-color);
          background: var(--primary-input-bg-color);
          cursor: text;
          &:hover,
          &:focus,
          &:active {
            border: 1px solid var(--primary-hover-color);
          }
          &::-webkit-calendar-picker-indicator {
            background-image: url("../../../../assets/icons/clock.svg");
            cursor: pointer;
          }
        }
    //   }
    }
  }
  
  .add_button__witdh {
    width: auto;
  }
  
  .pmp__form {
    .error__text {
      &:not(:empty) {
        padding-top: 0.5rem;
        // margin-bottom: 1rem;
      }
    }
  }