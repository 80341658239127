.double__login__setup__section {
  .heading {
    text-align: center;
    display: block;
    font-weight: 500;
    font-size: 2rem;
    color: #ffffff;
  }
  .email {
    text-align: center;

    display: block;
    color: #667085;
    font-weight: 400;
    font-size: 1.4rem;
    margin-top: 1rem;
  }
  .double__box__container {
    margin-top: 2rem;
    display: flex;
    gap: 2rem;
    .single__box {
      width: 100%;
      border: 1px solid #667085;
      border-radius: 0.8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4rem 0 3rem 0;
      cursor: pointer;
      &:hover {
        border: 1px solid #6f6ff1;
        background-color: #1c1c1c;
      }

      .img__container {
        width: 5.7rem;
        height: 5.6rem;
        border-radius: 8px;

        .image {
          width: 100%;
        }
      }
      .titile {
        display: block;
        font-weight: 500;
        font-size: 1.6rem;
        color: #ffffff;
        margin-top: 1.4rem;
      }
      .domain {
        display: block;
        font-weight: 400;
        font-size: 1.4rem;
        color: #bababa;
        margin-top: 0.8rem;
      }
    }
  }
}
