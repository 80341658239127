.into__text__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  .ai__title {
    display: block;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    line-height: 3.8rem;
  }
  p {
    width: 40rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin-top: 0.8rem;
    @media screen and (max-width:500px) {
      width: 100%;
      
    }
  }
}
