.bell__icon__cotainer {
  cursor: pointer;
  margin: 0 2rem;
  display: flex;
  align-items: center;
  height: 3.2rem;
  .bell__icon {
    position: relative;
    .bell {
      height: 2.2rem;
      width: 2.2rem;
      display: flex;
      // color: var( --seventh-bg-color);
      // color: var(--bell-icon-color);
      color: #fff;
      stroke-width: 1.4px;
      &.active {
        color: #000;
      }
      &.non__active{
        color: var(--fourth-text-color);
      }
      &.color__handle{
        color: var(--fourth-text-color);
      }
      &.bellIconColor{
        color: var(--fourth-text-color);

      }
    }
    .notification__count {
      position: absolute;
      height: 1.4rem;
      width: 1.4rem;
      background: #f04438;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 8px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -2px;
      right: -10%;
      color: #ffffff;
    }
  }
}
