.tour__done__wrapper{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1100;
    background: rgba(0, 0, 0, 0.70);
    // opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    .tour__done__container{
        width: 447px;
        background: var(--notification-bg-color);
        padding: 40px;
        padding-top: 20px;
        margin-top: 7rem;
        .close__icon{
            display: flex;
            justify-content: flex-end;
            svg{
                height: 2rem;
                cursor: pointer;
                margin-right: -2rem;
                stroke-width: 1.5px;
                color: var( --fourth-text-color);
                &:hover {
                    color: #bababa;
                }
            }

        }
        .content__wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            h3{
                color: var( --fourth-text-color);
                text-align: center;
                font-family: "Inter";
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                margin-top: 40px;
    
            }
            p{
                color: var( --fourth-text-color);
                text-align: center;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-top: 15px;
    
            }
            button{
                margin-top: 30px;
                padding: 12px;
                background: var(--tenth-colorful-text);
                border: none;
                width: 100%;
                outline: none;
                color: var(--button-inner);
                font-family: "Inter";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                cursor: pointer;
    
            }

        }
      

    }
}