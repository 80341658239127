$width: 373px;

.container__div {
  position: relative;
  z-index: 101;
  .wrpper__div {
    position: absolute;
    width: 320px;
    border-radius: 8px;
    background: var(--alpha-show-step-popup-bg-color);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
    padding-top: 10px;
    right: -33.5rem;
    top: -14.5rem;
    &::before {
      content: "";
      position: absolute;
      width: 15px;
      height: 15px;
      background: var(--alpha-show-step-popup-bg-color);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
      left: -6px;
      top: 100px;
      transform: rotate(45deg);
      z-index: -1;
    }

    .close__icon {
      display: flex;
      justify-content: flex-end;
      padding-right: 14px;
      cursor: pointer;
      margin-bottom: -14px;
      svg {
        height: 1.8rem;
        stroke-width: 1.5px;
        color: var( --fourth-text-color);
        &:hover {
            color: #bababa;
        }
      }
    }
    .tour__title {
      color: var( --fourth-text-color);
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      padding: 0 14px;
    }
    p {
      padding: 6px 14px 0px 14px;
      color: var( --fourth-text-color);
      font-family: "Inter";
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }

    .buttons__section {
      margin-top: 13px;
      padding-left: 14px;
      padding-right: 14px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-bottom: 20px;
      .back__button {
        background: var(--alpha-secondary-btn-bg-color);
        padding: 9px 20px;
        color: var(  --seventh-text-color);
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: none;
        outline: none;
        cursor: pointer;
        border: 1px solid var(--alpha-back-btn-border-color);
      }
      .next__button {
        background: var(--tenth-colorful-text);
        padding: 9px 20px;
        color: var(--button-inner);
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: none;
        outline: none;
        cursor: pointer;
      }
      .disable__button {
        padding: 9px 20px;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: none;
        outline: none;
        cursor: not-allowed;
        background-color: var(--alpha-disable-btn-bg-color);
        color: var(--alpha-disable-btn-text-color);
      }
    }
    .progress__section__div {
      width: 100%;
      .progress__bar__div {
        height: 3px;
        background: var(--tenth-colorful-text);
      }
    }
    .bottom__div {
      border-radius: 0px 0px 8px 8px;
      background: var(--alpha-show-step-popup-below-progress-bg-color);
      height: 14px;
      width: 100%;
    }
  }
  .step__two {
    // without Animation
    // top: -6.2rem;

    // with animation 
    top: -7rem;
    &::before {
      // without Animation
      // top: 90px;

      // with animation 
      top: 100px;
    }
  }
  .step__three {
    right: 0;
    left: 0;
    margin-top: 10px;
    top: -6px;
    // z-index: 1;
    width: $width;
    &::before {
      left: 20px;
      top: -6px;
      transform: rotate(135deg);
    }
  }
  .step__fourth {
    right: -46.5rem;
    top: -13.5rem;
    width: $width;
    &::before {
      right: 50px;
      top: -6px;
      left: unset;
    }
  }
  .step__fourth__without__warning {
    right: -46.5rem;
    top: -6.5rem;
    width: $width;
    &::before {
      right: 50px;
      top: -6px;
      left: unset;
    }
  }
  .step__fifth {
    right: -46.7rem;
    top: -17.5rem;
    width: $width;
    &::before {
      right: 71px;
      top: -6px;
      left: unset;
    }
  }
  .step__fifth__without__warning {
    right: -46.7rem;
    top: -10.5rem;
    width: $width;
    &::before {
      right: 71px;
      top: -6px;
      left: unset;
    }
  }
  .sixth__step {
    right: -39rem;
    top: 10.5rem;
    width: $width;
    &::before {
      // without Animation
      // top: 35px;

      // with animation 
      top: 100px;
    }
  }
  .seventh__step {
    top: 35.5rem;
    // z-index: 1;
    right: -38.5rem;
    width: $width;
    &::before {
      top: 87px;
    }
  }
  .eight__step{
    top: 34.5rem;
    // z-index: 1;
    right: -38.5rem;
    width: $width;
    &::before {
      top: 100px;
    }

  }
  .ninth__step{
    top: 32.5rem;
    // z-index: 1;
    right: -38.5rem;
    width: $width;
    &::before {
      top: 118px;
    }

  }
  .tenth__step {
    top: 26rem;
    right: 0;
    left: 0rem;
    // z-index: 21;
    width: $width;
    &::before {
      top: 157px;
      left: 35px;
      z-index: -1;
    }
  }
  .eleventh__step {
    top: 37.5rem;
    right: -32rem;
    // z-index: 101;
    width: $width;

    &::before {
      left: -6px;
      top: 135px;
      z-index: -1;
    }
  }
  .twelveth__step {
    right: 0rem;
    top: 38.5rem;
    // z-index: 21;
    left: 42rem;
    width: $width;
    &::before {
      top: 125px;
    }
  }
  .thirtheenth__step {
    top: 30rem;
    // z-index: 101;
    left: 12rem;
    right: 0;
    width: $width;
    &::before {
      left: 20px;
      top: 175px;
      z-index: -1;
    }
  }
  .fourteen__step {
    top: 30rem;
    // z-index: 101;
    left: 0rem;
    right: 0;
    width: $width;
    &::before {
      left: 20px;
      top: 175px;
      z-index: -1;
    }
  }
  .fifteen__step {
    right: 0;
    top: -1.5rem;
    // z-index: 1;
    width: $width;
    &::before {
      left: 365px;
      top: 36px;
    }
  }
  .sixteen__step {
    // z-index: 111;
    right: -42rem;
    top: -32rem;
    width: $width;
    &::before {
      top: -8px;
      left: 299px;
    }
  }
  .sixteen__step__without__warning {
    top: -24.5rem;
    width: $width;
  }
  .click__here__text__animation__step__five__wrapper{
    position: relative;
    .click__here__text__animation__step__five__container{
      position: absolute;
      top: -3.5rem;
      right: 5rem;
      display: grid;
      place-items: center;
      animation: stepFiveAnimate 2s infinite;
        svg{
          height: 15px;
          transform: translateY(4px);
        }
      
      p{
        all: unset;
        font-size: 12px;
        padding: 0;
      }
    }
  }
  .click__here__text__animation__wrapper{
    position: relative;
    .click__here__text__animation__container{
      position: absolute;
      top: 1.5rem;
      left: 2.2rem;
      animation: animate 2s infinite;
        svg{
          height: 15px;
          transform: translateY(4px);
        }
      
      p{
        all: unset;
        font-size: 12px;
        padding: 0;
      }

    }
    .seven__step__animation{
      top: 1.9rem;
    }
    .eleventh__step__animation{
      top: 3rem;
    }
  }
}


@keyframes animate {
  0% {
      opacity: 0;
      transform:  translatex(20px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform:translateX(-20px);
  }
}
@keyframes stepFiveAnimate {
    0% {
      opacity: 0;
      transform:  translateY(5px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform:translateY(-5px);
  }
  
}
