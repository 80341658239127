.assist__wrapper {
  margin-top: 12rem;
  display: flex;
  justify-content: center;

  .center__section {
    width: 63rem;
    @media screen and (max-width: 650px) {
      width: 100%;
      padding: 0 2rem;
    }
    .mt-22 {
      margin-top: 2.3rem;
    }
    .mt-25 {
      margin-top: 2.4rem;
    }
    .prompt__topography {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      @media screen and (max-width: 650px) {
        display: block;
        margin-top: 5rem;
      }
    }
    .prompt__wrapper {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: auto auto;
      gap: 1.5rem;
      @media screen and (max-width: 650px) {
        width: 100%;
        grid-template-columns: auto;
        gap: 0;
      }
    }
  }
}
.assist__wrapper__div{
  height: 100vh;
  margin: 0;
  padding-top: 12rem;
  background: var(--new-secondary-bg-color);
}