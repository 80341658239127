.card__emelent__section {
  margin-top: 1rem;
  .StripeElement {
    // border: 1px solid red;
    padding: 1rem;
  }

  .stripe__card {
    border: 1px solid var(--primary-border-color);
    border-radius: 8px;
    padding: 1.4rem;
    &:hover {
      border: 1px solid var(--primary-hover-color);
    }

    &:focus {
      box-shadow: 0 0 5px var(--primary-focus-color);
      border: 1px solid var(--primary-focus-color);
    }
  }
}
