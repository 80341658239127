body.indigo_dark {
  --modal-close-icon-color: #ffffff;
  // ****************Added colors ****************
  --gray-text-color: #444444;
  --gray-text-icon-color: #ffffff;
  --gray-btn-bg-color: #1c1c1c;
  --controls-bg: #0d0d0d;
  --heading-text-color: #000000;
  --feedback-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  );
  --wrapup-text-color: #bababa;
  --video-record-bg-color: #1c1c1c;
  // --file-item-border:1px solid #333333;
  --dashed-border: 1px dashed #333333;

  //*********PRODUCT MANAGER PAGE COLORS***************
  --course-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.08) 2.05%,
    rgba(82, 80, 80, 0.06) 68.52%
  );
  --our-course-divider: #303030;
  --archive-heading-colorful-text: #fe7268;
  --support-btn-bg-hover-color: #00cccc;

  //**********USER NOTIFICATION COLOR*************
  --user-notification-header-markdone-color: #bababa;
  --notification-header-border-color: transparent;
  --notification-border-bottom-color: #303538;
  --bell-icon-color: #ffffff;
  --scrollbar-thumb-background-color: rgba(0, 0, 0, 0.44);
  --first-letter-bg-color: #303538;
  --notification-feedback-hover-color: #2c2c2c;

  //*********** CHATBOT COLOR ******************
  --chatbotchatbox-bg-color: #383838;

  --chatbot-header-underline-color: #303239;
  --mobileview-chat-bot-controls-bg-color: #383838;

  // **************************************
  --primary-color: #47c9e6; //--
  --secondary-color: #fe7268; //--
  --success-color: #3ed0cf;
  --danger-color: #f04438; //--
  --muted-color: #d0d5dd;
  --active-color: #47c9e6; // --
  --background-color: #05103a; //--
  --sidebar-bg-color: #101c43; //---
  --header-bg-color: #05103a; //--
  --line-and-stroke: #25345e; //--
  --description: #dee2e8; //--
  --header-text: #ffffff; //--
  --table-header-text: #999999; //--
  --input-label-color: #e0e0e0; // --
  --sidebar-hover-color: #18244c; //--
  --mode-switch-button-hover: #9babc3; //--
  --apply-button-bg: #18244c; //--
  --apply-button-text: #ffffff; //--
  --welcome-description: #dee2e8; //

  --ai-assist-header-color: #05103a; //
  --ai-sidebar-color: #050f36; //--
  --ai-sidebar-border: #25345e; //--
  --ai-sidebar-tooltip-bg: #25345e; //--
  --ai-tooltip-bg: #25345e; //--
  --ai-prompt-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  ); //--
  --ai-prompt-border: transparent; //--
  --ai-user-stripe-line: #101c43; //--
  --ai-user-avatar: #05103a; //--
  --ai-boat-avatar: #25345e; //--
  --ai-scroll-to-top-icon: #424a69; //--
  --ai-scroll-thumb: #25345e; //--
  --ai-scroll-track: #101c43; //--
  --ai-generation-response-bg: #25345e; //--
  --ai-generation-response-border: #25345e; //--
  --ai-generation-response-text: #ffffff; //--

  --clear-conversation: #81879c; //--

  // text color
  --primary-text-color: #ffff; //--
  --secondary-text-color: #81879c; //--
  // color: #d0d5dd;
  // backgound--color
  --primary-bg-color: #121212;
  --secondary-bg-color: #101c43; //--
  --third-bg-color: #1c1c1c;
  --forth-bg-color: #232323;
  --fifth-bg-color: #303030;
  --sixth-bg-color: #474747;
  --seventh-bg-color: #232323;
  --eighth-bg-color: #232323;
  --ninth-bg-color: #1c1c1c;
  --twelveth-bg-color: #121212;
  --thirteen-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  );

  // background:

  --fourteen-bg-color: #2c2c2c;
  --fifteen-bg-color: #0d0d0d;
  --sixteen-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.045) 2.05%,
    rgba(82, 80, 80, 0.057) 68.52%
  );
  --seventeen-bg-color: linear-gradient(
    236.79deg,
    rgba(255, 69, 147, 0.3) 3.16%,
    rgba(255, 231, 19, 0.3) 30.23%,
    rgba(23, 215, 255, 0.3) 46.88%,
    rgba(7, 123, 255, 0.3) 69.78%
  );

  --eighteen-bg-color: #0d0d0d;
  --nineteen-bg-color: #0d0d0d;

  --twentyone-bg-color: #fda82c;
  --twentytwo-bg-color: #303030;
  --twentythree-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  );
  --twentyfour-bg-color: #81879c; //--
  --twentyfive-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  );
  --twentysix-bg-color: #444444;
  --twentyseven-bg-color: #303030;
  --twentyeight-bg-color: #303538;
  --twentynine-bg-color: #303030;
  --thirty-bg-color: #1c1c1c;
  --thirty-one-bg-color: #303030;
  --thirty-two-bg-color: #232323;
  --thirty-three-bg-color: #ffffff;

  --seotag-bg-color: #ffffff;
  --tag-bg-color: #d0d5dd;

  // primary input filed dark mode
  --primary-border-color: #25345e; //--
  --primary-input-bg-color: #18244c; //--
  --primary-input-text-color: #ffffff; //--
  --primary-hover-color: #d0d5dd;
  --primary-focus-color: #e0e0e0; //--
  --primary-placeholder-color: #81879c; //--
  --primary-tabs-underline-color: #ffc97a;

  --third-text-color: #d0d5dd;
  --fourth-text-color: #ffffff;
  --fifth-text-color: #ffff;
  --sixth-text-color: #d0d5dd;
  --seventh-text-color: #ffffff;
  --eight-text-color: #d0d5dd;
  --ninth-text-color: #bababa;
  --tenth-text-color: #ffc97a;
  --filter-label-top: #bababa;
  --eleventh-text-color: #000000; //--

  --helper-text-color: #bababa;
  // button color
  --plan-bg-color: #d0d5dd;
  --btn-text-color: #888888;

  // colorful text
  --primary-colorful-text: #ffc97a;
  --secondary-colorful-text: #6f6ff1;
  --third-colorful-text: #ffc97a;
  --fourth-colorful-text: #fe7268;
  --fifth-colorful-text: #fec97c;
  --sixth-colorful-text: #fdbd60;
  --seventh-colorful-text: #ffc97a;
  --eight-colorful-text: #53c4c7;
  --ninth-colorful-text: #ffffff;
  --tenth-colorful-text: #fec97c;
  --elevtnth-colorful-text: #6f6ff1;

  // table
  --even-tr-color: #303030;
  --odd-tr-color: #232323;

  --secondary-table-header-color: #303030;
  --ternary-table-header-color: #ffc97a;
  // instructor
  --instructor-text-color: #6f6ff1;

  --primary-course-title-color: #ffffff; //--
  // sidebar toggle button
  --primary-sidebar-toggle-color: #5c6577; //--

  // search icon color
  --primary-search-icon-color: #81879c; //--

  // table
  --primary-table-header-color: #303030;
  --primary-table-header-text: #ffc97a;
  // pagination color
  --primary-pg-bg-color: #303030;
  --primary-pg-text-color: #ffffff;
  --pg-three-dot-color: #ffffff;

  // audio player track
  --player-slider-bg: #bababa;
  --player-slider-track: #ffffff;

  // progress bar
  --primary-progress-bar-bg: #363636;
  --secondary-progress-bar-bg: #ffc97a;
  --secondary-progress-bar-title: #d0d5dd;

  // according
  --primary-progress-divider: #303030;
  --primary-divider: #303030;
  --secondary-progress-divider: #303030;

  // dropdown
  --dropdown-bg-color: #18244c; //--
  --primary-dropdown-border: #25345e; //--
  --primary-dropdown-text: #dee2e8; //--
  --primary-dropdown-bg: #1b2853; //--
  --secondary-dropdown-border: transparent;
  --primary-box-shadow: rgba(0, 0, 0, 0.05);

  // profile section
  --profile-divider-color: #232323;
  // stepper
  --stepper-bg-color: #121212;
  --counter--border-color: #444444;
  --dashed--border-color: #33333f;

  //  skleton
  --skleton--bg--color: linear-gradient(
    110deg,
    #2f406d 8%,
    #25345e 18%,
    #25345e 33%
  ); //--

  // header--drop-down
  --header-drop-bg-color: #25345e; //--
  --header-drop-divider: #232323;
  --header-drop-text-color: #ffffff;
  // select box
  --primary-select-text-color: #ffffff;

  // divider
  --primary-divider-color: #25345e; //
  --secondary-divider-color: #232323;
  --third-divider-color: #333333;
  --fourth-divider-color: #303030;

  //share certificate
  --share-certificate: #1c1c1c;
  //blog-box
  --blog-category-label: #ffc97a;
  //blog
  --single-blog-bg: #232323;
  //interviews-and-webinars
  --iw-duration: #ffc97a;
  //landing button
  --button-yellow: #fec97c;

  // scroll bar
  --thunmb-color: #101010;
  --track--color: #1d1d1d;
  --new-thumb: #52565a;
  --new-track: #29292a;
  --filupload--thumb-color: rgba(0, 0, 0, 0.17);

  // chat scroll button icon color
  --chat-scroll-icon-color: #000000;

  //custom select  box or drop down
  --select-bg-color: #1c1c1c;
  --select-search-bg-color: #232323;
  // moving ball color
  --ball-bg-color: #363636;

  //team member detail
  --select-border: #232323;
  --card-back-bg: #232323;
  --baw-card-back-bg: #232323;
  --filter-show-text: #d0d5dd;
  --darkonly-bg-color: #121212;
  --your-recent-courses: #98a2b3;
  --drop-down-according: #232323;

  --course--tag--title--bg: #232323;
  --course--tag--title: #ffc97a;

  // new design
  --black-trns-border: transparent; //--
  --black-trns-border2: transparent;
  --toggle-btn-border: #283841;

  --urc-card-bg: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  );
  --urc-card-border: transparent;
  --urc-card-border-bottom: #333333;
  --cp-user-top-bg: #0d0d0d;
  --button-inner: #201f1f;
  --ql-th: #ffc97a;
  --custom-label-txt: #d0d5dd;
  --date-txt-color: #bababa;
  --notgivent-text-color: #bababa;
  // test purpose

  //**************TAC COLORS STARTS HERE****************************
  --archive-border-color: #443e3e;
  --tac-active-color: #43ade8;
  --tac-table-header-color: #8b8c8d;
  --tac-coupon-title-color: #ef6c00;

  --tac-tab-active-color: #43ade8;
  --apply-now--view-details-btn-coor: #667085;
  --apply-now-view-details-border-color: #454545;
  // #454545
  --tac-duration-color: #8b8c8d;
  --tac-occupation-color: #bababa;
  --response-btn-color: #ffffff;

  --download-user-file-bg-color: #52565a;
  --tac-billing-button-border-color: #25345e; //--
  //**************TAC COLORS ENDS HERE****************************

  padding: 0;
  color: #fff;
  background-color: #05103a; //---
  font-size: 16px;
  word-break: break-word;
  font-family: "Inter", sans-serif;
  font-style: normal;
}

body.indigo_light {
  // **********************Added colors ****************
  --gray-text-color: #d1d3d4; //dark #444444
  --gray-text-icon-color: #d1d3d4;
  --controls-bg: #ffffff;
  --control-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  --gray-btn-bg-color: #e2e2e2;
  --feedback-bg-color: #ffffff;
  --wrapup-text-color: #000000;
  --video-record-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  );
  --file-item-border: 1px solid #d6d6d6;
  --dashed-border: 1px dashed #d6d6d6;

  //*********PRODUCT MANAGER PAGE COLORS***************
  --course-bg-color: #ffffff;
  --our-course-divider: #d6d6d6;
  --archive-heading-colorful-text: #000000;
  --support-btn-bg-hover-color: #7cc9fe;

  //**********USER NOTIFICATION COLOR*************
  --user-notification-header-markdone-color: #6f6f6f;
  --notification-header-border-color: #d0d5dd;
  --notification-border-bottom-color: #d0d5dd;
  --bell-icon-color: #232323;
  --scrollbar-thumb-background-color: #bababa;
  --first-letter-bg-color: #e4e4e4;
  --notification-feedback-hover-color: #f2f2f2;
  // --scrollbar-trach-bg-color:

  //***********CHATBOT COLORS**********/
  --chatbotchatbox-bg-color: #f2f2f2;
  --chatbot-header-underline-color: #d0d5dd;
  --mobileview-chat-bot-controls-bg-color: #f7f7f7;

  // ********************A**********************************
  // instructor
  --primary-color: #47c9e6; //--
  --secondary-color: #fe7268; //--
  --success-color: #3ed0cf;
  --danger-color: #f04438; //--
  --muted-color: #d0d5dd;
  --active-color: #47c9e6; //--
  --background-color: #ffffff; //--

  --sidebar-bg-color: #101c43; // ---
  --header-bg-color: #ffffff; //--
  --line-and-stroke: #dee2e8; //--
  --description: #000000; //--
  --header-text: #1a1a1a; //--
  --table-header-text: #243461; //--
  --input-label-color: #555555; //--
  --sidebar-hover-color: #18244c; //--
  --mode-switch-button-hover: #1a1a1a; //--
  --apply-button-bg: #5c6577; //--
  --apply-button-text: #ffffff; //--

  --primary-text-color: #000000; //--
  --secondary-text-color: #5c6577; //--

  --ai-assist-header-color: #05103a; //
  --ai-sidebar-color: #050f36; //--
  --ai-sidebar-border: #25345e; //--
  --ai-sidebar-tooltip-bg: #25345e; //--
  --ai-tooltip-bg: #40414f; //--
  --ai-prompt-bg-color: #ffffff; //--
  --ai-prompt-border: #d6d6d6; //--
  --ai-user-stripe-line: #f5f5f5; //--
  --ai-user-avatar: #bec0c4; //--
  --ai-boat-avatar: #5a5a5a; //--
  --ai-scroll-to-top-icon: #bababa; //--
  --ai-scroll-thumb: #25345e; //--
  --ai-scroll-track: #101c43; //--
  --ai-generation-response-bg: #f5f5f5; //--
  --ai-generation-response-border: #d0d5dd; //--
  --ai-generation-response-text: #40414f; //--

  --clear-conversation: #81879c; //--

  // primary input filed light mode
  --primary-border-color: #dee2e8; //--
  --primary-input-bg-color: #ffffff; //--
  --primary-input-text-color: #1a1a1a; //--

  --primary-hover-color: #6f6ff1;
  --primary-focus-color: #47c9e6; //--
  --primary-placeholder-color: #5c6577; //--
  --primary-tabs-underline-color: #6f6ff1;

  // backgound--color
  --primary-bg-color: #ffff;
  --secondary-bg-color: #ffff; //--
  --third-bg-color: #ffff;
  --forth-bg-color: #e4e4e4;
  --fifth-bg-color: #f9fafb;
  --sixth-bg-color: #f7f7f7;
  --seventh-bg-color: #ffffff;
  --eighth-bg-color: #f9fafb;
  --ninth-bg-color: #f9fafb;
  --twelveth-bg-color: #f8f8f8;
  --thirteen-bg-color: #ffffff;
  --fourteen-bg-color: #f5f5f5;
  --fifteen-bg-color: #ffffff;
  --sixteen-bg-color: #ffffff;
  --seventeen-bg-color: #000000;
  --eighteen-bg-color: #f2f2f2;
  --nineteen-bg-color: #f8f8f8;
  --twenty-bg-color: linear-gradient(
    132.06deg,
    rgba(255, 255, 255, 0.09) 2.05%,
    rgba(82, 80, 80, 0.114) 68.52%
  );
  --twentyone-bg-color: #383879;
  --twentytwo-bg-color: #f5f5f5;
  --twentythree-bg-color: #ffffff;
  --twentyfour-bg-color: #5c6577; //--
  --twentyfive-bg-color: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.09) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  --twentysix-bg-color: #f5f5f5;
  --twentyseven-bg-color: #ffffff;
  --twentyeight-bg-color: #e4e4e4;
  --twentynine-bg-color: #f2f2f2;
  --thirty-bg-color: #f5f5f5;
  --thirty-one-bg-color: #f2f2f2;
  --thirty-two-bg-color: #bababa;
  --thirty-three-bg-color: #bababa;

  --seotag-bg-color: #f9fafb;
  --tag-bg-color: #f2f2f2;
  // text color

  --third-text-color: #000000;
  --fourth-text-color: #000000;
  --fifth-text-color: #040505;
  --sixth-text-color: #667085;
  --seventh-text-color: #667085;
  --eight-text-color: #101828;
  --ninth-text-color: #000000;
  --tenth-text-color: #667085;
  --eleventh-text-color: #ffffff; //--
  --filter-label-top: #000000;
  --primary-course-title-color: #000000; //--
  // colorful text
  --primary-colorful-text: #6f6ff1;
  --secondary-colorful-text: #ffc97a;
  --third-colorful-text: #43ade8;
  --fourth-colorful-text: #53c4c7;
  --fifth-colorful-text: #ffffff;

  --sixth-colorful-text: #5454f9;

  --seventh-colorful-text: #5454f9;
  --eight-colorful-text: #fe7268;
  --ninth-colorful-text: #6f6ff1;
  --tenth-colorful-text: #6f6ff1;
  --elevtnth-colorful-text: #000000;

  // button color
  --plan-bg-color: #fff;
  --instructor-text-color: #667085;
  --btn-text-color: #201f1f;

  // sidebar toggle button
  --primary-sidebar-toggle-color: #81879c; //--

  // search icon color
  --primary-search-icon-color: #5c6577; //--

  // table
  --primary-table-header-color: #f9fafb;
  --secondary-table-header-color: #d0d5dd;
  --ternary-table-header-color: #6f6ff1;
  --primary-table-header-text: #667085;

  --secondary-table-header-color: #d0d5dd;
  --ternary-table-header-color: #6f6ff1;

  // table
  --even-tr-color: #f9fafb;
  --odd-tr-color: #ffffff;
  // pagination color
  --primary-pg-bg-color: #e9e9fd;
  --primary-pg-text-color: #4e7e95;
  --pg-three-dot-color: #667085;

  // audio player track
  --player-slider-bg: #d6d6d6;
  --player-slider-track: #bababa;

  // profile section
  --profile-divider-color: #d0d5dd;
  // progress bar
  --primary-progress-bar-bg: #d0d5dd;
  --secondary-progress-bar-bg: #6f6ff1;
  --secondary-progress-bar-title: #667085;
  // according
  --primary-progress-divider: #d0d5dd;
  --primary-divider: #d0d5dd;
  --secondary-progress-divider: transparent;
  // dropdown
  --dropdown-bg-color: #ffffff; //--
  --primary-dropdown-border: #dee2e8; //--
  --primary-dropdown-text: #000000; //--
  --primary-dropdown-bg: #ffffff; //--
  --secondary-dropdown-border: #d0d5dd;
  --primary-box-shadow: rgba(1, 1, 1, 0.05);
  // stepper
  --stepper-bg-color: #e3f3fc;
  --counter--border-color: #d0d5dd;
  --dashed--border-color: #bababa;
  // header--drop-down
  --header-drop-bg-color: #dee2e8; //--
  --header-drop-divider: #d0d5dd;
  --header-drop-text-color: #101828;
  // select box
  --primary-select-text-color: #101828;

  // divider
  --primary-divider-color: #dee2e8; //
  --third-divider-color: #d6d6d6;
  --fourth-divider-color: #e1e1e1;
  //share certificate
  --share-certificate: #f9fafb;
  //blog
  --single-blog-bg: #f9fafb;
  //interviews-and-webinars
  --iw-duration: #6f6ff1;
  //landing button
  --button-yellow: #fec97c;

  // scroll bar
  --thunmb-color: #bababa;
  --track--color: #d4d4d4;
  --new-thumb: #bababa;
  --new-track: rgba(0, 0, 0, 0.17);
  --filupload--thumb-color: #bababa;

  // chat scroll button icon color
  --chat-scroll-icon-color: #bababa;

  //custom select  box or drop down
  --select-bg-color: #f9fafb;
  --select-search-bg-color: #ffffff;

  // moving ball color
  --ball-bg-color: #eeeeee;

  //team member detail
  --select-border: #d0d5dd;
  --card-back-bg: #f9fafb;
  --baw-card-back-bg: #ffffff;
  --filter-show-text: #667085;
  --darkonly-bg-color: #fff;
  --your-recent-courses: #667085;
  --drop-down-according: #232323;

  --course--tag--title--bg: #ffc97a;
  --course--tag--title: #000000;
  // stepper

  //  skleton
  --skleton--bg--color: linear-gradient(
    110deg,
    #cfd5dc 8%,
    #dee2e8 18%,
    #dee2e8 33%
  ); //--

  // new design
  --black-trns-border: #d6d6d6; //--
  --black-trns-border2: transparent;
  --toggle-btn-border: #e3f3fc;

  --urc-card-bg: #fff;
  --urc-card-border: #d6d6d6;
  --urc-card-border-bottom: #d6d6d6;
  --cp-user-top-bg: #f8f8f8;
  --button-inner: #ffffff;
  --ql-th: #667085;
  --custom-label-txt: #344054;
  --date-txt-color: #344054;
  --notgivent-text-color: #667085;
  --modal-close-icon-color: #444444;

  //**************TAC COLORS STARTS HERE****************************
  --archive-border-color: #d0d5dd;
  --tac-active-color: #ef6c00;
  --tac-table-header-color: #667085;
  --tac-coupon-title-color: #667085;
  --tac-tab-active-color: #ee6c00;
  --apply-now--view-details-btn-coor: #ee6c00;
  --apply-now-view-details-border-color: #d0d5dd;
  --tac-duration-color: #667085;
  --tac-occupation-color: #667085;
  --response-btn-color: #40414f;

  --download-user-file-bg-color: #98a2b3;
  --tac-billing-button-border-color: #dee2e8; //--

  //**************TAC COLORS ENDS HERE****************************

  padding: 0;
  background-color: #ffffff;
  font-size: 16px;
  word-break: break-word;
  font-family: "Inter", sans-serif;
  font-style: normal;
}
