.video__processing__section {
  height: 35rem;
  width: 100%;
  border: 10px solid var(--admin-video-processing-border-color);
  background: #0d0d0d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .admin_video_processing_loader {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 5px solid #47494b;
    border-right-color: #d0d5dd;
    animation: spin 0.5s linear infinite;
  }

  p {
    display: block;
    margin-top: 1rem;
    font-weight: 400;
    font-size: 1.2rem;
    color: #6f6f6f;
    text-align: center;
  }
}
