@import "./breakpoints.scss";

@mixin form-input {
  display: block;
  width: 100%;
  height: 4.4rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border-radius: 8px;
  border: 1px solid var(--primary-border-color);
  padding: 1rem 1.2rem;
  font-size: 1.4rem;
  outline: none;
  color: var(--primary-input-text-color);
  background: var(--primary-input-bg-color);

  @include xl {
    height: 5rem;
  }
  &.error {
    border: 1px solid var(--danger-color);
  }
  &.search__input {
    height: 4rem;
    padding-left: 3rem;
    &::placeholder {
      font-size: 1.4rem;
      color: var(--primary-placeholder-color);
    }
  }
  &.file {
    display: none;
  }
  &:hover {
    border: 1px solid var(--primary-focus-color);
  }

  &:focus {
    box-shadow: 0 0 5px var(--primary-focus-color);
    border: 1px solid var(--primary-focus-color);
  }

  &:disabled {
    box-shadow: 0 0 5px var(--primary-input-bg-color);
    border: 1px solid var(--primary-input-bg-color);
  }

  &::placeholder {
    font-size: 1.4rem;
    color: var(--primary-placeholder-color);
  }
}
