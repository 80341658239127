.quicklinkhead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 24px;
  max-width: 100%;
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: var(--primary-text-color);
  }
  button {
    width: 90px;
  }
}

.hidden-input {
  .filefield {
    display: none !important;
  }
}

.img-up {
  //   width: 278px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  overflow: hidden;
  .file_fieldd {
    // border: 1px solid #fff !important;
    // flex: 1;
    width: 100% !important;
    height: 114px;
    background-color: var(--primary-input-bg-color);
    border: 1px solid var(--primary-border-color);
    &.error {
      border: 1px solid var(--danger-color);
    }

    border-radius: 1rem;
    .file_input_design {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .logout_icon {
      color: var(--primary-border-color);
      color: #667085;
      font-size: 1.5rem;
      cursor: pointer;
      transform: rotate(270deg);
      width: fit-content;
    }
    .clik_text {
      color: var(--primary-border-color);
      color: #667085;
      font-size: 1.5rem;
      cursor: pointer;
      width: fit-content;
    }

    .thumbnail__section {
      // border: 1px solid red;
      padding: 1rem;
      padding-bottom: 0;
      .thumbnail__image {
        width: 100%;
        height: 18rem;
        object-fit: cover;
        border-radius: 0.5rem;
        cursor: pointer;
        // border: 1px solid #fff;
      }
      .remove__image {
        line-height: 17px;
        display: block;
        text-align: center;
        text-decoration-line: underline;
        text-underline-offset: 4px;
        text-align: right;
        color: #fe7268;
        cursor: pointer;
      }
    }
  }
}

.form-label-ql {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: var(--custom-label-txt);
  margin-bottom: 13px;
}
.ql-thumbnail-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  .ql-image-section {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: #1c1c1c;
    background-color: var(--select-bg-color);

    img {
      cursor: pointer;
      height: 20px;
      width: 20px;
    }
  }
  button {
    all: unset;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #f04438;
    color: #6f6ff1;
  }
}
