.theme__mode__button {
  border: none;
  outline: none;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .moon__icon {
    width: 1.8rem;
    color: #000;
  }

  .sun__icon {
    width: 1.8rem;
    color: #d0d5dd;
  }

  &.dark {
    background: var(--primary-border-color);
    &:hover {
      background: var(--mode-switch-button-hover);
      .sun__icon {
        color: var(--eleventh-text-color);
      }
    }
  }
  &.light {
    background: var(--primary-border-color);
    &:hover {
      background: var(--mode-switch-button-hover);
      .moon__icon {
        color: var(--eleventh-text-color);
      }
    }
  }
}
