@mixin hideScrollbar{
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
}
.sidebar__container {
  background-color: var(--sidebar-bg-color);
  color: #ffff;
  // width: 24rem;
  width: 300px;
  height: 100vh;
  transition: all 0.5s ease;
  position: fixed;
  left: 0;
  z-index: 1000;
  overflow-y: scroll;
  @include hideScrollbar;
  .tac__user__sidebar__wrapper{
    max-height: 70vh;
    overflow-y: scroll;
    @include hideScrollbar;

  }

  @media screen and (max-width: 820px) {
    display: none;

    &.showSidebarInSmDevice {
      display: block;

      transition: all 0.5s ease;
    }
  }

  &.hide__container {
    width: 7rem;
    transition: all 0.5s ease;
  }

  .sidebar__toggle__section {
    position: absolute;
    right: -4.3rem;
    top: 1.6rem;
    z-index: 100;
    .menu__button {
      width: 1rem;
      color: var(--primary-sidebar-toggle-color);
      cursor: pointer;
    }
  }
  .logo__section {
    padding: 2rem 0 2rem 0;
    margin-left: 2.5rem;
    .sidebar__full__logo {
      width: 15rem;
    }
    .sidebar__half__logo {
      width: 3rem;
    }
  }

  .link__section2 {
    padding: 1.2rem 0 1.2rem 3rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    .image__section {
      display: flex;
      align-items: center;
      width: 3.5rem;

      .sidebar__iconify__icon {
        width: 2.1rem;
        height: 2.1rem;
        &.sm {
          width: 1.9rem;
          height: 1.9rem;
        }
        &.active {
          color: var(--active-color);
        }
      }
    }
    img {
      margin-right: 1rem;
    }
    .marginLeftimage {
      margin-left: -5px;
    }
    &:hover {
      // background-color: #2c2c2c;
      background-color: var(--sidebar-hover-color);
    }
    .route_icon {
      width: 2rem;
    }
    .linker {
      text-decoration: none !important;
      font-size: 1.4rem;
      font-weight: 400;
      color: #fff;
      // margin-left: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: "----";
    }
    .active {
      color: #ffc97a;
    }
    .tac_active {
      color: var(--active-color);
    }
    .hide_linker {
      display: none;
    }
    .activeStyle {
      color: #ffc97a;
      text-decoration: none;
    }
  }

  .links__section {
    width: 100%;
    margin-bottom: 1rem;
    cursor: pointer;

    &.active__parent {
      background-color: #2c2c2c;
    }
    .parent__element {
      display: flex;
      align-items: center;
      width: 100%;
      height: 4rem;
      &:hover {
        background-color: #2c2c2c;
      }
      &.border__parent {
        border: 1px solid #232323;
      }
      .sidebar__icon {
        color: #fff;
        width: 1.6rem !important;
        margin-left: 2rem;
      }

      .parnet__element__name {
        display: none;
        opacity: 0;
        transition: all 1s ease;
      }
      .arrow_icon {
        display: none;
      }
      .show.parnet__element__name {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: "----";
        width: 15rem !important;
        margin-left: 1rem;
        font-weight: 300;
        font-size: 1.4rem;
        padding-left: 1rem;
        opacity: 1;
        transition: all 1s ease;
      }
      .show.arrow_icon {
        display: block;
        margin-left: auto;
        margin-right: 2rem;
        color: #fff;
        width: 1.6rem;
        &.rotate__arrow {
          transform: rotate(90deg);
          transition: all 0.5s ease;
        }
      }
    }
    .child__element {
      display: none;
    }
    .child_open.child__element {
      display: block;
      all: unset;
      .child__link {
        overflow: hidden;
        display: block;
        padding-left: 5.7rem;
        color: #fff;
        text-decoration: none;
        font-weight: 400;
        font-size: 1.4rem;
        margin: 1.5rem 0;
      }

      .activeStyle {
        color: #ffc97a !important;
      }
    }
  }
  .divider {
    border-top: 2px solid #303030;
    padding-left: 2rem;
  }
  .sidebar__divider__title {
    display: block;
    font-weight: 400;
    font-size: 1.4rem;
    color: #667085;
    font-weight: 700;
    // margin: 3rem 0 2rem 0;
    padding-left: 3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: "----";
    &.hide {
      visibility: hidden;
    }
  }

  .upgrade__button__container {
    position: fixed;
    bottom: 2.4rem;
    left: 2.5rem;
    width: 25rem;
  }
}
.smMenu {
  display: none;
  @media screen and (max-width: 820px) {
    display: block;
    position: absolute;
    left: 2rem;
    top: 2rem;
    // transition: all 0.5s ease;

    cursor: pointer;
    z-index: 1000;
    &.showSidebarInSmDevice {
      // left: 26rem;
      left: 284px;
      // transition: all 0.5s ease;
    }
    .menu__button {
      width: 3rem;
      float: right;
    }
  }
}
