.apply__table__section__div{
    .table__container{
        table{
            thead{
          
            }
            tbody{
                tr{
                    td{
                        padding-top: 2rem;
                        padding-bottom: 2rem;
                        font-family: Inter;
                        font-style: normal;
                        line-height: 20px;
                    }
                    .view__details__td{
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 2rem;
                        color: var(--apply-now--view-details-btn-coor);
                        &:hover{
                            color: #43ADE8;
                        }
                    }
                }

            }
        }
    }
}