.pagination {
  display: flex;
  justify-content: center;
}

.question_td {
  width: 55rem;
  text-align: justify;
  padding: 2rem;
}
.answer_td {
  padding: 2rem;
  text-align: justify;
  width: 55rem !important;
}
// .faq__container {
//   margin-bottom: 5rem;
// }
.drag__faq__action__icons {
  // width: 10rem;

  color: #bababa;
  .faq_action__section__action {
    margin-left: 3rem;
  }

  .faq_drag_test_4544 {
    display: flex;
    width: 100%;
    padding-right: 2rem;
    //
  }
}
