.modal_width {
    width: 467px;
  }
  form {
    width: 100%;
    .content {
      .lg_width {
        width: 100%;
        .custom_date_picker{
            margin-bottom: 18px ;
            .custom_date_container{
                label{
                    margin-bottom: 6px;
                    font-size: 1.4rem;
                    font-weight: 400;
                    color: var(--secondary-text-color);
                    font-style: normal;
                    line-height: 20px;
                    position: relative;
                    }
                .react-datepicker {
                  width: 220px !important;
                  height: 230px;
                  .react-datepicker__current-month {
                    font-size: 12px;
                  }
                  .react-datepicker__day-name {
                    font-size: 11px;
                    width: 25px;
                    margin-top: 5px;
                  }
                  .react-datepicker__month-container {
                    width: 100%;
                    height: 220px;
                    overflow: hidden;
                  }
          
                  .react-datepicker__week {
                    margin: 5px 0;
                  }
                }
                .react-datepicker__day {
                  width: 25px;
                  height: 25px;
                  font-size: 11px !important;
                  padding: 3px;
                }
            }
      
       

        }
      
        .time-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;
            span {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: var(--secondary-text-color);
            }
            input[type="time"] {
              outline: none;
              border: none;
              border: 1px solid var(--primary-border-color);
              border-radius: 5px;
              padding: 12px 15px;
              // color: #fff;
              color: var(--primary-input-text-color);
              background: var(--primary-input-bg-color);
              cursor: text;
              &:hover,
              &:focus,
              &:active {
                border: 1px solid var(--primary-hover-color);
              }
              &.error{
                border: 1px solid var(--danger-color);
              }
              &::-webkit-calendar-picker-indicator {
                background-image: url("../../../../../../assets/icons/clock.svg");
                cursor: pointer;
              }
            }
          }
      }
      .addbtn {
        margin-top: 18px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }


  .handle__modal{
    overflow: visible;
  }