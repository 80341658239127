
.learning__path__steppers__container {
    background-color: var(--secondary-bg-color);
    width: calc(100% - 31.5rem);
    @media screen and (max-width: 768px) {
      width: 100%;

    }
    // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    // padding: 2rem 4rem;
    padding-left: 2rem;
    .course__title__section {
      margin-bottom: 2rem;
      img {
        width: 1.5rem;
        margin-left: -2rem;
        transform: rotate(90deg);
        cursor: pointer;
      }
      display: flex;
      .course__title {
        font-size: 1.6rem;
        font-style: medium;
        margin-left: 0.5rem;
        color: var(--primary-input-text-color);
        text-transform: uppercase;
      }
    }
    .stepper {
      border: 1px solid var(--primary-progress-divider);
      border-top: none;
      border-right: none;
      border-bottom: 1px solid transparent;
      min-height: 10rem;
      position: relative;
      padding-bottom: 14px;
      &:last-child {
        border: none !important;
        padding-bottom: 0px;
      }
      .counter {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: -2.5rem;
        top: 0;
        width: 4.5rem;
        height: 4.5rem;
        text-align: center;
        border-radius: 50%;
        font-size: 1.6rem;
        font-weight: 500;
        font-style: medium;
        background-color: var(--stepper-bg-color);
      }
      .course__detail {
        // border-top: 1px solid var(--primary-progress-divider);
        padding-top: 0.5rem;
        margin-left: 34px;
        // background:  #303030;
        background: var( --twentyseven-bg-color);
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        padding: 20px;

        .title {
          display: block;
          cursor: pointer;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          // color: #FFFFFF;
          color: var(--fourth-text-color);
          margin-bottom: 8px;
        }
        .duration {
          display: block;
          cursor: pointer;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 20px;
          color: var(--secondary-progress-bar-bg);
          color: #EF6C00;

        }
        .description {
          display: block;
          font-size: 1.4rem;
          margin-top: 0.7rem;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: var(--third-text-color);
        }
        .start__course__btn__width__handle__div {
          width: 11.1rem;
          margin-top: 1.7rem;
          .satrt__course__btn{
            background: #43ADE8;
          }
        }
        .resume__course__btn__width__handle__div {
            width: 15rem;
            margin-top: 1.7rem;
            .resume__course__btn{
              background: #43ADE8;
            }
          }
          .complete__course__btn__width__handle__div {
            width: 17rem;
            margin-top: 1.7rem;
            .complete__course__btn{
              background: #43ADE8;
            }
          }
      }
    }
  }