
.table__container table thead tr .width_handler_th{
  width: 45%;
  // width: 330px;
  // @media screen and (max-width:1024px) {
  //   width: 220px;
    
  // }
  // @media screen and (max-width:768px) {
  //   width: 237px;
    
  // }
  // @media screen and (max-width:480px) {
  //   width: 128px;
  // }
}
.delete_icon_td{
    display: flex;
    padding: 1.5rem 2rem !important;
    justify-content: flex-end;
    color: #bababa;
    // width: 213px;
    .delete_icon{
        width: 1.6rem;
        margin-left: 1rem;
        color: #bababa;
        cursor: pointer;
  
        &:hover {
          color: #f04438 !important;
        }
    }

}