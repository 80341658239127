.emptyQuest{
    min-height: 100vh;
    // height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 1px solid red;

    .empty{
        width: 37vw;
        h1{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
        }
        .line{
            height: 1px;
            width: 100%;
            margin: 10px  0px;
            background: linear-gradient(236.79deg, rgba(255, 69, 147, 0.2) 3.16%, rgba(255, 231, 19, 0.2) 30.23%, rgba(23, 215, 255, 0.2) 46.88%, rgba(7, 123, 255, 0.2) 69.78%);
        }
        .img{
            width: 100%;
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            img{
                height: 50%;
                width: 50%;
                object-fit: contain;
                // filter: invert(1);
            }
            p{
                font-weight: 400;
                font-size: 14px;
                margin-top: 10px;
            }
            
        }

        .role{
            font-size: 14px;
            p{
                font-size: 14px;
                font-weight: 400;
                color:  var( --primary-placeholder-color);
                span{
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--fourth-text-color);
                }
            }
        }
    }
}

@media screen and (max-width:1024px) {
    .emptyQuest{
        .empty{
            width: 55vw;
            margin-top: 120px;
        }
    }
}

@media screen and (max-width:768px) {
    .emptyQuest{
        .empty{
            width: 80vw;
            margin-top: 175px;
        }
    }
}

@media screen and (max-width:426px) {
    .emptyQuest{
        .empty{
            width: 90vw;
            margin-top: 200px;
            h1{
                font-size: 27px;
            }
        }
    }
}


@media screen and (max-width:321px) {
    .emptyQuest{
        .empty{
            margin-top: 250px;
        }
    }
}