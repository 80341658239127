.VerifyAccountMessage__container {
  border: 1px soldi red;
  font-weight: 400;
  text-align: center;
  .verify__account__title {
    display: block;
    font-size: 2rem;
    color: var(--primary-text-color);
  }
  .verify__account__description {
    display: block;
    color: var(--secondary-text-color);
    font-size: 1.4rem;
  }
  .alert__text {
    display: block;
    color: var(--danger-color);
    font-size: 1.4rem;
  }
}
