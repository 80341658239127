.weekly__learning__form {
  .form_row {
    display: flex;
    justify-content: space-between;
  }
  .width__for__interval {
    width: 16rem;
  }
  .width__for__title {
    width: 32rem;
  }
  .editor__wrapper {
    margin-bottom: 2rem;
    margin-top: 0.3rem;
  }
  .description__title {
    margin-bottom: 6px;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--secondary-text-color);
    font-style: normal;
    line-height: 20px;
  }
}
.dropdown__holder {
  margin-left: 2rem;
  width: 20rem;
}
// .jodit-popup__content {
//   display: none;
// }
