.custom__skleton__loading {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: var(--skleton--bg--color);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
