.mobile__chatting__area{
    position: relative;
    // height: 100%;
    max-height: 100vh;
    .mobile__message__area{
        padding: 2rem;
        // height: 575px;
        max-height: 60vh;
        width: 100%;
        overflow-y: scroll;
        // padding-bottom: 25rem;
        &::-webkit-scrollbar{
            width: 0;
        }
        &::-webkit-scrollbar-thumb{
            width: 0;
        }
        .chatbot__answer{
            width: 534px;
            // padding-bottom: 4rem;
            @media screen and (max-width:1000px) {
                width: 100%;
                
            }
            .chatbot__answer__wrapper{
                display: flex;
                gap: 10px;
                padding-bottom: 15rem;
                // padding-bottom: 15px;
                // position: relative;
                // z-index: -1;
               .image__width__handle{
                .image__avatar {
                    width: 3.5rem !important;
                    height: 3.5rem !important;
                    border-radius: 50%;
                    // background-color: #303030;
                    background-color: var(--alpha-eighth-bg-color);
                    display: flex;
                    align-items: center;
                    justify-content: center;
              
                    img {
                        height: 17px;
                        width: 21px;
                        object-fit: cover;
                        margin-left: 2px;
                    }
                  }
               }


                  .chatbot__answer__handle{
                    // padding-bottom: 4rem;
                      .chatbot__answer{
                        padding: 15px;
                        width: auto;
                        // background: #383838;
                        background: var(--alpha-seventh-bg-color);
                        border-radius: 15px 15px 15px 0px;
                        height: auto;
                        
                        p{
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                        }
                      }
                    //   .second__answer{
                    //     margin-top: 15px;
                    //   }

                  }
            }
        
        }
        .user__question{
            margin: 15px 0;
            display: flex;
            justify-content: flex-end;
            margin-top: -13.5rem;
            &:first-child{
              margin: 15px 0;
            }
            .user__question__wrapper{
                display: flex;
                gap: 15px;
                width: 100%;
                width: 65.9rem;
                flex-direction: row-reverse;
                @media screen and (max-width:1000px) {
                    width: 100%;
                    
                }
                .user__first__letter__handle__div{
                .user__avatar{
                    width: 3.5rem !important;
                    height: 3.5rem !important;
                    border-radius: 50%;
                    // background: var(--first-letter-bg-color);
                    background: var(--alpha-ninth-bg-color);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                        .first__letter{
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--fourth-text-color);

                        }

                    }
                   

                }
                .user__question__div__handle{
                    .user__question__div{
                        padding: 15px;
                        background: #53C4C7;
                        border-radius: 15px 15px 0px 15px;
                        width: 37.2rem;
                        color: #000000;
                        @media screen and (max-width:1000px) {
                            width: 100%;
                        }
                        p{
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                        }
                      }

                }
               
            }
        }

    }
    .fixed__button__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        .fixed__response__stop__button {
          position: fixed;
          bottom: 85px;
          button {
            all: unset;
            border: 1px solid var( --alpha-secondary-border-color);
            background: var( --alpha-tenth-bg-color);
            border-radius: 8px;
            padding: 12px 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Inter";
            font-style: normal;
            // cursor: pointer;
            font-weight: 400;
            font-size: 1.6rem;
            line-height: 19px;
            color: var(--response-btn-color);
            .refresh__icon {
              height: 1.5rem;
              width: 1.5rem;
              margin-right: 10px;
              color: var(--response-btn-color);
              animation: spin 1s linear infinite;
            }
            img {
              margin-right: 10px;
            }
          }
        }
      }


}