.support__wrapper{
    padding: 9rem 0;
    background: var(--new-primary-bg-color);
    @media screen and (max-width:800px) {
        padding: 7rem 0;
        
    }
    .support__content__center{
        display: flex;
        justify-content: center;
        align-items: center;
         @media screen and (max-width:1100px) {
            padding: 0 12rem;
            
         }
         @media screen and (max-width:800px) {
            padding: 0;
            
         }
        .support__container{
            display: flex;
            justify-content: space-between;
            width: 100rem;
            @media screen and (max-width:900px) {
                flex-direction: column;
                align-items: center;
                
            }

            .support__left__section{
                @media screen and (max-width:1000px) {
                    padding: 0 4rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    
                }
                @media screen and (max-width:600px) {
                    padding: 0 2rem;
                    
                }
                h3{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 39px;
                    color: #53C4C7;
                }
                p{
                    display: block;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    // color: #FFFFFF;
                    color: var(--fourth-text-color);
                    width: 440px;
                    margin-top: 17px;
                    @media screen and (max-width:1000px) {
                        width: 100%;
                        text-align: center;
                        
                    }
                }
                .support__btn__width{
                    width: 10rem;
                    margin-top: 3.5rem;
                    .support__btn__width__handle{
                        // background: #FEC97C;
                        background: var(--tenth-colorful-text);
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        // color: #201F1F;
                        color: var(--button-inner);
                        border-radius: 0;
                        transition: all 0.5s;
                        &:hover{
                            transform: scale(1.10);
                            background: var( --support-btn-bg-hover-color);
                        }
                    }
                }



            }
            .support__right__section{
                @media screen and (max-width:500px) {
                    width: 90%;
                    
                }
                .image__wrapper{
                    height: 286px;
                    width: 356px;
                    @media screen and (max-width:800px) {
                        margin-top: 35px;
                    }
                    @media screen and (max-width:500px) {
                        width: 100%;
                        
                    }
                    img{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }

            }
        }

    }
}