.mentor__header__container {
  padding: 1.8rem 2rem;
  display: flex;
  align-items: center;
  background-color: var(--secondary-bg-color);
  justify-content: space-between;

  @media screen and (max-width: 850px) {
    flex-direction: column;
  }

  .right__section {
    display: flex;
    align-items: center;
    @media screen and (max-width: 850px) {
      margin-top: 1.2rem;
    }
    @media screen and (max-width: 415px) {
      flex-direction: column;
      // margin-top: 1.2rem;
    }

    .search {
      width: 19rem;
      margin-right: 2rem;
      @media screen and (max-width: 415px) {
        width: 100%;
        // margin-top: 1.2rem;
        margin: 0;
      }
    }

    .mentor__button__witdh {
      width: 14rem;
      @media screen and (max-width: 415px) {
        width: 100%;
        margin-top: 1.2rem;
      }
    }
  }
  .modal_footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-left: auto;
    .spinner-margin {
      display: block;
      margin-right: 3rem;
    }
    .resend__invitation {
      // margin-left: 22rem;
      margin-right: 1.2rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: #6f6ff1;
      text-decoration-line: underline;
      text-underline-offset: 4px;
      // margin-top: 0.5rem;
      cursor: pointer;
      @media screen and (max-width: 480px) {
        margin-left: 1rem;
      }
    }
    .left-margin {
      margin-left: 28rem !important;
      margin-top: 0.5rem;
    }
    .button__witdh {
      width: 10rem;
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 480px) {
      width: 100%;
      flex-direction: column;
    }
    .sm_width {
      width: 48.5%;
      @media screen and (max-width: 480px) {
        width: 100%;
      }
      .css-1n929ud-Input {
        color: red !important;
        // display: none;
        border: none;
        background-color: transparent;
        // input {
        //   // display: none;
        //   background-color: red;
        // }
      }
    }
  }
}
.add_button__witdh {
  width: 7rem !important;
  margin-left: auto;
  &.update {
    width: 8.8rem !important;
  }
}
