.billing__type__button__wrapper {
  all: unset;
  border: 1px solid var(--tac-billing-button-border-color);
  padding: 1.5rem;
  border-radius: 8px;
  width: 21rem;
  cursor: pointer;
  &:hover {
    border: 1px solid var(--active-color);
  }

  &.active {
    border: 1px solid var(--active-color);
  }

  @media screen and (max-width: 450px) {
    margin-bottom: 2rem;
  }

  .titile {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--third-text-color);
  }
  .desc {
    display: block;
    font-weight: 500;
    font-size: 12px;
    color: var(--user-notification-header-markdone-color);
    margin-top: 0.5rem;
  }
  .price {
    display: block;
    font-weight: 500;
    font-size: 18px;
    margin-top: 0.7rem;
  }
  .type {
    font-weight: 400;
    font-size: 16px;
  }

  .top__section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
