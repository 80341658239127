.product__manager__archive__table__wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 9rem 0;
    background: var( --new-secondary-bg-color);
     @media screen and (max-width:1000px) {
        padding: 7rem 4rem;
     }
     @media screen and (max-width:600px) {
        padding: 7rem 2rem;
     }      
    .product__manager__archive__table__heading{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 40px;
        @media screen and (max-width:500px) {
            padding: 0 2rem;
            
        }
        span{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            text-align: center;
            // color: #FE7268;
            // color: var( --course--tag--title);
            color: var(--archive-heading-colorful-text);
        }
        p{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-top: 17px;
            text-align: center;
            color: #FFFFFF;
            width: 60%;
            color: var(--fourth-text-color);
            @media screen and (max-width:1000px) {
                width: 90%;
            }
            @media screen and (max-width:600px) {
                width: 100%;
            }

        }

    }
    .archive__class__table__container{
        @media screen and (max-width:1100px) {
            padding: 0 12rem;
            
        }
        @media screen and (max-width:800px) {
            padding: 0;
            
        }
    }
}