.commingsoon {
  //   background-color: red;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--new-secondary-bg-color);
  img {
    margin-bottom: 37px;
  }
  h1 {
    margin-bottom: 25px;
    color: #53c4c7;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 20px;
    text-align: center;
    color: #53c4c7;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    text-align: center;

    color: var(--ninth-text-color);
  }
}
