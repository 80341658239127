.custom__button {
  cursor: pointer;
  width: 100%;
  text-align: center;
  display: grid;
  border: none;
  outline: none;
  place-items: center;
  color: #ffff;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  border-radius: 0.8rem;
  padding: 1rem 2rem;
  white-space: nowrap;
  &.sm {
    padding: 0.8rem 1.5rem;
  }
  &.tiny {
    padding: 0.6rem 0.9rem;
  }
  &.submit {
    width: 9rem;
  }
}

.custom_yellow_button {
  width: 250px;

  cursor: pointer !important;
  text-align: center;
  // display: grid;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  // place-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 20px;
  white-space: normal;
  word-wrap: break-word !important;
  background: var(--primary-colorful-text);
  color: var(--primary-bg-color);
  margin: 3px 5px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  position: relative;
  grid-template-columns: auto auto;
  @media screen and (max-width: 850px) {
    width: 200px;
  }
  @media screen and (max-width: 767px) {
    width: 100% !important;
  }
  .text {
    max-width: calc(100% - 25px);
    line-height: normal;
  }
  .arrow_right {
    width: 0;
    overflow: hidden;
    transition: 0.5s;
    margin-left: 10px;
  }

  &:hover {
    background: var(--twentyone-bg-color);
    transition: background 0.5s;
    .arrow_right {
      width: 15px !important;
    }
  }
  &.normal {
    width: 100% !important;
  }
}
.beyond__button__color__handle {
  background: #53c4c7;
}
.sky_button {
  width: 250px;
  cursor: pointer !important;
  text-align: center;
  display: grid;
  border: none;
  outline: none;
  place-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 20px;
  white-space: normal;
  word-wrap: break-word !important;
  background: #53c4c7;
  color: #201f1f;
  margin: 3px 5px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  @media screen and (max-width: 850px) {
    width: 200px;
  }
  @media screen and (max-width: 767px) {
    width: 100% !important;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffc97a;
    transition: all 0.3s;
    z-index: -1;
  }
  &:hover {
    &:before {
      width: 100%;
    }
  }
  &.normal {
    width: 100% !important;
  }
}
.primary__button {
  background: var(--primary-color);
  &.sm {
    padding: 0.8rem 1.5rem;
  }
  &:disabled {
    cursor: no-drop;
  }
}
.disableBtnStyle{
  color: #667085;
  background: var(--plan-bg-color);
  border: 1px solid var(--secondary-dropdown-border);
  cursor: not-allowed;
}
.tac_primary__button {
  background: var(--primary-color);
  &.sm {
    padding: 0.8rem 1.5rem;
  }
  &:disabled {
    cursor: no-drop;
  }
}

.secondary__button {
  background: var(--secondary-color);
}
.success__button {
  background: var(--success-color);
}
.danger__button {
  background: var(--danger-color);
}
.danger_text___button {
  all: unset;
  display: inline-block;
  margin: 0 2rem;
  color: var(--danger-color);
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
}
.primary_text___button {
  all: unset;
  display: inline-block;
  margin: 0 2rem;
  color: var(--primary-color);
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
  &:hover {
    color: var(--seventh-colorful-text);
  }
  &.no_underline {
    text-decoration: none;
  }
  &.tac {
    color: var(--tac-active-color);
  }
}
.white__button {
  background: #fff;
  color: #667085;
  border: 1px solid var(--secondary-dropdown-border);
}

.smoke__button {
  background: var(--apply-button-bg);
  color: var(--apply-button-text);
  padding: 1.4rem 2rem;
}
.md__smoke__button{
  // background: var(--apply-button-bg);
  // color: var(--apply-button-text);
  background: #D0D5DD;
  color: #667085;
  padding: 1rem 2rem;

}
.google__button {
  // background: #232323;
  color: #ffffff;
  // border: 1px solid #667085;
  border: 1px solid var(--primary-border-color);
  background: var(--secondary-bg-color);
}
.plan__button {
  color: #667085;
  background: var(--plan-bg-color);
  border: 1px solid var(--secondary-dropdown-border);
}
.muted__button {
  background: var(--muted-color);
  cursor: no-drop;
}
.activetoggle__button {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}
.activetoggle__button-active {
  background-color: #fec97c;
  border: 1px solid #fec97c;
  color: #201f1f;
  font-weight: 500;
  font-size: 16px;
}
.spinner {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 3px solid #ddd;
  border-right-color: rgb(110, 108, 108);
  animation: spin 0.5s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.gooey {
  margin-left: -1rem;
  margin-bottom: 3px;
  display: flex;
  .dot {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background-color: #000;
    transform: translateX(0);
    filter: opacity(85%);

    animation: dot 2.8s infinite;
  }
  .dots {
    display: flex;
    animation: dots 2.8s infinite;
    .span-dot {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #000;
      margin-left: 16px;
      border-radius: 50%;
      filter: opacity(85%);
    }
  }
  @keyframes dot {
    50% {
      transform: translateX(96px);
    }
  }
  @keyframes dots {
    50% {
      transform: translateX(-31px);
    }
  }
}
