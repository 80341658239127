.user-reference-card {
  width: 320px;
  min-height: 154px;
  background: var(--urc-card-bg);
  border: 1px solid var(--urc-card-border);
  padding: 18px;
  .urc {
    display: flex;
    gap: 16px;

    .urc-img {
      min-width: 60px;
      min-height: 60px;
      max-width: 60px;
      max-height: 60px;
      background-color: #808080;
      border-radius: 50%;
      color: transparent;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        // object-fit: center;
        object-fit: cover;
      }
    }
    .urc-txt {
      width: 100%;
      .urc-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: var(--primary-text-color);
        margin-bottom: 3px;
        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;
        width: 182px;
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .urc-designation {
        color: hsla(221, 13%, 46%, 1);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--alpha-primary-text-color);
        margin-bottom: 2px;
      }
    }
  }
  .urc-dashed {
    width: 100%;
    margin: 20px 0 16px 0;
    border: 1px dashed var( --alpha-primar-divider-color);
    border-spacing: 4px;
  }
  .urc-email {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6f6ff1;
    margin-bottom: 2px;
  }
  .linkedin__cname{
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    .urc-companyname {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--primary-text-color);
    }
    .linkedInIcon{
      display: flex;
      align-items: center;
      cursor: pointer;
    }

  }
  .linkedin__link{
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--alpha-primary-text-color);
    // margin-bottom: 2px;
    cursor: pointer;
  }

  // .urc-bottom {
  //   font-weight: 400;
  //   font-size: 14px;
  //   line-height: 20px;
  //   color: var(--primary-text-color);
  // }
}
