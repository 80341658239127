
.course__progress__wrapper{
  background: var(--new-secondary-bg-color);
  min-height: 100vh;
}
.course-progress-top {
  background-color: var(--alpha-fourteen-bg-color);
  padding-top: 132px;
  padding-bottom: 65px;
  .cpt-section-wrapper {
    display: flex;
    width: 80%;
    max-width: 90%;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    .cpt-left {
      @media screen and (max-width:767px) {
        width: 100%;
        
      }
      h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: var(--primary-text-color);
        margin-bottom: 20px;
      }
      > p {
        width: 635px;
        max-width: 90%;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary-dropdown-text);
        margin-bottom: 30px;
      }
      .button__width {
        min-width: 14rem !important;
        max-width: 19rem;
      }
    }
    .cpt-right {
      .cpt-r {
        margin-bottom: 10px;
        .cpt-r-top {
          font-weight: 600;
          font-size: 14px;
          line-height: 28px;
          color: var( --alpha-primary-text-color);
          margin-bottom: 1px;
        }
        .cpt-r-bottom {
          width: 229px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #d0d5dd;
          color: var(--third-text-color);
        }
      }

    }
    .cpt__right{
      padding: 2rem;
      background: var(--feedback-bg-color);
      border: 1px solid var( --secondary-dropdown-border);
      width: 42rem;
      @media screen and (max-width:1315px) {
        width: 100%;
        margin-top: 4rem;
        
      }
      h5{
        all: unset;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 600;
        line-height: 19.36px;

      }
      .cpt-info-wrapper{
        margin-top: 2.4rem;
        .cpt-info-box{
          border-bottom: 1px solid var(  --alpha-primar-divider-color);
          margin-top: 1.8rem;
          padding-bottom: 1.4rem;
          display: flex;
          justify-content: space-between;
          &:first-child{
            margin-top: 0;
          }
          &:last-child{
            padding-bottom: 0;
            border-bottom: 0;
          }
          .cpt-box-left{
            display: flex;
            align-items: center;
            svg{
              margin-right: 1rem;
              color: var(--alpha-primary-text-color);

            }
            .cpt-title{
              font-family: "Inter";
              font-size: 14px;
              font-weight: 600;
              line-height: 28px;
              color: var(--alpha-primary-text-color);


            }

          }
          .cpt-box-right{

            .percentage{
              font-family: "Inter";
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              color: #6f6ff1;


            }
            .hours{
              font-family: "Inter";
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;


            }
            .total-courses{
              font-family: "Inter";
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;

            }

          }

        }

      }
    }
  }
  .cpt-section-wrapper{
    // all: unset;
    // display: flex;
    // width: 80%;
    // max-width: 90%;
    // margin: 0 auto;
    // justify-content: space-between;
    // flex-wrap: wrap;
  }
  .cpt-line {
    background: linear-gradient(
      236.79deg,
      rgba(255, 69, 147, 0.2) 3.16%,
      rgba(255, 231, 19, 0.2) 30.23%,
      rgba(23, 215, 255, 0.2) 46.88%,
      rgba(7, 123, 255, 0.2) 69.78%
    );
    border-radius: 5px;
    height: 1px;
    width: 80%;
    margin: 79px auto 25px auto;
  }
  .cpt-line-bottom {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1150px) {
      gap: 50px;
    }
    gap: 100px;
    .cpt-line-b {
      .cpt-txt {
        font-weight: 600;
        font-size: 14px;
        line-height: 28px;
        color: var( --alpha-primary-text-color);
        margin-bottom: 10px;
      }
      .cpt-icon-txt-container-div {
        display: flex;
        align-items: center;
        gap: 50px;
        flex-wrap: wrap;

        .cpt-icon-txt {
          display: flex;
          gap: 8px;
          align-items: center;
          .cpt-icon {
            object-fit: cover;
            min-width: 65px;
            min-height: 65px;
            height:65px;
            width: 65px;
            overflow: hidden;
            background-color: rgb(77, 77, 77);
            border-radius: 50%;
            color: transparent;
          }
          .cpt-text-icon {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            // color: #ffffff;
            color: var(--primary-text-color);
          }
        }
        .no-inst{
          color: var(--alpha-primary-text-color);
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;

        }
        .cpt-text-avatar-wrapper{
          gap: 1rem;
        }
      }
    }
  }
}

.course-progress-bottom {
  padding-top: 120px;
  padding-bottom: 112px;
  background: var( --alpha-thirteen-bg-color);
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    color: #ffffff;
    color: var(--primary-text-color);
    margin-bottom: 55px;
  }
  .cpb {
    width: 80%;
    margin: 0 auto;
    .cpb-list {
      .cpb-container {
        min-height: 70px;
        background: linear-gradient(
          132.06deg,
          rgba(255, 255, 255, 0.09) 2.05%,
          rgba(82, 80, 80, 0.114) 68.52%
        );
        background: var(--urc-card-bg);
        border: 1px solid var(--urc-card-border);
        margin-bottom: 20px;
        padding: 20px 30px;

        .cpb-head {
          all: unset;
          cursor: pointer;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:hover {
            color: #fec97c;
          }
          .cpb-icon {
            svg {
              height: 12px;
              width: 12px;
            }
          }
        }
        .cpb-body {
          display: none;
          .cpb-line {
            border: 1px solid var(--alpha-seventh-divider-color);
            width: 100%;
            margin: 15px 0;
          }
          .ull {
            padding-left: 2rem;
          }
        }
        .d-block {
          display: block;
        }
      }
    }
  }
}
