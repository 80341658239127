.landinglayout__container {
  height: 100vh;
  width: 100%;

  .landinglayout-content {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 15rem;
    }
  }
}
.layout__container{
  background: var(--new-secondary-bg-color);
  .landinglayout-content {
    background: var(--new-secondary-bg-color);
  }
}
