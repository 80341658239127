.course_section {
  cursor: pointer;
  .course {
    display: flex;
    .image__wrapper{
      position: relative;
      .premium__image{
        // height: 40px;
        width: 109.5px;
        position: absolute;
        top: 0;
        left: -59px;
      }
    }
    .course__thumbnail {
      width: 13.7rem;
      height: 13.7rem;
      object-fit: cover;
      border-radius: 0.5rem;
      cursor: pointer;
    }
    .course__info {
      margin-left: 2rem;
      position: relative;
      width: 100%;
      &.course__info__wrapper{
        margin-top: 1.4rem;
        width: 100%;
      }
      .course__title {
        // width: 35rem;
        display: block;
        cursor: pointer;
        &:hover {
          color: var(--active-color);
        }
      }
      .title__height{
        min-height: 50px;
      }
      .course_secondary__title {
        display: block;
        font-size: 1.3rem;
        cursor: pointer;
        color: var(--active-color);
      }
      .course__description {
        margin-top: 1rem;
        display: block;
        font-size: 1.4rem;
        color: var(--description);
        font-weight: 400;
        max-height: 40px;
        min-height: 40px;
        overflow-y: scroll;
        &::-webkit-scrollbar{
          display: none;
        }
      }
      // .description__handle{
      //   max-height: 100px;
      // }
      .premium__paid__info{
        margin-top: 0.7rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media screen and (max-width:400px) {
          flex-direction: column;
          align-items: start;
          
        }
      
        .premium__price{
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: var(--active-color);
          @media screen and (max-width:1100px) {
            font-size: 12px;
          }
        }
      }
      .buy__now__btn{
        all: unset;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0px;
        padding: 8px 16px;
        border: 1px solid var(--active-color);
        color: var(--active-color);
        color: #EF6C00;
        background: var(--dropdown-bg-color);
        border-radius: 5px;
        border: 1px solid var(--line-and-stroke);
      }
    }
  }
  .text__left{
    display: flex;
    justify-content: flex-end;
    // position: relative;
    .agile__badge{
      position: absolute;
      top: 0.5rem;
      right: 2rem;
    }

  }
  .badge__wrapper{
    // position: absolute;
    // right: 10px;
    // top: 10px;
  }
  .name__tag{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
