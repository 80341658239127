@mixin aiEditDeletStyle{
  height: 3.6rem;
  width: 3.6rem;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: var(--alpha-eleventh-bg-color);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  svg{
    height: 1.5rem;
    color: var(--fourth-text-color);
  }

}

.mobile__chatbot__message__input__section {
    padding: 1rem;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    background: var(--alpha-primary-modal-bg-color);
    .text__area__handle{
      display: flex;
      width: 86%;
    }
    .right_arrow {
      position: absolute;
      right: 25px;
      width: 2rem;
      stroke-width: 1px;
      bottom: 27px;
      color: var(--seventh-text-color);
      cursor: pointer;
    }
    .right__arrow {
        position: absolute;
        right: 70px;
        // width: 2rem;
        height: 35px;
        width: 35px;
        // background:  #53C4C7;
        border-radius: 50%;
        bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .edit__delete__controls__dropdown{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        bottom: 10px;
        .add__chat__circle{
          height: 4.2rem;
          width: 4.2rem;
          border-radius: 50%;
          background: #53C4C7;
          display: flex;
          justify-content: center;
          align-items: center;
          svg{
            color: #ffffff;
            stroke-width: 1.5px;
          }
        }
        .edit__delete__dropdown{
          position: absolute;
          top: -100px;
          .ai__chat__delete{
            @include aiEditDeletStyle;
            margin-bottom: 1.2rem;

          }
          .ai__chat__edit{
            @include aiEditDeletStyle;

          }
        }
      }
  }
  
  .mobile__chatbot__chat__textarea {
    resize: none;
    width: 100%;
    max-height: 15rem;
    height: 5rem ;
    transition: height 0.3s;
    padding: 1.2rem 1.5rem;
    padding-top: 1.2rem;
    border-radius: 0.5rem;
    color: var(--seventh-text-color);
    padding-right: 8rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    font-style: normal;
    text-align: justify;
    font-family: "Inter", sans-serif !important;
    outline: none;
    background: var(--alpha-primary-input-bg-color) ;
    border: 1px solid var(--alpha-ternary-input-border-color) ;

    &::placeholder{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--alpha-primary-text-color);
    }
    &:hover {
      border: 1px solid var(--primary-hover-color);
    }
  
    &:focus {
      box-shadow: 0 0 5px var(--primary-focus-color);
      border: 1px solid var(--primary-focus-color);
    }
  
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
  }
  